import dayjs from "dayjs";
import i18next, { t } from "i18next";
import _ from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useMatch } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import { Button } from "../../ui/Button/Button";
import { Dropdown } from "../../ui/Dropdown/Dropdown";
import { Checkbox } from "../../ui/Forms/Checkbox";
import Form from "../../ui/Forms/Form";
import { SingleDatePicker } from "../../ui/Forms/SingleDatePicker";
import { Switch } from "../../ui/Forms/Switch";
import TextField from "../../ui/Forms/TextField";
import { convertToCurrentLocalesFormat } from "../../ui/Forms/utils/dateConversion";
import { ElementType } from "../../ui/Group/ElementTypeEnum";
import { IconDevices } from "../../ui/Icon/Line/Devices";
import { IconEdit } from "../../ui/Icon/Line/Edit";
import { Slider } from "../../ui/Map/Slider";
import { Tag } from "../../ui/Tags/Tag";
import {
  TypeOfVehicleType,
  VehicleFuelType,
  typeOfVehicleValues,
  vehicleFuelValues,
} from "../../ui/Vehicles/VehicleTypes";
import { ToastNotification } from "../../utils/ToastNotification";
import {
  format,
  formatData,
  kmPerHToMilesPerH,
  kmPerLtToMilesPerG,
  kmToMiles,
  ltToGal,
  milesPerGTokmPerLt,
  milesToKm,
  mtToKm,
  mtToMiles,
  numberAnnotation,
  truncateDecimalNumber,
} from "../../utils/Utils";
import BeaconsSelect from "../beacon/BeaconsSelect";
import {
  Beacon,
  BeaconAssociationEntityType,
  BeaconAssociationListRequest,
  BeaconAssociationRequest,
  beaconEmptyState,
  beaconsSelectors,
  getBeaconsFilteredAsync,
  selectBeaconsSliceReasonCode,
  selectBeaconsSliceStatus,
  updateBeaconAssociationAsync,
} from "../beacon/beaconSlice";
import {
  BeaconThreshold,
  beaconThresholdEmptyState,
  beaconThresholdsSelectors,
  getBeaconsThresholdsAsync,
  selectBeaconThresholdsReasonCode,
  selectBeaconThresholdsSliceStatus,
} from "../beacon/beaconThresholdSlice";
import {
  Contract,
  contractsSelectors,
  getContractAsync,
} from "../contract/contractsSlice";
import {
  Device,
  devicesEmptyState,
  devicesSelectors,
} from "../device/devicesSlice";
import {
  Driver,
  driversEmptyState,
  driversSelectors,
  selectDriversSliceReasonCode,
  selectDriversSliceStatus,
} from "../driver/driversSlice";
import { getFilteredDriversStatusAndDetailsAsync } from "../driver/driversStatusSlice";
import { Preferences } from "../users/preference/preferencesSlice";
import { UserPermissions } from "../users/privilege/privilegesSlice";
import UserContext from "../users/userContext";
import {
  AdminVehicleSensorTab,
  BeaconResourceProps,
} from "./AdminVehicleSensorsTab";
import "./AdminVehicles.css";
import { BeaconThresholdModal } from "./BeaconThresholdModal";
import {
  Vehicle,
  selectVehiclesSliceReasonCode,
  selectVehiclesSliceStatus,
  updateVehicleAsync,
  vehiclesEmptyState,
  vehiclesSelectors,
} from "./vehiclesSlice";
import {
  VehicleStatus,
  getVehicleAsync,
  selectVehiclesStatusSliceReasonCode,
  selectVehiclesStatusSliceStatus,
  vehiclesStatusEmptyState,
  vehiclesStatusSelectors,
} from "./vehiclesStatusSlice";

interface EditVehicleProps {
  permissions: UserPermissions;
}

export type SelectedEntities = {
  sensors: number[];
};

const getStateorNull = (state: boolean | undefined) => {
  if (state) {
    return t("common.statusOption.on");
  } else if (state !== undefined) {
    return t("common.statusOption.off");
  }
  return undefined;
};
const EditVehicle: React.FC<EditVehicleProps> = ({ permissions }) => {
  const navigate = useNavigate();
  const [preferencesContext]: [Preferences] = useContext(UserContext);

  const [editVehicle, setEditVehicle] = useState<Vehicle>({} as Vehicle);
  const [editDevice, setEditDevice] = useState<Device>({} as Device);
  const [editContract, setEditContract] = useState<Contract>({} as Contract);
  const [removedDriver, setRemovedDriver] = useState<boolean>(false);
  const vehicleSliceStatus = useAppSelector(selectVehiclesSliceStatus);
  const vehicleSliceReasonCode = useAppSelector(selectVehiclesSliceReasonCode);
  const vehicleStatusSliceStatus = useAppSelector(
    selectVehiclesStatusSliceStatus
  );
  const vehicleStatusSliceReasonCode = useAppSelector(
    selectVehiclesStatusSliceReasonCode
  );
  const driversSliceStatus = useAppSelector(selectDriversSliceStatus);
  const driversSliceReasonCode = useAppSelector(selectDriversSliceReasonCode);
  const beaconSliceStatus = useAppSelector(selectBeaconsSliceStatus);
  const beaconSliceReasonCode = useAppSelector(selectBeaconsSliceReasonCode);
  const beaconThresholdSliceStatus = useAppSelector(
    selectBeaconThresholdsSliceStatus
  );
  const beaconThresholdSliceReasonCode = useAppSelector(
    selectBeaconThresholdsReasonCode
  );

  const vehicleRoute = useMatch("/admin/vehicles/edit/:fleetId/:vehicleId");

  const [id, setId] = useState<number>(-1);
  const [fleet, setFleet] = useState<number>(-1);
  const [errorList, setErrorList] = useState([]);
  const [initialBeacons, setInitialBeacons] = useState<Beacon[] | null>(null);
  const [unLinkedBeacons, setUnlinkedBeacons] = useState<BeaconResourceProps[]>(
    []
  );
  const [linkedBeacons, setLinkedBeacons] = useState<BeaconResourceProps[]>([]);
  const [manageSensors, setManageSensors] = useState(false);
  const [thresholdOptions, setThresholdOptions] = useState<any>();
  const [beaconThreshold, setBeaconThreshold] = useState<BeaconThreshold>(
    {} as BeaconThreshold
  );
  const [currentThreshold, setCurrentThreshold] =
    useState<BeaconThreshold | null>({} as BeaconThreshold);
  const [beaconThresholdModal, setBeaconThresholdModal] =
    useState<"create" | "update" | "">("");
  const [editBeacons, setEditBeacons] = useState<{
    type: BeaconAssociationEntityType;
    beaconsToAssociate: BeaconAssociationRequest[];
    beaconNamespacesToDisassociate: string[];
  }>({
    type: "VEHICLE" as BeaconAssociationEntityType,
    beaconsToAssociate: [],
    beaconNamespacesToDisassociate: [],
  });

  let vehicle: Vehicle =
    useAppSelector((state) => vehiclesSelectors.selectById(state, id)) ??
    ({} as Vehicle);
  let vehicleStatus: VehicleStatus =
    useAppSelector((state) =>
      vehiclesStatusSelectors.selectById(state, vehicle.vehicleStatus)
    ) ?? ({} as VehicleStatus);
  let drivers: Driver[] = useAppSelector(driversSelectors.selectAll);
  let driver: Driver =
    useAppSelector((state) =>
      driversSelectors.selectById(state, editVehicle.driver ?? vehicle.driver)
    ) ?? ({} as Driver);
  let device: Device =
    useAppSelector((state) =>
      devicesSelectors.selectById(state, vehicle.device)
    ) ?? ({} as Device);
  let contract: Contract =
    useAppSelector((state) =>
      contractsSelectors.selectById(state, vehicle.contract)
    ) ?? ({} as Contract);
  let beacons: Beacon[] = useAppSelector(beaconsSelectors.selectAll);
  let beaconThresholds: BeaconThreshold[] = useAppSelector(
    beaconThresholdsSelectors.selectAll
  );
  const allName: { label: string; driverId: number }[] =
    drivers.map((x) => {
      return { label: x.firstName + " " + x.lastName, driverId: x.id };
    }) ?? ({} as { label: string; driverId: number }[]);

  const fuelOption: { type: VehicleFuelType; label: string }[] =
    _.keys(vehicleFuelValues).map((fuelKey) => {
      return {
        type: _.get(vehicleFuelValues, fuelKey),
        label: t("common.optionsEnum.fuel." + fuelKey),
      };
    }) ?? [];

  const typeOption: { type: TypeOfVehicleType; label: string }[] =
    _.keys(typeOfVehicleValues).map((typeKey) => {
      return {
        type: _.get(typeOfVehicleValues, typeKey),
        label: t("common.optionsEnum.type." + typeKey),
      };
    }) ?? [];

  const defaultThresholdNames = [
    "foodAndBeverages",
    "pharmaceuticalsAndMedical",
    "chemicalsAndIndustrial",
    "otherProducts",
  ];

  const [updatedFuelPrice, setUpdatedFuelPrice] = useState<string | null>(null);
  const [updateConsumption, setUpdatedConsumption] =
    useState<string | null>(null);

  useEffect(() => {
    if (beacons.length && !initialBeacons) {
      setInitialBeacons(beacons);
    }
  }, [initialBeacons, beacons]);

  useEffect(() => {
    detectChanges();
  }, [linkedBeacons, unLinkedBeacons]);

  const detectChanges = () => {
    if (!initialBeacons) return;

    const newlyLinkedBeacons = linkedBeacons.filter(
      (linked) =>
        !initialBeacons.some(
          (beacon) =>
            beacon.mac === linked.mac &&
            beacon.inUse &&
            beacon.beaconThreshold?.id === linked.thresholdId &&
            beacon.name === linked.name
        )
    );

    const newlyUnlinkedBeacons = unLinkedBeacons.filter(
      (linked) =>
        !initialBeacons.some(
          (beacon) => beacon.mac === linked.mac && !beacon.inUse
        )
    );

    const beaconsToAssociate = newlyLinkedBeacons.map(
      (beacon: BeaconResourceProps) => ({
        namespace: beacon.id,
        name: beacon.name,
        thresholdId:
          !!beacon?.thresholdId && beacon.thresholdId > -1
            ? +beacon.thresholdId
            : null,
      })
    );

    const beaconNamespacesToDisassociate = newlyUnlinkedBeacons.map(
      (beacon: BeaconResourceProps) => beacon.id
    );

    setEditBeacons({
      ...editBeacons,
      beaconsToAssociate,
      beaconNamespacesToDisassociate,
    });
  };

  const updateCurrentThreshold = (
    id: number,
    updatedThreshold: BeaconThreshold | null
  ) => {
    const beaconThreshold = beaconThresholds.filter(
      (threshold) => threshold.id == id
    );
    if (beaconThreshold.length > 0) {
      beaconThreshold.forEach((currentThreshold) => {
        let matchingBeacons = linkedBeacons.filter(
          (beacon) => beacon.thresholdName === currentThreshold.name
        );
        matchingBeacons.concat(
          unLinkedBeacons.filter(
            (beacon) => beacon.thresholdName === currentThreshold.name
          )
        );
        if (matchingBeacons.length > 0) {
          matchingBeacons.forEach((matchingBeacon) => {
            if (updatedThreshold != null) {
              matchingBeacon.thresholdName =
                updatedThreshold.name != ""
                  ? updatedThreshold.name
                  : t("common.notAvailable");
              setCurrentThreshold(updatedThreshold);
            } else {
              matchingBeacon.thresholdName = "";
              setCurrentThreshold(null);
            }
          });
        }
      });
    }
  };

  useEffect(() => {
    if (vehicle && !_.isEmpty(vehicle) && vehicle?.device) {
      if (!(vehicle?.contract && contract.id === vehicle.contract)) {
        store.dispatch(getContractAsync({ deviceId: vehicle.device }));
      }
    }
  }, [vehicle]);

  document.title = useMemo(() => {
    if (!!vehicle?.alias && !!vehicle?.plate) {
      return (
        `${vehicle.alias} - ${vehicle.plate} - ` +
        t("navigation.adminMenu.vehicles") +
        " - Admin"
      );
    } else {
      return t("navigation.adminMenu.vehicles") + " - Admin";
    }
  }, [vehicle]);

  useEffect(() => {
    // get vehicle id and fleet name
    if (vehicleRoute !== null) {
      if (vehicleRoute.params.vehicleId && vehicleRoute.params.fleetId) {
        setId(Number(vehicleRoute.params.vehicleId));
        setFleet(Number(vehicleRoute.params.fleetId));
        store.dispatch(
          getVehicleAsync({
            fleetId: parseInt(vehicleRoute.params.fleetId),
            id: parseInt(vehicleRoute.params.vehicleId),
          })
        );
        store.dispatch(getFilteredDriversStatusAndDetailsAsync(""));
        return;
      }
    }
    ToastNotification({
      toastId: "unauthorizedError",
      status: "error",
      description: t("common.unauthorizedError"),
    });
    navigate("/admin/vehicles");
  }, [navigate, vehicleRoute]);

  useEffect(() => {
    if (
      vehicleSliceStatus === "idle" &&
      vehicleSliceReasonCode === GTFleetSuccessCodes.PATCH &&
      fleet !== -1 &&
      id !== -1
    ) {
      ToastNotification({
        toastId: "vehicleUpdateSuccess",
        status: "success",
        description: t("common.vehicleUpdateSuccess"),
      });
      navigate("/admin/vehicles/view/" + fleet + "/" + id);
    } else if (vehicleSliceStatus === "failed") {
      if (
        vehicleSliceReasonCode ===
        GTFleetErrorCodes.VEHICLE_ALIAS_ALREADY_EXISTS
      ) {
        ToastNotification({
          toastId: "vehicleAliasExist",
          status: "error",
          description: t("common.veicleAliasExist"),
        });
      } else if (vehicleSliceReasonCode === "") {
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }
  }, [navigate, vehicleSliceStatus, vehicleSliceReasonCode]);

  useEffect(() => {
    if (vehicleStatusSliceStatus === "failed") {
      switch (vehicleStatusSliceStatus) {
        case GTFleetErrorCodes.USER_UNAUTHORIZED:
          ToastNotification({
            toastId: "unauthorizedError",
            status: "error",
            description: t("common.unauthorizedError"),
          });
          break;
        case GTFleetErrorCodes.VEHICLE_STATUS_NOT_FOUND:
          ToastNotification({
            toastId: "vehicleNotFoundError",
            status: "error",
            description: t("common.vehicleNotFoundError"),
          });
          break;
        default:
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
          break;
      }
      navigate("/admin/vehicles");
    }
  }, [vehicleStatusSliceStatus, vehicleStatusSliceReasonCode, navigate]);

  useEffect(() => {
    if (driversSliceStatus === "failed" && driversSliceReasonCode === "") {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [navigate, driversSliceStatus, driversSliceReasonCode]);

  useEffect(() => {
    if (beaconSliceStatus === "failed") {
      if (
        beaconSliceReasonCode === GTFleetErrorCodes.BEACON_ASSOCIATION_NOT_FOUND
      ) {
        ToastNotification({
          toastId: "beaconAssociationNotFound",
          status: "error",
          description: t("common.beaconAssociationNotFound"),
        });
      } else if (
        beaconSliceReasonCode ===
        GTFleetErrorCodes.BEACON_ASSOCIATION_NOT_ELIGIBLE_EXCEPTION
      ) {
        ToastNotification({
          toastId: "beaconAssociationNotEligible",
          status: "error",
          description: t("common.beaconAssociationNotEligible"),
        });
      } else {
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }
  }, [navigate, beaconSliceStatus, beaconSliceReasonCode]);

  useEffect(() => {
    if (beaconThresholdSliceStatus === "failed") {
      if (
        beaconThresholdSliceReasonCode ===
        GTFleetErrorCodes.BEACON_THRESHOLD_NOT_FOUND
      ) {
        ToastNotification({
          toastId: "beaconThresholdNotFound",
          status: "error",
          description: t("common.beaconThresholdNotFound"),
        });
      } else if (
        beaconThresholdSliceReasonCode ===
        GTFleetErrorCodes.BEACON_THRESHOLD_CONFLICT_EXCEPTION
      ) {
        ToastNotification({
          toastId: "beaconThresholdConflict",
          status: "error",
          description: t("common.beaconThresholdConflict"),
        });
      } else {
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }

    if (beaconThresholdSliceStatus === "idle") {
      if (beaconThresholdSliceReasonCode === GTFleetSuccessCodes.POST) {
        ToastNotification({
          toastId: "beaconThresholdCreateSuccess",
          status: "success",
          description: t("common.beaconThresholdCreateSuccess"),
        });
        setBeaconThresholdModal("");
      } else if (beaconThresholdSliceReasonCode === GTFleetSuccessCodes.PATCH) {
        ToastNotification({
          toastId: "beaconThresholdUpdateSuccess",
          status: "success",
          description: t("common.beaconThresholdUpdateSuccess"),
        });
        setBeaconThresholdModal("");
        store.dispatch(getBeaconsThresholdsAsync({}));
      } else if (
        beaconThresholdSliceReasonCode === GTFleetSuccessCodes.DELETE
      ) {
        ToastNotification({
          toastId: "beaconThresholdDeleteSuccess",
          status: "success",
          description: t("common.beaconThresholdDeleteSuccess"),
        });
        setBeaconThresholdModal("");
        store.dispatch(getBeaconsThresholdsAsync({}));
      }
    }
  }, [navigate, beaconThresholdSliceStatus, beaconThresholdSliceReasonCode]);

  useEffect(() => {
    setUnlinkedBeacons(
      beacons
        .filter((beacon) => !beacon.inUse)
        .map((beacon) => {
          return {
            id: beacon.namespace,
            name: beacon.name !== null ? beacon.name : t("common.mac"),
            mac: beacon.mac ?? t("common.na"),
            thresholdId: beacon.beaconThreshold?.id ?? -1,
            thresholdName:
              beacon.beaconThreshold?.name ?? t("common.noThresholdSelected"),
            type: ElementType.beacon as ElementType,
            icon: <IconDevices size={16} />,
            inUse: beacon.inUse,
          } as BeaconResourceProps;
        })
    );
    setLinkedBeacons(
      beacons
        .filter((beacon) => beacon.inUse)
        .map((beacon) => {
          return {
            id: beacon.namespace,
            name: beacon.name !== null ? beacon.name : t("common.mac"),
            mac: beacon.mac,
            thresholdId: beacon.beaconThreshold?.id ?? -1,
            thresholdName: beacon.beaconThreshold?.name ?? t("common.na"),
            type: ElementType.beacon as ElementType,
            icon: <IconDevices size={16} />,
            inUse: beacon.inUse,
          } as BeaconResourceProps;
        })
    );
  }, [beacons]);

  useEffect(() => {
    const beaconOptions: any = [] as any[];

    defaultThresholdNames.forEach((thresholdName) => {
      const defaultBeaconThresholds = beaconThresholds.filter((beacon) =>
        beacon.name.includes(`${thresholdName}.`)
      );
      if (defaultBeaconThresholds.length > 0) {
        beaconOptions.push({
          label: t(`beacons.thresholds.${thresholdName}.title`),
          options: defaultBeaconThresholds.map((beaconThreshold) => ({
            value: beaconThreshold.id,
            label:
              t(`beacons.thresholds.${beaconThreshold.name}`) +
              " (" +
              beaconThreshold.minTemperature +
              "°C " +
              t("common.completedAt") +
              " " +
              beaconThreshold.maxTemperature +
              "°C)",
          })),
        });
      }
    });

    const customBeaconThresholds: BeaconThreshold[] = beaconThresholds.filter(
      (threshold) => {
        return defaultThresholdNames.every(
          (defaultThreshold) => !threshold.name.includes(defaultThreshold)
        );
      }
    );

    beaconOptions.push({
      label: t("common.customThreshold"),
      options: customBeaconThresholds.map(
        (customThreshold: BeaconThreshold) => ({
          value: customThreshold.id,
          label:
            customThreshold.name +
            " (" +
            customThreshold.minTemperature +
            "°C " +
            t("common.completedAt") +
            " " +
            customThreshold.maxTemperature +
            "°C)",
          openModal: {
            icon: <IconEdit size={12} color={"--global-colors-ui-primary"} />,
            iconSelected: (
              <IconEdit size={12} color={"--global-colors-ui-white"} />
            ),
          },
        })
      ),
    });

    setThresholdOptions(beaconOptions);
  }, [beaconThresholds]);

  useEffect(() => {
    if (vehicleRoute !== null)
      if (vehicleRoute.params.vehicleId)
        store.dispatch(
          getBeaconsFilteredAsync({
            queryParams:
              "?entityType=VEHICLE&type=EYE_SENSOR&entityId=" +
              vehicleRoute.params.vehicleId,
          })
        );
    setEditBeacons({
      type: "VEHICLE" as BeaconAssociationEntityType,
      beaconsToAssociate: [],
      beaconNamespacesToDisassociate: [],
    });
    store.dispatch(getBeaconsThresholdsAsync({}));
    document.title = t("navigation.adminMenu.vehicles") + " - Admin";
    return function cleanUp() {
      store.dispatch(driversEmptyState());
      store.dispatch(vehiclesStatusEmptyState());
      store.dispatch(vehiclesEmptyState());
      store.dispatch(devicesEmptyState());
      store.dispatch(beaconEmptyState());
      store.dispatch(beaconThresholdEmptyState());
    };
  }, []);

  return (
    <>
      {!!vehicle && !_.isEmpty(vehicle) && (
        <PageFilters>
          <div className="col col-16">
            <div className="ev-subtopbar">
              <div className="ev-subsection-name">
                {t("admin.vehicle.editView.title")}: {vehicle.alias} -{" "}
                {vehicle.plate}
              </div>
              <div className="ev-button-save-group">
                <Button
                  size="small"
                  aspect="secondary"
                  label={t("common.cancel")}
                  onClick={() => navigate(-1)}
                />
                <Button
                  size="small"
                  aspect="primary"
                  disabled={
                    errorList.length !== 0 ||
                    (Object.values(editVehicle).length === 0 &&
                      Object.values(editDevice).length === 0 &&
                      Object.values(editContract).length === 0 &&
                      Object.values(editBeacons.beaconsToAssociate).length ===
                        0 &&
                      Object.values(editBeacons.beaconNamespacesToDisassociate)
                        .length === 0)
                  }
                  label={t("common.save")}
                  onClick={() => {
                    if (
                      Object.values(editVehicle).length > 0 ||
                      Object.values(editDevice).length > 0 ||
                      Object.values(editContract).length > 0 ||
                      Object.values(
                        editBeacons.beaconsToAssociate ||
                          editBeacons.beaconNamespacesToDisassociate
                      )
                    ) {
                      store.dispatch(
                        updateVehicleAsync({
                          fleetId: fleet,
                          id: id,
                          vehicle: preferencesContext.isMetric
                            ? editVehicle
                            : {
                                ...editVehicle,
                                consumption: Number(
                                  milesPerGTokmPerLt(editVehicle.consumption)
                                ),
                                initialKms: Number(
                                  milesToKm(editVehicle.initialKms)
                                ),
                              },
                          removeDriver: removedDriver,
                          contract:
                            Object.values(editContract).length > 0
                              ? editContract
                              : null,
                          device:
                            Object.values(editDevice).length > 0
                              ? editDevice
                              : null,
                        })
                      );
                      if (
                        vehicleRoute &&
                        vehicleRoute.params.vehicleId != undefined
                      )
                        store.dispatch(
                          updateBeaconAssociationAsync({
                            beaconAssociationListRequest:
                              editBeacons as BeaconAssociationListRequest,
                            entityId: vehicleRoute.params.vehicleId,
                          })
                        );
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </PageFilters>
      )}
      {vehicle.id && device.id && (
        <PageContent>
          <div className="d-block">
            <div className="ev-form-container">
              <div className="ev-general-info-form">
                <div className="ev-forms-title">
                  {t("admin.vehicle.editView.general.title")}
                </div>
                <div className="ev-forms-container">
                  <div className="ev-forms-label">
                    {t("admin.vehicle.editView.general.id.label")}
                  </div>
                  <Form>
                    <div className="ev-forms">
                      <TextField
                        id="id"
                        placeholder={
                          vehicle.id
                            ? t("admin.vehicle.editView.general.id.placeholder")
                            : t("common.na")
                        }
                        disabled={true}
                        value={vehicle.id ?? null}
                      ></TextField>
                    </div>
                  </Form>
                </div>
                <div className="ev-forms-container">
                  <div className="ev-forms-label">
                    {t("admin.vehicle.editView.general.alias.label")}*
                  </div>
                  <Form>
                    <div className="ev-forms">
                      <TextField
                        id="alias"
                        placeholder={
                          editVehicle.alias || vehicle.alias
                            ? t(
                                "admin.vehicle.editView.general.alias.placeholder"
                              )
                            : t("common.na")
                        }
                        validate="alphaNumeric|isNotEmpty"
                        errorList={setErrorList}
                        events={{
                          onChange: (data) =>
                            setEditVehicle({
                              ...editVehicle,
                              alias: data.value ?? vehicle.alias,
                            }),
                        }}
                        value={editVehicle.alias ?? vehicle.alias ?? null}
                        maxLength="10"
                      ></TextField>
                    </div>
                  </Form>
                </div>
                <div className="ev-forms-container">
                  <div className="ev-forms-label">
                    {t("admin.vehicle.editView.general.model.label")}*
                  </div>
                  <Form>
                    <div className="ev-forms">
                      <div className="ev-left-form-col">
                        <SingleDatePicker
                          id="RegistrationDate"
                          validation="isNotEmpty"
                          setErrorList={setErrorList}
                          errorList={errorList}
                          dateRange={[dayjs().subtract(100, "year"), dayjs()]}
                          oldDate={vehicle.registrationDate}
                          setDate={(data) =>
                            setEditVehicle({
                              ...editVehicle,
                              registrationDate:
                                data ?? vehicle.registrationDate,
                            })
                          }
                          placeholder={
                            editVehicle.registrationDate ||
                            vehicle.registrationDate
                              ? t(
                                  "admin.vehicle.editView.general.model.placeholder.year"
                                )
                              : t("common.na")
                          }
                          localeFormat={
                            preferencesContext.localeFormat ?? "DD / MM / YYYY"
                          }
                          language={preferencesContext.language ?? "it"}
                        />
                      </div>
                      <div className="ev-left-form-col">
                        <TextField
                          id="brand"
                          placeholder={
                            editVehicle.brand || vehicle.brand
                              ? t(
                                  "admin.vehicle.editView.general.model.placeholder.brand"
                                )
                              : t("common.na")
                          }
                          validate="alphabetic|isNotEmpty"
                          errorList={setErrorList}
                          events={{
                            onChange: (data) =>
                              setEditVehicle({
                                ...editVehicle,
                                brand: data.value,
                              }),
                          }}
                          value={editVehicle.brand ?? vehicle.brand ?? null}
                          maxLength="25"
                        ></TextField>
                      </div>
                      <div className="ev-right-form-col">
                        <TextField
                          id="model"
                          placeholder={
                            editVehicle.model || vehicle.model
                              ? t(
                                  "admin.vehicle.editView.general.model.placeholder.model"
                                )
                              : t("common.na")
                          }
                          validate="alphaNumeric|isNotEmpty"
                          errorList={setErrorList}
                          events={{
                            onChange: (data) =>
                              setEditVehicle({
                                ...editVehicle,
                                model: data.value ?? vehicle.model,
                              }),
                          }}
                          value={editVehicle.model ?? vehicle.model ?? null}
                          maxLength="25"
                        ></TextField>
                      </div>
                    </div>
                  </Form>
                </div>
                <div className="ev-forms-container">
                  <div className="ev-forms-label">
                    {t("admin.vehicle.editView.general.fuel")}*
                  </div>
                  <Form>
                    <div className="ev-forms">
                      <div className="ev-drpdwn-large">
                        <Dropdown
                          placeholder={
                            editVehicle.fuelType || vehicle.fuelType
                              ? t("admin.vehicle.editView.general.fuel")
                              : t("common.na")
                          }
                          size="normal"
                          itemAttribute="label"
                          isClearable={false}
                          value={{
                            label:
                              fuelOption.find(
                                (option) =>
                                  option.type ===
                                  (editVehicle.fuelType ?? vehicle.fuelType)
                              )?.label ?? undefined,
                          }}
                          onChange={(val) =>
                            setEditVehicle({
                              ...editVehicle,
                              fuelType: val[0].type ?? vehicle.fuelType,
                            })
                          }
                          options={fuelOption}
                        />
                      </div>
                    </div>
                  </Form>
                </div>
                <div className="ev-forms-container">
                  <div className="ev-forms-label">{t("common.license")}*</div>
                  <Form>
                    <div className="ev-forms">
                      <TextField
                        id="license"
                        placeholder={
                          editVehicle.plate || vehicle.plate
                            ? t("common.license")
                            : t("common.na")
                        }
                        disabled={true}
                        validate="alphaNumeric|isNotEmpty"
                        errorList={setErrorList}
                        events={{
                          onChange: (data) =>
                            setEditVehicle({
                              ...editVehicle,
                              plate: data.value ?? vehicle.plate,
                            }),
                        }}
                        value={editVehicle.plate ?? vehicle.plate ?? null}
                        maxLength="10"
                      ></TextField>
                    </div>
                  </Form>
                </div>
                <div className="ev-forms-container">
                  <div className="ev-forms-label">
                    {t("admin.vehicle.editView.general.type")}
                  </div>
                  <Form>
                    <div className="ev-forms">
                      <div className="ev-drpdwn-large">
                        <Dropdown
                          placeholder={
                            editVehicle.type || vehicle.type
                              ? t("admin.vehicle.editView.general.type")
                              : t("common.na")
                          }
                          size="normal"
                          isClearable={false}
                          itemAttribute="label"
                          value={{
                            label:
                              typeOption.find(
                                (option) =>
                                  option.type ===
                                  (editVehicle.type ?? vehicle.type)
                              )?.label ?? undefined,
                          }}
                          onChange={(val) =>
                            setEditVehicle({
                              ...editVehicle,
                              type: val[0].type ?? vehicle.type,
                            })
                          }
                          options={typeOption}
                        />
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="ev-specs-info-form">
                <div className="ev-forms-title">
                  {t("admin.vehicle.editView.specs.title")}
                </div>
                <div className="ev-forms-container">
                  <div className="ev-forms-label">
                    {preferencesContext.isMetric
                      ? t("admin.vehicle.editView.specs.initialKms") + " (km)"
                      : t("admin.vehicle.editView.specs.initialMiles") +
                        " (mi)"}
                  </div>
                  <Form>
                    <div className="ev-forms">
                      <TextField
                        id="initialKms"
                        placeholder={
                          preferencesContext.isMetric
                            ? t("admin.vehicle.editView.specs.initialKms")
                            : t("admin.vehicle.editView.specs.initialMiles")
                        }
                        disabled={false}
                        events={{
                          onChange: (data) => {
                            const numericValue =
                              data.value != "" && data.value != undefined
                                ? data.value.replace(/[^0-9]/g, "")
                                : 0;

                            setEditVehicle({
                              ...editVehicle,
                              initialKms: numericValue,
                            });
                          },
                        }}
                        value={
                          preferencesContext.isMetric
                            ? editVehicle.initialKms != null
                              ? editVehicle.initialKms
                              : vehicle.initialKms
                            : editVehicle.initialKms != null
                            ? editVehicle.initialKms
                            : kmToMiles(vehicle.initialKms)
                        }
                      />
                    </div>
                  </Form>
                </div>
                <div className="ev-forms-container">
                  <div className="ev-forms-label">
                    {preferencesContext?.isMetric
                      ? t("admin.vehicle.editView.specs.currentKms") + " (km)"
                      : t("admin.vehicle.editView.specs.currentMiles") +
                        " (mi)"}
                  </div>
                  <Form>
                    <div className="ev-forms">
                      <TextField
                        id="currentKms"
                        placeholder={
                          preferencesContext?.isMetric
                            ? t("admin.vehicle.editView.specs.currentKms")
                            : t("admin.vehicle.editView.specs.currentMiles")
                        }
                        disabled={true}
                        value={
                          vehicleStatus.dynamicFields?.odometer
                            ? preferencesContext.isMetric
                              ? numberAnnotation(
                                  mtToKm(vehicleStatus.dynamicFields?.odometer),
                                  1
                                )
                              : formatData(
                                  vehicleStatus.dynamicFields?.odometer,
                                  mtToMiles,
                                  preferencesContext,
                                  1
                                )
                            : `${t("common.na")}`
                        }
                      ></TextField>
                    </div>
                  </Form>
                </div>
                <div className="ev-forms-container">
                  <div className="ev-forms-label">
                    {preferencesContext?.isMetric
                      ? t("admin.vehicle.editView.specs.consumption") +
                        " (km/l)"
                      : t("admin.vehicle.editView.specs.consumption") +
                        " (mpg)"}
                  </div>
                  <Form>
                    <div className="ev-forms">
                      <TextField
                        id="consumption"
                        placeholder={
                          editVehicle.consumption || vehicle.consumption
                            ? t("admin.vehicle.editView.specs.consumption")
                            : t("common.na")
                        }
                        validate="numeric"
                        errorList={setErrorList}
                        formattedNumber={true}
                        maxIntegerDigits={2}
                        maxDecimalDigits={1}
                        events={{
                          onChange: (data) => {
                            setEditVehicle({
                              ...editVehicle,
                              consumption: data.edit,
                            });
                            setUpdatedConsumption(data.value);
                          },
                        }}
                        value={
                          !_.isEmpty(editVehicle) &&
                          editVehicle.consumption !== undefined
                            ? updateConsumption
                            : preferencesContext.isMetric
                            ? format(vehicle.consumption)
                            : format(kmPerLtToMilesPerG(vehicle.consumption))
                        }
                      ></TextField>
                    </div>
                  </Form>
                </div>
                <div className="ev-forms-container">
                  <div className="ev-forms-label">
                    {t("admin.vehicle.editView.specs.fuelPrice") + " (€/l)"}
                  </div>
                  <Form>
                    <div className="ev-forms">
                      <TextField
                        id="fuelPrice"
                        placeholder={
                          editVehicle.fuelPrice || vehicle.fuelPrice
                            ? t("admin.vehicle.editView.specs.fuelPrice")
                            : t("common.na")
                        }
                        validate="numeric"
                        formattedNumber={true}
                        maxIntegerDigits={1}
                        maxDecimalDigits={3}
                        errorList={setErrorList}
                        events={{
                          onChange: (data) => {
                            setUpdatedFuelPrice(data.value);
                            setEditVehicle({
                              ...editVehicle,
                              fuelPrice: data.edit,
                            });
                          },
                        }}
                        value={
                          !_.isEmpty(editVehicle) &&
                          editVehicle.fuelPrice !== undefined
                            ? updatedFuelPrice
                            : format(vehicle.fuelPrice)
                        }
                      ></TextField>
                    </div>
                  </Form>
                </div>
                <div className="ev-forms-container">
                  <div className="ev-forms-label">
                    {t("admin.vehicle.editView.specs.co2.label")}
                  </div>
                  <Form>
                    <div className="ev-forms">
                      <TextField
                        id="emission"
                        placeholder={
                          editVehicle.emissions || vehicle.emissions
                            ? t("admin.vehicle.editView.specs.co2.placeholder")
                            : t("common.na")
                        }
                        validate="numeric"
                        errorList={setErrorList}
                        events={{
                          onChange: (data) =>
                            setEditVehicle({
                              ...editVehicle,
                              emissions: truncateDecimalNumber(data, 2),
                            }),
                        }}
                        value={
                          editVehicle.emissions ??
                          numberAnnotation(Number(vehicle.emissions), 2, 2) ??
                          null
                        }
                      ></TextField>
                    </div>
                  </Form>
                </div>
                <div className="ev-forms-container">
                  <div className="ev-forms-label">
                    {preferencesContext?.isMetric
                      ? t("admin.vehicle.editView.specs.maxSpeed") + " (km/h)"
                      : t("admin.vehicle.editView.specs.maxSpeed") + " (mph)"}
                  </div>
                  <Form>
                    <div className="ev-forms">
                      <TextField
                        id="speedMax"
                        placeholder={
                          editVehicle.maxSpeed || vehicle.maxSpeed
                            ? t("admin.vehicle.editView.specs.maxSpeed")
                            : t("common.na")
                        }
                        errorList={setErrorList}
                        events={{
                          onChange: (data) =>
                            setEditVehicle({
                              ...editVehicle,
                              maxSpeed: data.value ?? vehicle.maxSpeed,
                            }),
                        }}
                        value={
                          (preferencesContext?.isMetric
                            ? editVehicle.maxSpeed ?? vehicle.maxSpeed
                            : kmPerHToMilesPerH(
                                editVehicle.maxSpeed ?? vehicle.maxSpeed
                              ).toFixed(0)) ?? null
                        }
                      ></TextField>
                    </div>
                  </Form>
                </div>
                <div className="ev-forms-container">
                  <div className="ev-forms-label">
                    {t("admin.vehicle.editView.specs.stopTimeThreshold") +
                      " (sec)"}
                  </div>
                  <Form>
                    <div className="ev-forms">
                      <TextField
                        id="stopTimeThreshold"
                        placeholder={
                          editVehicle.stopTimeThreshold ||
                          vehicle.stopTimeThreshold
                            ? t(
                                "admin.vehicle.editView.specs.stopTimeThreshold"
                              )
                            : t("common.na")
                        }
                        errorList={setErrorList}
                        events={{
                          onChange: (data) =>
                            setEditVehicle({
                              ...editVehicle,
                              stopTimeThreshold:
                                data.value ?? vehicle.stopTimeThreshold,
                            }),
                        }}
                        value={
                          editVehicle.stopTimeThreshold ??
                          vehicle.stopTimeThreshold
                        }
                      ></TextField>
                    </div>
                  </Form>
                </div>
                <div className="ev-forms-container">
                  <div className="ev-forms-label">
                    {preferencesContext?.isMetric
                      ? t("admin.vehicle.editView.specs.fuelCapacity") + " (l)"
                      : t("admin.vehicle.editView.specs.fuelCapacity") +
                        " (gal)"}
                  </div>
                  <Form>
                    <div className="ev-forms">
                      <TextField
                        id="speedMax"
                        placeholder={
                          editVehicle.fuelCapacity || vehicle.fuelCapacity
                            ? t("admin.vehicle.editView.specs.fuelCapacity")
                            : t("common.na")
                        }
                        errorList={setErrorList}
                        events={{
                          onChange: (data) =>
                            setEditVehicle({
                              ...editVehicle,
                              fuelCapacity: data.value ?? vehicle.fuelCapacity,
                            }),
                        }}
                        value={
                          (preferencesContext?.isMetric
                            ? editVehicle.fuelCapacity ?? vehicle.fuelCapacity
                            : ltToGal(
                                editVehicle.fuelCapacity ?? vehicle.fuelCapacity
                              ).toFixed(0)) ?? null
                        }
                      ></TextField>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="ev-driver-info-form">
                <div className="ev-forms-title">
                  {t("admin.vehicle.editView.driver.title")}
                </div>
                <div className="ev-forms-container">
                  <div className="ev-forms-label">
                    {t("admin.vehicle.editView.driver.id.label")}
                  </div>
                  <Form>
                    <div className="ev-forms">
                      <div className="ev-drpdwn-large">
                        <Dropdown
                          isClearable={true}
                          placeholder={
                            driver.id
                              ? t(
                                  "admin.vehicle.editView.driver.id.placeholder"
                                )
                              : t("common.na")
                          }
                          size="normal"
                          itemAttribute="id"
                          value={{
                            id: removedDriver
                              ? undefined
                              : driver.id ?? undefined,
                          }}
                          onChange={(val) => {
                            const driverId =
                              val && val.length > 0
                                ? val[0].id
                                : val && val.length === 0
                                ? null
                                : vehicle.driver;
                            if (val && val.length === 0) {
                              setRemovedDriver(true);
                            } else if (val && val.length > 0) {
                              setRemovedDriver(false);
                            }
                            setEditVehicle({
                              ...editVehicle,
                              driver: driverId,
                            });
                          }}
                          options={drivers}
                        />
                      </div>
                    </div>
                  </Form>
                </div>
                <div className="ev-forms-container">
                  <div className="ev-forms-label">
                    {t("admin.vehicle.editView.driver.name")}
                  </div>
                  <div className="ev-forms">
                    <Form>
                      <div className="ev-drpdwn-large">
                        <Dropdown
                          isClearable={true}
                          placeholder={
                            driver.firstName && driver.lastName
                              ? t("admin.vehicle.editView.driver.name")
                              : t("common.na")
                          }
                          size="normal"
                          value={{
                            label: removedDriver
                              ? undefined
                              : driver.firstName && driver.lastName
                              ? driver.firstName + " " + driver.lastName
                              : undefined,
                            driverId: removedDriver
                              ? undefined
                              : driver.id ?? undefined,
                          }}
                          itemAttribute="label"
                          onChange={(val) => {
                            const driverId =
                              val && val.length > 0
                                ? val[0].driverId
                                : val && val.length === 0
                                ? null
                                : vehicle.driver;
                            if (val && val.length === 0) {
                              setRemovedDriver(true);
                            } else if (val && val.length > 0) {
                              setRemovedDriver(false);
                            }
                            setEditVehicle({
                              ...editVehicle,
                              driver: driverId,
                            });
                          }}
                          options={allName}
                        />
                      </div>
                    </Form>
                  </div>
                </div>
                <div className="ev-forms-container">
                  <div className="ev-forms-label">
                    {t("admin.vehicle.editView.driver.tachograph.label")}
                  </div>
                  <Form>
                    <div className="ev-forms">
                      <TextField
                        id="tachoGraph"
                        placeholder={
                          driver.tachographCard
                            ? t(
                                "admin.vehicle.editView.driver.tachograph.placeholder"
                              )
                            : t("common.na")
                        }
                        disabled={true}
                        value={driver.tachographCard ?? null}
                      ></TextField>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="ev-device-info-form">
                <div className="ev-forms-title">
                  {t("admin.vehicle.editView.device.title")}
                </div>
                <div className="ev-forms-container">
                  <div className="ev-forms-label">{t("common.device")}</div>
                  <Form>
                    <div className="ev-forms">
                      <TextField
                        id="deviceId"
                        placeholder={
                          device.id ? t("common.device") : t("common.na")
                        }
                        disabled={true}
                        value={device.id ?? null}
                      ></TextField>
                    </div>
                  </Form>
                </div>
                <div className="ev-forms-container">
                  <div className="ev-forms-label">
                    {t("admin.vehicle.editView.device.model.label")}
                  </div>
                  <Form>
                    <div className="ev-forms">
                      <TextField
                        id="deviceModel"
                        placeholder={
                          device.modelName
                            ? t(
                                "admin.vehicle.editView.device.model.placeholder"
                              )
                            : t("common.na")
                        }
                        disabled={true}
                        value={device.modelName ?? null}
                      ></TextField>
                    </div>
                  </Form>
                </div>
                <div className="ev-forms-container">
                  <div className="ev-forms-label">{t("common.imei")}</div>
                  <Form>
                    <div className="ev-forms">
                      <TextField
                        id="deviceImei"
                        placeholder={
                          device.imei ? t("common.imei") : t("common.na")
                        }
                        disabled={true}
                        value={device.imei ?? null}
                      ></TextField>
                    </div>
                  </Form>
                </div>
                <div className="ev-forms-container">
                  <div className="ev-forms-label">
                    {t("admin.vehicle.editView.device.fineService.label")}
                  </div>
                  <Form>
                    <div className="ev-forms">
                      <Checkbox
                        label={t(
                          "admin.vehicle.editView.device.fineService.option"
                        )}
                        subLabel={t(
                          "admin.vehicle.editView.device.fineService.placeholder"
                        )}
                        checked={
                          editDevice.fineServiceEnabled ??
                          device.fineServiceEnabled
                        }
                        onChange={() => {
                          setEditDevice({
                            ...editDevice,
                            fineServiceEnabled:
                              editDevice.fineServiceEnabled ??
                              device.fineServiceEnabled
                                ? false
                                : true,
                          });
                        }}
                      ></Checkbox>
                    </div>
                  </Form>
                </div>
                <div className="ev-forms-container">
                  <div className="ev-forms-label">
                    {t("admin.vehicle.editView.device.speaker")}
                  </div>
                  <Form>
                    <div className="ev-forms ev-forms-slider">
                      <Slider
                        data={{ min: "0", max: "100" }}
                        valueLabel="%"
                        val={
                          editDevice.speakerVolumePercentage ??
                          device.speakerVolumePercentage ??
                          0
                        }
                        onChange={(data) => {
                          setEditDevice({
                            ...editDevice,
                            speakerVolumePercentage: data,
                          });
                        }}
                      />
                    </div>
                  </Form>
                </div>
              </div>
              {!_.isEmpty(beacons) && (
                <div className="ev-sensor-info-form">
                  <div className="ev-forms-title">
                    {t("admin.vehicle.editView.sensors.title")}
                  </div>
                  <div className="ev-tag-forms-container">
                    <div className="ev-forms-label">
                      {t("admin.vehicle.editView.sensors.linked") +
                        " (" +
                        linkedBeacons.length +
                        ")"}
                    </div>
                    <div className="ev-forms">
                      {linkedBeacons.length > 0 ? (
                        linkedBeacons.map((beacon) => {
                          return (
                            <Tag
                              className="edit-tags"
                              key={beacon.id}
                              text={
                                beacon.name === t("common.mac") ||
                                beacon.name === ""
                                  ? beacon.mac
                                  : beacon.name
                              }
                              size="small"
                              mode="outline"
                              type="neutro"
                            />
                          );
                        })
                      ) : (
                        <Tag
                          className="edit-tags"
                          key={""}
                          text={t("admin.vehicle.editView.sensors.noLinked")}
                          size="small"
                          mode="outline"
                          type="neutro"
                        />
                      )}
                    </div>
                  </div>
                  <div className="ev-forms-container">
                    <div className="ev-forms-label">
                      {t("admin.vehicle.editView.sensors.manage")}
                    </div>
                    <div className="ev-forms">
                      <Switch
                        label=""
                        checked={manageSensors}
                        onChange={() => {
                          setManageSensors(!manageSensors);
                        }}
                      />
                    </div>
                  </div>
                  {manageSensors && (
                    <div className="ev-forms-sensors-container">
                      <AdminVehicleSensorTab
                        tabType="beacons"
                        leftButtonText={t("beacons.modal.unlinkAll")}
                        rightGroupProp={unLinkedBeacons}
                        leftGroupProp={linkedBeacons}
                        rightButtonText={t("beacons.modal.linkAll")}
                        output={(
                          rightResources: BeaconResourceProps[],
                          leftResources: BeaconResourceProps[]
                        ) => {
                          setLinkedBeacons(leftResources);
                          setUnlinkedBeacons(rightResources);
                        }}
                        customTitleFirstGroup={t("beacons.modal.linked")}
                        customTitleSecondGroup={t("beacons.modal.unlinked")}
                      />
                    </div>
                  )}
                  {linkedBeacons &&
                    linkedBeacons.map((beacon, index) => {
                      return (
                        <div className="ev-forms-container">
                          <div className="ev-forms-label">
                            {t("common.name")}
                          </div>
                          <Form>
                            <div className="ev-forms">
                              <div className="ev-left-form-col">
                                <TextField
                                  id="beacon"
                                  placeholder={""}
                                  validate="isNotEmpty"
                                  errorList={setErrorList}
                                  value={
                                    beacon.name == t("common.mac")
                                      ? ""
                                      : beacon.name
                                  }
                                  children={
                                    <IconEdit
                                      size={12}
                                      color={"--global-colors-ink-light"}
                                    />
                                  }
                                  events={{
                                    onChange: (data) => {
                                      const updatedBeacons = [...linkedBeacons];
                                      updatedBeacons[index].name = data.value;
                                      setLinkedBeacons(updatedBeacons);
                                    },
                                  }}
                                  maxLength="30"
                                ></TextField>
                              </div>
                              <div className="ev-middle-form-col">
                                <div className="ev-forms-label-right">
                                  {t("table.columns.category")}
                                </div>
                              </div>
                              <div className="ev-right-form-col">
                                <BeaconsSelect
                                  id="thresholds"
                                  events={{
                                    onChange: (data) => {
                                      const updatedBeacons = [...linkedBeacons];
                                      let beaconThreshhold =
                                        beaconThresholds.filter(
                                          (beaconThreshold) =>
                                            beaconThreshold.id == data.value
                                        )[0];
                                      updatedBeacons[index].thresholdName =
                                        beaconThreshhold.name;
                                      updatedBeacons[index].thresholdId =
                                        beaconThreshhold.id;
                                      setLinkedBeacons(updatedBeacons);
                                      setCurrentThreshold(
                                        {} as BeaconThreshold
                                      );
                                    },
                                  }}
                                  itemAttribute="label"
                                  options={thresholdOptions}
                                  hasButton={true}
                                  createClick={() => {
                                    setBeaconThresholdModal("create");
                                  }}
                                  updateClick={(e) => {
                                    setBeaconThresholdModal("update");
                                    setBeaconThreshold(
                                      beaconThresholds.filter(
                                        (beaconThresold: BeaconThreshold) =>
                                          beaconThresold.id == e.value
                                      )[0]
                                    );
                                  }}
                                  buttonText={t(
                                    "admin.vehicle.editView.sensors.createThreshold"
                                  )}
                                  children={<IconEdit size={12} />}
                                  placeholder={t("common.selectThresholds")}
                                  updatedElement={currentThreshold}
                                  value={
                                    linkedBeacons.filter(
                                      (linkedBeacon) =>
                                        linkedBeacon.thresholdId ==
                                        beacon.thresholdId
                                    )[0].name
                                  }
                                  selectedElement={
                                    beacon.thresholdName
                                      ? defaultThresholdNames.find(
                                          (thresholdName) =>
                                            beacon.thresholdName.includes(
                                              thresholdName
                                            )
                                        )
                                        ? t(
                                            `beacons.thresholds.${beacon.thresholdName}`
                                          )
                                        : beacon.thresholdName
                                      : t("common.noThresholdSelected")
                                  }
                                ></BeaconsSelect>
                              </div>
                            </div>
                          </Form>
                        </div>
                      );
                    })}
                </div>
              )}
              <div className="ev-service-info-form">
                <div className="ev-forms-title">
                  {t("admin.vehicle.editView.service.title")}
                </div>
                <div className="ev-forms-container">
                  <div className="ev-forms-label">
                    {t("admin.vehicle.editView.service.name")}
                  </div>
                  <Form>
                    <div className="ev-forms">
                      <TextField
                        id="serviceName"
                        placeholder={
                          contract?.service
                            ? t("admin.vehicle.editView.service.name")
                            : t("common.na")
                        }
                        disabled={true}
                        value={contract?.service ? contract?.service : null}
                      ></TextField>
                    </div>
                  </Form>
                </div>
                <div className="ev-forms-container">
                  <div className="ev-forms-label">
                    {t("admin.vehicle.editView.service.activation")}
                  </div>
                  <Form>
                    <div className="ev-forms">
                      <TextField
                        id="serviceActivation"
                        placeholder={
                          contract.activationDate
                            ? t("admin.vehicle.edit.service.activation")
                            : t("common.na")
                        }
                        disabled={true}
                        value={
                          preferencesContext.localeFormat
                            ? convertToCurrentLocalesFormat(
                                contract.activationDate,
                                true,
                                preferencesContext.localeFormat ??
                                  "DD / MM / YYYY"
                              )
                            : new Date(
                                contract.activationDate
                              ).toLocaleDateString(i18next.language) ?? null
                        }
                      ></TextField>
                    </div>
                  </Form>
                </div>
                <div className="ev-forms-container">
                  <div className="ev-forms-label">
                    {t("admin.vehicle.editView.service.expiration")}
                  </div>
                  <Form>
                    <div className="ev-forms">
                      <TextField
                        id="serviceExpiration"
                        placeholder={
                          contract.expirationDate
                            ? t("admin.vehicle.edit.service.expiration")
                            : t("common.na")
                        }
                        disabled={true}
                        value={
                          preferencesContext.localeFormat
                            ? convertToCurrentLocalesFormat(
                                contract.expirationDate,
                                true,
                                preferencesContext.localeFormat ??
                                  "DD / MM / YYYY"
                              )
                            : new Date(
                                contract.expirationDate
                              ).toLocaleDateString(i18next.language) ?? null
                        }
                      ></TextField>
                    </div>
                  </Form>
                </div>
                <div className="ev-forms-container">
                  <div className="ev-forms-label">
                    {t("admin.vehicle.editView.service.credits.label")}
                  </div>
                  <Form>
                    <div className="ev-forms d-flex">
                      <div className="w-100">
                        <TextField
                          id="credits"
                          placeholder={
                            contract.availableCredits
                              ? t(
                                  "admin.vehicle.editView.service.credits.label"
                                )
                              : t("common.na")
                          }
                          disabled={true}
                          value={
                            contract.availableCredits
                              ? contract.availableCredits + "/100"
                              : null
                          }
                        ></TextField>
                      </div>
                      <div>
                        <Button
                          aspect="primary"
                          size="regular"
                          label={t(
                            "admin.vehicle.editView.service.credits.button"
                          )}
                        />
                      </div>
                    </div>
                  </Form>
                </div>
                <div className="ev-forms-container">
                  <div className="ev-forms-label">
                    {t("admin.vehicle.editView.service.threshold")}
                  </div>
                  <Form>
                    <div className="ev-forms">
                      <div className="ev-drpdwn-large">
                        <Dropdown
                          isClearable={false}
                          placeholder={
                            getStateorNull(
                              editContract.threshold ?? contract.threshold
                            ) !== null
                              ? t("admin.vehicle.editView.service.threshold")
                              : t("common.na")
                          }
                          size="normal"
                          value={{
                            label: getStateorNull(
                              editContract.threshold ?? contract.threshold
                            ),
                          }}
                          itemAttribute="label"
                          onChange={(val) =>
                            setEditContract({
                              ...editContract,
                              threshold:
                                t("common.statusOption.on") === val[0].label ||
                                false,
                            })
                          }
                          options={[
                            {
                              label: t("common.statusOption.on"),
                            },
                            {
                              label: t("common.statusOption.off"),
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </Form>
                </div>
                <div className="ev-forms-container">
                  <div className="ev-forms-label">
                    {t("admin.vehicle.editView.service.status")}
                  </div>
                  <Form>
                    <div className="ev-forms">
                      <TextField
                        id="serviceStatus"
                        placeholder={
                          getStateorNull(contract.status) !== null
                            ? t("admin.vehicle.editView.service.status")
                            : t("common.na")
                        }
                        disabled={true}
                        value={getStateorNull(contract.status) ?? null}
                      ></TextField>
                    </div>
                  </Form>
                </div>
                <div className="ev-tag-forms-container">
                  <div className="ev-forms-label">
                    {t("admin.vehicle.editView.service.associated")}
                  </div>
                  <div className="ev-forms ">
                    {contract?.options?.length > 0 &&
                      contract.options.map((option) => {
                        return (
                          <Tag
                            key={option.name}
                            className="edit-tags"
                            text={t(`serviceOption.${option.name}.name`)}
                            size="small"
                            mode="outline"
                            type="neutro"
                          ></Tag>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {beaconThresholdModal && (
            <BeaconThresholdModal
              onClose={() => {
                setBeaconThresholdModal("");
              }}
              open={
                beaconThresholdModal == "create" ||
                beaconThresholdModal == "update"
              }
              beaconThreshold={
                beaconThresholdModal == "create"
                  ? ({} as BeaconThreshold)
                  : beaconThreshold
              }
              action={beaconThresholdModal}
              vehicleInfo={" " + vehicle.alias + " - " + vehicle.plate}
              updateCurrentThreshold={updateCurrentThreshold}
            />
          )}
        </PageContent>
      )}
    </>
  );
};
export default EditVehicle;
