import { t } from "i18next";
import { useState } from "react";
import { store } from "../../app/store";
import { Button } from "../../ui/Button/Button";
import Form from "../../ui/Forms/Form";
import TextField from "../../ui/Forms/TextField";
import { IconTrash } from "../../ui/Icon/Line/Trash";
import { ModalBody } from "../../ui/Modal/ModalBody";
import { ModalFooter } from "../../ui/Modal/ModalFooter";
import { ModalWrapper } from "../../ui/Modal/ModalWrapper";
import {
  BeaconThreshold,
  createBeaconThresholdAsync,
  deleteBeaconThresholdAsync,
  updateBeaconThresholdAsync,
} from "../beacon/beaconThresholdSlice";
import "./BeaconThresholdModal.css";

interface BeaconThresholdModalProps {
  open: boolean;
  onClose: (e: any) => any;
  beaconThreshold: BeaconThreshold;
  action: "create" | "update";
  vehicleInfo: string;
  updateCurrentThreshold: (
    id: number,
    beaconThreshold: BeaconThreshold | null
  ) => any;
}

export const BeaconThresholdModal: React.FC<BeaconThresholdModalProps> = ({
  open,
  onClose,
  action,
  beaconThreshold,
  vehicleInfo,
  updateCurrentThreshold,
}) => {
  const [updatedBeaconThreshold, setUpdatedBeaconThreshold] =
    useState<BeaconThreshold>(beaconThreshold);

  return (
    <ModalWrapper open={open} closeAction={onClose}>
      <ModalBody
        title={t("common.customThreshold")}
        desc={t("common.configuration") + vehicleInfo}
      >
        <div className="beacon-threshold-row-form">
          <div className="">
            {t("admin.vehicle.editView.sensors.thresholdName")}
          </div>
          <div className="text-field">
            <Form>
              <TextField
                id="name"
                validate="alphabetic|isNotEmpty"
                placeholder={t("admin.vehicle.editView.sensors.defaultName")}
                value={updatedBeaconThreshold.name}
                type="text"
                events={{
                  onChange: (data) => {
                    setUpdatedBeaconThreshold({
                      ...updatedBeaconThreshold,
                      name: data.value,
                    });
                  },
                }}
                disabled={false}
              />
            </Form>
          </div>
        </div>
        <div className="beacon-threshold-row-form">
          <div className="">
            {t("admin.vehicle.editView.sensors.minTemperature")}
          </div>
          <div className="text-field">
            <Form>
              <TextField
                id="name"
                validate="alphabetic|isNotEmpty"
                placeholder={t(
                  "admin.vehicle.editView.sensors.defaultMinTemperature"
                )}
                value={updatedBeaconThreshold.minTemperature}
                type="number"
                events={{
                  onChange: (data) => {
                    setUpdatedBeaconThreshold({
                      ...updatedBeaconThreshold,
                      minTemperature: data.value,
                    });
                  },
                }}
                disabled={false}
              />
            </Form>
          </div>
        </div>
        <div className="beacon-threshold-row-form">
          <div className="">
            {t("admin.vehicle.editView.sensors.maxTemperature")}{" "}
          </div>
          <div className="text-field">
            <Form>
              <TextField
                id="name"
                validate="alphabetic|isNotEmpty"
                placeholder={t(
                  "admin.vehicle.editView.sensors.defaultMaxTemperature"
                )}
                value={updatedBeaconThreshold.maxTemperature}
                type="number"
                events={{
                  onChange: (data) => {
                    setUpdatedBeaconThreshold({
                      ...updatedBeaconThreshold,
                      maxTemperature: data.value,
                    });
                  },
                }}
                disabled={false}
              />
            </Form>
          </div>
        </div>
        <div className="beacon-threshold-row-form">
          <div className="">
            {t("admin.vehicle.editView.sensors.minHumidity")}{" "}
          </div>
          <div className="text-field">
            <Form>
              <TextField
                id="name"
                validate="alphabetic|isNotEmpty"
                placeholder={t(
                  "admin.vehicle.editView.sensors.defaultMinHumidity"
                )}
                value={updatedBeaconThreshold.minHumidity}
                type="number"
                events={{
                  onChange: (data) => {
                    setUpdatedBeaconThreshold({
                      ...updatedBeaconThreshold,
                      minHumidity: data.value,
                    });
                  },
                }}
                disabled={false}
              />
            </Form>
          </div>
        </div>
        <div className="beacon-threshold-row-form">
          <div className="">
            {t("admin.vehicle.editView.sensors.maxHumidity")}{" "}
          </div>
          <div className="text-field">
            <Form>
              <TextField
                id="name"
                validate="alphabetic|isNotEmpty"
                placeholder={t(
                  "admin.vehicle.editView.sensors.defaultMaxHumidity"
                )}
                value={updatedBeaconThreshold.maxHumidity}
                type="number"
                events={{
                  onChange: (data) => {
                    setUpdatedBeaconThreshold({
                      ...updatedBeaconThreshold,
                      maxHumidity: data.value,
                    });
                  },
                }}
                disabled={false}
              />
            </Form>
          </div>
        </div>
      </ModalBody>
      {action == "create" ? (
        <ModalFooter
          primaryLabel={t("common.save")}
          primaryAction={() => {
            store.dispatch(
              createBeaconThresholdAsync({
                beaconThreshold: updatedBeaconThreshold,
              })
            );
          }}
          secondaryLabel={t("common.cancel")}
          secondaryAction={onClose}
        />
      ) : (
        <>
          <div className="custom-threshold-modal-footer">
            <div className="button-left">
              <Button
                size={"regular"}
                label={t("common.delete")}
                aspect="danger"
                children={
                  <IconTrash size={14} color={"--global-colors-ui-white"} />
                }
                onClick={() => {
                  store.dispatch(
                    deleteBeaconThresholdAsync({
                      thresholdId: beaconThreshold.id,
                    })
                  );
                  updateCurrentThreshold(beaconThreshold.id, null);
                }}
              />
            </div>
            <div className="buttons-right">
              <Button
                size={"regular"}
                label={t("common.back")}
                aspect="secondary"
                onClick={onClose}
              />
              <Button
                size={"regular"}
                label={t("common.save")}
                disabled={!beaconThreshold}
                aspect="primary"
                onClick={() => {
                  store.dispatch(
                    updateBeaconThresholdAsync({
                      beaconThreshold: updatedBeaconThreshold,
                    })
                  );
                  updateCurrentThreshold(
                    updatedBeaconThreshold.id,
                    updatedBeaconThreshold
                  );
                }}
              />
            </div>
          </div>
        </>
      )}
    </ModalWrapper>
  );
};
