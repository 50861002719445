import i18next, { t } from "i18next";
import _ from "lodash";
import { NavigateFunction } from "react-router-dom";
import { DropdownButton } from "../../ui/Button/DropdownButton";
import { Tooltip } from "../../ui/Forms/Tooltip";
import { IconAlert } from "../../ui/Icon/Line/Alert";
import { IconBuild } from "../../ui/Icon/Line/Build";
import { IconCarInsurance } from "../../ui/Icon/Line/CarInsurance";
import { IconCarRepair } from "../../ui/Icon/Line/CarRepair";
import { IconCarTax } from "../../ui/Icon/Line/CarTax";
import { IconEdit } from "../../ui/Icon/Line/Edit";
import { IconEngineLock } from "../../ui/Icon/Line/EngineLock";
import { IconGenericalInspection } from "../../ui/Icon/Line/GenericalInspection";
import { IconMaintenanceDeadlines } from "../../ui/Icon/Line/MaintenanceDeadlines";
import { IconRepeat } from "../../ui/Icon/Line/Repeat";
import { IconUtilization } from "../../ui/Icon/Line/Utilization";
import { IconSuccess } from "../../ui/Icon/Solid/Success";
import { IconWheelChange } from "../../ui/Icon/Solid/WheelChange";
import { LabelText } from "../../ui/LabelText/LabelText";
import { Table } from "../../ui/Table/Table";
import {
  AbstractTableBuilder,
  Row,
} from "../../ui/Table/utils/AbstractTableBuilder";
import "../../ui/Table/utils/TableBuilder.css";
import { datetoLocaleFormat } from "../../utils/DateAndTimeUtils";
import {
  convertEuroOnCurrency,
  mtToKm,
  numberAnnotation,
} from "../../utils/Utils";
import { FleetView } from "../fleet/fleetViewsSlice";
import { Preferences } from "../users/preference/preferencesSlice";
import { Vehicle } from "../vehicle/vehiclesSlice";
import { Deadline } from "./deadlinesSlice";

export class MaintenanceTableBuilder extends AbstractTableBuilder {
  rowsBuilder = (
    columns: { label: string; field: string; sort: boolean }[] | undefined,
    context: string,
    entities: {
      vehicle?: Vehicle;
      deadlineStatus?: Deadline;
      fleetView?: FleetView;
    },
    navigate: NavigateFunction,
    preferencesContext: Preferences,
    writePermission: boolean = false,
    queryParams: string
  ) => {
    let row: Row = { render: {} as Row };

    let vehicle: Vehicle = entities.vehicle ?? ({} as Vehicle);

    let fleetView: FleetView = entities.fleetView ?? ({} as FleetView);

    let deadlineStatus: Deadline = entities.deadlineStatus ?? ({} as Deadline);
    const currency =
      preferencesContext.currency === "EURO"
        ? "€"
        : preferencesContext.currency === "DOLLAR"
        ? "$"
        : "£";
    columns &&
      columns.forEach((column) => {
        switch (column.field) {
          case "vehicle":
            row.vehicle = vehicle.alias ?? t("common.na");
            row.render.vehicle = (
              <LabelText
                label={vehicle.alias ?? t("common.na")}
                text={
                  vehicle.brand
                    ? vehicle.brand + " " + vehicle.model
                    : t("common.na")
                }
              />
            );
            break;
          case "license":
            row.render.license = vehicle.plate ?? t("common.na");
            break;
          case "group":
            row.group = fleetView.name ?? t("common.na");
            row.render.group = fleetView.name ?? t("common.na");
            break;
          case "maintenanceDeadlines":
            const maintenanceDeadlinesCases = () => {
              switch (deadlineStatus.type) {
                case "MAINTENANCE_CAR_SERVICE":
                case "MAINTENANCE_CAR_BODYWORK": {
                  return (
                    <IconCarRepair
                      size={14}
                      color="--global-colors-ink-light"
                    />
                  );
                }
                case "MAINTENANCE_TIRES": {
                  return (
                    <IconWheelChange
                      size={14}
                      color="--global-colors-ink-light"
                    />
                  );
                }
                case "MAINTENANCE_INSPECTION": {
                  return (
                    <IconGenericalInspection
                      size={14}
                      color="--global-colors-ink-light"
                    />
                  );
                }
                case "MAINTENANCE_ORDINARY":
                case "MAINTENANCE_EXTRAORDINARY": {
                  return (
                    <IconBuild size={14} color="--global-colors-ink-light" />
                  );
                }
                case "INSURANCE": {
                  return (
                    <IconCarInsurance
                      size={14}
                      color="--global-colors-ink-light"
                    />
                  );
                }
                case "TAXES": {
                  return (
                    <IconCarTax size={14} color="--global-colors-ink-light" />
                  );
                }
                case "REVISION": {
                  return (
                    <IconEngineLock
                      size={14}
                      color="--global-colors-ink-light"
                    />
                  );
                }
                case "MAINTENANCE_CUSTOM":
                case "CUSTOM": {
                  return (
                    <IconEdit size={14} color="--global-colors-ink-light" />
                  );
                }
                default: {
                  return <div></div>;
                }
              }
            };
            let customName = t(
              "maintenance.table.maintenanceDeadlines." + deadlineStatus.type
            );
            if (
              (deadlineStatus?.type === "CUSTOM" ||
                deadlineStatus?.type === "MAINTENANCE_CUSTOM") &&
              !!deadlineStatus?.additionalInfoJson
            ) {
              try {
                const parsedJson = JSON.parse(
                  deadlineStatus?.additionalInfoJson
                );
                customName = parsedJson?.customTypeName ?? customName;
              } catch (ex) {}
            }
            row.render.maintenanceDeadlines =
              deadlineStatus.type !== undefined && deadlineStatus.type ? (
                <Table.TextIcon
                  text={customName}
                  icon={maintenanceDeadlinesCases()}
                />
              ) : (
                "-"
              );
            break;
          case "expire":
            let maintenanceKm = preferencesContext.isMetric ? "km" : "mil";
            let showDate = "";
            if (deadlineStatus?.expirationDate) {
              showDate = datetoLocaleFormat(deadlineStatus.expirationDate);
            }
            let diffOdometer =
              deadlineStatus.expirationMeters &&
              deadlineStatus.currentOdometer >= 0
                ? deadlineStatus.expirationMeters -
                  deadlineStatus.currentOdometer
                : undefined;

            let diffUtilization =
              deadlineStatus.expirationUtilizationMinutes &&
              deadlineStatus.currentUtilizationMinutes >= 0
                ? deadlineStatus.expirationUtilizationMinutes -
                  deadlineStatus.currentUtilizationMinutes
                : undefined;

            let showKM = "";
            let showUtilization = "";
            let showExpiredCompletedInfo = "";
            if (deadlineStatus.status === "COMPLETED") {
              if (
                deadlineStatus?.expirationMeters &&
                deadlineStatus?.currentOdometer &&
                deadlineStatus?.currentOdometer >= 0
              ) {
                showKM =
                  (numberAnnotation(mtToKm(deadlineStatus.currentOdometer)) ??
                    "") +
                  " " +
                  maintenanceKm;
              }
              if (
                deadlineStatus?.expirationUtilizationMinutes &&
                deadlineStatus?.currentUtilizationMinutes &&
                deadlineStatus?.currentUtilizationMinutes >= 0
              ) {
                showUtilization =
                  (numberAnnotation(deadlineStatus.currentUtilizationMinutes) ??
                    "") + " h";
              }
              showExpiredCompletedInfo +=
                (showDate ? showDate + " " : "") +
                (showKM ? t("common.completedAt") + " " + showKM : "") +
                (showUtilization
                  ? t("common.completedAt") + " " + showUtilization
                  : "");
            }
            if (
              deadlineStatus.status === "SCHEDULED" ||
              deadlineStatus.status === "EXPIRING" ||
              deadlineStatus.status === "IN_PROGRESS"
            ) {
              if (diffOdometer) {
                diffOdometer =
                  diffOdometer < 0 ? diffOdometer * -1 : diffOdometer;
                showKM =
                  (numberAnnotation(mtToKm(diffOdometer)) ?? "") +
                  " " +
                  maintenanceKm;
              }
              if (diffUtilization) {
                diffUtilization =
                  diffUtilization < 0 ? diffUtilization * -1 : diffUtilization;
                showUtilization =
                  (numberAnnotation(diffUtilization) ?? "") + " h";
              }

              if (!showUtilization) {
                showExpiredCompletedInfo +=
                  showDate +
                  (showDate && showKM
                    ? t("common.orBetween")
                    : showKM
                    ? t("common.between")
                    : "") +
                  " " +
                  showKM;
              } else {
                showExpiredCompletedInfo +=
                  showUtilization &&
                  showDate +
                    (showDate && showUtilization
                      ? t("common.orBetween")
                      : showUtilization
                      ? t("common.between")
                      : "") +
                    " " +
                    showUtilization;
              }
            }
            if (deadlineStatus.status === "EXPIRED") {
              if (diffOdometer) {
                diffOdometer =
                  diffOdometer < 0 ? diffOdometer * -1 : diffOdometer;
                showKM =
                  (numberAnnotation(mtToKm(diffOdometer)) ?? "") +
                  " " +
                  maintenanceKm;
              }
              if (diffUtilization) {
                diffUtilization =
                  diffUtilization < 0 ? diffUtilization * -1 : diffUtilization;
                showUtilization =
                  (numberAnnotation(diffUtilization) ?? "") + " h";
              }

              if (!showUtilization) {
                showExpiredCompletedInfo +=
                  showDate +
                  (showDate && showKM
                    ? t("maintenance.expired.orExpiredFrom")
                    : showKM
                    ? t("maintenance.expired.expiredFrom")
                    : "") +
                  " " +
                  showKM;
              } else {
                showExpiredCompletedInfo +=
                  showUtilization &&
                  showDate +
                    (showDate && showUtilization
                      ? t("maintenance.expired.orExpiredFrom")
                      : showUtilization
                      ? t("maintenance.expired.expiredFrom")
                      : "") +
                    " " +
                    showUtilization;
              }
            }
            if (!showDate && !deadlineStatus.expirationDate)
              showDate = "--/--/----";
            if (
              deadlineStatus?.complete !== true &&
              (deadlineStatus?.rescheduleMeters > 0 ||
                deadlineStatus?.rescheduleMonths > 0)
            ) {
              const repeatIcon = () => {
                return (
                  <>
                    <Tooltip />
                    <span
                      data-for="tooltip"
                      data-tip={i18next.t("maintenance.table.tooltip.repeat")}
                    >
                      <IconRepeat size={14} color="--global-colors-ink-dark" />
                    </span>
                  </>
                );
              };
              row.render.date = (
                <Table.TextIcon
                  text={showExpiredCompletedInfo}
                  icon={repeatIcon()}
                />
              );
            } else {
              row.render.date = showExpiredCompletedInfo;
            }
            break;
          case "maintenanceStatus":
            const statusCases = () => {
              switch (deadlineStatus.status) {
                case "SCHEDULED": {
                  return (
                    <IconMaintenanceDeadlines
                      size={14}
                      color="--global-colors-ui-primary"
                    />
                  );
                }
                case "IN_PROGRESS": {
                  return (
                    <IconBuild size={16} color="--global-colors-ui-primary" />
                  );
                }
                case "EXPIRED": {
                  return (
                    <IconAlert
                      size={16}
                      color="--global-colors-feedback-danger"
                    />
                  );
                }
                case "EXPIRING": {
                  return (
                    <IconUtilization
                      size={16}
                      color="--global-colors-ui-secondary"
                    />
                  );
                }
                case "COMPLETED": {
                  return (
                    <IconSuccess
                      size={16}
                      color="--global-colors-feedback-success"
                    />
                  );
                }
                default: {
                  return <div></div>;
                }
              }
            };
            row.render.status =
              deadlineStatus.status !== undefined && deadlineStatus.status ? (
                <>
                  <Table.TextIcon
                    text={t(
                      "maintenance.table.status." + deadlineStatus.status
                    )}
                    icon={statusCases()}
                  />
                </>
              ) : (
                "-"
              );
            break;
          case "cost":
            let cost = `${t("common.na")}`;
            if (!!deadlineStatus?.cost) {
              cost = deadlineStatus?.cost
                ? numberAnnotation(
                    convertEuroOnCurrency(
                      preferencesContext.currency,
                      deadlineStatus.cost
                    )
                  ) +
                  " " +
                  currency
                : `${t("common.na")}`;
            }
            row.render.cost = cost;
            break;
          case "note":
            row.render.note = deadlineStatus?.note ? (
              <div
                style={{
                  width: "200px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                <Tooltip />
                <span
                  data-for="tooltip"
                  data-tooltip-delay-hide={1000}
                  data-tip={deadlineStatus?.note}
                >
                  {deadlineStatus?.note}
                </span>
              </div>
            ) : (
              t("common.na")
            );
            break;

          default:
            break;
        }
      });
    switch (context) {
      case "maintenanceActions":
        const maintenanceDropDownList = !deadlineStatus.complete
          ? [
              {
                id: 0,
                title: t("common.viewEdit"),
                onClick: () => {
                  navigate(
                    {
                      pathname: "/dashboard/maintenance/view-edit",
                      search: queryParams,
                    },
                    {
                      state: {
                        deadlineStatus: deadlineStatus,
                        fleetId: fleetView.id,
                        vehicleId: vehicle.id,
                      },
                    }
                  );
                },
              },
              {
                id: 1,
                title: t("common.markComplete"),
                onClick: () => {
                  navigate(
                    {
                      pathname: "/dashboard/maintenance/completed-deadline",
                      search: queryParams,
                    },
                    {
                      state: {
                        deadlineStatus: deadlineStatus,
                        fleetId: fleetView.id,
                        vehicleId: vehicle.id,
                      },
                    }
                  );
                },
              },
              {
                id: 2,
                title: t("common.delete"),
                onClick: () => {
                  let deadlineMetric = preferencesContext.isMetric
                    ? "km"
                    : "mil";
                  navigate(
                    {
                      pathname: "/dashboard/maintenance/delete",
                      search: queryParams,
                    },
                    {
                      state: {
                        vehicle: vehicle.alias,
                        date:
                          deadlineStatus.expirationDate &&
                          new Date(
                            deadlineStatus.expirationDate
                          ).toLocaleDateString(),
                        type: t(
                          "maintenance.table.maintenanceDeadlines." +
                            deadlineStatus.type
                        ),
                        deadlineId: deadlineStatus.id,
                        meters:
                          mtToKm(deadlineStatus.expirationMeters) +
                          " " +
                          deadlineMetric,
                        utilization:
                          deadlineStatus.expirationUtilizationMinutes + " h",
                      },
                    }
                  );
                },
              },
            ]
          : [
              {
                id: 1,
                title: t("common.view"),
                onClick: () => {
                  navigate(
                    {
                      pathname:
                        "/dashboard/maintenance/view-edit-completed-deadline",
                      search: queryParams,
                    },
                    {
                      state: {
                        deadlineStatus: deadlineStatus,
                        fleetId: fleetView.id,
                        vehicleId: vehicle.id,
                      },
                    }
                  );
                },
              },
              {
                id: 0,
                title: t("common.resume"),
                onClick: () => {
                  navigate(
                    {
                      pathname: "/dashboard/maintenance/view-edit",
                      search: queryParams,
                    },
                    {
                      state: {
                        deadlineStatus: deadlineStatus,
                        fleetId: fleetView.id,
                        vehicleId: vehicle.id,
                      },
                    }
                  );
                },
              },
            ];

        const maintenanceFileDropDownList = [
          {
            id: 0,
            title: t("common.preview"),
            onClick: () => {
              navigate(
                {
                  pathname: "/dashboard/maintenance/preview-attachment",
                  search: queryParams,
                },
                {
                  state: {
                    vehicleId: vehicle.id,
                    fileName: deadlineStatus.uploadFile,
                  },
                }
              );
            },
          },
          {
            id: 1,
            title: t("common.download"),
            onClick: () => {
              const attachmentDownloadLink = document.createElement("a");
              attachmentDownloadLink.href = `https://gtfleetbucket.s3.eu-central-1.amazonaws.com/Maintenance/Vehicles/${vehicle.id}/${deadlineStatus.uploadFile}`;
              attachmentDownloadLink.setAttribute("target", "_blank");
              document.body.appendChild(attachmentDownloadLink);
              attachmentDownloadLink.click();
              document.body.removeChild(attachmentDownloadLink);
            },
          },
          {
            id: 2,
            title: t("common.delete"),
            onClick: () => {
              navigate(
                {
                  pathname: "/dashboard/maintenance/delete-attachment",
                  search: queryParams,
                },
                {
                  state: {
                    vehicleId: vehicle.id,
                    fileName: deadlineStatus.uploadFile,
                    deadlineStatus: deadlineStatus,
                  },
                }
              );
            },
          },
        ];

        row.render.action = (
          <>
            {!_.isEmpty(deadlineStatus.uploadFile) ? (
              <DropdownButton
                aspect="outline"
                size="small"
                list={maintenanceFileDropDownList}
                isAttached={true}
              />
            ) : (
              ""
            )}
            <DropdownButton
              aspect="ghost"
              size="small"
              list={maintenanceDropDownList}
            />
          </>
        );
        break;
      default:
        break;
    }
    return row;
  };
}
