import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { t } from "i18next";
import _ from "lodash";
import { useContext } from "react";
import { Link } from "react-router-dom";
import {
  DriverStatus,
  TachographData,
} from "../../features/driver/driversStatusSlice";
import { Preferences } from "../../features/users/preference/preferencesSlice";
import UserContext from "../../features/users/userContext";

import {
  activityIconRecognition,
  activityRecognition,
  cardRecognition,
  currentIcon,
} from "../../utils/ChronoUtils";
import { ConvertTimeZone } from "../../utils/DateAndTimeUtils";
import { capitalizeFirstLetter, formatDuration } from "../../utils/Utils";
import { Button } from "../Button/Button";
import { DataLabel } from "../DataLabel/DataLabel";
import { Tooltip } from "../Forms/Tooltip";
import { CreditBalance } from "../Icon/Line/CreditBalance";
import { IconLicense } from "../Icon/Line/License";
import { IconList } from "../Icon/Line/List";
import { IconUser } from "../Icon/Solid/User";
import ProgressBar from "./ProgressBar";
import "./VehicleDetailsModal.css";

dayjs.extend(duration);

export const driverStatusValues = { ACTIVE: "ACTIVE", INACTIVE: "INACTIVE" };
export type DriverStatusType = keyof typeof driverStatusValues;

export type Driver = {
  id: number;
  username: string;
  firstName: string;
  address: string;
  zip: string;
  avatar: string;
  city: string;
  dateOfBirth: string;
  email: string;
  fiscalCode: string;
  fleet: number;
  lastName: string;
  licenseExpirationDate: string;
  licenseId: string;
  licenseType: string;
  phoneNumber: string;
  phoneNumberPrefix: string;
  linkedToAccount: boolean;
  status: DriverStatusType;
  tachographCard: string;
  tenant: number;
  vehicle: number;
};

export type DeviceStatusType = "ONLINE" | "OFFLINE" | "NO_SIGNAL" | "UNKNOWN";

export type StatusVehicleType =
  | "MOVING"
  | "STOP"
  | "PARKING"
  | "UNKNOWN"
  | "OFFLINE";

interface TachographCardProps {
  driverStatus: DriverStatus;
  tachographData: TachographData;
  driver: Driver;
}

const TachographCard: React.FC<TachographCardProps> = ({
  driverStatus,
  tachographData,
  driver,
}) => {
  let driverName = !_.isEmpty(driver)
    ? driver.firstName + " " + driver.lastName
    : `${t("common.na")}`;

  const [preferencesContext]: [Preferences] = useContext(UserContext);

  const recognitionProgressBars = (status: number) => {
    switch (status) {
      case 0:
        return "REST";
      case 2:
      case 3:
        return "WORK";
      default:
        return null;
    }
  };

  const getProgressBarData = () => {
    const fields = tachographData || {};
    const isWork =
      tachographData?.tachographStatus === "WORK" ||
      tachographData?.tachographStatus === "DRIVE";

    return [
      {
        label: isWork
          ? t("fleetControl.vehicleDetailsDriver.drivingSince")
          : t("fleetControl.hoursOfService.restSince"),
        value: fields.activityDuration
          ? formatDuration(fields.activityDuration, false)
          : "--:--",
        color: "#00cfa2",

        progress: fields.activityDuration, // Current value of the progressBar
        max: isWork ? fields.maxDriveTime : fields.maxRestTime, // Max value of the progressBar
      },
      {
        label: isWork
          ? t("fleetControl.vehicleDetailsDriver.nextBreak")
          : t("fleetControl.hoursOfService.timeUntilNextDrive"),
        value: isWork
          ? fields.timeUntilRest
            ? formatDuration(fields.timeUntilRest, false)
            : "--:--"
          : fields.timeUntilDrive
          ? formatDuration(fields.timeUntilDrive, false)
          : "--:--",
        color: "#f7ab82",

        progress: isWork ? fields.timeUntilRest : fields.timeUntilDrive ?? 0, // Current value of the progressBar
        max: isWork ? fields.maxDriveTime : fields.maxRestTime, // Max value of the progressBar
      },
      {
        label: t("fleetControl.vehicleDetailsDriver.available"),
        value: fields.dailyDriveAvailable
          ? formatDuration(fields.dailyDriveAvailable, false)
          : "--:--",
        color: "#00aaff",

        progress: fields?.dailyDriveAvailable ? fields.dailyDriveAvailable : 0, // Current value of the progressBar
        max: fields.maxDriveAvailable, // Max value of the progressBar
      },
    ];
  };

  const progressBarData = getProgressBarData();

  let drivenToday = tachographData?.dailyDriveTime
    ? formatDuration(tachographData?.dailyDriveTime, false)
    : "--:--";

  let hoursAvailableTomorrow = tachographData?.tomorrowDriveAvailable
    ? formatDuration(tachographData?.tomorrowDriveAvailable, false)
    : "--:--";

  let restedToday = tachographData?.todayRest
    ? formatDuration(tachographData?.todayRest, false)
    : "--:--";

  let restRemainingToday = tachographData?.dailyRestAvailable
    ? formatDuration(tachographData?.dailyRestAvailable, false)
    : "--:--";

  let weeklyRestRequired = tachographData?.minWeeklyRest
    ? formatDuration(tachographData?.minWeeklyRest, false)
    : "--:--";

  let drivingTimeLeftThisWeek = tachographData?.weekDriveTimeLeft
    ? formatDuration(tachographData?.weekDriveTimeLeft, false)
    : "--:--";

  let driverLastUpdate = driverStatus?.dynamicFields?.lastUpdate
    ? ConvertTimeZone(
        driverStatus?.dynamicFields?.lastUpdate,
        preferencesContext.timeZone,
        preferencesContext.localeFormat
      )
    : "";

  // Shows sloe and status only if the driver has inserted the card
  let show_Slot_Status =
    tachographData && tachographData?.cardPresence === "CARD_PRESENT";

  // Shows progress data only if the driver has inserted the card and is in Work, Drive or Rest mode
  let show_ProgressBar =
    show_Slot_Status &&
    (tachographData?.tachographStatus === "REST" ||
      tachographData?.tachographStatus === "DRIVE" ||
      tachographData?.tachographStatus === "WORK");

  return (
    <div className="mn-vehicle-details__driver-detail">
      <div>
        {t("fleetControl.vehicleDetailsDriver.driver") +
          " " +
          tachographData.slot}
      </div>
      <>
        <div className="mn-vehicle-details__driver-detail-upper-row">
          <div className="data-label-driver">
            <DataLabel
              dataText={driverName}
              icon={<IconUser size={12} />}
              label={t("fleetControl.vehicleDetailsDriver.currentDriver")}
            />
          </div>
          <div className="data-label-driver">
            <DataLabel
              dataText={
                capitalizeFirstLetter(driverStatus?.driverStatus) ??
                t("common.na")
              }
              icon={currentIcon(driverStatus?.driverStatus)}
              label={t("fleetControl.hoursOfService.status")}
            />
          </div>
        </div>
        <div className="mn-vehicle-details__driver-detail-upper-row">
          <div className="data-label-driver">
            <DataLabel
              dataText={
                cardRecognition(tachographData?.cardPresence) ?? t("common.na")
              }
              icon={<CreditBalance size={12} />}
              hasIssue={tachographData?.cardPresence === "ERROR"}
              label={t("fleetControl.hoursOfService.card")}
            />
          </div>
          {show_Slot_Status && (
            <div className="data-label-driver">
              <DataLabel
                dataText={
                  tachographData
                    ? `Slot ${tachographData.slot}`
                    : `${t("common.na")}`
                }
                icon={<IconLicense size={14} />}
                label={t("fleetControl.vehicleDetailsDriver.chronotachograph")}
              />
            </div>
          )}
        </div>
        {show_Slot_Status && (
          <>
            <div className="mn-vehicle-details__driver-detail-upper-row">
              <div className="data-label-driver">
                <DataLabel
                  dataText={activityRecognition(
                    tachographData?.tachographStatus
                  )}
                  icon={activityIconRecognition(
                    tachographData?.tachographStatus
                  )}
                  label={t("fleetControl.hoursOfService.activityStatus")}
                />
              </div>
            </div>
          </>
        )}
        {show_ProgressBar && (
          <>
            <div className="divider" />
            {/* REGION PROGRESS BAR  */}
            <div className="progress-bar-container-drivers">
              {tachographData.tachographStatus != null &&
                progressBarData != null &&
                progressBarData.map((bar, index) => (
                  <div key={index} className="progress-bar-container-driver">
                    <span className="progress-bar-driver-data-label">
                      <Tooltip />
                      <span
                        data-for="tooltip"
                        data-tooltip-delay-hide={1000}
                        data-tip={bar.label}
                      >
                        {bar.label}
                      </span>
                    </span>
                    <span className="progress-bar-driver-data">
                      {bar.value}
                    </span>
                    <div className="progress-bar-driver">
                      <Tooltip />
                      <span
                        data-for="tooltip"
                        data-tooltip-delay-hide={1000}
                        data-tip={`${formatDuration(
                          bar.progress,
                          false
                        )} / ${formatDuration(bar.max, false)}`}
                      >
                        <ProgressBar
                          value={bar.progress}
                          max={bar.max}
                          color={bar.color}
                        />
                      </span>
                    </div>
                  </div>
                ))}
            </div>
            {/* ENDREGION PROGRESS BAR  */}
            {/* REGION HOURS CHRONO  */}
            <div className="mn-vehicle-details__driver-detail-upper-row">
              <div className="data-label-driver">
                <DataLabel
                  dataText={drivenToday}
                  label={t("fleetControl.hoursOfService.drivenToday")}
                />
              </div>
              <div className="data-label-driver">
                <DataLabel
                  dataText={hoursAvailableTomorrow}
                  label={t(
                    "fleetControl.hoursOfService.hoursAvailableTomorrow"
                  )}
                />
              </div>
            </div>
            <div className="mn-vehicle-details__driver-detail-upper-row">
              <div className="data-label-driver">
                <DataLabel
                  dataText={restedToday}
                  label={t("fleetControl.hoursOfService.restedToday")}
                />
              </div>
              <div className="data-label-driver">
                <DataLabel
                  dataText={restRemainingToday}
                  label={t("fleetControl.hoursOfService.restRemainingToday")}
                />
              </div>
            </div>
            <div className="mn-vehicle-details__driver-detail-upper-row">
              <div className="data-label-driver">
                <DataLabel
                  dataText={weeklyRestRequired}
                  label={t("fleetControl.hoursOfService.weeklyRestRequired")}
                />
              </div>
              <div className="data-label-driver">
                <DataLabel
                  dataText={drivingTimeLeftThisWeek}
                  label={t(
                    "fleetControl.hoursOfService.drivingTimeLeftThisWeek"
                  )}
                />
              </div>
            </div>
            <div className="mn-vehicle-details__driver-detail-upper-row-last-update">
              {t("fleetControl.vehicleDetailsDriver.lastUpdate")}
              {driverLastUpdate}
            </div>
          </>
        )}
        {/* ENDREGION HOURS CHRONO  */}
        <div className="mn-vehicle-details__actions">
          <Link
            to={"/dashboard/drivers/details/" + driver.id}
            target="_blank"
            rel="noreferrer"
          >
            <Button
              label={t("fleetControl.vehicleDetailsDriver.details")}
              size="small"
              id="driver-details"
              disabled={!(driver && !_.isEmpty(driver))}
            >
              <IconList color="--global-colors-ink-ink" size={14} />
            </Button>
          </Link>
          {(process.env.REACT_APP_DEPLOY_TARGET === "local" ||
            process.env.REACT_APP_DEPLOY_TARGET === "stage") && (
            <Link
              to={"/dashboard/drivers/details/" + driver.id}
              target="_blank"
              rel="noreferrer"
            >
              <Button
                label={t("fleetControl.vehicleDetailsDriver.details")}
                size="small"
                id="driver-details"
                disabled={!(driver && !_.isEmpty(driver))}
              >
                <IconList color="--global-colors-ink-ink" size={14} />
              </Button>
            </Link>
          )}
        </div>
      </>
    </div>
  );
};

export default TachographCard;
