import { t } from "i18next";
import _ from "lodash";
import PropTypes from "prop-types";
import { useContext, useEffect, useRef, useState } from "react";
import { Button } from "../../ui/Button/Button";
import { FormCtx } from "../../ui/Forms/Form";
import { IconArrowDown } from "../../ui/Icon/Line/ArrowDown";
import { IconClose } from "../../ui/Icon/Line/Close";
import { IconSearch } from "../../ui/Icon/Line/Search";

const BeaconsSelect = (props) => {
  const { id } = props;
  const { options } = props;
  const { defaultValue } = props;
  const wrapperRef = useRef(null);
  const parentRef = useRef(null);
  let inputElement = null;
  const { setFields, addField, fields, validateField } = useContext(FormCtx);
  const field = fields[id] || {};
  const {
    size,
    isDisabled,
    value,
    itemAttribute,
    name,
    validate,
    menuIsOpen,
    clearable,
    noOptionsMessage,
    searchable,
    label,
    events = {},
    classes = {},
    hasButton,
    buttonText,
    createClick,
    updateClick,
    children,
    updatedElement,
    selectedElement,
  } = field;
  const [isOpen, setIsOpen] = useState(menuIsOpen || false);
  const { onChange, ...restEvents } = events;
  let { contClass } = classes;
  const [hiddenValue, setHiddenValue] = useState({
    value: value ?? "",
    label: "",
  });

  const setNativeValue = (element, val) => {
    const valueSetter = Object.getOwnPropertyDescriptor(element, "value").set;
    const prototype = Object.getPrototypeOf(element);
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(
      prototype,
      "value"
    ).set;

    if (valueSetter && valueSetter !== prototypeValueSetter) {
      prototypeValueSetter.call(element, val);
    } else {
      valueSetter.call(element, val);
    }
  };

  if ("normal" !== size) {
    contClass = [contClass, "mn-select--small"].join(" ");
  }

  if (hiddenValue.value) {
    contClass = [contClass, "mn-select--has-value"].join(" ");
  }

  if (isDisabled) {
    contClass = [contClass, "mn-select--is-disabled"].join(" ");
  }

  if (isOpen) {
    contClass = [contClass, "mn-select--is-open"].join(" ");
  }

  const handleChange = (event) => {
    const inputValue = JSON.parse(event.target.value).value;

    try {
      setFields(event, field);
    } catch (error) {
      console.err(error);
      throw error;
    }

    if (typeof onChange === "function") {
      onChange({
        ...field,
        value: inputValue,
      });
    }
  };

  useEffect(() => {
    if (defaultValue && Object.keys(defaultValue).length) {
      setHiddenValue({
        value: defaultValue.value,
        label: defaultValue.label,
      });
      setNativeValue(
        inputElement,
        JSON.stringify({
          value: defaultValue.value,
          label: defaultValue.label,
        })
      );
    }
  }, [defaultValue]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        !parentRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    addField({
      field: props,
      value,
    });
  }, [props]);

  useEffect(() => {
    setIsOpen(menuIsOpen);
  }, [menuIsOpen]);

  // Display selected menu item in dropdown
  function displayValue() {
    if (options.length === 0) {
      return "";
    } else if (options.length === 1) {
      if (options[0][itemAttribute] !== undefined) {
        return options[0][itemAttribute];
      } else {
        return "";
      }
    } else {
      if (options && options.length > 1) {
        return `${options.length} ${t("common.itemSelected")}`;
      } else {
        return options.map((el) => el[options]);
      }
    }
  }

  // Mark menu item with the option selected or with default option (when value is not empty)
  function optionSelected(opt, label) {
    return opt.label === label;
  }

  useEffect(() => {
    if (field.value !== undefined && field.value !== "") {
      if (typeof field.value === "string") {
        field.value = JSON.parse(field.value);
        validateField(id);
      }
    }
  }, [hiddenValue]);

  const fieldProps = {
    ...restEvents,
    id,
    name,
    validate,
    value: hiddenValue.value,
    onChange: handleChange,
  };

  function manageValue() {
    if (!_.isEmpty(updatedElement) && hiddenValue.label != "") {
      return (
        updatedElement.name +
        " (" +
        updatedElement.minTemperature +
        "°C " +
        t("common.completedAt") +
        " " +
        updatedElement.maxTemperature +
        "°C)"
      );
    } else if (updatedElement == null) {
      return t("common.customThresholds");
    } else if (selectedElement) {
      return selectedElement;
    } else {
      return hiddenValue.label
        ? hiddenValue.label
        : options.length > 0
        ? options[0].label
        : noOptionsMessage;
    }
  }

  return (
    field &&
    field.value !== undefined && (
      <>
        <div className={[contClass, "mn-select"].join(" ")} ref={parentRef}>
          {label && <label htmlFor={field.id}>{label}</label>}
          <div className="mn-select__inner" ref={wrapperRef}>
            <div
              key={"mn-select__inner-field"}
              className="mn-select__inner-field"
              onClick={(event) => {
                if (
                  !(
                    event.target.classList.contains("mn-select__clear-btn") ||
                    event.target?.ownerSVGElement?.parentElement.classList.contains(
                      "mn-select__clear-btn"
                    )
                  )
                ) {
                  setIsOpen(!isOpen);
                }
              }}
            >
              <input
                type="text"
                style={{ display: "none" }}
                value={displayValue()}
                ref={(input) => {
                  if (input) {
                    inputElement = input;
                  }
                }}
                {...fieldProps}
              />
              <span key={"mn-select__label"} className="mn-select__label">
                {manageValue()}
              </span>
              {clearable && hiddenValue.value !== "" && (
                <span
                  className="mn-select__clear-btn"
                  onClick={() => {
                    setHiddenValue({
                      value: null,
                      label: null,
                    });
                    setNativeValue(
                      inputElement,
                      JSON.stringify({
                        value: null,
                        label: null,
                      })
                    );
                  }}
                >
                  <IconClose size={9} color="--global-colors-ui-white" />
                </span>
              )}
              <span
                className={
                  isOpen ? "mn-select__drop-btn-open" : " mn-select__drop-btn"
                }
              >
                <IconArrowDown size={14} color="--global-colors-ink-light" />
              </span>
            </div>
            {isOpen && (
              <div className="mn-select__options">
                {searchable && (
                  <div className="mn-select__options-search">
                    <input type="search" onChange={(e) => {}} />
                    <IconSearch size={14} color="--global-colors-ink-light" />
                  </div>
                )}

                {options.map((option, index) => {
                  if (Object.keys(option).includes("options")) {
                    return option.options.map((suboption, idx) => {
                      return (
                        <>
                          {idx === 0 && (
                            <div
                              className="mn-select__options-groups"
                              key={option.label + idx}
                            >
                              {option.label}
                            </div>
                          )}
                          {suboption.openModal ? (
                            <>
                              <div
                                className={`mn-select__option${
                                  suboption.openModal ? "_icon" : ""
                                } ${
                                  optionSelected(hiddenValue, suboption.label)
                                    ? "selected"
                                    : ""
                                }`}
                                type="button"
                                key={suboption.value + idx}
                                data-value={suboption.value}
                                onClick={(e) => {
                                  setHiddenValue({
                                    value: e.currentTarget.dataset.value,
                                    label: e.currentTarget.innerText.replace(
                                      "\n",
                                      ""
                                    ),
                                  });
                                  setIsOpen(false);
                                  setNativeValue(
                                    inputElement,
                                    JSON.stringify({
                                      value: e.currentTarget.dataset.value,
                                      label: e.currentTarget.innerText.replace(
                                        "\n",
                                        ""
                                      ),
                                    })
                                  );
                                  inputElement.dispatchEvent(
                                    new Event("input", { bubbles: true })
                                  );
                                }}
                              >
                                <div
                                  key={suboption.label + idx}
                                  className="mn-select__item"
                                >
                                  <span
                                    style={{
                                      marginRight: "12px",
                                    }}
                                    key={suboption.label + idx + 1}
                                  >
                                    {suboption.label}
                                  </span>
                                  {suboption.openModal.icon !== undefined && (
                                    <div
                                      onClick={() => {
                                        setIsOpen(false);
                                        updateClick(suboption);
                                      }}
                                    >
                                      {optionSelected(
                                        hiddenValue,
                                        suboption.label
                                      )
                                        ? suboption.openModal.iconSelected
                                        : suboption.openModal.icon}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <button
                                type="button"
                                className={`mn-select__option${
                                  suboption.openModal ? "_icon" : ""
                                } ${
                                  optionSelected(hiddenValue, suboption.label)
                                    ? "selected"
                                    : ""
                                }`}
                                key={suboption.value + idx}
                                data-value={suboption.value}
                                onClick={(e) => {
                                  setHiddenValue({
                                    value: e.currentTarget.dataset.value,
                                    label: e.currentTarget.innerText,
                                  });
                                  setIsOpen(false);
                                  setNativeValue(
                                    inputElement,
                                    JSON.stringify({
                                      value: e.currentTarget.dataset.value,
                                      label: e.currentTarget.innerText.replace(
                                        "\n",
                                        ""
                                      ),
                                    })
                                  );
                                  inputElement.dispatchEvent(
                                    new Event("input", { bubbles: true })
                                  );
                                }}
                              >
                                <div
                                  key={suboption.label + idx}
                                  className="mn-select__item"
                                >
                                  <span key={suboption.label + idx + 1}>
                                    {suboption.label}
                                  </span>
                                </div>
                              </button>
                            </>
                          )}
                        </>
                      );
                    });
                  } else {
                    return (
                      <button
                        type="button"
                        className="mn-select__option"
                        key={option.value + index}
                        data-value={option.value}
                        onClick={(e) => {
                          setHiddenValue({
                            value: e.currentTarget.dataset.value,
                            label: e.currentTarget.innerText,
                          });
                          setIsOpen(false);
                          setNativeValue(
                            inputElement,
                            JSON.stringify({
                              value: e.currentTarget.dataset.value,
                              label: e.currentTarget.innerText,
                            })
                          );
                          inputElement.dispatchEvent(
                            new Event("input", { bubbles: true })
                          );
                        }}
                      >
                        <div
                          key={option.label + index}
                          className="mn-select__item"
                        >
                          {option.icon !== undefined && option.icon}
                          {option.color !== undefined && (
                            <span
                              key={option.label + index + 0}
                              className="mn-select__color-icon"
                              style={{
                                width: "14px",
                                height: "14px",
                                backgroundColor: option.color,
                                borderRadius: "150px",
                                display: "inline-block",
                              }}
                            ></span>
                          )}
                          <span key={option.label + index + 1}>
                            {option.label}
                          </span>
                        </div>
                      </button>
                    );
                  }
                })}
                {hasButton && (
                  <div className="mn-select-item-custom">
                    <div className="split-line"></div>
                    <div className="dropdown-button">
                      <Button
                        aspect="ghost"
                        size="small"
                        label={buttonText}
                        onClick={() => {
                          createClick();
                          setIsOpen(false);
                        }}
                      >
                        {children}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </>
    )
  );
};

BeaconsSelect.propTypes = {
  /**
   * Show the clear button.
   */
  clearable: PropTypes.bool,
  noOptionsMessage: PropTypes.string,
  menuIsOpen: PropTypes.bool,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(["normal", "small"]),
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
  searchable: PropTypes.bool,
  hasButton: PropTypes.bool,
  createClick: PropTypes.any,
  updateClick: PropTypes.any,
  buttonText: PropTypes.string,
  children: PropTypes.any,
};

BeaconsSelect.defaultProps = {
  noOptionsMessage: t("common.noThresholdSelected"),
  placeholder: t("common.selectOption"),
  size: "normal",
  isDisabled: false,
  menuIsOpen: false,
  clearable: false,
  searchable: false,
};

export default BeaconsSelect;
