import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { store } from "../../app/store";
import { Button } from "../../ui/Button/Button";
import { Dropdown } from "../../ui/Dropdown/Dropdown";
import Form from "../../ui/Forms/Form";
import SearchField from "../../ui/Forms/SearchField";
import { IconAddView } from "../../ui/Icon/Line/AddView";
import { getQueryString } from "../../utils/Utils";
import { VehicleStatusCounts } from "../vehicle/vehiclesStatusAmountSlice";
import { statusVehicleType } from "../vehicle/vehiclesStatusSlice";
import { getVehiclesViewAsync } from "../vehicle/vehiclesStatusViewSlice";
import {
  getVehicleTypesCountAsync,
  vehicleTypeCountSelectors,
} from "../vehicle/vehicleTypeCountSlice";
import "./FleetControlFilterBar.css";
import { fleetViewsSelectors, getFleetViewsAsync } from "./fleetViewsSlice";

interface QueryParams {
  [paramName: string]: any;
}

interface FleetControlFilterBarProps {
  selectedVehicleStatus: VehicleStatusCounts;
  callback: (buildQueryParam: string) => any;
  vehiclesSearch: (searchKeya: string) => any;
}

interface JsonOption {
  label: string;
  code: any;
}
interface DropDownItem {
  label: string;
  value: string;
  checked?: boolean;
}

export const FleetControlFilterBar: React.FC<FleetControlFilterBarProps> = ({
  selectedVehicleStatus,
  callback,
  vehiclesSearch,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [idDriverVehicle, setIdDriverVehicle] = useState("");
  const [statusesValues, setStatusesValues] = useState([] as DropDownItem[]);
  const [vehicleStatusesColdStart, setVehicleStatusesColdStart] =
    useState(true);
  const fleets = fleetViewsSelectors.selectAll(store.getState());
  const vehicleTypeCount = vehicleTypeCountSelectors.selectAll(
    store.getState()
  );
  const groupsValues: DropDownItem[] = !!fleets
    ? fleets.map((value) => {
        return {
          label: value.name,
          value: value.name,
          count: value.vehiclesSize,
        };
      })
    : [];
  const vehicleTypeCountValue: DropDownItem[] = !!vehicleTypeCount
    ? vehicleTypeCount.map((value) => {
        return {
          label: t(`common.optionsEnum.type.${value.name}`),
          value: value.name,
          count: value.count,
        };
      })
    : [];
  const queryParamsRef = useRef<QueryParams>({});
  let queryParams: QueryParams = queryParamsRef.current;
  const [currentlySelectedValueForSort, setCurrentlySelectedValueForSort] =
    useState<JsonOption>({
      label: t("fleetControl.filterBar.optionOrderBy.lastUpdate"),
      code: "dynamicFields.lastUpdate,DESC",
    });
  const sortByOptions = new Map();
  sortByOptions.set(
    t("fleetControl.filterBar.optionOrderBy.signalState"),
    "dynamicFields.vehicleStatus,ASC"
  );
  sortByOptions.set(
    t("fleetControl.filterBar.optionOrderBy.lastUpdate"),
    "dynamicFields.lastUpdate,DESC"
  );
  sortByOptions.set(t("fleetControl.filterBar.optionOrderBy.id"), "alias");
  sortByOptions.set(t("fleetControl.filterBar.optionOrderBy.driverName"), [
    "driver.firstName",
    "driver.lastName",
  ]);
  const sortByValues: DropDownItem[] = Array.from(sortByOptions.keys()).map(
    (key: string) => {
      return { label: key, value: key };
    }
  );

  useEffect(() => {
    store.dispatch(getFleetViewsAsync());
    store.dispatch(getVehicleTypesCountAsync());

    const initialValues = _.keys(statusVehicleType)
      .map((key) => key)
      .filter((x) => x !== statusVehicleType.UNKNOWN);
    handleChanges(initialValues, ["vehicleStatus"], false);
    handleChanges("dynamicFields.lastUpdate,DESC", ["sort"], false);
  }, []);

  // This method updates the dropdown value based on the selectedVehicleStatus from statusFilterBadge component.
  useEffect(() => {
    setStatusesValues(
      _.keys(statusVehicleType)
        .filter((key) => key !== statusVehicleType?.UNKNOWN)
        .map((key) => {
          return {
            label: t(
              `fleetControl.filterBar.optionStatusVehicle.${key.toLowerCase()}`
            ),
            checked:
              !!selectedVehicleStatus && selectedVehicleStatus.status
                ? key === selectedVehicleStatus?.status
                : _.isEmpty(selectedVehicleStatus),
            value: key,
          };
        })
    );

    !!selectedVehicleStatus && selectedVehicleStatus?.status
      ? handleChanges(
          selectedVehicleStatus?.status,
          ["vehicleStatus"],
          !vehicleStatusesColdStart
        )
      : handleChanges(
          _.keys(statusVehicleType)
            .map((key) => key)
            .filter((x) => x !== statusVehicleType.UNKNOWN),
          ["vehicleStatus"],
          !vehicleStatusesColdStart
        );

    if (vehicleStatusesColdStart) {
      setVehicleStatusesColdStart(false);
    }
  }, [selectedVehicleStatus, statusVehicleType]);

  const handleChanges = (
    data: string[] | string,
    params: string[],
    executeDispatch: boolean = true
  ): void => {
    let queryString = "";
    let executeQuery = false;
    if (!!data && data.length > 0 && data !== "") {
      params.forEach((param) => {
        if (queryParams[param] !== data) {
          queryParams[param] = data;
          executeQuery = true;
        }
      });
    } else {
      params.forEach((param) => {
        if (queryParams.hasOwnProperty(param)) {
          delete queryParams[param];
          executeQuery = true;
        }
      });
    }
    queryString = getQueryString(queryParams);
    // effettuare chiamata API con parametri in query string
    if (executeQuery && executeDispatch) {
      store.dispatch(getVehiclesViewAsync(queryString));
    }
    callback(queryString);
  };

  return (
    <div className="filter-row">
      <div className="fleet-control-search">
        <Form>
          <SearchField
            name="search"
            id="search-field"
            size="small"
            placeholder={t("fleetControl.filterBar.searchField")}
            value={idDriverVehicle}
            onChange={(val: string) => {
              vehiclesSearch && vehiclesSearch(val);
              setIdDriverVehicle(val);
            }}
          />
        </Form>
      </div>
      <div className="dropdown-groups">
        <Dropdown
          placeholderInValue={t(
            "fleetControl.filterBar.placeholderInValGroups"
          )}
          hasCheckbox={true}
          itemAttribute="label"
          placeholder={t("fleetControl.filterBar.searchGroups")}
          size={"small"}
          onChange={(val: DropDownItem[]) => {
            handleChanges(
              val.map((x) => x.label),
              ["fleet.name"]
            );
          }}
          options={groupsValues}
        />
      </div>
      <div className="dropdown-groups">
        <Dropdown
          placeholderInValue={t("fleetControl.filterBar.placeholderInValTypes")}
          hasCheckbox={true}
          itemAttribute="label"
          placeholder={t("fleetControl.filterBar.searchVehicleTypes")}
          size={"small"}
          onChange={(val: DropDownItem[]) => {
            handleChanges(
              val.map((x) => x.value),
              ["vehicle.type"]
            );
          }}
          options={vehicleTypeCountValue}
        />
      </div>
      <div className="dropdown-statuses">
        <Dropdown
          placeholderInValue={t(
            "fleetControl.filterBar.placeholderInValStatus"
          )}
          hasCheckbox={true}
          itemAttribute="label"
          placeholder={t("fleetControl.filterBar.searchStatus")}
          size={"small"}
          onChange={(val: DropDownItem[]) => {
            handleChanges(
              val.map((x) => x.value),
              ["vehicleStatus"]
            );
            setStatusesValues(
              statusesValues.map((statusesValue) => {
                if (val.includes(statusesValue)) {
                  statusesValue.checked = true;
                } else {
                  statusesValue.checked = false;
                }
                return statusesValue;
              })
            );
          }}
          value={statusesValues.filter((x) => x.checked)}
          options={statusesValues}
        />
      </div>
      <div className="dropdown-orderby">
        <Dropdown
          placeholderInValue={t(
            "fleetControl.filterBar.placeholderInValOrderBy"
          )}
          itemAttribute="label"
          placeholder={t("fleetControl.filterBar.orderBy")}
          size={"small"}
          onChange={(val: DropDownItem[]) => {
            const selectedSortAttribute =
              val[0]?.label ??
              "fleetControl.filterBar.optionOrderBy.lastUpdate";
            const selectedSortValue =
              sortByOptions.get(val[0]?.label) ??
              "dynamicFields.lastUpdate,DESC";
            handleChanges(selectedSortValue, ["sort"]);
            setCurrentlySelectedValueForSort({
              label: t(selectedSortAttribute),
              code: selectedSortValue,
            });
          }}
          options={sortByValues}
          value={currentlySelectedValueForSort}
        />
      </div>
      {(process.env.REACT_APP_DEPLOY_TARGET === "local" ||
        process.env.REACT_APP_DEPLOY_TARGET === "stage") && (
        <div className="multiview-btn">
          <Button
            aspect="primary"
            size="small"
            label="Multi View"
            onlyIcon={false}
            onClick={() => navigate("/multiview")}
          >
            <IconAddView
              className=""
              size={14}
              color="--global-colors-ui-white"
            />
          </Button>
        </div>
      )}
    </div>
  );
};
