import PropTypes from "prop-types";

export const IllustrationPackage = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 56 61"
    >
      <g clip-path="url(#clip0_643_15292)">
        <path
          opacity="0.29"
          d="M29.4957 42.7154L53.3795 37.0471V41.1787L29.4957 51.4355V42.7154Z"
          fill="#EDD6B7"
        />
        <g opacity="0.2">
          <path
            d="M18.2497 37.047L31.3305 30.8071L55.5766 45.5922C55.5766 45.5922 48.8793 49.4301 46.4334 50.8321C41.025 53.9323 29.5374 60.7238 29.5374 60.7238L3.72729 45.6628L18.2497 37.047Z"
            fill="black"
          />
        </g>
        <path
          d="M41.8744 37.0805L15.917 52.1865L17.3938 53.0464L43.3503 37.9395L41.8744 37.0805Z"
          fill="#927246"
        />
        <path
          d="M43.3503 38.5048L17.3938 53.6118V53.0464L43.3503 37.9395V38.5048Z"
          fill="#C3A46F"
        />
        <path
          d="M31.1108 45.063L29.634 45.9228V43.3713L31.1108 42.5115V45.063Z"
          fill="#DFBB7D"
        />
        <path
          d="M18.8706 52.1865L17.3938 53.0464V50.4949L18.8706 49.635V52.1865Z"
          fill="#DFBB7D"
        />
        <path
          d="M43.3503 37.9394L41.8744 38.7993V36.2469L43.3503 35.3879V37.9394Z"
          fill="#DFBB7D"
        />
        <path
          d="M15.917 52.7528L17.3938 53.6118V53.0464L15.917 52.1865V52.7528Z"
          fill="#927246"
        />
        <path
          d="M15.917 52.1865L17.3938 53.0464V50.4949L15.917 49.635V52.1865Z"
          fill="#A98158"
        />
        <path
          d="M28.1573 45.063L29.6341 45.9228V43.3713L28.1573 42.5115V45.063Z"
          fill="#A98158"
        />
        <path
          d="M40.3976 37.9394L41.8744 38.7993V36.2469L40.3976 35.3879V37.9394Z"
          fill="#A98158"
        />
        <path
          d="M29.7491 30.024L3.7926 45.1301L5.26938 45.9899L31.2259 30.883L29.7491 30.024Z"
          fill="#927246"
        />
        <path
          d="M31.2259 30.883L29.7491 31.7428V29.1904L31.2259 28.3315V30.883Z"
          fill="#DFBB7D"
        />
        <path
          d="M28.2723 30.883L29.7491 31.7428V29.1904L28.2723 28.3315V30.883Z"
          fill="#A98158"
        />
        <path
          d="M31.2259 31.4484L5.26941 46.5553V45.9899L31.2259 30.883V31.4484Z"
          fill="#C3A46F"
        />
        <path
          d="M18.9856 38.0065L17.5088 38.8664V36.314L18.9856 35.455V38.0065Z"
          fill="#DFBB7D"
        />
        <path
          d="M6.74619 45.13L5.26941 45.9899V43.4384L6.74619 42.5786V45.13Z"
          fill="#DFBB7D"
        />
        <path
          d="M29.7491 26.9062L3.7926 42.0132L5.26938 42.8721L31.2259 27.7652L29.7491 26.9062Z"
          fill="#EDC894"
        />
        <path
          d="M31.2259 28.3315L5.26941 43.4384V42.8721L31.2259 27.7652V28.3315Z"
          fill="#C3A46F"
        />
        <path
          d="M3.7926 45.6964L5.26938 46.5553V45.9899L3.7926 45.1301V45.6964Z"
          fill="#927246"
        />
        <path
          d="M3.7926 45.13L5.26938 45.9899V43.4384L3.7926 42.5786V45.13Z"
          fill="#A98158"
        />
        <path
          d="M16.033 38.0065L17.5088 38.8664V36.314L16.033 35.455V38.0065Z"
          fill="#A98158"
        />
        <path
          d="M53.9987 44.137L28.0422 59.2439L29.5181 60.1029L55.4755 44.996L53.9987 44.137Z"
          fill="#EFD9A7"
        />
        <path
          d="M55.4755 45.5614L29.5181 60.6683V60.1029L55.4755 44.996V45.5614Z"
          fill="#C3A46F"
        />
        <path
          d="M43.2352 52.1195L41.7584 52.9794V50.4279L43.2352 49.568V52.1195Z"
          fill="#DFBB7D"
        />
        <path
          d="M30.9948 59.2439L29.5181 60.1029V57.5514L30.9948 56.6915V59.2439Z"
          fill="#DFBB7D"
        />
        <path
          d="M55.4756 44.996L53.9988 45.8558V43.3034L55.4756 42.4445V44.996Z"
          fill="#DFBB7D"
        />
        <path
          d="M28.0422 59.8093L29.5181 60.6683V60.1029L28.0422 59.244V59.8093Z"
          fill="#927246"
        />
        <path
          d="M28.0422 59.2439L29.5181 60.1029V57.5514L28.0422 56.6915V59.2439Z"
          fill="#A98158"
        />
        <path
          d="M40.2816 52.1195L41.7584 52.9794V50.4279L40.2816 49.568V52.1195Z"
          fill="#A98158"
        />
        <path
          d="M52.522 44.996L53.9988 45.8558V43.3034L52.522 42.4445V44.996Z"
          fill="#A98158"
        />
        <path
          d="M18.9856 35.455L43.2352 49.568L41.7584 50.4278L17.5088 36.314L18.9856 35.455Z"
          fill="#EDC894"
        />
        <path
          d="M53.9987 41.0192L28.0422 56.1261L29.5181 56.985L55.4755 41.879L53.9987 41.0192Z"
          fill="#EDC894"
        />
        <path
          d="M55.4755 42.4444L29.5181 57.5513V56.985L55.4755 41.879V42.4444Z"
          fill="#C3A46F"
        />
        <path
          d="M32.3683 54.6465L29.5181 56.3046V56.9851L32.3683 55.3269V54.6465Z"
          fill="#DFBB7D"
        />
        <path
          d="M36.9892 51.9573L34.1399 53.6154V54.2958L36.9892 52.6377V51.9573Z"
          fill="#DFBB7D"
        />
        <path
          d="M41.611 49.2672L38.7617 50.9253V51.6057L41.611 49.9476V49.2672Z"
          fill="#DFBB7D"
        />
        <path
          d="M46.2328 46.5779L43.3826 48.2361V48.9165L46.2328 47.2584V46.5779Z"
          fill="#DFBB7D"
        />
        <path
          d="M50.8537 43.8878L48.0044 45.5459V46.2264L50.8537 44.5683V43.8878Z"
          fill="#DFBB7D"
        />
        <path
          d="M55.4754 41.1977L52.6261 42.8567V43.5372L55.4754 41.8791V41.1977Z"
          fill="#DFBB7D"
        />
        <path
          d="M3.7926 42.5785L29.5181 57.5513V56.985L3.7926 42.0131V42.5785Z"
          fill="#927246"
        />
        <path
          d="M26.8998 27.8839L52.6262 42.8567V43.5371L26.8998 28.5643V27.8839Z"
          fill="#A98158"
        />
        <path
          d="M55.4755 41.1977L52.6262 42.8567L26.8998 27.8839L29.7491 26.2258L55.4755 41.1977Z"
          fill="#EFD9A7"
        />
        <path
          d="M55.4755 41.1977L52.6262 42.8567L26.8998 27.8839L29.7491 26.2258L55.4755 41.1977Z"
          fill="#EFD9A7"
        />
        <path
          d="M22.278 30.574L48.0043 45.5459V46.2263L22.278 31.2544V30.574Z"
          fill="#A98158"
        />
        <path
          d="M50.8536 43.8878L48.0043 45.5459L22.278 30.574L25.1282 28.915L50.8536 43.8878Z"
          fill="#EFD9A7"
        />
        <path
          d="M47.9962 42.5568L45.7168 43.8833L25.1362 31.905L27.4157 30.5786L47.9962 42.5568Z"
          fill="#EFD9A7"
        />
        <path
          d="M17.6571 33.2632L43.3826 48.236V48.9165L17.6571 33.9436V33.2632Z"
          fill="#A98158"
        />
        <path
          d="M46.2328 46.5779L43.3826 48.236L17.6571 33.2632L20.5064 31.6051L46.2328 46.5779Z"
          fill="#EFD9A7"
        />
        <path
          d="M40.5171 43.915L38.8075 44.9098L23.3718 35.9261L25.0814 34.9313L40.5171 43.915Z"
          fill="#EFD9A7"
        />
        <path
          d="M13.0353 35.9533L38.7617 50.9252V51.6057L13.0353 36.6338V35.9533Z"
          fill="#A98158"
        />
        <path
          d="M41.611 49.2671L38.7617 50.9252L13.0353 35.9533L15.8846 34.2943L41.611 49.2671Z"
          fill="#EFD9A7"
        />
        <path
          d="M33.038 45.2732L31.8982 45.9364L21.6084 39.9473L22.7481 39.2841L33.038 45.2732Z"
          fill="#EFD9A7"
        />
        <path
          d="M8.41443 38.6425L34.1399 53.6153V54.2958L8.41443 39.323V38.6425Z"
          fill="#A98158"
        />
        <path
          d="M36.9892 51.9573L34.1399 53.6154L8.41443 38.6425L11.2637 36.9844L36.9892 51.9573Z"
          fill="#EFD9A7"
        />
        <path
          d="M25.5597 46.6313L24.9898 46.963L19.844 43.9684L20.4139 43.6368L25.5597 46.6313Z"
          fill="#EFD9A7"
        />
        <path
          d="M3.7926 41.3326L29.5181 56.3046V56.985L3.7926 42.0131V41.3326Z"
          fill="#A98158"
        />
        <path
          d="M32.3683 54.6465L29.5181 56.3046L3.7926 41.3327L6.6419 39.6736L32.3683 54.6465Z"
          fill="#EFD9A7"
        />
        <path
          d="M41.2838 34.1393L29.803 40.8216V27.5404L41.2838 20.8582V34.1393Z"
          fill="#D4985B"
        />
        <path
          d="M36.2342 24.8802L34.8527 25.6839V24.602L36.2342 23.7974V24.8802Z"
          fill="#E0AE7B"
        />
        <path
          d="M37.079 26.5673L34.0068 28.355C33.8082 28.471 33.6464 28.3477 33.6464 28.0804V28.0224C33.6464 27.7552 33.8073 27.4444 34.0068 27.3293L37.079 25.5416C37.2776 25.4256 37.4385 25.5489 37.4385 25.8162V25.8742C37.4385 26.1414 37.2776 26.4522 37.079 26.5673Z"
          fill="#965D3B"
        />
        <path
          d="M37.257 25.5045C37.3047 25.5588 37.3343 25.6431 37.3343 25.7554V25.8134C37.3343 26.0807 37.1734 26.3915 36.9739 26.5066L33.9017 28.2942C33.837 28.3323 33.7767 28.3423 33.7246 28.3323C33.7902 28.4075 33.8918 28.4229 34.0077 28.3559L37.08 26.5682C37.2786 26.4522 37.4404 26.1423 37.4404 25.875V25.8171C37.4404 25.6367 37.3667 25.5253 37.2579 25.5045H37.257Z"
          fill="#E6AD73"
        />
        <path
          d="M39.6811 32.6208L39.3125 32.1478L39.2379 32.3299L39.0024 32.2982L39.0923 32.078C39.14 31.9611 39.2227 31.8606 39.3125 31.808C39.4024 31.7555 39.4851 31.7609 39.5328 31.8216L39.9166 32.3145L39.6811 32.6208Z"
          fill="#8C6043"
        />
        <path
          d="M39.9687 33.2496L39.193 33.7008V33.3801L39.9354 32.9479L39.8455 32.8328L40.081 32.5266L40.188 32.6643C40.2374 32.7277 40.2401 32.8428 40.1943 32.9642C40.1485 33.0856 40.0622 33.1952 39.9678 33.2496H39.9687Z"
          fill="#8C6043"
        />
        <path
          d="M38.8703 33.8892L38.6555 34.0143C38.5612 34.0686 38.4749 34.0605 38.429 33.9916C38.3832 33.9237 38.3859 33.8059 38.4353 33.6845L38.8002 32.7902L39.0357 32.8219L38.6879 33.6736L38.8695 33.5676V33.8883L38.8703 33.8892Z"
          fill="#8C6043"
        />
        <path
          d="M39.0061 33.6501L39.407 33.004V33.4163V33.8295L39.0061 33.6501Z"
          fill="#8C6043"
        />
        <path
          d="M39.8869 32.5809L39.9795 31.9158L39.6757 32.31L39.3728 32.7041L39.8869 32.5809Z"
          fill="#8C6043"
        />
        <path
          d="M39.0547 32.4731L39.1472 33.0312L38.8443 32.9895L38.5405 32.9488L39.0547 32.4731Z"
          fill="#8C6043"
        />
        <path
          d="M39.2282 34.3405L38.4857 34.7727V34.5435L39.2282 34.1113V34.3405Z"
          fill="#8C6043"
        />
        <path
          d="M29.803 40.8216L18.3213 34.1393V20.8582L29.803 27.5404V40.8216Z"
          fill="#B07740"
        />
        <path
          d="M24.7525 25.6839L23.371 24.8802V23.7975L24.7525 24.6021V25.6839Z"
          fill="#CC9662"
        />
        <path
          d="M29.803 27.5404L18.3213 20.8582L29.803 14.1759L41.2838 20.8582L29.803 27.5404Z"
          fill="#E6AD73"
        />
        <path
          d="M36.2342 23.7974L34.8527 24.602L23.371 17.9198L24.7525 17.1152L36.2342 23.7974Z"
          fill="#E6BF8C"
        />
        <path
          d="M24.7525 24.602L23.371 23.7974L34.8527 17.1152L36.2342 17.9198L24.7525 24.602Z"
          fill="#E6C498"
        />
        <path
          d="M25.5983 28.355L22.5261 26.5673C22.3274 26.4513 22.1656 26.1414 22.1656 25.8742V25.8162C22.1656 25.5489 22.3265 25.4256 22.5261 25.5416L25.5983 27.3293C25.7969 27.4453 25.9578 27.7552 25.9578 28.0224V28.0804C25.9578 28.3477 25.7969 28.471 25.5983 28.355Z"
          fill="#874F2D"
        />
        <path
          d="M25.7035 28.2943L22.6312 26.5066C22.4326 26.3907 22.2708 26.0808 22.2708 25.8135V25.7555C22.2708 25.6431 22.3005 25.5589 22.3481 25.5045C22.2394 25.5245 22.1656 25.6368 22.1656 25.8171V25.8751C22.1656 26.1424 22.3265 26.4532 22.5252 26.5682L25.5974 28.3559C25.7133 28.423 25.8149 28.4076 25.8805 28.3324C25.8275 28.3423 25.7682 28.3324 25.7035 28.2943Z"
          fill="#D4985B"
        />
        <path
          d="M19.3945 31.6812L21.1211 32.686V34.683L19.3945 33.6782V31.6812ZM19.2893 31.4982V33.7389L19.3945 33.8005L21.1211 34.8053L21.2263 34.8669V32.6262L21.1211 32.5646L19.3945 31.5598L19.2893 31.4982Z"
          fill="#785438"
        />
        <path
          d="M19.9382 32.7132L19.7872 32.6253V33.3538L19.9382 33.4417V32.7132Z"
          fill="#785438"
        />
        <path
          d="M20.1729 32.9923L19.8628 32.2792L19.5518 32.6307L20.1729 32.9923Z"
          fill="#785438"
        />
        <path
          d="M20.714 33.1653L20.563 33.0774V33.8059L20.714 33.8938V33.1653Z"
          fill="#785438"
        />
        <path
          d="M19.5518 33.2939V33.4444L20.9494 34.2571V34.1067L19.5518 33.2939Z"
          fill="#785438"
        />
        <path
          d="M20.9639 33.4525L20.6529 32.7385L20.3428 33.091L20.9639 33.4525Z"
          fill="#785438"
        />
        <path
          d="M21.7711 33.0647L23.4977 34.0696V36.0666L21.7711 35.0617V33.0647ZM21.6659 32.8817V35.1224L21.7711 35.184L23.4977 36.1889L23.6029 36.2505V34.0098L23.4977 33.9482L21.7711 32.9433L21.6659 32.8817Z"
          fill="#785438"
        />
        <path
          d="M22.5216 35.1514C22.4074 35.1723 22.2492 35.0644 22.2492 35.117V35.1623L22.6348 35.3861V33.834L22.2663 33.6193C22.2663 33.6193 22.2402 34.0261 22.2402 34.1656C22.2402 34.4855 22.3984 34.6839 22.5818 34.8615C22.5818 35.1406 22.6366 35.1297 22.5225 35.1505L22.5216 35.1514Z"
          fill="#785438"
        />
        <path
          d="M22.748 35.2828C22.8622 35.4359 23.0204 35.5129 23.0204 35.5655V35.6108L22.6348 35.387V33.8349L23.0033 34.0496C23.0033 34.0496 23.0294 34.4873 23.0294 34.6268C23.0294 34.9467 22.8712 34.9611 22.6878 34.9249C22.6878 35.204 22.633 35.1306 22.7471 35.2828H22.748Z"
          fill="#785438"
        />
        <path
          d="M24.1484 34.4474L25.875 35.4523V37.4492L24.1484 36.4444V34.4474ZM24.0432 34.2644V36.5051L24.1484 36.5667L25.875 37.5716L25.9802 37.6332V35.3925L25.875 35.3308L24.1484 34.326L24.0432 34.2644Z"
          fill="#785438"
        />
        <path
          d="M25.6611 36.4172L24.3605 35.6597L24.4045 35.5673C24.5007 35.3625 24.7425 35.3317 25.0112 35.4876C25.28 35.6443 25.5218 35.956 25.6179 36.2731L25.662 36.4172H25.6611Z"
          fill="#785438"
        />
        <path
          d="M25.1451 36.8403C25.2449 36.8983 25.3258 36.8521 25.3258 36.7361C25.3258 36.7062 25.3042 36.6691 25.2781 36.6537C25.2521 36.6383 25.2305 36.651 25.2305 36.6809C25.2305 36.7361 25.1918 36.7588 25.1442 36.7307C25.0966 36.7026 25.0579 36.6356 25.0579 36.5803V35.5854C25.0579 35.5555 25.0364 35.5184 25.0103 35.503C24.9842 35.4876 24.9626 35.5003 24.9626 35.5302V36.525C24.9626 36.6401 25.0435 36.7814 25.1433 36.8394L25.1451 36.8403Z"
          fill="#785438"
        />
        <path
          d="M24.6625 35.2647C24.6715 35.2701 24.6814 35.2728 24.6894 35.2701C24.711 35.2647 24.7155 35.2339 24.7002 35.2003L24.5726 34.9195C24.5573 34.8859 24.5277 34.8633 24.507 34.8687C24.4854 34.8742 24.4809 34.905 24.4962 34.9385L24.6238 35.2194C24.6328 35.2393 24.6472 35.2565 24.6625 35.2647Z"
          fill="#785438"
        />
        <path
          d="M24.8935 35.2502C24.9034 35.2556 24.9124 35.2583 24.9205 35.2556C24.942 35.2502 24.9465 35.2194 24.9313 35.1858L24.8764 35.0653C24.8612 35.0318 24.8315 35.0092 24.8108 35.0146C24.7892 35.02 24.7848 35.0508 24.8 35.0844L24.8549 35.2049C24.8638 35.2248 24.8782 35.242 24.8935 35.2502Z"
          fill="#785438"
        />
        <path
          d="M24.4099 35.2176C24.4198 35.223 24.4288 35.2257 24.4378 35.223C24.4594 35.2176 24.4639 35.1868 24.4486 35.1533L24.3938 35.0327C24.3785 34.9992 24.3497 34.9766 24.3282 34.982C24.3066 34.9874 24.3021 35.0183 24.3174 35.0518L24.3722 35.1723C24.3812 35.1922 24.3956 35.2094 24.4108 35.2176H24.4099Z"
          fill="#785438"
        />
        <path
          d="M24.1143 24.2306L35.596 17.5483L35.4908 17.4867L24.0092 24.169L24.1143 24.2306Z"
          fill="#D9B484"
        />
        <path
          d="M53.3794 41.1786L41.8977 47.8609V34.5797L53.3794 27.8983V41.1786Z"
          fill="#D4985B"
        />
        <path
          d="M48.3289 31.9195L46.9474 32.7232V31.6413L48.3289 30.8367V31.9195Z"
          fill="#E0AE7B"
        />
        <path
          d="M49.1747 33.6065L46.1025 35.3942C45.9038 35.5102 45.7429 35.387 45.7429 35.1197V35.0617C45.7429 34.7944 45.9038 34.4836 46.1025 34.3685L49.1747 32.5809C49.3733 32.4649 49.5351 32.5881 49.5351 32.8554V32.9134C49.5351 33.1807 49.3742 33.4915 49.1747 33.6065Z"
          fill="#965D3B"
        />
        <path
          d="M49.3517 32.5438C49.3994 32.5981 49.4291 32.6824 49.4291 32.7947V32.8527C49.4291 33.12 49.2682 33.4308 49.0686 33.5459L45.9964 35.3336C45.9317 35.3716 45.8715 35.3816 45.8193 35.3716C45.885 35.4468 45.9865 35.4622 46.1025 35.3952L49.1747 33.6075C49.3733 33.4915 49.5351 33.1816 49.5351 32.9143V32.8564C49.5351 32.676 49.4614 32.5646 49.3526 32.5438H49.3517Z"
          fill="#E6AD73"
        />
        <path
          d="M51.7759 39.6601L51.4074 39.1871L51.3328 39.3692L51.0973 39.3375L51.1872 39.1173C51.2348 39.0004 51.3175 38.8999 51.4074 38.8473C51.4973 38.7948 51.58 38.8002 51.6276 38.8609L52.0114 39.3538L51.7759 39.6601Z"
          fill="#8C6043"
        />
        <path
          d="M52.0644 40.2889L51.2887 40.7401V40.4194L52.0311 39.9872L51.9412 39.8721L52.1767 39.5659L52.2837 39.7036C52.3331 39.767 52.3358 39.8821 52.29 40.0035C52.2442 40.1249 52.1579 40.2345 52.0635 40.2889H52.0644Z"
          fill="#8C6043"
        />
        <path
          d="M50.9661 40.9285L50.7512 41.0536C50.6578 41.1079 50.5706 41.0998 50.5247 41.0309C50.4789 40.9621 50.4816 40.8452 50.531 40.7238L50.8959 39.8295L51.1314 39.8612L50.7836 40.7129L50.9652 40.6069V40.9276L50.9661 40.9285Z"
          fill="#8C6043"
        />
        <path
          d="M51.1018 40.6894L51.5018 40.0433V40.4556V40.8688L51.1018 40.6894Z"
          fill="#8C6043"
        />
        <path
          d="M51.9826 39.6202L52.0743 38.9551L51.7714 39.3493L51.4685 39.7434L51.9826 39.6202Z"
          fill="#8C6043"
        />
        <path
          d="M51.1504 39.5124L51.242 40.0705L50.9391 40.0289L50.6362 39.9881L51.1504 39.5124Z"
          fill="#8C6043"
        />
        <path
          d="M51.3237 41.3797L50.5813 41.8119V41.5827L51.3237 41.1505V41.3797Z"
          fill="#8C6043"
        />
        <path
          d="M41.8977 47.8609L30.416 41.1786V27.8983L41.8977 34.5797V47.8609Z"
          fill="#B07740"
        />
        <path
          d="M36.8481 32.7232L35.4666 31.9195V30.8367L36.8481 31.6413V32.7232Z"
          fill="#CC9662"
        />
        <path
          d="M41.8977 34.5797L30.416 27.8983L41.8977 21.2161L53.3794 27.8983L41.8977 34.5797Z"
          fill="#E6AD73"
        />
        <path
          d="M48.3289 30.8368L46.9473 31.6413L35.4666 24.9591L36.8481 24.1545L48.3289 30.8368Z"
          fill="#E6BF8C"
        />
        <path
          d="M36.8481 31.6413L35.4666 30.8368L46.9473 24.1545L48.3289 24.9591L36.8481 31.6413Z"
          fill="#E6C498"
        />
        <path
          d="M37.693 35.3951L34.6208 33.6075C34.4222 33.4915 34.2604 33.1816 34.2604 32.9143V32.8563C34.2604 32.589 34.4213 32.4658 34.6208 32.5818L37.693 34.3695C37.8917 34.4854 38.0534 34.7953 38.0534 35.0626V35.1206C38.0534 35.3879 37.8926 35.5111 37.693 35.3951Z"
          fill="#874F2D"
        />
        <path
          d="M37.7981 35.3336L34.7259 33.5459C34.5273 33.4299 34.3664 33.12 34.3664 32.8527V32.7947C34.3664 32.6824 34.3961 32.5981 34.4437 32.5438C34.3349 32.5637 34.2612 32.676 34.2612 32.8564V32.9143C34.2612 33.1816 34.4221 33.4924 34.6217 33.6075L37.6939 35.3952C37.8098 35.4622 37.9114 35.4468 37.977 35.3716C37.9249 35.3816 37.8647 35.3716 37.7999 35.3336H37.7981Z"
          fill="#D4985B"
        />
        <path
          d="M31.4902 38.7204L33.2168 39.7253V41.7223L31.4902 40.7174V38.7204ZM31.385 38.5374V40.7781L31.4902 40.8397L33.2168 41.8446L33.322 41.9062V39.6655L33.2168 39.6039L31.4902 38.599L31.385 38.5374Z"
          fill="#785438"
        />
        <path
          d="M32.0331 39.7534L31.8821 39.6655V40.3939L32.0331 40.4818V39.7534Z"
          fill="#785438"
        />
        <path
          d="M32.2686 40.0315L31.9576 39.3184L31.6475 39.6709L32.2686 40.0315Z"
          fill="#785438"
        />
        <path
          d="M32.8097 40.2045L32.6587 40.1166V40.8451L32.8097 40.933V40.2045Z"
          fill="#785438"
        />
        <path
          d="M31.6475 40.3332V40.4836L33.0442 41.2963V41.1459L31.6475 40.3332Z"
          fill="#785438"
        />
        <path
          d="M33.0586 40.4918L32.7485 39.7778L32.4375 40.1302L33.0586 40.4918Z"
          fill="#785438"
        />
        <path
          d="M33.8666 40.104L35.5933 41.1088V43.1058L33.8666 42.101V40.104ZM33.7615 39.921V42.1617L33.8666 42.2233L35.5933 43.2281L35.6985 43.2897V41.049L35.5933 40.9874L33.8666 39.9826L33.7615 39.921Z"
          fill="#785438"
        />
        <path
          d="M34.6163 42.1907C34.5021 42.2106 34.3439 42.1037 34.3439 42.1562V42.2015L34.7295 42.4253V40.8732L34.361 40.6585C34.361 40.6585 34.335 41.0653 34.335 41.2049C34.335 41.5247 34.4932 41.7231 34.6765 41.9007C34.6765 42.1798 34.7313 42.1698 34.6172 42.1898L34.6163 42.1907Z"
          fill="#785438"
        />
        <path
          d="M34.8428 42.322C34.957 42.4752 35.1152 42.5522 35.1152 42.6047V42.65L34.7305 42.4262V40.8741L35.099 41.0889C35.099 41.0889 35.1251 41.5265 35.1251 41.6651C35.1251 41.985 34.9669 41.9995 34.7835 41.9632C34.7835 42.2423 34.7287 42.1689 34.8428 42.3211V42.322Z"
          fill="#785438"
        />
        <path
          d="M36.2431 41.4867L37.9698 42.4915V44.4885L36.2431 43.4837V41.4867ZM36.1379 41.3036V43.5444L36.2431 43.606L37.9698 44.6108L38.0749 44.6724V42.4317L37.9698 42.3701L36.2431 41.3653L36.1379 41.3036Z"
          fill="#785438"
        />
        <path
          d="M37.7568 43.4574L36.4562 42.6999L36.5002 42.6075C36.5964 42.4027 36.8382 42.3719 37.1069 42.5277C37.3757 42.6845 37.6175 42.9962 37.7136 43.3133L37.7577 43.4574H37.7568Z"
          fill="#785438"
        />
        <path
          d="M37.24 43.8796C37.3397 43.9376 37.4206 43.8914 37.4206 43.7754C37.4206 43.7455 37.3991 43.7083 37.373 43.6929C37.3469 43.6775 37.3253 43.6902 37.3253 43.7201C37.3253 43.7754 37.2867 43.798 37.2391 43.77C37.1914 43.7419 37.1528 43.6748 37.1528 43.6195V42.6247C37.1528 42.5948 37.1312 42.5576 37.1051 42.5422C37.0791 42.5268 37.0575 42.5395 37.0575 42.5694V43.5643C37.0575 43.6793 37.1384 43.8207 37.2382 43.8787L37.24 43.8796Z"
          fill="#785438"
        />
        <path
          d="M36.7582 42.3039C36.7681 42.3094 36.7771 42.3121 36.7852 42.3094C36.8067 42.3039 36.8112 42.2731 36.7959 42.2396L36.6683 41.9587C36.653 41.9252 36.6234 41.9025 36.6027 41.908C36.5811 41.9134 36.5766 41.9442 36.5919 41.9777L36.7195 42.2586C36.7285 42.2786 36.7429 42.2958 36.7582 42.3039Z"
          fill="#785438"
        />
        <path
          d="M36.9891 42.2903C36.999 42.2958 37.008 42.2985 37.0161 42.2958C37.0376 42.2903 37.0421 42.2595 37.0268 42.226L36.972 42.1055C36.9567 42.072 36.9271 42.0493 36.9064 42.0548C36.8848 42.0602 36.8803 42.091 36.8956 42.1245L36.9504 42.245C36.9594 42.265 36.9738 42.2822 36.9891 42.2903Z"
          fill="#785438"
        />
        <path
          d="M36.5047 42.2568C36.5146 42.2623 36.5236 42.265 36.5317 42.2623C36.5533 42.2568 36.5577 42.226 36.5425 42.1925L36.4876 42.072C36.4724 42.0385 36.4427 42.0158 36.422 42.0213C36.4005 42.0267 36.396 42.0575 36.4112 42.091L36.4661 42.2115C36.4751 42.2315 36.4894 42.2487 36.5047 42.2568Z"
          fill="#785438"
        />
        <path
          d="M36.2099 31.2698L47.6916 24.5876L47.5856 24.5259L36.1039 31.2082L36.2099 31.2698Z"
          fill="#D9B484"
        />
        <path
          d="M28.8817 41.3571L17.4009 48.0394V34.7591L28.8817 28.0768V41.3571Z"
          fill="#D4985B"
        />
        <path
          d="M23.8321 32.098L22.4506 32.9017V31.8198L23.8321 31.0153V32.098Z"
          fill="#E0AE7B"
        />
        <path
          d="M24.6779 33.7851L21.6057 35.5727C21.407 35.6887 21.2452 35.5655 21.2452 35.2982V35.2402C21.2452 34.9729 21.4061 34.6621 21.6057 34.5471L24.6779 32.7594C24.8765 32.6434 25.0383 32.7666 25.0383 33.0339V33.0919C25.0383 33.3592 24.8774 33.67 24.6779 33.7851Z"
          fill="#965D3B"
        />
        <path
          d="M24.8549 32.7223C24.9026 32.7767 24.9322 32.8609 24.9322 32.9733V33.0313C24.9322 33.2986 24.7713 33.6093 24.5718 33.7244L21.4996 35.5121C21.4349 35.5501 21.3746 35.5601 21.3225 35.5501C21.3881 35.6253 21.4897 35.6407 21.6056 35.5737L24.6779 33.786C24.8765 33.67 25.0383 33.3602 25.0383 33.0929V33.0349C25.0383 32.8546 24.9646 32.7431 24.8558 32.7223H24.8549Z"
          fill="#E6AD73"
        />
        <path
          d="M27.2791 39.8386L26.9106 39.3656L26.836 39.5477L26.6005 39.516L26.6903 39.2959C26.738 39.179 26.8207 39.0784 26.9106 39.0258C27.0004 38.9733 27.0831 38.9787 27.1308 39.0394L27.5146 39.5323L27.2791 39.8386Z"
          fill="#8C6043"
        />
        <path
          d="M27.5676 40.4674L26.7919 40.9187V40.5979L27.5343 40.1657L27.4444 40.0506L27.6799 39.7444L27.7869 39.8821C27.8363 39.9455 27.839 40.0606 27.7932 40.182C27.7473 40.3034 27.661 40.4131 27.5667 40.4674H27.5676Z"
          fill="#8C6043"
        />
        <path
          d="M26.4692 41.1071L26.2544 41.2321C26.1609 41.2865 26.0737 41.2783 26.0279 41.2094C25.9821 41.1406 25.9848 41.0237 26.0342 40.9023L26.3991 40.008L26.6346 40.0397L26.2868 40.8914L26.4683 40.7854V41.1062L26.4692 41.1071Z"
          fill="#8C6043"
        />
        <path
          d="M26.605 40.8679L27.005 40.2219V40.635V41.0473L26.605 40.8679Z"
          fill="#8C6043"
        />
        <path
          d="M27.485 39.7987L27.5775 39.1337L27.2746 39.5278L26.9708 39.9219L27.485 39.7987Z"
          fill="#8C6043"
        />
        <path
          d="M26.6535 39.6909L26.7452 40.2491L26.4423 40.2074L26.1394 40.1666L26.6535 39.6909Z"
          fill="#8C6043"
        />
        <path
          d="M26.826 41.5583L26.0845 41.9905V41.7612L26.826 41.329V41.5583Z"
          fill="#8C6043"
        />
        <path
          d="M17.4009 48.0394L5.91919 41.3571V28.0768L17.4009 34.7591V48.0394Z"
          fill="#B07740"
        />
        <path
          d="M12.3504 32.9017L10.9689 32.098V31.0153L12.3504 31.8198V32.9017Z"
          fill="#CC9662"
        />
        <path
          d="M17.4009 34.7591L5.91919 28.0769L17.4009 21.3946L28.8817 28.0769L17.4009 34.7591Z"
          fill="#E6AD73"
        />
        <path
          d="M23.8321 31.0153L22.4506 31.8199L10.9689 25.1376L12.3504 24.333L23.8321 31.0153Z"
          fill="#E6BF8C"
        />
        <path
          d="M12.3504 31.8199L10.9689 31.0153L22.4506 24.333L23.8321 25.1376L12.3504 31.8199Z"
          fill="#E6C498"
        />
        <path
          d="M13.1961 35.5737L10.1239 33.786C9.9253 33.67 9.7644 33.3601 9.7644 33.0928V33.0349C9.7644 32.7676 9.9253 32.6443 10.1239 32.7603L13.1961 34.548C13.3948 34.664 13.5566 34.9738 13.5566 35.2411V35.2991C13.5566 35.5664 13.3957 35.6887 13.1961 35.5737Z"
          fill="#874F2D"
        />
        <path
          d="M13.3013 35.5121L10.2291 33.7244C10.0305 33.6084 9.86957 33.2986 9.86957 33.0313V32.9733C9.86957 32.8609 9.89923 32.7767 9.94687 32.7223C9.83811 32.7422 9.7644 32.8546 9.7644 33.0349V33.0929C9.7644 33.3602 9.9253 33.6709 10.1239 33.786L13.1961 35.5737C13.3121 35.6407 13.4137 35.6253 13.4793 35.5501C13.4271 35.5601 13.3669 35.5501 13.3022 35.5121H13.3013Z"
          fill="#D4985B"
        />
        <path
          d="M6.99237 38.899L8.71903 39.9038V41.9008L6.99237 40.8959V38.899ZM6.88721 38.7159V40.9567L6.99237 41.0183L8.71903 42.0231L8.82419 42.0847V39.844L8.71903 39.7824L6.99237 38.7776L6.88721 38.7159Z"
          fill="#785438"
        />
        <path
          d="M7.53614 39.9319L7.38513 39.844V40.5725L7.53614 40.6604V39.9319Z"
          fill="#785438"
        />
        <path
          d="M7.77161 40.2109L7.46061 39.4969L7.15051 39.8494L7.77161 40.2109Z"
          fill="#785438"
        />
        <path
          d="M8.31177 40.3831L8.16077 40.2952V41.0236L8.31177 41.1115V40.3831Z"
          fill="#785438"
        />
        <path
          d="M7.15051 40.5117V40.6621L8.5473 41.4749V41.3245L7.15051 40.5117Z"
          fill="#785438"
        />
        <path
          d="M8.56164 40.6703L8.25065 39.9563L7.94055 40.3088L8.56164 40.6703Z"
          fill="#785438"
        />
        <path
          d="M9.36981 40.2825L11.0965 41.2873V43.2843L9.36981 42.2795V40.2825ZM9.26465 40.0995V42.3402L9.36981 42.4018L11.0965 43.4066L11.2016 43.4683V41.2275L11.0965 41.1659L9.36981 40.1611L9.26465 40.0995Z"
          fill="#785438"
        />
        <path
          d="M10.1194 42.3692C10.0052 42.39 9.84705 42.2822 9.84705 42.3357V42.381L10.2317 42.6048V41.0527L9.86322 40.838C9.86322 40.838 9.83716 41.2448 9.83716 41.3843C9.83716 41.7042 9.99535 41.9026 10.1787 42.0802C10.1787 42.3592 10.2326 42.3493 10.1194 42.3692Z"
          fill="#785438"
        />
        <path
          d="M10.3459 42.5006C10.4601 42.6537 10.6183 42.7307 10.6183 42.7833V42.8286L10.2327 42.6048V41.0527L10.6012 41.2674C10.6012 41.2674 10.6273 41.705 10.6273 41.8437C10.6273 42.1635 10.4691 42.178 10.2857 42.1418C10.2857 42.4208 10.2309 42.3474 10.345 42.4997L10.3459 42.5006Z"
          fill="#785438"
        />
        <path
          d="M11.7463 41.6652L13.4729 42.67V44.667L11.7463 43.6622V41.6652ZM11.6411 41.4822V43.7229L11.7463 43.7845L13.4729 44.7893L13.5781 44.8509V42.6102L13.4729 42.5486L11.7463 41.5438L11.6411 41.4822Z"
          fill="#785438"
        />
        <path
          d="M13.26 43.6359L11.9594 42.8784L12.0034 42.786C12.0996 42.5812 12.3414 42.5504 12.6101 42.7063C12.8789 42.863 13.1206 43.1747 13.2168 43.4918L13.2609 43.6359H13.26Z"
          fill="#785438"
        />
        <path
          d="M12.7431 44.0581C12.8429 44.1161 12.9238 44.0699 12.9238 43.9539C12.9238 43.924 12.9022 43.8869 12.8762 43.8715C12.8501 43.8561 12.8285 43.8687 12.8285 43.8986C12.8285 43.9539 12.7899 43.9766 12.7422 43.9485C12.6946 43.9204 12.6559 43.8533 12.6559 43.7981V42.8032C12.6559 42.7733 12.6344 42.7362 12.6083 42.7208C12.5822 42.7053 12.5607 42.718 12.5607 42.7479V43.7428C12.5607 43.8579 12.6416 43.9992 12.7413 44.0572L12.7431 44.0581Z"
          fill="#785438"
        />
        <path
          d="M12.2604 42.4825C12.2703 42.4879 12.2793 42.4906 12.2874 42.4879C12.3089 42.4825 12.3134 42.4516 12.2981 42.4181L12.1705 42.1372C12.1552 42.1037 12.1256 42.0811 12.1049 42.0865C12.0833 42.0919 12.0788 42.1227 12.0941 42.1563L12.2217 42.4371C12.2307 42.4571 12.2451 42.4743 12.2604 42.4825Z"
          fill="#785438"
        />
        <path
          d="M12.4923 42.4689C12.5022 42.4743 12.5111 42.477 12.5192 42.4743C12.5408 42.4689 12.5453 42.4381 12.53 42.4045L12.4752 42.284C12.4599 42.2505 12.4302 42.2279 12.4096 42.2333C12.388 42.2387 12.3835 42.2695 12.3988 42.3031L12.4536 42.4236C12.4626 42.4435 12.477 42.4607 12.4923 42.4689Z"
          fill="#785438"
        />
        <path
          d="M12.0079 42.4354C12.0178 42.4408 12.0268 42.4435 12.0349 42.4408C12.0564 42.4354 12.0609 42.4046 12.0456 42.371L11.9908 42.2505C11.9755 42.217 11.9459 42.1943 11.9252 42.1998C11.9036 42.2052 11.8991 42.236 11.9144 42.2695L11.9692 42.3901C11.9782 42.41 11.9926 42.4272 12.0079 42.4354Z"
          fill="#785438"
        />
        <path
          d="M11.7131 31.4483L23.1939 24.7661L23.0887 24.7045L11.6071 31.3867L11.7131 31.4483Z"
          fill="#D9B484"
        />
        <path
          d="M40.9773 48.3964L29.4956 55.0786V41.7984L40.9773 35.1161V48.3964Z"
          fill="#D4985B"
        />
        <path
          d="M35.9267 39.1373L34.5461 39.9409V38.8591L35.9267 38.0545V39.1373Z"
          fill="#E0AE7B"
        />
        <path
          d="M36.7726 40.8243L33.7004 42.612C33.5018 42.728 33.34 42.6047 33.34 42.3375V42.2795C33.34 42.0122 33.5009 41.7014 33.7004 41.5863L36.7726 39.7986C36.9713 39.6827 37.133 39.8059 37.133 40.0732V40.1312C37.133 40.3985 36.9721 40.7092 36.7726 40.8243Z"
          fill="#965D3B"
        />
        <path
          d="M36.9505 39.7615C36.9981 39.8159 37.0278 39.9002 37.0278 40.0125V40.0705C37.0278 40.3378 36.8669 40.6486 36.6674 40.7636L33.5952 42.5513C33.5304 42.5894 33.4702 42.5994 33.4181 42.5894C33.4837 42.6646 33.5853 42.68 33.7012 42.6129L36.7734 40.8253C36.9721 40.7093 37.1339 40.3994 37.1339 40.1321V40.0741C37.1339 39.8938 37.0602 39.7824 36.9514 39.7615H36.9505Z"
          fill="#E6AD73"
        />
        <path
          d="M39.3738 46.8778L39.0053 46.4049L38.9307 46.587L38.6952 46.5553L38.7851 46.3351C38.8327 46.2182 38.9154 46.1176 39.0053 46.0651C39.0952 46.0125 39.1779 46.018 39.2255 46.0787L39.6093 46.5716L39.3738 46.8778Z"
          fill="#8C6043"
        />
        <path
          d="M39.6623 47.5075L38.8866 47.9588V47.638L39.629 47.2058L39.5391 47.0907L39.7746 46.7845L39.8816 46.9222C39.931 46.9856 39.9337 47.1007 39.8879 47.2221C39.8421 47.3435 39.7558 47.4532 39.6614 47.5075H39.6623Z"
          fill="#8C6043"
        />
        <path
          d="M38.564 48.1463L38.3491 48.2713C38.2557 48.3257 38.1685 48.3176 38.1226 48.2487C38.0768 48.1798 38.0795 48.0629 38.1289 47.9415L38.4938 47.0472L38.7293 47.079L38.3815 47.9307L38.5631 47.8247V48.1454L38.564 48.1463Z"
          fill="#8C6043"
        />
        <path
          d="M38.6997 47.9071L39.0997 47.2611V47.6743V48.0865L38.6997 47.9071Z"
          fill="#8C6043"
        />
        <path
          d="M39.5805 46.838L39.6722 46.1738L39.3693 46.5671L39.0664 46.9612L39.5805 46.838Z"
          fill="#8C6043"
        />
        <path
          d="M38.7483 46.7311L38.8399 47.2883L38.537 47.2475L38.2341 47.2059L38.7483 46.7311Z"
          fill="#8C6043"
        />
        <path
          d="M38.9216 48.5975L38.1792 49.0297V48.8005L38.9216 48.3683V48.5975Z"
          fill="#8C6043"
        />
        <path
          d="M29.4956 55.0786L18.0139 48.3964V35.1161L29.4956 41.7984V55.0786Z"
          fill="#B07740"
        />
        <path
          d="M24.446 39.9409L23.0645 39.1373V38.0545L24.446 38.8591V39.9409Z"
          fill="#CC9662"
        />
        <path
          d="M29.4956 41.7984L18.0139 35.1161L29.4956 28.4338L40.9773 35.1161L29.4956 41.7984Z"
          fill="#E6AD73"
        />
        <path
          d="M35.9267 38.0545L34.5461 38.8591L23.0645 32.1768L24.446 31.3731L35.9267 38.0545Z"
          fill="#E6BF8C"
        />
        <path
          d="M24.446 38.8591L23.0645 38.0545L34.5461 31.3731L35.9267 32.1768L24.446 38.8591Z"
          fill="#E6C498"
        />
        <path
          d="M25.2909 42.6129L22.2187 40.8253C22.0201 40.7093 21.8583 40.3994 21.8583 40.1321V40.0741C21.8583 39.8068 22.0192 39.6845 22.2187 39.7996L25.2909 41.5873C25.4896 41.7033 25.6514 42.0131 25.6514 42.2804V42.3384C25.6514 42.6057 25.4905 42.728 25.2909 42.6129Z"
          fill="#874F2D"
        />
        <path
          d="M25.3969 42.5513L22.3247 40.7636C22.1261 40.6477 21.9643 40.3378 21.9643 40.0705V40.0125C21.9643 39.9002 21.994 39.8159 22.0416 39.7615C21.9328 39.7815 21.8591 39.8938 21.8591 40.0741V40.1321C21.8591 40.3994 22.02 40.7102 22.2196 40.8253L25.2918 42.6129C25.4077 42.68 25.5093 42.6646 25.5749 42.5894C25.5228 42.5994 25.4626 42.5894 25.3969 42.5513Z"
          fill="#D4985B"
        />
        <path
          d="M19.0881 45.9382L20.8147 46.943V48.94L19.0881 47.9352V45.9382ZM18.9829 45.7552V47.9959L19.0881 48.0575L20.8147 49.0623L20.9199 49.124V46.8832L20.8147 46.8216L19.0881 45.8168L18.9829 45.7552Z"
          fill="#785438"
        />
        <path
          d="M19.6319 46.9711L19.48 46.8832V47.6117L19.6319 47.6996V46.9711Z"
          fill="#785438"
        />
        <path
          d="M19.8665 47.2502L19.5564 46.5362L19.2454 46.8887L19.8665 47.2502Z"
          fill="#785438"
        />
        <path
          d="M20.4076 47.4223L20.2566 47.3344V48.0629L20.4076 48.1508V47.4223Z"
          fill="#785438"
        />
        <path
          d="M19.2454 47.551V47.7014L20.6421 48.515V48.3637L19.2454 47.551Z"
          fill="#785438"
        />
        <path
          d="M20.6565 47.7095L20.3464 46.9965L20.0354 47.348L20.6565 47.7095Z"
          fill="#785438"
        />
        <path
          d="M21.4645 47.3218L23.1912 48.3266V50.3236L21.4645 49.3187V47.3218ZM21.3594 47.1387V49.3794L21.4645 49.4411L23.1912 50.4459L23.2964 50.5075V48.2668L23.1912 48.2052L21.4645 47.2003L21.3594 47.1387Z"
          fill="#785438"
        />
        <path
          d="M22.2142 49.4085C22.1 49.4293 21.9419 49.3215 21.9419 49.3749V49.4202L22.3274 49.644V48.0919L21.9589 47.8772C21.9589 47.8772 21.9329 48.284 21.9329 48.4236C21.9329 48.7434 22.0911 48.9418 22.2744 49.1194C22.2744 49.3976 22.3292 49.3876 22.2151 49.4085H22.2142Z"
          fill="#785438"
        />
        <path
          d="M22.4406 49.5398C22.5548 49.6929 22.713 49.77 22.713 49.8225V49.8678L22.3274 49.644V48.0919L22.6959 48.3067C22.6959 48.3067 22.722 48.7443 22.722 48.8829C22.722 49.2028 22.5638 49.2173 22.3804 49.181C22.3804 49.4601 22.3256 49.3867 22.4397 49.5389L22.4406 49.5398Z"
          fill="#785438"
        />
        <path
          d="M23.841 48.7044L25.5677 49.7093V51.7063L23.841 50.7014V48.7044ZM23.7358 48.5214V50.7621L23.841 50.8237L25.5677 51.8286L25.6728 51.8902V49.6495L25.5677 49.5879L23.841 48.583L23.7358 48.5214Z"
          fill="#785438"
        />
        <path
          d="M25.3547 50.6751L24.0541 49.9177L24.0981 49.8252C24.1943 49.6205 24.4361 49.5897 24.7048 49.7455C24.9736 49.9023 25.2154 50.2139 25.3115 50.5311L25.3556 50.6751H25.3547Z"
          fill="#785438"
        />
        <path
          d="M24.8379 51.0974C24.9376 51.1553 25.0185 51.1091 25.0185 50.9932C25.0185 50.9633 24.997 50.9261 24.9709 50.9107C24.9448 50.8953 24.9232 50.908 24.9232 50.9379C24.9232 50.9932 24.8846 51.0158 24.837 50.9877C24.7893 50.9596 24.7507 50.8926 24.7507 50.8373V49.8424C24.7507 49.8125 24.7291 49.7754 24.703 49.76C24.677 49.7446 24.6554 49.7573 24.6554 49.7872V50.782C24.6554 50.8971 24.7363 51.0385 24.8361 51.0965L24.8379 51.0974Z"
          fill="#785438"
        />
        <path
          d="M24.3561 49.5217C24.366 49.5271 24.375 49.5299 24.3831 49.5271C24.4046 49.5217 24.4091 49.4909 24.3938 49.4574L24.2662 49.1765C24.2509 49.143 24.2213 49.1203 24.2006 49.1257C24.179 49.1312 24.1745 49.162 24.1898 49.1955L24.3174 49.4764C24.3264 49.4963 24.3408 49.5135 24.3561 49.5217Z"
          fill="#785438"
        />
        <path
          d="M24.587 49.5081C24.596 49.5136 24.6059 49.5163 24.614 49.5136C24.6355 49.5081 24.64 49.4773 24.6247 49.4438L24.5699 49.3233C24.5546 49.2897 24.525 49.2671 24.5043 49.2725C24.4827 49.278 24.4782 49.3088 24.4935 49.3423L24.5483 49.4628C24.5573 49.4827 24.5717 49.5 24.587 49.5081Z"
          fill="#785438"
        />
        <path
          d="M24.1034 49.4746C24.1124 49.48 24.1223 49.4828 24.1313 49.48C24.1529 49.4746 24.1574 49.4438 24.1421 49.4103L24.0872 49.2898C24.072 49.2562 24.0432 49.2336 24.0216 49.239C24.0001 49.2445 23.9956 49.2753 24.0108 49.3088L24.0657 49.4293C24.0747 49.4492 24.089 49.4664 24.1043 49.4746H24.1034Z"
          fill="#785438"
        />
        <path
          d="M23.8078 38.4876L35.2895 31.8053L35.1834 31.7437L23.7026 38.426L23.8078 38.4876Z"
          fill="#D9B484"
        />
        <path
          opacity="0.29"
          d="M53.3794 41.1786L29.4956 55.0786V41.7983L53.3794 27.8983V41.1786Z"
          fill="#F0DDC3"
        />
        <path
          opacity="0.35"
          d="M29.803 14.1759L5.91919 28.0768L29.4956 41.7984L53.3794 27.8983L29.803 14.1759Z"
          fill="#F9F1E6"
        />
        <path
          opacity="0.29"
          d="M18.3213 20.8582L5.91919 28.0768L29.4956 41.7984L41.8977 34.5797L53.3794 27.8983L18.3213 20.8582Z"
          fill="#F0DDC3"
        />
        <path
          opacity="0.29"
          d="M34.5461 38.8591L46.9473 31.6413L29.803 14.1759L17.4009 21.3946L5.91919 28.0768L34.5461 38.8591Z"
          fill="#F5E8D7"
        />
        <path
          opacity="0.29"
          d="M20.4074 19.644L15.1277 22.7165L29.4955 41.7984L41.8976 34.5797L53.3793 27.8983L20.4074 19.644Z"
          fill="#F6EBDC"
        />
        <path
          opacity="0.29"
          d="M53.3794 41.1786L29.4956 55.0786V50.9469L53.3794 27.8983V41.1786Z"
          fill="#EDD6B7"
        />
        <g opacity="0.29">
          <path
            d="M29.4956 55.0787L5.91919 41.3571V28.0768L29.4956 41.7984V55.0787Z"
            fill="#F4E5D2"
          />
        </g>
        <g opacity="0.29">
          <path
            d="M29.4956 55.0787L5.91919 40.095V28.0768L29.4956 50.947V55.0787Z"
            fill="#E9CAA3"
          />
        </g>
        <g opacity="0.29">
          <path
            d="M29.4956 51.4353L5.91919 41.3571V37.2254L29.4956 42.7153V51.4353Z"
            fill="#E9CAA3"
          />
        </g>
        <path
          d="M41.2838 20.6316L29.803 27.3139V14.0327L41.2838 7.35046V20.6316Z"
          fill="#D4985B"
        />
        <path
          d="M36.2342 11.3716L34.8527 12.1753V11.0934L36.2342 10.2897V11.3716Z"
          fill="#E0AE7B"
        />
        <path
          d="M37.079 13.0596L34.0068 14.8473C33.8082 14.9633 33.6464 14.84 33.6464 14.5727V14.5148C33.6464 14.2475 33.8073 13.9367 34.0068 13.8216L37.079 12.0339C37.2776 11.918 37.4385 12.0412 37.4385 12.3085V12.3665C37.4385 12.6338 37.2776 12.9445 37.079 13.0596Z"
          fill="#965D3B"
        />
        <path
          d="M37.257 11.9959C37.3047 12.0503 37.3343 12.1345 37.3343 12.2469V12.304C37.3343 12.5713 37.1734 12.882 36.9739 12.9971L33.9017 14.7848C33.837 14.8228 33.7767 14.8328 33.7246 14.8228C33.7902 14.898 33.8918 14.9134 34.0077 14.8464L37.08 13.0587C37.2786 12.9427 37.4404 12.6329 37.4404 12.3656V12.3076C37.4404 12.1273 37.3667 12.0158 37.2579 11.995L37.257 11.9959Z"
          fill="#E6AD73"
        />
        <path
          d="M39.6811 19.1122L39.3125 18.6392L39.2379 18.8214L39.0024 18.7896L39.0923 18.5695C39.14 18.4526 39.2227 18.352 39.3125 18.2995C39.4024 18.2469 39.4851 18.2523 39.5328 18.3131L39.9166 18.806L39.6811 19.1122Z"
          fill="#8C6043"
        />
        <path
          d="M39.9687 19.7419L39.193 20.1931V19.8724L39.9354 19.4402L39.8455 19.3251L40.081 19.0189L40.188 19.1566C40.2374 19.22 40.2401 19.3351 40.1943 19.4565C40.1485 19.5779 40.0622 19.6875 39.9678 19.7419H39.9687Z"
          fill="#8C6043"
        />
        <path
          d="M38.8703 20.3807L38.6555 20.5057C38.5612 20.5601 38.4749 20.5519 38.429 20.4831C38.3832 20.4142 38.3859 20.2973 38.4353 20.1759L38.8002 19.2816L39.0357 19.3133L38.6879 20.165L38.8695 20.059V20.3798L38.8703 20.3807Z"
          fill="#8C6043"
        />
        <path
          d="M39.0061 20.1415L39.407 19.4964V19.9087V20.3209L39.0061 20.1415Z"
          fill="#8C6043"
        />
        <path
          d="M39.8869 19.0724L39.9795 18.4082L39.6757 18.8014L39.3728 19.1956L39.8869 19.0724Z"
          fill="#8C6043"
        />
        <path
          d="M39.0547 18.9655L39.1472 19.5227L38.8443 19.4819L38.5405 19.4402L39.0547 18.9655Z"
          fill="#8C6043"
        />
        <path
          d="M39.2282 20.8319L38.4857 21.2641V21.0349L39.2282 20.6027V20.8319Z"
          fill="#8C6043"
        />
        <path
          d="M29.803 27.3139L18.3213 20.6316V7.35046L29.803 14.0327V27.3139Z"
          fill="#B07740"
        />
        <path
          d="M24.7525 12.1753L23.371 11.3716V10.2898L24.7525 11.0935V12.1753Z"
          fill="#CC9662"
        />
        <path
          d="M29.803 14.0328L18.3213 7.35048L29.803 0.668213L41.2838 7.35048L29.803 14.0328Z"
          fill="#E6AD73"
        />
        <path
          d="M36.2342 10.2898L34.8527 11.0934L23.371 4.41117L24.7525 3.60748L36.2342 10.2898Z"
          fill="#E6BF8C"
        />
        <path
          d="M24.7525 11.0934L23.371 10.2898L34.8527 3.60748L36.2342 4.41117L24.7525 11.0934Z"
          fill="#E6C498"
        />
        <path
          d="M25.5983 14.8473L22.5261 13.0597C22.3274 12.9437 22.1656 12.6338 22.1656 12.3665V12.3085C22.1656 12.0412 22.3265 11.918 22.5261 12.034L25.5983 13.8217C25.7969 13.9376 25.9578 14.2475 25.9578 14.5148V14.5728C25.9578 14.8401 25.7969 14.9624 25.5983 14.8473Z"
          fill="#874F2D"
        />
        <path
          d="M25.7035 14.7857L22.6312 12.998C22.4326 12.8821 22.2708 12.5722 22.2708 12.3049V12.2478C22.2708 12.1355 22.3005 12.0512 22.3481 11.9968C22.2394 12.0168 22.1656 12.1291 22.1656 12.3094V12.3674C22.1656 12.6347 22.3265 12.9455 22.5252 13.0606L25.5974 14.8482C25.7133 14.9153 25.8149 14.8999 25.8805 14.8247C25.8275 14.8346 25.7682 14.8247 25.7035 14.7866V14.7857Z"
          fill="#D4985B"
        />
        <path
          d="M19.3945 18.1726L21.1211 19.1774V21.1744L19.3945 20.1696V18.1726ZM19.2893 17.9896V20.2303L19.3945 20.2919L21.1211 21.2967L21.2263 21.3583V19.1176L21.1211 19.056L19.3945 18.0512L19.2893 17.9896Z"
          fill="#785438"
        />
        <path
          d="M19.9382 19.2055L19.7872 19.1176V19.8461L19.9382 19.934V19.2055Z"
          fill="#785438"
        />
        <path
          d="M20.1729 19.4846L19.8628 18.7706L19.5518 19.123L20.1729 19.4846Z"
          fill="#785438"
        />
        <path
          d="M20.714 19.6567L20.563 19.5688V20.2973L20.714 20.3852V19.6567Z"
          fill="#785438"
        />
        <path
          d="M19.5518 19.7853V19.9357L20.9494 20.7494V20.5981L19.5518 19.7853Z"
          fill="#785438"
        />
        <path
          d="M20.9639 19.9439L20.6529 19.2308L20.3428 19.5833L20.9639 19.9439Z"
          fill="#785438"
        />
        <path
          d="M21.7711 19.5561L23.4977 20.561V22.5579L21.7711 21.5531V19.5561ZM21.6659 19.3731V21.6138L21.7711 21.6754L23.4977 22.6803L23.6029 22.7419V20.5012L23.4977 20.4396L21.7711 19.4347L21.6659 19.3731Z"
          fill="#785438"
        />
        <path
          d="M22.5216 21.6428C22.4074 21.6628 22.2492 21.5558 22.2492 21.6084V21.6537L22.6348 21.8775V20.3254L22.2663 20.1107C22.2663 20.1107 22.2402 20.5175 22.2402 20.657C22.2402 20.9769 22.3984 21.1753 22.5818 21.3529C22.5818 21.6319 22.6366 21.622 22.5225 21.6419L22.5216 21.6428Z"
          fill="#785438"
        />
        <path
          d="M22.748 21.7751C22.8622 21.9282 23.0204 22.0053 23.0204 22.0578V22.1031L22.6348 21.8793V20.3272L23.0033 20.5419C23.0033 20.5419 23.0294 20.9796 23.0294 21.1182C23.0294 21.4381 22.8712 21.4525 22.6878 21.4163C22.6878 21.6954 22.633 21.622 22.7471 21.7742L22.748 21.7751Z"
          fill="#785438"
        />
        <path
          d="M24.1484 20.9397L25.875 21.9446V23.9416L24.1484 22.9367V20.9397ZM24.0432 20.7567V22.9974L24.1484 23.059L25.875 24.0639L25.9802 24.1255V21.8848L25.875 21.8232L24.1484 20.8183L24.0432 20.7567Z"
          fill="#785438"
        />
        <path
          d="M25.6611 22.9095L24.3605 22.152L24.4045 22.0596C24.5007 21.8548 24.7425 21.824 25.0112 21.9799C25.28 22.1366 25.5218 22.4483 25.6179 22.7654L25.662 22.9095H25.6611Z"
          fill="#785438"
        />
        <path
          d="M25.1451 23.3326C25.2449 23.3906 25.3258 23.3444 25.3258 23.2285C25.3258 23.1985 25.3042 23.1614 25.2781 23.146C25.2521 23.1306 25.2305 23.1433 25.2305 23.1732C25.2305 23.2285 25.1918 23.2511 25.1442 23.223C25.0966 23.1949 25.0579 23.1279 25.0579 23.0726V22.0777C25.0579 22.0478 25.0364 22.0107 25.0103 21.9953C24.9842 21.9799 24.9626 21.9926 24.9626 22.0225V23.0173C24.9626 23.1324 25.0435 23.2738 25.1433 23.3317L25.1451 23.3326Z"
          fill="#785438"
        />
        <path
          d="M24.6625 21.7561C24.6715 21.7616 24.6814 21.7643 24.6894 21.7616C24.711 21.7561 24.7155 21.7253 24.7002 21.6918L24.5726 21.4109C24.5573 21.3774 24.5277 21.3547 24.507 21.3602C24.4854 21.3656 24.4809 21.3964 24.4962 21.4299L24.6238 21.7108C24.6328 21.7308 24.6472 21.748 24.6625 21.7561Z"
          fill="#785438"
        />
        <path
          d="M24.8935 21.7425C24.9034 21.7479 24.9124 21.7506 24.9205 21.7479C24.942 21.7425 24.9465 21.7117 24.9313 21.6782L24.8764 21.5576C24.8612 21.5241 24.8315 21.5015 24.8108 21.5069C24.7892 21.5123 24.7848 21.5432 24.8 21.5767L24.8549 21.6972C24.8638 21.7171 24.8782 21.7343 24.8935 21.7425Z"
          fill="#785438"
        />
        <path
          d="M24.4099 21.7099C24.4198 21.7153 24.4288 21.718 24.4378 21.7153C24.4594 21.7099 24.4639 21.6791 24.4486 21.6456L24.3938 21.5251C24.3785 21.4915 24.3497 21.4689 24.3282 21.4743C24.3066 21.4798 24.3021 21.5106 24.3174 21.5441L24.3722 21.6646C24.3812 21.6845 24.3956 21.7017 24.4108 21.7099H24.4099Z"
          fill="#785438"
        />
        <path
          d="M24.1143 10.722L35.596 4.03971L35.4908 3.979L24.0092 10.6613L24.1143 10.722Z"
          fill="#D9B484"
        />
        <path
          d="M53.3794 27.6709L41.8977 34.3532V21.072L53.3794 14.3897V27.6709Z"
          fill="#D4985B"
        />
        <path
          d="M48.3289 18.4108L46.9474 19.2145V18.1327L48.3289 17.329V18.4108Z"
          fill="#E0AE7B"
        />
        <path
          d="M49.1747 20.0988L46.1025 21.8865C45.9038 22.0025 45.7429 21.8793 45.7429 21.612V21.554C45.7429 21.2867 45.9038 20.9759 46.1025 20.8609L49.1747 19.0732C49.3733 18.9572 49.5351 19.0804 49.5351 19.3477V19.4057C49.5351 19.673 49.3742 19.9838 49.1747 20.0988Z"
          fill="#965D3B"
        />
        <path
          d="M49.3517 19.0352C49.3994 19.0895 49.4291 19.1738 49.4291 19.2861V19.3441C49.4291 19.6114 49.2682 19.9222 49.0686 20.0373L45.9964 21.8249C45.9317 21.863 45.8715 21.873 45.8193 21.863C45.885 21.9382 45.9865 21.9536 46.1025 21.8866L49.1747 20.0989C49.3733 19.9829 49.5351 19.673 49.5351 19.4057V19.3478C49.5351 19.1674 49.4614 19.056 49.3526 19.0352H49.3517Z"
          fill="#E6AD73"
        />
        <path
          d="M51.7759 26.1515L51.4074 25.6785L51.3328 25.8606L51.0973 25.8289L51.1872 25.6087C51.2348 25.4918 51.3175 25.3913 51.4074 25.3387C51.4973 25.2862 51.58 25.2916 51.6276 25.3523L52.0114 25.8452L51.7759 26.1515Z"
          fill="#8C6043"
        />
        <path
          d="M52.0644 26.7812L51.2887 27.2324V26.9116L52.0311 26.4794L51.9412 26.3644L52.1767 26.0581L52.2837 26.1958C52.3331 26.2593 52.3358 26.3743 52.29 26.4957C52.2442 26.6171 52.1579 26.7268 52.0635 26.7812H52.0644Z"
          fill="#8C6043"
        />
        <path
          d="M50.9661 27.42L50.7512 27.545C50.6578 27.5994 50.5706 27.5912 50.5247 27.5224C50.4789 27.4535 50.4816 27.3366 50.531 27.2152L50.8959 26.3209L51.1314 26.3526L50.7836 27.2043L50.9652 27.0983V27.4191L50.9661 27.42Z"
          fill="#8C6043"
        />
        <path
          d="M51.1018 27.1808L51.5018 26.5356V26.9479V27.3602L51.1018 27.1808Z"
          fill="#8C6043"
        />
        <path
          d="M51.9826 26.1116L52.0743 25.4474L51.7714 25.8416L51.4685 26.2348L51.9826 26.1116Z"
          fill="#8C6043"
        />
        <path
          d="M51.1504 26.0047L51.242 26.5619L50.9391 26.5212L50.6362 26.4804L51.1504 26.0047Z"
          fill="#8C6043"
        />
        <path
          d="M51.3237 27.8712L50.5813 28.3033V28.0741L51.3237 27.6428V27.8712Z"
          fill="#8C6043"
        />
        <path
          d="M41.8977 34.3532L30.416 27.6709V14.3897L41.8977 21.072V34.3532Z"
          fill="#B07740"
        />
        <path
          d="M36.8481 19.2146L35.4666 18.4109V17.329L36.8481 18.1327V19.2146Z"
          fill="#CC9662"
        />
        <path
          d="M41.8977 21.072L30.416 14.3897L41.8977 7.70746L53.3794 14.3897L41.8977 21.072Z"
          fill="#E6AD73"
        />
        <path
          d="M48.3289 17.329L46.9473 18.1327L35.4666 11.4504L36.8481 10.6467L48.3289 17.329Z"
          fill="#E6BF8C"
        />
        <path
          d="M36.8481 18.1327L35.4666 17.329L46.9473 10.6467L48.3289 11.4504L36.8481 18.1327Z"
          fill="#E6C498"
        />
        <path
          d="M37.693 21.8865L34.6208 20.0989C34.4222 19.9829 34.2604 19.673 34.2604 19.4057V19.3477C34.2604 19.0804 34.4213 18.9572 34.6208 19.0732L37.693 20.8609C37.8917 20.9768 38.0534 21.2867 38.0534 21.554V21.612C38.0534 21.8793 37.8926 22.0016 37.693 21.8865Z"
          fill="#874F2D"
        />
        <path
          d="M37.7981 21.8249L34.7259 20.0373C34.5273 19.9213 34.3664 19.6114 34.3664 19.3441V19.2861C34.3664 19.1738 34.3961 19.0895 34.4437 19.0352C34.3349 19.0551 34.2612 19.1674 34.2612 19.3478V19.4057C34.2612 19.673 34.4221 19.9838 34.6217 20.0989L37.6939 21.8866C37.8098 21.9536 37.9114 21.9382 37.977 21.863C37.9249 21.873 37.8647 21.863 37.7999 21.8249H37.7981Z"
          fill="#D4985B"
        />
        <path
          d="M31.4902 25.2128L33.2168 26.2176V28.2146L31.4902 27.2097V25.2128ZM31.385 25.0297V27.2704L31.4902 27.3321L33.2168 28.3369L33.322 28.3985V26.1578L33.2168 26.0962L31.4902 25.0913L31.385 25.0297Z"
          fill="#785438"
        />
        <path
          d="M32.0331 26.2448L31.8821 26.1569V26.8854L32.0331 26.9733V26.2448Z"
          fill="#785438"
        />
        <path
          d="M32.2686 26.5239L31.9576 25.8099L31.6475 26.1623L32.2686 26.5239Z"
          fill="#785438"
        />
        <path
          d="M32.8097 26.696L32.6587 26.6081V27.3366L32.8097 27.4245V26.696Z"
          fill="#785438"
        />
        <path
          d="M31.6475 26.8246V26.9751L33.0442 27.7887V27.6383L31.6475 26.8246Z"
          fill="#785438"
        />
        <path
          d="M33.0586 26.9841L32.7485 26.2701L32.4375 26.6226L33.0586 26.9841Z"
          fill="#785438"
        />
        <path
          d="M33.8666 26.5954L35.5933 27.6002V29.5972L33.8666 28.5924V26.5954ZM33.7615 26.4124V28.6531L33.8666 28.7147L35.5933 29.7195L35.6985 29.7811V27.5404L35.5933 27.4788L33.8666 26.474L33.7615 26.4124Z"
          fill="#785438"
        />
        <path
          d="M34.6163 28.6821C34.5021 28.7029 34.3439 28.5951 34.3439 28.6486V28.6939L34.7295 28.9177V27.3656L34.361 27.1508C34.361 27.1508 34.335 27.5576 34.335 27.6972C34.335 28.017 34.4932 28.2154 34.6765 28.393C34.6765 28.6712 34.7313 28.6621 34.6172 28.6821H34.6163Z"
          fill="#785438"
        />
        <path
          d="M34.8428 28.8144C34.957 28.9675 35.1152 29.0445 35.1152 29.0971V29.1424L34.7305 28.9186V27.3665L35.099 27.5812C35.099 27.5812 35.1251 28.0188 35.1251 28.1575C35.1251 28.4773 34.9669 28.4918 34.7835 28.4556C34.7835 28.7346 34.7287 28.6612 34.8428 28.8134V28.8144Z"
          fill="#785438"
        />
        <path
          d="M36.2431 27.979L37.9698 28.9838V30.9808L36.2431 29.976V27.979ZM36.1379 27.796V30.0367L36.2431 30.0983L37.9698 31.1031L38.0749 31.1647V28.924L37.9698 28.8624L36.2431 27.8576L36.1379 27.796Z"
          fill="#785438"
        />
        <path
          d="M37.7568 29.9488L36.4562 29.1913L36.5002 29.0989C36.5964 28.8941 36.8382 28.8633 37.1069 29.0191C37.3757 29.1759 37.6175 29.4876 37.7136 29.8047L37.7577 29.9488H37.7568Z"
          fill="#785438"
        />
        <path
          d="M37.24 30.3719C37.3397 30.4299 37.4206 30.3837 37.4206 30.2677C37.4206 30.2378 37.3991 30.2006 37.373 30.1852C37.3469 30.1698 37.3253 30.1825 37.3253 30.2124C37.3253 30.2677 37.2867 30.2903 37.2391 30.2623C37.1914 30.2342 37.1528 30.1671 37.1528 30.1119V29.117C37.1528 29.0871 37.1312 29.0499 37.1051 29.0345C37.0791 29.0191 37.0575 29.0318 37.0575 29.0617V30.0566C37.0575 30.1717 37.1384 30.313 37.2382 30.371L37.24 30.3719Z"
          fill="#785438"
        />
        <path
          d="M36.7582 28.7954C36.7681 28.8008 36.7771 28.8035 36.7852 28.8008C36.8067 28.7954 36.8112 28.7646 36.7959 28.731L36.6683 28.4502C36.653 28.4166 36.6234 28.394 36.6027 28.3994C36.5811 28.4049 36.5766 28.4357 36.5919 28.4692L36.7195 28.7501C36.7285 28.77 36.7429 28.7872 36.7582 28.7954Z"
          fill="#785438"
        />
        <path
          d="M36.9891 28.7818C36.999 28.7872 37.008 28.7899 37.0161 28.7872C37.0376 28.7818 37.0421 28.751 37.0268 28.7175L36.972 28.597C36.9567 28.5634 36.9271 28.5408 36.9064 28.5462C36.8848 28.5517 36.8803 28.5825 36.8956 28.616L36.9504 28.7365C36.9594 28.7564 36.9738 28.7736 36.9891 28.7818Z"
          fill="#785438"
        />
        <path
          d="M36.5047 28.7491C36.5146 28.7546 36.5236 28.7573 36.5317 28.7546C36.5533 28.7491 36.5577 28.7183 36.5425 28.6848L36.4876 28.5643C36.4724 28.5308 36.4427 28.5081 36.422 28.5136C36.4005 28.519 36.396 28.5498 36.4112 28.5833L36.4661 28.7038C36.4751 28.7238 36.4894 28.741 36.5047 28.7491Z"
          fill="#785438"
        />
        <path
          d="M36.2099 17.7612L47.6916 11.079L47.5856 11.0182L36.1039 17.7005L36.2099 17.7612Z"
          fill="#D9B484"
        />
        <path
          d="M28.8817 27.8494L17.4009 34.5317V21.2505L28.8817 14.5682V27.8494Z"
          fill="#D4985B"
        />
        <path
          d="M23.8321 18.5894L22.4506 19.393V18.3112L23.8321 17.5075V18.5894Z"
          fill="#E0AE7B"
        />
        <path
          d="M24.6779 20.2774L21.6057 22.0651C21.407 22.181 21.2452 22.0578 21.2452 21.7905V21.7325C21.2452 21.4652 21.4061 21.1545 21.6057 21.0394L24.6779 19.2517C24.8765 19.1357 25.0383 19.259 25.0383 19.5262V19.5842C25.0383 19.8515 24.8774 20.1623 24.6779 20.2774Z"
          fill="#965D3B"
        />
        <path
          d="M24.8549 19.2137C24.9026 19.268 24.9322 19.3523 24.9322 19.4647V19.5227C24.9322 19.7899 24.7713 20.1007 24.5718 20.2158L21.4996 22.0035C21.4349 22.0415 21.3746 22.0515 21.3225 22.0415C21.3881 22.1167 21.4897 22.1321 21.6056 22.0651L24.6779 20.2774C24.8765 20.1614 25.0383 19.8516 25.0383 19.5843V19.5263C25.0383 19.346 24.9646 19.2345 24.8558 19.2137H24.8549Z"
          fill="#E6AD73"
        />
        <path
          d="M27.2791 26.33L26.9106 25.857L26.836 26.0391L26.6005 26.0074L26.6903 25.7872C26.738 25.6704 26.8207 25.5698 26.9106 25.5172C27.0004 25.4647 27.0831 25.4701 27.1308 25.5308L27.5146 26.0237L27.2791 26.33Z"
          fill="#8C6043"
        />
        <path
          d="M27.5676 26.9597L26.7919 27.4109V27.0902L27.5343 26.658L27.4444 26.5429L27.6799 26.2366L27.7869 26.3744C27.8363 26.4378 27.839 26.5529 27.7932 26.6743C27.7473 26.7957 27.661 26.9053 27.5667 26.9597H27.5676Z"
          fill="#8C6043"
        />
        <path
          d="M26.4692 27.5994L26.2544 27.7244C26.1609 27.7788 26.0737 27.7706 26.0279 27.7018C25.9821 27.6338 25.9848 27.516 26.0342 27.3946L26.3991 26.5003L26.6346 26.532L26.2868 27.3837L26.4683 27.2777V27.5985L26.4692 27.5994Z"
          fill="#8C6043"
        />
        <path
          d="M26.605 27.3593L27.005 26.7142V27.1264V27.5387L26.605 27.3593Z"
          fill="#8C6043"
        />
        <path
          d="M27.485 26.291L27.5775 25.626L27.2746 26.0201L26.9708 26.4134L27.485 26.291Z"
          fill="#8C6043"
        />
        <path
          d="M26.6535 26.1832L26.7452 26.7405L26.4423 26.6997L26.1394 26.6589L26.6535 26.1832Z"
          fill="#8C6043"
        />
        <path
          d="M26.826 28.0497L26.0845 28.4819V28.2526L26.826 27.8214V28.0497Z"
          fill="#8C6043"
        />
        <path
          d="M17.4009 34.5317L5.91919 27.8494V14.5682L17.4009 21.2505V34.5317Z"
          fill="#B07740"
        />
        <path
          d="M12.3504 19.3931L10.9689 18.5894V17.5076L12.3504 18.3113V19.3931Z"
          fill="#CC9662"
        />
        <path
          d="M17.4009 21.2505L5.91919 14.5683L17.4009 7.88599L28.8817 14.5683L17.4009 21.2505Z"
          fill="#E6AD73"
        />
        <path
          d="M23.8321 17.5075L22.4506 18.3112L10.9689 11.6289L12.3504 10.8253L23.8321 17.5075Z"
          fill="#E6BF8C"
        />
        <path
          d="M12.3504 18.3112L10.9689 17.5075L22.4506 10.8253L23.8321 11.6289L12.3504 18.3112Z"
          fill="#E6C498"
        />
        <path
          d="M13.1961 22.0651L10.1239 20.2774C9.9253 20.1614 9.7644 19.8516 9.7644 19.5843V19.5263C9.7644 19.259 9.9253 19.1367 10.1239 19.2517L13.1961 21.0394C13.3948 21.1554 13.5566 21.4653 13.5566 21.7326V21.7906C13.5566 22.0578 13.3957 22.1802 13.1961 22.0651Z"
          fill="#874F2D"
        />
        <path
          d="M13.3013 22.0035L10.2291 20.2158C10.0305 20.0998 9.86957 19.7899 9.86957 19.5227V19.4647C9.86957 19.3523 9.89923 19.268 9.94687 19.2137C9.83811 19.2336 9.7644 19.346 9.7644 19.5263V19.5843C9.7644 19.8516 9.9253 20.1623 10.1239 20.2774L13.1961 22.0651C13.3121 22.1321 13.4137 22.1167 13.4793 22.0415C13.4271 22.0515 13.3669 22.0415 13.3022 22.0035H13.3013Z"
          fill="#D4985B"
        />
        <path
          d="M6.99237 25.3913L8.71903 26.3961V28.3931L6.99237 27.3883V25.3913ZM6.88721 25.2083V27.449L6.99237 27.5106L8.71903 28.5154L8.82419 28.577V26.3363L8.71903 26.2747L6.99237 25.2699L6.88721 25.2083Z"
          fill="#785438"
        />
        <path
          d="M7.53614 26.4233L7.38513 26.3354V27.0639L7.53614 27.1518V26.4233Z"
          fill="#785438"
        />
        <path
          d="M7.77161 26.7024L7.46061 25.9884L7.15051 26.3409L7.77161 26.7024Z"
          fill="#785438"
        />
        <path
          d="M8.31177 26.8754L8.16077 26.7875V27.516L8.31177 27.6039V26.8754Z"
          fill="#785438"
        />
        <path
          d="M7.15051 27.0032V27.1545L8.5473 27.9672V27.8168L7.15051 27.0032Z"
          fill="#785438"
        />
        <path
          d="M8.56164 27.1627L8.25065 26.4487L7.94055 26.8011L8.56164 27.1627Z"
          fill="#785438"
        />
        <path
          d="M9.36981 26.7739L11.0965 27.7787V29.7757L9.36981 28.7709V26.7739ZM9.26465 26.5909V28.8316L9.36981 28.8932L11.0965 29.898L11.2016 29.9597V27.7189L11.0965 27.6573L9.36981 26.6525L9.26465 26.5909Z"
          fill="#785438"
        />
        <path
          d="M10.1194 28.8606C10.0052 28.8814 9.84705 28.7736 9.84705 28.8271V28.8724L10.2317 29.0962V27.5441L9.86322 27.3293C9.86322 27.3293 9.83716 27.7362 9.83716 27.8757C9.83716 28.1955 9.99535 28.394 10.1787 28.5716C10.1787 28.8497 10.2326 28.8407 10.1194 28.8606Z"
          fill="#785438"
        />
        <path
          d="M10.3459 28.9929C10.4601 29.146 10.6183 29.223 10.6183 29.2756V29.3209L10.2327 29.0971V27.545L10.6012 27.7597C10.6012 27.7597 10.6273 28.1974 10.6273 28.336C10.6273 28.6558 10.4691 28.6703 10.2857 28.6341C10.2857 28.9131 10.2309 28.8398 10.345 28.992L10.3459 28.9929Z"
          fill="#785438"
        />
        <path
          d="M11.7463 28.1575L13.4729 29.1623V31.1593L11.7463 30.1545V28.1575ZM11.6411 27.9745V30.2152L11.7463 30.2768L13.4729 31.2816L13.5781 31.3433V29.1025L13.4729 29.0409L11.7463 28.0361L11.6411 27.9745Z"
          fill="#785438"
        />
        <path
          d="M13.26 30.1273L11.9594 29.3698L12.0034 29.2774C12.0996 29.0726 12.3414 29.0418 12.6101 29.1977C12.8789 29.3544 13.1206 29.6661 13.2168 29.9832L13.2609 30.1273H13.26Z"
          fill="#785438"
        />
        <path
          d="M12.7431 30.5504C12.8429 30.6084 12.9238 30.5622 12.9238 30.4462C12.9238 30.4163 12.9022 30.3792 12.8762 30.3638C12.8501 30.3484 12.8285 30.3611 12.8285 30.391C12.8285 30.4462 12.7899 30.4689 12.7422 30.4408C12.6946 30.4127 12.6559 30.3456 12.6559 30.2904V29.2955C12.6559 29.2656 12.6344 29.2285 12.6083 29.2131C12.5822 29.1977 12.5607 29.2103 12.5607 29.2402V30.2351C12.5607 30.3502 12.6416 30.4915 12.7413 30.5495L12.7431 30.5504Z"
          fill="#785438"
        />
        <path
          d="M12.2604 28.9748C12.2703 28.9803 12.2793 28.983 12.2874 28.9803C12.3089 28.9748 12.3134 28.944 12.2981 28.9105L12.1705 28.6296C12.1552 28.5961 12.1256 28.5734 12.1049 28.5789C12.0833 28.5843 12.0788 28.6151 12.0941 28.6486L12.2217 28.9295C12.2307 28.9495 12.2451 28.9658 12.2604 28.9748Z"
          fill="#785438"
        />
        <path
          d="M12.4923 28.9603C12.5022 28.9658 12.5111 28.9685 12.5192 28.9658C12.5408 28.9603 12.5453 28.9295 12.53 28.896L12.4752 28.7755C12.4599 28.742 12.4302 28.7193 12.4096 28.7247C12.388 28.7302 12.3835 28.761 12.3988 28.7945L12.4536 28.915C12.4626 28.935 12.477 28.9522 12.4923 28.9603Z"
          fill="#785438"
        />
        <path
          d="M12.0079 28.9277C12.0178 28.9331 12.0268 28.9358 12.0349 28.9331C12.0564 28.9277 12.0609 28.8969 12.0456 28.8633L11.9908 28.7428C11.9755 28.7093 11.9459 28.6867 11.9252 28.6921C11.9036 28.6975 11.8991 28.7283 11.9144 28.7619L11.9692 28.8824C11.9782 28.9023 11.9926 28.9186 12.0079 28.9277Z"
          fill="#785438"
        />
        <path
          d="M11.7131 17.9398L23.1939 11.2584L23.0887 11.1968L11.6071 17.879L11.7131 17.9398Z"
          fill="#D9B484"
        />
        <path
          d="M40.9773 34.8887L29.4956 41.5709V28.2898L40.9773 21.6075V34.8887Z"
          fill="#D4985B"
        />
        <path
          d="M35.9267 25.6286L34.5461 26.4332V25.3504L35.9267 24.5468V25.6286Z"
          fill="#E0AE7B"
        />
        <path
          d="M36.7726 27.3166L33.7004 29.1043C33.5018 29.2203 33.34 29.0971 33.34 28.8298V28.7718C33.34 28.5045 33.5009 28.1937 33.7004 28.0786L36.7726 26.291C36.9713 26.175 37.133 26.2982 37.133 26.5655V26.6235C37.133 26.8908 36.9721 27.2016 36.7726 27.3166Z"
          fill="#965D3B"
        />
        <path
          d="M36.9505 26.2529C36.9981 26.3073 37.0278 26.3916 37.0278 26.5039V26.5619C37.0278 26.8292 36.8669 27.14 36.6674 27.255L33.5952 29.0427C33.5304 29.0808 33.4702 29.0907 33.4181 29.0808C33.4837 29.156 33.5853 29.1714 33.7012 29.1043L36.7734 27.3167C36.9721 27.2007 37.1339 26.8908 37.1339 26.6235V26.5655C37.1339 26.3852 37.0602 26.2738 36.9514 26.2529H36.9505Z"
          fill="#E6AD73"
        />
        <path
          d="M39.3738 33.3692L39.0053 32.8963L38.9307 33.0784L38.6952 33.0467L38.7851 32.8265C38.8327 32.7096 38.9154 32.609 39.0053 32.5565C39.0952 32.5039 39.1779 32.5094 39.2255 32.5701L39.6093 33.063L39.3738 33.3692Z"
          fill="#8C6043"
        />
        <path
          d="M39.6623 33.9989L38.8866 34.4501V34.1294L39.629 33.6972L39.5391 33.5821L39.7746 33.2759L39.8816 33.4136C39.931 33.477 39.9337 33.5921 39.8879 33.7135C39.8421 33.8349 39.7558 33.9446 39.6614 33.9989H39.6623Z"
          fill="#8C6043"
        />
        <path
          d="M38.564 34.6386L38.3491 34.7637C38.2557 34.818 38.1685 34.8099 38.1226 34.7419C38.0768 34.6731 38.0795 34.5562 38.1289 34.4348L38.4938 33.5405L38.7293 33.5722L38.3815 34.4239L38.5631 34.3179V34.6386H38.564Z"
          fill="#8C6043"
        />
        <path
          d="M38.6997 34.3985L39.0997 33.7534V34.1657V34.5788L38.6997 34.3985Z"
          fill="#8C6043"
        />
        <path
          d="M39.5805 33.3303L39.6722 32.6652L39.3693 33.0594L39.0664 33.4526L39.5805 33.3303Z"
          fill="#8C6043"
        />
        <path
          d="M38.7483 33.2225L38.8399 33.7797L38.537 33.7389L38.2341 33.6982L38.7483 33.2225Z"
          fill="#8C6043"
        />
        <path
          d="M38.9216 35.0898L38.1792 35.5211V35.2919L38.9216 34.8606V35.0898Z"
          fill="#8C6043"
        />
        <path
          d="M29.4956 41.5709L18.0139 34.8887V21.6075L29.4956 28.2898V41.5709Z"
          fill="#B07740"
        />
        <path
          d="M24.446 26.4333L23.0645 25.6287V24.5468L24.446 25.3505V26.4333Z"
          fill="#CC9662"
        />
        <path
          d="M29.4956 28.2898L18.0139 21.6075L29.4956 14.9252L40.9773 21.6075L29.4956 28.2898Z"
          fill="#E6AD73"
        />
        <path
          d="M35.9267 24.5468L34.5461 25.3505L23.0645 18.6682L24.446 17.8645L35.9267 24.5468Z"
          fill="#E6BF8C"
        />
        <path
          d="M24.446 25.3505L23.0645 24.5468L34.5461 17.8645L35.9267 18.6682L24.446 25.3505Z"
          fill="#E6C498"
        />
        <path
          d="M25.2909 29.1043L22.2187 27.3166C22.0201 27.2006 21.8583 26.8908 21.8583 26.6235V26.5655C21.8583 26.2982 22.0192 26.175 22.2187 26.291L25.2909 28.0786C25.4896 28.1946 25.6514 28.5045 25.6514 28.7718V28.8298C25.6514 29.0971 25.4905 29.2203 25.2909 29.1043Z"
          fill="#874F2D"
        />
        <path
          d="M25.3969 29.0427L22.3247 27.255C22.1261 27.1391 21.9643 26.8292 21.9643 26.5619V26.5039C21.9643 26.3916 21.994 26.3073 22.0416 26.2529C21.9328 26.2729 21.8591 26.3852 21.8591 26.5655V26.6235C21.8591 26.8908 22.02 27.2016 22.2196 27.3167L25.2918 29.1043C25.4077 29.1714 25.5093 29.156 25.5749 29.0808C25.5228 29.0907 25.4626 29.0808 25.3969 29.0427Z"
          fill="#D4985B"
        />
        <path
          d="M19.0881 32.4305L20.8147 33.4354V35.4323L19.0881 34.4275V32.4305ZM18.9829 32.2475V34.4882L19.0881 34.5498L20.8147 35.5547L20.9199 35.6163V33.3756L20.8147 33.3139L19.0881 32.3091L18.9829 32.2475Z"
          fill="#785438"
        />
        <path
          d="M19.6319 33.4626L19.48 33.3747V34.1032L19.6319 34.1911V33.4626Z"
          fill="#785438"
        />
        <path
          d="M19.8665 33.7416L19.5564 33.0276L19.2454 33.3801L19.8665 33.7416Z"
          fill="#785438"
        />
        <path
          d="M20.4076 33.9147L20.2566 33.8268V34.5553L20.4076 34.6432V33.9147Z"
          fill="#785438"
        />
        <path
          d="M19.2454 34.0424V34.1937L20.6421 35.0065V34.8561L19.2454 34.0424Z"
          fill="#785438"
        />
        <path
          d="M20.6565 34.2019L20.3464 33.4879L20.0354 33.8404L20.6565 34.2019Z"
          fill="#785438"
        />
        <path
          d="M21.4645 33.8132L23.1912 34.818V36.815L21.4645 35.8101V33.8132ZM21.3594 33.6301V35.8708L21.4645 35.9325L23.1912 36.9373L23.2964 36.9989V34.7582L23.1912 34.6966L21.4645 33.6917L21.3594 33.6301Z"
          fill="#785438"
        />
        <path
          d="M22.2142 35.8998C22.1 35.9207 21.9419 35.8129 21.9419 35.8654V35.9107L22.3274 36.1345V34.5824L21.9589 34.3677C21.9589 34.3677 21.9329 34.7745 21.9329 34.914C21.9329 35.2339 22.0911 35.4323 22.2744 35.6099C22.2744 35.8881 22.3292 35.8781 22.2151 35.8989L22.2142 35.8998Z"
          fill="#785438"
        />
        <path
          d="M22.4406 36.0321C22.5548 36.1853 22.713 36.2623 22.713 36.3148V36.3601L22.3274 36.1363V34.5842L22.6959 34.799C22.6959 34.799 22.722 35.2366 22.722 35.3752C22.722 35.6951 22.5638 35.7096 22.3804 35.6733C22.3804 35.9515 22.3256 35.879 22.4397 36.0312L22.4406 36.0321Z"
          fill="#785438"
        />
        <path
          d="M23.841 35.1968L25.5677 36.2016V38.1986L23.841 37.1937V35.1968ZM23.7358 35.0137V37.2544L23.841 37.3161L25.5677 38.3209L25.6728 38.3825V36.1418L25.5677 36.0802L23.841 35.0753L23.7358 35.0137Z"
          fill="#785438"
        />
        <path
          d="M25.3547 37.1665L24.0541 36.4091L24.0981 36.3166C24.1943 36.1119 24.4361 36.0811 24.7048 36.2369C24.9736 36.3937 25.2154 36.7053 25.3115 37.0225L25.3556 37.1665H25.3547Z"
          fill="#785438"
        />
        <path
          d="M24.8379 37.5897C24.9376 37.6477 25.0185 37.6014 25.0185 37.4855C25.0185 37.4556 24.997 37.4184 24.9709 37.403C24.9448 37.3876 24.9232 37.4003 24.9232 37.4302C24.9232 37.4855 24.8846 37.5081 24.837 37.48C24.7893 37.4519 24.7507 37.3849 24.7507 37.3296V36.3348C24.7507 36.3049 24.7291 36.2677 24.703 36.2523C24.677 36.2369 24.6554 36.2496 24.6554 36.2795V37.2744C24.6554 37.3894 24.7363 37.5308 24.8361 37.5888L24.8379 37.5897Z"
          fill="#785438"
        />
        <path
          d="M24.3561 36.014C24.366 36.0194 24.375 36.0222 24.3831 36.0194C24.4046 36.014 24.4091 35.9832 24.3938 35.9497L24.2662 35.6688C24.2509 35.6353 24.2213 35.6126 24.2006 35.6181C24.179 35.6235 24.1745 35.6543 24.1898 35.6878L24.3174 35.9687C24.3264 35.9886 24.3408 36.0049 24.3561 36.014Z"
          fill="#785438"
        />
        <path
          d="M24.587 35.9996C24.596 36.005 24.6059 36.0077 24.614 36.005C24.6355 35.9996 24.64 35.9688 24.6247 35.9352L24.5699 35.8147C24.5546 35.7812 24.525 35.7586 24.5043 35.764C24.4827 35.7694 24.4782 35.8002 24.4935 35.8338L24.5483 35.9543C24.5573 35.9742 24.5717 35.9905 24.587 35.9996Z"
          fill="#785438"
        />
        <path
          d="M24.1034 35.9669C24.1124 35.9723 24.1223 35.9751 24.1313 35.9724C24.1529 35.9669 24.1574 35.9361 24.1421 35.9026L24.0872 35.7821C24.072 35.7486 24.0432 35.7259 24.0216 35.7313C24.0001 35.7368 23.9956 35.7676 24.0108 35.8011L24.0657 35.9216C24.0747 35.9415 24.089 35.9579 24.1043 35.9669H24.1034Z"
          fill="#785438"
        />
        <path
          d="M23.8078 24.979L35.2895 18.2976L35.1834 18.236L23.7026 24.9183L23.8078 24.979Z"
          fill="#D9B484"
        />
        <path
          opacity="0.29"
          d="M53.3794 27.671L29.4956 41.571V28.2898L53.3794 14.3898V27.671Z"
          fill="#F0DDC3"
        />
        <path
          opacity="0.35"
          d="M29.803 0.668213L5.91919 14.5682L29.4956 28.2898L53.3794 14.3897L29.803 0.668213Z"
          fill="#F9F1E6"
        />
        <path
          opacity="0.29"
          d="M18.3213 7.35046L5.91919 14.5682L29.4956 28.2898L41.8977 21.072L53.3794 14.3897L18.3213 7.35046Z"
          fill="#F0DDC3"
        />
        <path
          opacity="0.29"
          d="M34.5461 25.3505L46.9473 18.1327L29.803 0.668213L17.4009 7.88597L5.91919 14.5682L34.5461 25.3505Z"
          fill="#F5E8D7"
        />
        <path
          opacity="0.29"
          d="M20.4074 6.13635L15.1277 9.20884L29.4955 28.2898L41.8976 21.072L53.3793 14.3898L20.4074 6.13635Z"
          fill="#F6EBDC"
        />
        <path
          opacity="0.29"
          d="M53.3794 27.671L29.4956 41.571V37.4384L53.3794 14.3898V27.671Z"
          fill="#EDD6B7"
        />
        <g opacity="0.29">
          <path
            d="M29.4956 41.571L5.91919 27.8494V14.5682L29.4956 28.2898V41.571Z"
            fill="#F4E5D2"
          />
        </g>
        <g opacity="0.29">
          <path
            d="M29.4956 41.571L5.91919 26.5864V14.5682L29.4956 37.4384V41.571Z"
            fill="#E9CAA3"
          />
        </g>
        <path
          opacity="0.29"
          d="M29.4956 29.2067L53.3794 23.5383V27.6709L29.4956 37.9276V29.2067Z"
          fill="#EDD6B7"
        />
        <g opacity="0.29">
          <path
            d="M29.4956 37.9277L5.91919 27.8494V23.7169L29.4956 29.2067V37.9277Z"
            fill="#E9CAA3"
          />
        </g>
        <path
          d="M33.3012 33.825L30.5256 35.4405V32.0491L33.3012 30.4335V33.825Z"
          fill="#EBEBEB"
        />
        <path
          d="M33.0307 30.9436V33.6446L30.7953 34.9457V32.2447L33.0307 30.9436ZM33.1098 30.805L32.9911 30.8739L30.7557 32.175L30.7162 32.1976V35.0835L30.8348 35.0146L33.0702 33.7135L33.1098 33.6908V30.805Z"
          fill="#CFCFCF"
        />
        <path
          d="M31.8739 32.0237L30.9778 32.5456V32.3598L31.8739 31.838V32.0237Z"
          fill="#CFCFCF"
        />
        <path
          d="M32.6155 31.9222L30.9778 32.8754V32.6896L32.6155 31.7365V31.9222Z"
          fill="#CFCFCF"
        />
        <path
          d="M31.4254 33.3982L31.2501 33.5006V34.2635L31.4254 34.1611V33.3982Z"
          fill="#CFCFCF"
        />
        <path
          d="M31.6986 33.3882L31.3382 33.0403L30.9778 33.8077L31.6986 33.3882Z"
          fill="#CFCFCF"
        />
        <path
          d="M32.326 32.8745L32.1508 32.9759V33.7388L32.326 33.6374V32.8745Z"
          fill="#CFCFCF"
        />
        <path
          d="M30.9778 34.5018V34.6594L32.5984 33.7162V33.5585L30.9778 34.5018Z"
          fill="#CFCFCF"
        />
        <path
          d="M32.6155 32.8545L32.2551 32.5066L31.8947 33.274L32.6155 32.8545Z"
          fill="#CFCFCF"
        />
      </g>
      <defs>
        <clipPath id="clip0_643_15292">
          <rect
            width="55.102"
            height="60"
            fill="white"
            transform="translate(0.373413 0.668213)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

IllustrationPackage.propType = {
  /**
   * Size of the illustration.
   */
  size: PropTypes.number,
};

IllustrationPackage.defaultProps = {
  size: 70,
};

export { IllustrationPackage as default } from "./Package";
