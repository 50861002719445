import { t } from "i18next";
import { useEffect, useState } from "react";
import { TypeOptions } from "react-toastify";
import { Button } from "../../ui/Button/Button";
import { ElementType } from "../../ui/Group/ElementTypeEnum";
import { GroupElement } from "../../ui/Group/GroupElement";
import { GroupWidget } from "../../ui/Group/GroupWidget";
import { ToastNotification } from "../../utils/ToastNotification";

//#region types
export interface BeaconResourceProps {
  id: string;
  name: string;
  mac: string;
  thresholdId: number;
  thresholdName: string;
  type: ElementType;
  icon: JSX.Element;
  inUse: boolean;
}

interface AdminPrivilegesResourceTabProps {
  tabType?: "beacons";
  customTitleFirstGroup?: string;
  customTitleSecondGroup?: string;
  rightGroupProp: BeaconResourceProps[];
  leftGroupProp: BeaconResourceProps[];
  rightButtonText: String;
  leftButtonText: String;
  disabled?: boolean;
  output: (
    leftResources: BeaconResourceProps[],
    rightResources: BeaconResourceProps[]
  ) => void;
}
//#endregion

export const AdminVehicleSensorTab: React.FC<AdminPrivilegesResourceTabProps> =
  ({
    tabType,
    customTitleFirstGroup,
    customTitleSecondGroup,
    rightGroupProp,
    leftGroupProp,
    leftButtonText,
    rightButtonText,
    disabled,
    output,
  }) => {
    const defaultThresholdNames = [
      "foodAndBeverages",
      "pharmaceuticalsAndMedical",
      "chemicalsAndIndustrial",
      "otherProducts",
    ];

    //#region variables to manage groups between cards
    // These hooks keep the search string for selected items
    const [rightSearchString, setRightSearchString] = useState("");
    const [leftSearchString, setLeftSearchString] = useState("");

    const [rightGroupList, setRightGroupList] = useState<BeaconResourceProps[]>(
      []
    );
    const [leftGroupList, setLeftGroupList] = useState<BeaconResourceProps[]>(
      []
    );
    //#endregion

    useEffect(() => {
      if (leftGroupProp.length > 0) {
        setLeftGroupList(leftGroupProp);
      }
    }, [leftGroupProp]);

    useEffect(() => {
      if (rightGroupProp.length > 0) {
        setRightGroupList(rightGroupProp);
      }
    }, [rightGroupProp]);

    //#region filter selected items
    const filterResources = (
      resources: BeaconResourceProps[],
      searchString: string
    ) => {
      if (searchString === "") return resources;
      else {
        return resources.filter(
          (resource: BeaconResourceProps) =>
            (resource?.name &&
              resource?.name
                ?.toLowerCase()
                .includes(searchString.toLowerCase())) ||
            (resource?.mac &&
              resource?.mac?.toLowerCase().includes(searchString.toLowerCase()))
        );
      }
    };
    //#endregion

    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: "36px",
          }}
        >
          <GroupWidget
            title={
              customTitleFirstGroup && customTitleFirstGroup != ""
                ? customTitleFirstGroup
                : ""
            }
            counter={leftGroupList.length}
            type={tabType}
            hasNoChildIcon={leftGroupList.length === 0}
            titleButton={
              <Button
                size="small"
                aspect="outline"
                label={leftButtonText}
                onClick={() => {
                  // Remove all elements from right group
                  setLeftGroupList([]);

                  // Add removed elements into left group
                  const newResourcesList = [
                    ...rightGroupList,
                    ...leftGroupList,
                  ];

                  // Update left group list
                  setRightGroupList(newResourcesList);

                  // Get out updated groups
                  if (output) {
                    output(newResourcesList, []);
                  }
                }}
                disabled={leftGroupList.length === 0 || disabled}
              />
            }
            filterCallback={(val: string) => {
              setLeftSearchString(val);
            }}
          >
            {filterResources(leftGroupList, leftSearchString).map(
              (resource: BeaconResourceProps) => (
                <GroupElement
                  key={resource.id}
                  id={resource.id}
                  disableClick={disabled}
                  firstTitle={resource.name}
                  secondTitle={resource.mac}
                  firstSubtitle={
                    defaultThresholdNames.find((thresholdName) =>
                      resource.thresholdName.includes(thresholdName)
                    )
                      ? t(`beacons.thresholds.${resource.thresholdName}`)
                      : resource.thresholdName
                  }
                  type={resource.type}
                  isAdded={true}
                  icon={resource.icon}
                  onClicked={(id) => {
                    // Find the index of element clicked
                    const clickedIndex = leftGroupList.findIndex(
                      (item) => item.id === id
                    );

                    // If element exists
                    if (clickedIndex !== -1) {
                      const clickedItem = leftGroupList[clickedIndex];

                      // Check whether item is already present or no
                      const isAlreadyInResourcesList = rightGroupList.some(
                        (item) => item.id === id
                      );
                      if (!isAlreadyInResourcesList) {
                        clickedItem.inUse = !clickedItem.inUse;
                        rightGroupList.unshift(clickedItem);
                        setRightGroupList(rightGroupList);
                      }

                      // Remove element from right group
                      const updatedSelectedResourcesList = [...leftGroupList];
                      updatedSelectedResourcesList.splice(clickedIndex, 1);
                      setLeftGroupList(updatedSelectedResourcesList);

                      // Get out updated groups
                      if (output) {
                        output(rightGroupList, updatedSelectedResourcesList);
                      }
                    }
                  }}
                />
              )
            )}
          </GroupWidget>
          <GroupWidget
            counter={
              rightGroupList.filter(
                (x) => !leftGroupList.some((y) => y.id === x.id)
              ).length
            }
            title={
              customTitleSecondGroup && customTitleSecondGroup != ""
                ? customTitleSecondGroup
                : ""
            }
            type={tabType}
            titleButton={
              <Button
                size="small"
                aspect="outline"
                label={rightButtonText}
                onClick={() => {
                  const maxAllowedToMove = Math.max(
                    4 - leftGroupList.length,
                    0
                  );
                  const elementsToMove = rightGroupList
                    .slice(0, maxAllowedToMove)
                    .map((element) => ({
                      ...element,
                      inUse: true,
                    }));
                  const remainingElements =
                    rightGroupList.slice(maxAllowedToMove);
                  if (remainingElements.length > 0) {
                    ToastNotification({
                      toastId: "maxBeaconAssociations",
                      status: "default" as TypeOptions,
                      description: t(
                        "admin.vehicle.editView.sensors.maxBeaconAssociations"
                      ),
                    });
                  }
                  const newLeftGroupList = [
                    ...elementsToMove,
                    ...leftGroupList,
                  ];
                  setRightGroupList(remainingElements);
                  setLeftGroupList(newLeftGroupList);
                  if (output) {
                    output(remainingElements, newLeftGroupList);
                  }
                }}
                disabled={rightGroupList.length === 0 || disabled}
              />
            }
            filterCallback={(val: string) => {
              setRightSearchString(val);
            }}
          >
            {filterResources(rightGroupList, rightSearchString)
              .filter((x) => !leftGroupList.some((y) => y.id === x.id))
              .map((resource: BeaconResourceProps) => (
                <GroupElement
                  key={resource.id}
                  id={resource.id}
                  disableClick={disabled}
                  firstTitle={resource.name}
                  secondTitle={resource.mac}
                  firstSubtitle={
                    defaultThresholdNames.find((thresholdName) =>
                      resource.thresholdName.includes(thresholdName)
                    )
                      ? t(`beacons.thresholds.${resource.thresholdName}`)
                      : resource.thresholdName
                  }
                  type={resource.type}
                  isAdded={false}
                  icon={resource.icon}
                  onClicked={(id) => {
                    if (leftGroupList.length < 4) {
                      // Find the index of element clicked
                      const clickedIndex = rightGroupList.findIndex(
                        (item) => item.id === id
                      );

                      // If element exists
                      if (clickedIndex !== -1) {
                        const clickedItem = rightGroupList[clickedIndex];

                        // Remove element from left group
                        const updatedResourcesList = [...rightGroupList];
                        updatedResourcesList.splice(clickedIndex, 1);
                        setRightGroupList(updatedResourcesList);

                        // Add element to the right group
                        clickedItem.inUse = !clickedItem.inUse;
                        leftGroupList.unshift(clickedItem);
                        setLeftGroupList(leftGroupList);

                        // Get out updated groups
                        if (output) {
                          output(updatedResourcesList, leftGroupList);
                        }
                      }
                    } else {
                      ToastNotification({
                        toastId: t(""),
                        status: "default" as TypeOptions,
                        description: t(
                          "admin.vehicle.editView.sensors.maxBeaconAssociations"
                        ),
                      });
                    }
                  }}
                />
              ))}
          </GroupWidget>
        </div>
      </>
    );
  };
