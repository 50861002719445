import { UserPermissions } from "../../users/privilege/privilegesSlice";

import { t } from "i18next";
import _ from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { Tooltip } from "../../../ui/Forms/Tooltip";
import { Table } from "../../../ui/Table/Table";
import { formatDateForQueryParams, getQueryString } from "../../../utils/Utils";
import { Beacon } from "../../beacon/beaconSlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import {
  getPresetsAsync,
  Preset,
  presetsSelectors,
  selectpresetsSliceStatus,
} from "../../users/preset/presetsSlice";
import UserContext from "../../users/userContext";
import { getVehiclesDetailsAsync, Vehicle } from "../../vehicle/vehiclesSlice";
import "./AnalogicReport.css";
import { AnalogicReportChart } from "./AnalogicReportChart";
import {
  analogicReportChartDataSelector,
  getAnalogicReportChartDataAsync,
  selectAnalogicReportChartDataSliceStatus,
  SensorChartData,
} from "./analogicReportChartDataSlice";
import { AnalogicReportFilterbar } from "./AnalogicReportFIlterbar";
import {
  analogicReportMacroSummarySelector,
  getAnalogicReportMacroSummaryAsync,
  MacroSummarySensor,
} from "./analogicReportMacroSummarySlice";
import { AnalogicReportSensorDataModal } from "./AnalogicReportSensorDataModal";
import { AnalogicReportSensorSummaryTableBuilder } from "./AnalogicReportSensorSummaryTableBuilder";
import {
  analogicReportSummarySelector,
  getAnalogicReportSummaryAsync,
  selectAnalogicReportSummarySliceStatus,
  SensorSummary,
} from "./analogicReportSummarySlice";
import { AnalogicSummaryReport } from "./AnalogicSummaryReport";

export interface AnalogicReportProps {
  permissions: UserPermissions;
}

interface TableData {
  columns: { label: string; field: string; sort: boolean }[] | undefined;
  rows: { [key: string]: any };
}

export const AnalogicReport: React.FC<AnalogicReportProps> = ({
  permissions,
}) => {
  //CHART SECTION
  const [currentParams, setCurrentParams] = useState<string>("");
  const [ignitionKeyData, setIgnitionKeyData] = useState<boolean[]>([]);
  const [humidityData, setHumidityData] = useState<number[]>([]);
  const [temperatureData, setTemperatureData] = useState<number[]>([]);
  const [periodData, setPeriodData] = useState<any[]>([]);
  const [dropdownPeriod, setDropdownPeriod] =
    useState<"daily" | "allPeriod">("daily");
  //DEFAULT SECTION
  const [preferencesContext]: [Preferences] = useContext(UserContext);
  const navigate = useNavigate();

  //SENSOR DATA SECTION
  const pathMatchViewSummary = useMatch(
    "/reports/analogic-report/:summaryId/details/:id"
  );
  const [sensorDataModal, setSensorDataModal] = useState(false);

  //PRESECT SECTION
  let presets: Preset[] = useAppSelector(presetsSelectors.selectAll);
  const presetsAnalogicReportSliceStatus = useAppSelector(
    selectpresetsSliceStatus
  );

  //CHARTDATA SECTION
  const chartDataByRedux: SensorChartData[] = _.orderBy(
    useAppSelector(analogicReportChartDataSelector.selectAll),
    "date",
    "asc"
  );

  //SUMMARY SECTION
  const pathMatchSummary = useMatch("/reports/analogic-report/:id");
  const reportArchiveSummaries: SensorSummary[] = _.orderBy(
    useAppSelector(analogicReportSummarySelector.selectAll),
    "date",
    "asc"
  );
  const summariesStatus = useAppSelector(
    selectAnalogicReportSummarySliceStatus
  );
  const chartDataStatus = useAppSelector(
    selectAnalogicReportChartDataSliceStatus
  );
  const macroSummarySensor: MacroSummarySensor = useAppSelector(
    analogicReportMacroSummarySelector.selectAll
  )[0];
  const [vehicleSelected, setVehicleSelected] = useState<Vehicle>(
    {} as Vehicle
  );
  const [chartData, setChartData] = useState<SensorChartData[]>([]);
  const [sensorSelected, setSensorSelected] = useState<Beacon>();
  const [statusSelected, setStatusSelected] = useState({
    label: t("report.analogic.status.allStatuses"),
    value: undefined,
  });
  const [reportArchiveSummary, setReportArchiveSummary] =
    useState<SensorSummary>();

  const tableBuilderRef = useRef<AnalogicReportSensorSummaryTableBuilder>(
    new AnalogicReportSensorSummaryTableBuilder()
  );
  const tableData: TableData = {} as TableData;
  let tableBuilder = tableBuilderRef.current;

  const context = "reportAnalogicSummary";
  const modalContext = "analogicReport";

  if (presetsAnalogicReportSliceStatus === "idle" && presets.length > 0) {
    let preset =
      presets.find((element) => element.name === undefined) ??
      presets.find((element) => element.lastSelected === true);
    if (!preset) {
      preset = presets.find(
        (element) => element.name === "Default" && element.context === context
      );
    }

    tableData.columns = tableBuilder.setColumns(
      preset?.columns
        ? preset?.columns.map((element: any) => {
            return {
              name: element,
            };
          })
        : []
    );

    tableData.rows =
      tableData.columns && tableData?.columns?.length > 0
        ? reportArchiveSummaries?.map((summary: SensorSummary) => {
            return tableBuilder.rowsBuilder(
              tableData.columns,
              {
                SensorSummary: summary,
              },
              sensorSelected?.beaconThreshold?.maxTemperature ?? 0,
              sensorSelected?.beaconThreshold?.minTemperature ?? 0,
              vehicleSelected.id,
              navigate,
              preferencesContext
            );
          })
        : tableData.rows;
  }

  if (
    reportArchiveSummaries.length > 0 &&
    pathMatchSummary === null &&
    pathMatchViewSummary == null
  ) {
    navigate("/reports/analogic-report/" + reportArchiveSummaries[0].uuid);
  }

  //FUNCTIONS SECTION
  const areArraysEqual = (arr1: any[], arr2: any[]) =>
    arr1.length === arr2.length &&
    arr1.every((item) => arr2.includes(item)) &&
    arr2.every((item) => arr1.includes(item));

  //USEEFFECTS SECTION
  useEffect(() => {
    store.dispatch(
      getPresetsAsync(getQueryString({ context: [context, modalContext] }))
    );
    store.dispatch(getVehiclesDetailsAsync());
    navigate("/reports/analogic-report");
  }, []);

  useEffect(() => {
    if (
      reportArchiveSummaries.length > 0 &&
      !reportArchiveSummary?.uuid &&
      summariesStatus === "idle"
    ) {
      pathMatchSummary?.params.id !== reportArchiveSummaries[0].uuid &&
        navigate("/reports/analogic-report/" + reportArchiveSummaries[0].uuid);
    }
  }, [summariesStatus]);

  useEffect(() => {
    setSensorDataModal(pathMatchViewSummary?.params.id !== undefined);
  }, [pathMatchViewSummary]);

  useEffect(() => {
    setReportArchiveSummary(
      reportArchiveSummaries.find(
        (summary) => summary.uuid === pathMatchSummary?.params.id
      )
    );
  }, [pathMatchSummary]);

  useEffect(() => {
    if (!_.isEmpty(reportArchiveSummary) && dropdownPeriod === "daily") {
      let startDate = new Date(reportArchiveSummary?.date ?? "");
      let endDate = new Date(reportArchiveSummary?.date ?? "");
      startDate.setUTCHours(0);
      startDate.setUTCMinutes(0);
      startDate.setUTCSeconds(0);
      endDate.setUTCHours(23);
      endDate.setUTCMinutes(59);
      endDate.setUTCSeconds(59);
      let params =
        "?startPeriod=" +
        encodeURIComponent(formatDateForQueryParams(startDate)) +
        "&endPeriod=" +
        encodeURIComponent(formatDateForQueryParams(endDate));
      store.dispatch(
        getAnalogicReportChartDataAsync({
          beaconNamespace: reportArchiveSummary?.namespace,
          entityId: vehicleSelected?.id?.toString(),
          queryParam: params,
        })
      );
    }
  }, [reportArchiveSummary, dropdownPeriod]);

  useEffect(() => {
    if (
      (currentParams !== "" || reportArchiveSummaries.length > 1) &&
      dropdownPeriod === "allPeriod"
    ) {
      if (
        currentParams === "" &&
        reportArchiveSummaries[0]?.date &&
        reportArchiveSummaries[reportArchiveSummaries.length - 1]?.date
      ) {
        setCurrentParams(
          "?startPeriod=" +
            encodeURIComponent(
              formatDateForQueryParams(reportArchiveSummaries[0].date)
            ) +
            "&endPeriod=" +
            encodeURIComponent(
              formatDateForQueryParams(
                reportArchiveSummaries[reportArchiveSummaries.length - 1].date
              )
            )
        );
      } else {
        store.dispatch(
          getAnalogicReportChartDataAsync({
            beaconNamespace: reportArchiveSummaries[0]?.namespace,
            entityId: vehicleSelected?.id?.toString(),
            queryParam: currentParams,
          })
        );
      }
    }
  }, [currentParams, dropdownPeriod]);

  useEffect(() => {
    if (!areArraysEqual(chartDataByRedux, chartData)) {
      setChartData(chartDataByRedux);
    }
  }, [chartDataByRedux]);

  useEffect(() => {
    setIgnitionKeyData([]);
    setHumidityData([]);
    setPeriodData([]);
    setTemperatureData([]);
    if (chartData.length > 0) {
      chartData.forEach((data, index) => {
        if (data?.date) {
          const currentDate = new Date(data.date);
          let formattedDate: string;
          if (chartData[index - 1] && chartData[index - 1]?.date) {
            const previousDate = new Date(chartData[index - 1].date);
            if (
              currentDate.getDate() !== previousDate.getDate() ||
              currentDate.getMonth() !== previousDate.getMonth() ||
              currentDate.getFullYear() !== previousDate.getFullYear()
            ) {
              formattedDate = `${currentDate.getDate()}/${
                currentDate.getMonth() + 1
              }/${currentDate.getFullYear()} ${currentDate.getHours()}:${String(
                currentDate.getMinutes()
              ).padStart(2, "0")}`;
            } else {
              formattedDate = `${currentDate.getHours()}:${String(
                currentDate.getMinutes()
              ).padStart(2, "0")}`;
            }
            setIgnitionKeyData((prevState) => [...prevState, data.ignitionKey]);
            setHumidityData((prevState) => [...prevState, data.humidity]);
            setPeriodData((prevState) => [...prevState, formattedDate]);
            setTemperatureData((prevState) => [...prevState, data.temperature]);
          }
        }
      });
    }
  }, [chartData]);

  return (
    <>
      <div
        style={{
          overflow: "scroll",
          height: "1250px",
        }}
      >
        {/* FILTERBAR SECTION */}
        <AnalogicReportFilterbar
          setVehicle={(vehicle) => setVehicleSelected(vehicle)}
          setSensor={(sensor) => setSensorSelected(sensor)}
          statusSelected={(status) => setStatusSelected(status)}
          apiCall={(beaconNameSpace, vehicleId, startDate, endDate) => {
            let params =
              "?startPeriod=" +
              encodeURIComponent(formatDateForQueryParams(startDate)) +
              "&endPeriod=" +
              encodeURIComponent(formatDateForQueryParams(endDate));
            navigate(`/reports/analogic-report`);
            setDropdownPeriod("daily");
            setCurrentParams(params);
            setReportArchiveSummary({} as SensorSummary);
            store.dispatch(
              getAnalogicReportSummaryAsync({
                beaconNamespace: beaconNameSpace,
                entityId: vehicleId,
                queryParam: params,
              })
            );
            store.dispatch(
              getAnalogicReportMacroSummaryAsync({
                entityId: vehicleId,
                queryParam: params,
              })
            );
          }}
        />

        {/* LEGEND AND DAYSELECTED SECTION */}
        {reportArchiveSummary?.uuid && macroSummarySensor && (
          <AnalogicSummaryReport
            permissions={permissions}
            reportArchiveSummary={reportArchiveSummary}
            macroSummarySensor={macroSummarySensor}
            reportArchiveSummaries={reportArchiveSummaries}
            isLoading={
              summariesStatus === "loading" || chartDataStatus === "loading"
            }
            onPeriodChange={(period) => {
              setDropdownPeriod(period);
            }}
          />
        )}

        {/* CHART SECTION */}
        {summariesStatus !== "loading" && chartDataStatus !== "loading" ? (
          <AnalogicReportChart
            ignitionKey={chartData.length > 0 ? ignitionKeyData : []}
            humidity={chartData.length > 0 ? humidityData : []}
            temperature={chartData.length > 0 ? temperatureData : []}
            period={chartData.length > 0 ? periodData : []}
            thresholdMax={sensorSelected?.beaconThreshold?.maxTemperature ?? 0}
            thresholdMin={sensorSelected?.beaconThreshold?.minTemperature ?? 0}
          />
        ) : (
          <div
            style={{
              width: "100%",
              height: "270px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="page-counter-loader" />
          </div>
        )}

        {/* TABLE SECTION */}
        <div className="analogic-report-details">
          {tableData?.rows?.length > 0 && (
            <div style={{ height: "100%", marginBottom: "100px" }}>
              <Table data={tableData}>
                <Table.Head />
                <Tooltip />
                <Table.Body id="ior-table-body" />
              </Table>
            </div>
          )}
        </div>
        {/* MODAL SECTION */}
        <AnalogicReportSensorDataModal
          sensorSelected={sensorSelected ?? ({} as Beacon)}
          vehicleSelected={vehicleSelected}
          statusSelected={statusSelected}
          open={sensorDataModal}
          onClose={() => navigate("/reports/analogic-report")}
        />
      </div>
    </>
  );
};
