import i18next, { t } from "i18next";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Driver } from "../../features/driver/driversSlice";
import { Event } from "../../features/event/eventsSlice";
import { Preferences } from "../../features/users/preference/preferencesSlice";
import UserContext from "../../features/users/userContext";
import { Vehicle } from "../../features/vehicle/vehiclesSlice";
import { ToastNotification } from "../../utils/ToastNotification";
import {
  getIllustrationFromVehicleType,
  getTranslationByLanguage,
} from "../../utils/Utils";
import { UserBox } from "../Chat/UserBox";
import { VehicleBox } from "../FleetControl/VehicleBox";
import { Checkbox } from "../Forms/Checkbox";
import { IconArrowDown } from "../Icon/Line/ArrowDown";
import { IconLoader } from "../Icon/Line/Loader";
import { IconSearch } from "../Icon/Line/Search";
import { Geofence } from "../Map/GeofenceDataStructure";
import { ThumbProfile } from "../ThumbProfile/ThumbProfile";
import "./ChildDropdown.css";

/**
 * Unificare questa struttura con driver option
 */
interface VehicleOption {
  id: number;
  alias: string;
  licensePlate: string;
  driverName: string;
  city: string;
  signalState: string;
  active: boolean;
  icon: object;
}

interface DriverOption {
  id: number;
  size: string;
  isOnline: string;
  firstName: string;
  lastName: string;
  active: boolean;
  icon: object;
}

interface GeofenceOption {
  id: number;
  category: string;
  name: string;
  icon: object;
}
/**
 * Questa struttura dovrebbe avere un solo attributo rappresentativo
 * dei dati da visualizzare nella checklist. In caso di component come
 * elemento della checklist passarlo esternamente.
 */
export interface Option {
  label: string;
  hasDropdown?: boolean;
  hasCheckbox: boolean;
  hasCount?: boolean;
  category?: string;
  color?: string;
  vehicles?: Vehicle[] | VehicleOption[];
  drivers?: Driver[] | DriverOption[];
  geofences?: Geofence[] | GeofenceOption[];
  events?: Event[];
}

interface InternalOption extends Option {
  lenght: number;
  optionsExist: boolean;
  callback: (...a: any) => any;
  fatherColor?: string;
}

interface ChildDropdownProps {
  visible: boolean | any;
  hasSearch: boolean;
  options: Option[];
  value:
    | Vehicle[]
    | Driver[]
    | Geofence[]
    | VehicleOption[]
    | DriverOption[]
    | GeofenceOption[]
    | Event[];

  setValue: (e: any) => any;
  type: string;
  onlyCheckbox: boolean;
  loading: boolean;
  elementsLimit?: number;
}

interface FatherProps {
  label: string;
  select: boolean;
  elementsNumber: number;
  counter: number;
}

/**
 * @bug Quando si seleziona "Select All" e si prova ad uncheckare qualcosa
 * la "Select All" resta spuntata.
 * @param param0
 * @returns
 */
export const ChildDropdown: React.FC<ChildDropdownProps> = ({
  visible,
  hasSearch,
  options,
  value,
  setValue,
  type,
  onlyCheckbox,
  loading,
  elementsLimit,
}: ChildDropdownProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [optionOpen, setOptionOpen] = useState<boolean[]>([]);
  const [fathers, setFathers] = useState<FatherProps[]>([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [prevValue, setPrevValue] = useState(value);
  const [preferencesContext]: [Preferences] = useContext(UserContext);

  useEffect(() => {
    if (searchValue !== "" && options !== undefined && visible) {
      let counter = 0;
      let newOptionOpen = { ...optionOpen };
      options.map((option, index) => {
        if (
          option.geofences?.length &&
          option.geofences.length > 0 &&
          option.label !==
            i18next.t("report.filterBar.geofencesDropdownSelectAll")
        ) {
          counter = filterGeofences(option.geofences, searchValue).length;
        } else if (
          option.drivers?.length &&
          option.drivers.length > 0 &&
          option.label !== i18next.t("common.selectAll")
        ) {
          option.drivers?.length &&
            (counter = filterDrivers(option.drivers, searchValue).length);
        } else if (option.events?.length && option.events.length > 0) {
          option.events?.length &&
            (counter = filterEvents(option.events, searchValue).length);
        } else {
          option.vehicles?.length &&
            (counter = filterVehicles(option.vehicles, searchValue).length);
        }
        counter > 0
          ? (newOptionOpen[index] = true)
          : (newOptionOpen[index] = false);
      });
      setOptionOpen(newOptionOpen);
    }
  }, [searchValue, visible]);

  /**
   * This useEffect is useful to update all fathers (Select All included) every time the selected "values" change.
   * This code acts both when the page is reloading and when the user clicks on a check box. What's the side effect?
   * All fathers are checked or not whether their children are all selected or not.
   */
  useEffect(() => {
    let myValue: any;
    if (type == "vehicles") {
      myValue = value as Vehicle[] | VehicleOption[];
    }
    if (type == "drivers") {
      myValue = value as Driver[] | DriverOption[];
    }
    if (type == "geofences") {
      myValue = value as Geofence[] | GeofenceOption[];
    }
    if (type == "events") {
      myValue = value as Event[];
    }
    let fathersSelectedElements: {
      label: string;
      selected: number;
      allSelected: boolean;
    }[] = [];
    if (value.length > 0) {
      const elementIds: number[] = myValue.map((x: any) => x.id).sort();
      options.forEach((option) => {
        let areAllSelected = true;
        let counter = 0;
        if (type == "geofences") {
          option?.geofences &&
            option?.geofences.forEach((gId: Geofence | GeofenceOption) => {
              const isSelected = elementIds.includes(gId.id);
              areAllSelected = areAllSelected && isSelected;

              counter = isSelected ? counter + 1 : counter;
            });
        }
        if (type == "events") {
          option?.events &&
            option?.events.forEach((eId: Event) => {
              const isSelected = elementIds.includes(eId.id);
              areAllSelected = areAllSelected && isSelected;

              counter = isSelected ? counter + 1 : counter;
            });
        }
        if (type == "drivers") {
          option?.drivers &&
            option?.drivers.forEach((dId: Driver | DriverOption) => {
              const isSelected = elementIds.includes(dId.id);
              areAllSelected = areAllSelected && isSelected;

              counter = isSelected ? counter + 1 : counter;
            });
        }
        if (type == "vehicles") {
          option?.vehicles &&
            option?.vehicles.forEach((vId: Vehicle | VehicleOption) => {
              const isSelected = elementIds.includes(vId.id);

              areAllSelected = areAllSelected && isSelected;
              counter = isSelected ? counter + 1 : counter;
            });
        }
        fathersSelectedElements.push({
          label: option.label,
          allSelected: areAllSelected,
          selected: counter,
        });
      });
    } else {
      if (!_.isEqual(prevValue, value)) {
        fathersSelectedElements = fathers.map((x) => {
          const fatherSelectedElement = {
            label: x.label,
            selected: x.counter,
            allSelected: false,
          } as {
            label: string;
            selected: number;
            allSelected: boolean;
          };
          return fatherSelectedElement;
        });
      }
    }
    //#region SELECT_ALL_UPDATE
    let areSelectedAll = true;
    let updatedFathers = fathers?.map((father) => {
      const updatedFather = { ...father };
      const fatherSelectedElements = fathersSelectedElements.filter(
        (x) => x.label === father.label
      )[0];

      fathersSelectedElements.forEach((x) => {
        if (x.allSelected !== true && areSelectedAll === true) {
          areSelectedAll = false;
        }
      });

      updatedFather.counter = fatherSelectedElements?.selected;
      updatedFather.select = fatherSelectedElements?.allSelected;
      areSelectedAll = fatherSelectedElements?.allSelected && areSelectedAll;
      return updatedFather;
    });
    areSelectedAll = areSelectedAll && fathersSelectedElements.length > 0;
    setIsSelectAllChecked(areSelectedAll);
    updatedFathers = updatedFathers.map((x) => {
      if (
        x.label === i18next.t("report.filterBar.geofencesDropdownSelectAll")
      ) {
        let updatedX = { ...x };
        updatedX.select = areSelectedAll;
        updatedX.counter = updatedFathers.length - 1;
        return updatedX;
      }
      return x;
    });
    //#endregion SELECT_ALL_UPDATE
    if (fathers.length > 0) {
      if (!_.isEqual(updatedFathers, fathers)) {
        setFathers(updatedFathers);
      }
    }
  }, [value]);

  useEffect(() => {
    if (options && options.length !== 0 && optionOpen.length === 0) {
      setOptionOpen(Array.from({ length: options.length }).map(() => false));

      let labelProps: FatherProps[] = options?.map((option: Option) => {
        return {
          label: option.label,
          select: false,
          elementsNumber:
            option.label ===
            i18next.t("report.filterBar.geofencesDropdownSelectAll")
              ? options.length - 1
              : _.get(option, type)?.length,
          counter: 0,
        };
      });
      setFathers(labelProps);
    }
  }, [options]);

  function handleItemClick(index: number) {
    let updatedValue = optionOpen;
    if (optionOpen.length > 0) {
      updatedValue = optionOpen.map((option, idx) => {
        return idx === index && index !== 0 ? !option : false;
      });
    }
    setOptionOpen(updatedValue);
  }

  function isSelectAll(checked: boolean) {
    if (fathers && checked) {
      let counter: number = value.length;
      !elementsLimit &&
        fathers.forEach((item) => {
          item.select = true;
        });
      options.forEach((option: any) => {
        if (elementsLimit ? counter < elementsLimit : true) {
          option[type].forEach((item: Vehicle | Driver | Geofence | Event) => {
            const foundVehicle = value.some((el: any) => el === item);
            if (elementsLimit ? counter < elementsLimit : true) {
              if (!foundVehicle) {
                setPrevValue(value);
                setValue((prevArray: Option[]) => [...prevArray, item]);
                counter++;
              }
            } else {
              ToastNotification({
                toastId: "limitSelectableVehicleAll",
                status: "default",
                title: `${t(
                  "report.toastNotification.moreThanLimitVehiclesTitle"
                )} ${elementsLimit} ${t("report.toastNotification.elements")}`,
                description: `${t(
                  "report.toastNotification.moreThanLimitVehiclesDescription"
                )} ${elementsLimit} ${t("report.toastNotification.elements")}`,
              });
            }
          });
          (option.vehicles?.length <= counter ||
            option.drivers?.length <= counter ||
            option.geofences?.length <= counter) &&
            fathers.forEach((item) => {
              if (item.label === option.label) item.select = true;
            });
        }
      });
    } else if (fathers && !checked) {
      fathers.forEach((item) => {
        item.select = false;
      });
      setPrevValue(value);
      setValue([]);
    }
  }

  function isSelected(option: any) {
    if (option && option[type]) {
      const found = value.length
        ? option[type].map((v: Vehicle | Driver | Geofence | Event) =>
            value.some(
              (
                el:
                  | Vehicle
                  | Driver
                  | Geofence
                  | VehicleOption
                  | DriverOption
                  | GeofenceOption
                  | Event
              ) => el === v
            )
          )
        : null;
      if (found && found.length) {
        return found.every(
          (elem: boolean) => [false, undefined].indexOf(elem) > -1
        )
          ? false
          : true;
      }
    } else if (option) {
      const found = value.length
        ? value.some((el: any) => el.id === option.id)
        : null;
      if (found) {
        return found;
      }
    }
    return null;
  }

  // Function for count total
  function totalCount() {
    let count = 0;
    options.forEach((option: any) => {
      if (option[type]) {
        count += option[type].length;
      }
      return null;
    });
    return count;
  }

  const search = (key: number | string, inputArray: any) => {
    for (const element of inputArray) {
      if (element.label === key) {
        return element.select;
      }
    }
  };

  /**
   * Function for update state of nested checkbox
   * @param checkbox
   * @param option
   * @returns
   */
  function updateNestedChecklist(
    checkbox: Vehicle | Driver | Geofence | Event,
    option: Option
  ) {
    if (checkbox) {
      if (value.length && _.find(value, { id: checkbox.id })) {
        setPrevValue(value);
        setValue((val: any) => val.filter((el: any) => el.id !== checkbox.id));
      } else {
        if (elementsLimit ? value.length === elementsLimit : false) {
          ToastNotification({
            toastId: "limitSelectableVehicle",
            status: "default",
            description: `${t(
              "report.toastNotification.moreThanLimitVehiclesTitle"
            )} ${elementsLimit} ${t("report.toastNotification.elements")}`,
          });
          return;
        }
        setPrevValue(value);
        setValue((prevArray: any) => [...prevArray, checkbox]);
      }
    }
  }

  /**
   * Function to render Children in ChildDropdown (wrapper)
   * @param type
   * @param option
   * @returns
   */
  function renderProvidedOptionsWrapper(type: string, option: Option) {
    let internalOption: InternalOption = {
      ...option,
      lenght: 0,
      optionsExist: false,
      callback: () => {},
    };
    if (type === "vehicles") {
      internalOption.lenght = internalOption.vehicles?.length ?? 0;
      internalOption.optionsExist = !!internalOption?.vehicles;
      internalOption.callback = (option, e) => {
        internalOption?.vehicles &&
          updateChecklistOfElements(
            option,
            e.target.checked,
            internalOption?.vehicles
          );
      };
    }
    if (type === "events") {
      internalOption.lenght = internalOption.events?.length ?? 0;
      internalOption.optionsExist = !!internalOption?.events;
      internalOption.callback = (option, e) => {
        internalOption?.events &&
          updateChecklistOfElements(
            option,
            e.target.checked,
            internalOption?.events
          );
      };
      internalOption.fatherColor = option.color;
    }
    if (type === "drivers") {
      internalOption.lenght = internalOption.drivers?.length ?? 0;
      internalOption.optionsExist = !!internalOption?.drivers;
      internalOption.callback = (option, e) => {
        internalOption?.drivers &&
          updateChecklistOfElements(
            option,
            e.target.checked,
            internalOption?.drivers
          );
      };
    }
    if (type === "geofences") {
      internalOption.lenght = internalOption.geofences?.length ?? 0;
      internalOption.optionsExist = !!internalOption?.geofences;
      internalOption.callback = (option, e) => {
        internalOption?.geofences &&
          updateChecklistOfElements(
            option,
            e.target.checked,
            internalOption?.geofences
          );
      };
      internalOption.fatherColor = option.color;
    }
    return renderProvidedOption(internalOption);
  }

  /**
   * Function to render Children in ChildDropdown
   * @param option
   * @returns
   */
  function renderProvidedOption(option: InternalOption) {
    if (option.optionsExist) {
      if (option.hasCount && !option.hasDropdown) {
        return (
          <Checkbox
            label={`${option.label} (${totalCount()})`}
            checked={isSelectAllChecked}
            color={option.color}
            onChange={(e) => {
              isSelectAll(e.target.checked);
              setIsSelectAllChecked(e.target.checked);
            }}
          />
        );
      } else {
        return (
          <Checkbox
            label={
              option.hasCount && option.optionsExist
                ? `${option.label} (${option.lenght})`
                : option.label
            }
            color={option.fatherColor ?? undefined}
            checked={search(option.label, fathers)}
            onChange={(e) => {
              option.callback(option, e);
            }}
          />
        );
      }
    }
  }

  /**
   * Function to update checklist of children
   * @param checkbox
   * @param checked
   * @param elements
   * @returns
   */
  function updateChecklistOfElements(
    checkbox: Option,
    checked: boolean,
    elements:
      | Vehicle[]
      | VehicleOption[]
      | Geofence[]
      | GeofenceOption[]
      | Driver[]
      | DriverOption[]
      | Event[]
  ) {
    if (!checked && value) {
      if (elements) {
        elements?.forEach((element: any) => {
          setPrevValue(value);
          setValue((val: any) =>
            val.filter((el: Vehicle | Geofence) => el !== element)
          );
        });
      }
    } else {
      if (elementsLimit ? value.length === elementsLimit : false) {
        ToastNotification({
          toastId: "limitSelectableVehicle",
          status: "default",
          description: `${t(
            "report.toastNotification.moreThanLimitVehiclesTitle"
          )} ${elementsLimit} ${t("report.toastNotification.elements")}`,
        });
        return;
      }
      let counter: number = value.length;
      elements?.forEach((element: any, index: number) => {
        if (elementsLimit ? counter < elementsLimit : true) {
          const found = value.some(
            (
              el:
                | Vehicle
                | Driver
                | Geofence
                | VehicleOption
                | DriverOption
                | GeofenceOption
                | Event
            ) => el === element
          );
          if (!found) {
            setPrevValue(value);
            setValue(
              (
                prevArray:
                  | Vehicle[]
                  | VehicleOption[]
                  | Driver[]
                  | DriverOption[]
                  | Geofence[]
                  | GeofenceOption[]
                  | Event[]
              ) => [...prevArray, element]
            );
            counter++;
          }
        } else {
          ToastNotification({
            toastId: "limitSelectableVehicleAll",
            status: "default",
            title: `${t(
              "report.toastNotification.moreThanLimitVehiclesTitle"
            )} ${elementsLimit} ${t("report.toastNotification.elements")}`,
            description: `${t(
              "report.toastNotification.moreThanLimitVehiclesDescription"
            )} ${elementsLimit} ${t("report.toastNotification.elements")}`,
          });
        }
      });
    }
  }
  //#region  VEHICLE_FUNCTIONS

  // Filters for alias, plate, name
  function filterVehicles(vehicles: any, searchValue: string) {
    if (searchValue === "") return vehicles;
    else {
      if (searchValue)
        return vehicles.filter(
          (vehicle: any) =>
            vehicle.alias?.toLowerCase().includes(searchValue.toLowerCase()) ||
            vehicle.plate?.toLowerCase().includes(searchValue.toLowerCase()) ||
            vehicle.licensePlate
              ?.toLowerCase()
              .includes(searchValue.toLowerCase())
        );
    }
  }
  //#endregion VEHICLE_FUNCTIONS

  //#region DRIVER_FUNCTIONS

  // Filters for driverName
  function filterDrivers(drivers: any, searchValue: string) {
    if (searchValue === "") return drivers;
    else {
      if (searchValue)
        return drivers.filter(
          (driver: any) =>
            driver.firstName
              ?.toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            driver.lastName?.toLowerCase().includes(searchValue.toLowerCase())
        );
    }
  }
  //#endregion DRIVER_FUNCTIONS

  //#region  GEOFENCE_FUNCTIONS
  //Filters for name
  function filterGeofences(geofences: any, searchValue: string) {
    if (searchValue === "") return geofences;
    else {
      if (searchValue)
        return geofences.filter((geofence: any) =>
          geofence.name?.toLowerCase().includes(searchValue.toLowerCase())
        );
    }
  }

  //#Endregion GEOFENCE_FUNCTIONS

  //#region  EVENT_FUNCTIONS
  function filterEvents(events: any, searchValue: string) {
    if (searchValue === "") return events;
    else {
      if (searchValue)
        return events.filter((event: any) =>
          getTranslationByLanguage(
            event.translations,
            preferencesContext?.language
          )
            ?.toLowerCase()
            .includes(searchValue.toLowerCase())
        );
    }
  }
  //#Endregion  EVENT_FUNCTIONS

  return (
    (isSelectAllChecked !== null || isSelectAllChecked !== undefined) &&
    visible &&
    options &&
    fathers && (
      <div data-testid="dropdown-content" className="dropdown-content">
        {hasSearch && (
          <div className="dropdown-search" data-testid="dropdown-search">
            <input
              type="text"
              placeholder={i18next.t("common.search")}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                e.target.value === ""
                  ? setOptionOpen(
                      Array.from({ length: options.length }, () => false)
                    )
                  : setOptionOpen(
                      Array.from({ length: options.length }, () => true)
                    );
              }}
            />
            <IconSearch size={14} color="--global-colors-ink-light" />
          </div>
        )}
        {!loading && (
          <div className="dropdown-item-wrapper">
            {options.length !== 0 &&
              options.map((option, index) => {
                return (
                  <React.Fragment key={index}>
                    <div
                      onClick={() => handleItemClick(index)}
                      className={
                        optionOpen[index]
                          ? "dropdown-item active"
                          : "dropdown-item"
                      }
                      data-testid="dropdown-item"
                    >
                      {option.hasCheckbox ? (
                        <div className="drop-checkbox">
                          {renderProvidedOptionsWrapper(type, option)}
                        </div>
                      ) : (
                        <div
                          className="option-label"
                          data-testid="option-label"
                        >
                          {option.label}
                        </div>
                      )}
                      {option.hasDropdown && (
                        <div
                          data-testid="sub-arrow"
                          className={
                            optionOpen[index] ? "sub-arrow open" : "sub-arrow"
                          }
                        >
                          <IconArrowDown
                            size={14}
                            color="--global-colors-ink-light"
                          />
                        </div>
                      )}
                    </div>
                    {/* VehiclesDropdown region */}
                    {optionOpen[index] && (
                      <div className="option-content">
                        {option?.vehicles &&
                          filterVehicles(option.vehicles, searchValue)?.map(
                            (vehicle: Vehicle, idx: number) => {
                              return (
                                <div className="option-content-items" key={idx}>
                                  {onlyCheckbox ? (
                                    <Checkbox
                                      key={idx}
                                      label={`${vehicle.alias} - ${vehicle.plate}`}
                                      checked={isSelected(vehicle) ?? false}
                                      onChange={() =>
                                        updateNestedChecklist(vehicle, option)
                                      }
                                    />
                                  ) : (
                                    <VehicleBox
                                      key={idx}
                                      vehicle={vehicle}
                                      id={vehicle.id}
                                      driverName={vehicle.name}
                                      city={vehicle.city}
                                      active={false}
                                      icon={getIllustrationFromVehicleType(
                                        vehicle
                                      )}
                                    />
                                  )}
                                </div>
                              );
                            }
                          )}
                        {/* DriversDropdown region */}
                        {option?.drivers &&
                          filterDrivers(option.drivers, searchValue)?.map(
                            (driver: Driver, idx: number) => {
                              return (
                                <div className="option-content-items" key={idx}>
                                  {onlyCheckbox ? (
                                    <Checkbox
                                      label={`${driver.firstName} ${driver.lastName}`}
                                      checked={isSelected(driver) ?? false}
                                      onChange={() =>
                                        updateNestedChecklist(driver, option)
                                      }
                                    />
                                  ) : (
                                    <UserBox
                                      key={idx}
                                      size="regular"
                                      isOnline={driver.status}
                                      driver={`${driver.firstName} ${driver.lastName}`}
                                      icon={
                                        <ThumbProfile
                                          size="small"
                                          alt="User Thumbnail"
                                        />
                                      }
                                      text={driver.id}
                                      disableClick={true}
                                    />
                                  )}
                                </div>
                              );
                            }
                          )}
                        {/* GeofenceDropdown region */}
                        {option?.geofences &&
                          filterGeofences(option.geofences, searchValue)?.map(
                            (geofence: Geofence, idx: number) => {
                              return (
                                <div className="option-content-items" key={idx}>
                                  <Checkbox
                                    key={idx}
                                    label={`${geofence.name}`}
                                    checked={isSelected(geofence) ?? false}
                                    onChange={() =>
                                      updateNestedChecklist(geofence, option)
                                    }
                                  />
                                </div>
                              );
                            }
                          )}
                        {/* EventDropdown region */}
                        {option.events &&
                          filterEvents(option.events, searchValue).map(
                            (event: Event, idx: number) => {
                              return (
                                <div className="option-content-items" key={idx}>
                                  <Checkbox
                                    key={idx}
                                    label={getTranslationByLanguage(
                                      event.translations ?? [],
                                      preferencesContext?.language
                                    )}
                                    checked={isSelected(event) ?? false}
                                    onChange={() =>
                                      updateNestedChecklist(event, option)
                                    }
                                  />
                                </div>
                              );
                            }
                          )}
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
          </div>
        )}
        {loading && (
          <div className="childs-loader-wrapper">
            <div className="childs-loader">
              <IconLoader size={16} color="--global-colors-ink-light" />
            </div>
          </div>
        )}
      </div>
    )
  );
};
