import PropTypes from "prop-types";
import { useContext, useEffect } from "react";
import { FormCtx } from "./Form";

import i18next from "i18next";
import "./TextField.css";

const TextField = (props) => {
  const {
    id,
    maxLength,
    minLength,
    errorList,
    formattedNumber,
    maxIntegerDigits,
    maxDecimalDigits,
  } = props;
  const { setFields, addField, fields, errors, validateField } =
    useContext(FormCtx);
  const field = fields[id] || {};
  const fieldError = errors[id] || "";
  const {
    name,
    rows,
    value,
    validate,
    disabled,
    size = "normal",
    label,
    placeholder,
    type = "text",
    events = {},
    classes = {},
  } = field;
  const { onChange, onClick, ...restEvents } = events;
  let { contClass, fieldClass, errorClass } = classes;

  if (fieldError !== "") {
    fieldClass = [fieldClass, "input--has-error"].join(" ");
  }
  if (size !== "normal") {
    fieldClass = [fieldClass, "input--small"].join(" ");
  }

  if (props.children !== undefined) {
    contClass = [contClass, "text-field--has-icon"].join(" ");
  }

  //#region format numbers based on langauge
  const { format } = new Intl.NumberFormat(i18next.language);
  const decimalSign = getDecimalSign();
  const thousandSign = ".,".replace(decimalSign, "").charAt(0);

  function getDecimalSign() {
    const sign = format(1.1);
    return sign.replace(/\d/g, "").charAt(0);
  }

  function truncateNumber(
    num,
    maxIntegers,
    maxDecimals,
    decimalSign,
    thousandSign
  ) {
    let regex;
    const maxThousand = Math.floor((maxIntegers - 1) / 3);
    if (maxIntegers > 3) {
      regex = new RegExp(
        `^(\\d{1,${maxIntegers}}(?:[${thousandSign}]\\d{0,3}){0,${maxThousand}})?([${decimalSign}]\\d{0,${maxDecimals}})?`
      );
    } else {
      regex = new RegExp(
        `^(\\d{1,${maxIntegers}})([${decimalSign}]\\d{0,${maxDecimals}})?`
      );
    }
    const match = num.match(regex);
    return match ? match[0] : "";
  }

  function convertToJSFormat(num) {
    let cleanedNumber = num
      .replace(new RegExp(`[^${decimalSign}\\d]`, "g"), "")
      .replace(decimalSign, ".");

    return parseFloat(cleanedNumber) || null;
  }
  //#endregion

  function handleChange(event) {
    setFields(event, field);

    if (typeof onChange === "function") {
      const userInput = event.target.value;

      if (formattedNumber) {
        const truncatedValue = truncateNumber(
          userInput,
          maxIntegerDigits,
          maxDecimalDigits,
          decimalSign,
          thousandSign
        ); // string
        const numericValue = convertToJSFormat(truncatedValue); // number (format JS)
        onChange({
          ...field,
          value: truncatedValue || userInput,
          edit: numericValue,
        });
      } else {
        onChange({
          ...field,
          value: userInput,
        });
      }
    }
  }

  const handleClick = (event) => {
    setFields(event, field);

    if (typeof onClick === "function") {
      onClick({
        ...field,
        value: event.target.value,
      });
    }
  };

  useEffect(() => {
    addField({
      field: props,
      value,
    });
  }, [props]);

  useEffect(() => {
    if (field.value !== undefined && errorList) {
      validateField(id);
      fieldError !== ""
        ? errorList((oldArray) => [...oldArray, id])
        : errorList((oldArray) => oldArray.filter((item) => item !== id)); //metodo che mantiene lo stato in un array dei form non corretti
    }
  }, [value, fieldError]);
  //#endregion

  const fieldProps = {
    ...restEvents,
    id,
    name,
    type,
    value: value || "",
    validate,
    placeholder,
    disabled,
    label,
    maxLength,
    minLength,
    className: fieldClass,
    onChange: handleChange,
    onClick: handleClick,
  };

  if (type === "textarea") {
    delete fieldProps.type;
    fieldProps.rows = rows || 2;
  }

  function preventIncrementDecrement(event) {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
    }
  }
  return (
    <>
      <div className={[contClass, "text-field"].join(" ")}>
        <label htmlFor={field.id}>{label}</label>
        {type === "textarea" ? (
          <textarea {...fieldProps} />
        ) : (
          <div>
            <input
              {...fieldProps}
              autoComplete="off"
              name="text-field"
              onKeyDown={(event) =>
                fieldProps.type === "number" && preventIncrementDecrement(event)
              }
              onWheel={(event) =>
                fieldProps.type === "number" && preventIncrementDecrement(event)
              }
            />
            {props.children}
          </div>
        )}
        {errorList && <p className={errorClass}>{fieldError}</p>}
      </div>
    </>
  );
};

TextField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  rows: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  maxLength: PropTypes.string,
  minLength: PropTypes.string,
  size: PropTypes.oneOf(["small", "normal"]),
  label: PropTypes.string,
  type: PropTypes.oneOf([
    "text",
    "email",
    "tel",
    "textarea",
    "password",
    "file",
    "search",
    "time",
    "number",
  ]),
  events: PropTypes.exact({
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onClick: PropTypes.func,
  }),
  classes: PropTypes.exact({
    contClass: PropTypes.string,
    fieldClass: PropTypes.string,
    errorClass: PropTypes.string,
  }),
};

TextField.defaultProps = {
  id: "",
  placeholder: "Inserisci",
  maxIntegerDigits: 7,
  maxDecimalDigits: 2,
};

export default TextField;
