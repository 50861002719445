import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { EventOutcomeEnum } from "../log/logSlice";
import { travelEventsSchema } from "./routeEventsNormalization";
import RouteEventsRepository from "./routeEventsRepository";

//#region Type
export type RouteEvents = {
  id: number;
  event: GpsDataEvent[];
};

export type GpsDataEvent = {
  typeId: number;
  dynamicFields: {
    address: string;
    odometer: number;
    latitude: number;
    longitude: number;
  };
  driverId: number;
  routeId: number;
  eventOutcomeEnum: EventOutcomeEnum;
  eventLogByVehicleIdKey: {
    name: string;
    vehicleId: number;
    trimesterBucket: number;
    timestamp: string;
  };
};

//#endregion Type

//#region API

export const getEventAsync = createAsyncThunk(
  "events/getEventAsync",
  async (
    data: { vehicleId: number; queryParams?: string },
    { rejectWithValue }
  ) => {
    try {
      const routeEventRepository = new RouteEventsRepository();
      const response = await routeEventRepository.getEvents(
        data.vehicleId,
        data.queryParams
      );
      const eventList = _.get(response, Config.GPS_DATA_EVENT_RESPONSE_PATH);
      const normalizedData = normalize(eventList, travelEventsSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.status);
    }
  }
);

//#endregion API

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.gpsDataEvent
    ? routeEventAdapter.setAll(state, action.payload.gpsDataEvent)
    : routeEventAdapter.setAll(state, []);
}
//#endregiorn Custom Functions

//#region Slice
const routeEventAdapter = createEntityAdapter<RouteEvents>({
  selectId: (event) => event.id,
  sortComparer: (a, b) => a.id.toString().localeCompare(b.id.toString()),
});

export const RoutEventSlice = createSlice({
  name: "routeEvent",
  initialState: routeEventAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    routeEventEmptyState: (state: any) => {
      routeEventAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(
        getEventAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getEventAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getEventAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});

//#endregion Slice

//#region Status
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const routeEventSelectors = routeEventAdapter.getSelectors<RootState>(
  (state) => state.routeEvents
);

export const selectRouteEventSliceStatus = (state: any) =>
  state.routeEvents.status;
export const selectRouteEventSliceReasonCode = (state: any) =>
  state.routeEvents.reasonCode;

export const { routeEventEmptyState: routeEventEmptyState } =
  RoutEventSlice.actions;

//#endregion Status

export default RoutEventSlice.reducer;
