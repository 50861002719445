import React, { useContext, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { RouteEvent } from "../../features/route/routesHistorySlice";
import { Preferences } from "../../features/users/preference/preferencesSlice";
import UserContext from "../../features/users/userContext";
import { getTranslationByLanguage } from "../../utils/Utils";
import { IconArrowLeft } from "../Icon/Line/ArrowLeft";
import { IconArrowRight } from "../Icon/Line/ArrowRight";
import { Stop } from "../Icon/Line/Stop";
import { StopF } from "../Icon/Line/StopF";
import "./EventDetail.css";

interface EventDetailProps {
  events: RouteEventReduced[];
}

interface RouteEventReduced {
  event: RouteEvent;
  count: number;
}

export const EventDetail: React.FC<EventDetailProps> = ({ events }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [preferencesContext]: [Preferences] = useContext(UserContext);

  const getColorBySeverity = (severity: any) => {
    if (severity === "ALARM") {
      return "var(--global-colors-palette-red)";
    }
    if (severity === "WARNING") {
      return "var(--global-colors-palette-dark-yellow)";
    }
    if (severity === "INFO") {
      return "var(--global-colors-palette-blue)";
    }
    return "var(--global-colors-ui-primary)";
  };

  const speedLimitEventDetail = (event: RouteEvent) => {
    return (
      <div className="mn-event-detail__icon">
        <div
          className={
            event?.value?.speedLimit && event?.value?.speedLimit > 99
              ? "mn-event-detail-circle_speed_limit mn-event-detail-circle_speed_limit_high"
              : "mn-event-detail-circle_speed_limit"
          }
        >
          {event?.value?.speedLimit !== undefined
            ? Math.floor(event?.value?.speedLimit)
            : ""}
        </div>
      </div>
    );
  };

  const stopEventDetail = (event: RouteEvent) => {
    if (preferencesContext.language == "it") {
      return (
        <div className="mn-event-detail__icon">
          <StopF color="--global-colors-palette-blue" size={24} />
        </div>
      );
    } else {
      return (
        <div className="mn-event-detail__icon">
          <Stop color="--global-colors-palette-blue" size={24} />
        </div>
      );
    }
  };

  const detailsType = (event: RouteEvent) => {
    if (event.type.name === "SPEED_LIMIT" && event?.value?.speedLimit) {
      return speedLimitEventDetail(event);
    }
    if (event.type.name === "STOP") {
      return stopEventDetail(event);
    }
    if (!!event.type.iconCode) {
      return (
        <div className="mn-event-detail__icon">
          <div
            className="svg"
            style={{
              color: getColorBySeverity(event.type.severity),
              fontSize: "24px",
            }}
          >
            <span>
              {String.fromCharCode(
                parseInt(event.type?.iconCode?.replace("&#x", ""), 16)
              )}
            </span>
          </div>
        </div>
      );
    } else {
      return <div className="mn-event-detail__icon"></div>;
    }
  };

  const getArrowPrec = (
    onClickHandler: React.MouseEventHandler<HTMLDivElement> | undefined,
    hasPrev: boolean
  ) => {
    return (
      <div
        className="arrow-carousel arrow-left"
        style={{ display: !hasPrev ? "none" : "inherit" }}
        onClick={onClickHandler}
      >
        <IconArrowLeft size={14} color="--global-colors-sky-dark" />
      </div>
    );
  };

  const getArrowNext = (
    onClickHandler: React.MouseEventHandler<HTMLDivElement> | undefined,
    hasNext: boolean
  ) => {
    return (
      <div
        className="arrow-carousel arrow-right"
        style={{ display: !hasNext ? "none" : "inherit" }}
        onClick={onClickHandler}
      >
        <IconArrowRight size={14} color="--global-colors-sky-dark" />
      </div>
    );
  };

  const getDetail = (eventReduced: RouteEventReduced, index: number) => {
    return (
      <div className="mn-detail" id={`${index}`}>
        <div
          className="mn-event-detail"
          style={{ width: events.length === 1 ? "261px" : "" }}
        >
          <div onClick={prev}>{detailsType(eventReduced.event)}</div>
          <div className="mn-event-detail__event" onClick={next}>
            {eventReduced.count > 1 ? "(" + eventReduced.count + "x)" : ""}
            {getTranslationByLanguage(
              eventReduced.event.type?.translations ?? [],
              preferencesContext.language
            )}
          </div>
        </div>
      </div>
    );
  };

  const next = () => {
    setCurrentSlide((index) => index + 1);
  };

  const prev = () => {
    setCurrentSlide((index) => index - 1);
  };

  const updateCurrentSlide = (index: number) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  return (
    <React.Fragment>
      {events.length === 1 ? (
        getDetail(events[0], 0)
      ) : (
        <React.Fragment>
          <Carousel
            selectedItem={currentSlide}
            onChange={updateCurrentSlide}
            autoFocus={true}
            showThumbs={false}
            showStatus={false}
            useKeyboardArrows
            renderArrowPrev={(onClickHandler, hasPrev) =>
              getArrowPrec(onClickHandler, hasPrev)
            }
            renderArrowNext={(onClickHandler, hasNext) =>
              getArrowNext(onClickHandler, hasNext)
            }
            className="presentation-mode"
          >
            {events.map((event, index) => {
              return (
                <React.Fragment key={index}>
                  {getDetail(event, index)}
                </React.Fragment>
              );
            })}
          </Carousel>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
