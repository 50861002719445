import { Loader } from "@googlemaps/js-api-loader";
import dayjs from "dayjs";
import i18next, { t } from "i18next";
import _ from "lodash";
import PropTypes from "prop-types";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router";
import { useLocation, useMatch } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import i18n from "../../i18n/i18nextConf";
import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import { Button } from "../../ui/Button/Button";
import { ButtonGroup } from "../../ui/Button/ButtonGroup";
import { Dropdown } from "../../ui/Dropdown/Dropdown";
import Form from "../../ui/Forms/Form";
import SearchField from "../../ui/Forms/SearchField";
import { SingleDatePicker } from "../../ui/Forms/SingleDatePicker";
import TextField from "../../ui/Forms/TextField";
import { IconEdit } from "../../ui/Icon/Line/Edit";
import { IconUpload } from "../../ui/Icon/Line/Upload";
import { IconPhone } from "../../ui/Icon/Solid/Phone";
import { FeedbackModal } from "../../ui/Modal/CustomModals/FeedbackModal";
import { DataCommon } from "../../utils/Common";
import { ToastNotification } from "../../utils/ToastNotification";
import { autoCompleteAddressFields } from "../../utils/Utils";
import { UploadImage } from "../components/UploadImage";
import { AddressInfo } from "../driver/CreateDriver";
import {
  Tenant,
  tenantsSelectors,
  updateTenantAsync,
} from "../tenants/tenantsSlice";
import {
  Preferences,
  updatePreferencesAsync,
} from "../users/preference/preferencesSlice";
import "./EditAccount.css";
import UserContext from "./userContext";
import {
  UserInfo,
  getUserInfoAsync,
  selectUsersInfoSliceReasonCode,
  selectUsersInfoSliceStatus,
  updateUserInfoAsync,
  userInfoSelectors,
  usersInfoEmptyState,
} from "./usersInfoSlice";

interface EditAccountProps {
  action: "view" | "edit";
}

interface DataCommonProps {
  label: string;
}

let checkShowToast = false;

export const EditAccount = (myProps: EditAccountProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [openModalAvatar, setOpenModalAvatar] = useState(false);
  const [action, setAction] = useState(myProps.action);
  const [errorList, setErrorList] = useState([]);
  const [accountFieldsDisabled, setAccountFieldsDisabled] = useState(true);
  const [companyFieldsDisabled, setCompanyFieldsDisabled] = useState(true);

  const prefixList: DataCommonProps[] =
    DataCommon()?.prefixList ?? ([] as DataCommonProps[]);
  const timeZoneList: DataCommonProps[] =
    DataCommon()?.timeZoneList ?? ([] as DataCommonProps[]);
  const languageList: DataCommonProps[] =
    DataCommon()?.languageList ?? ([] as DataCommonProps[]);

  const usersInfoSliceStatus = useAppSelector(selectUsersInfoSliceStatus);
  const usersInfoSliceReasonCode = useAppSelector(
    selectUsersInfoSliceReasonCode
  );
  const editAccountRoute = useMatch("/account-settings/edit-account/:action");
  const isAccountLoading = usersInfoSliceStatus === "loading";

  const tenant: Tenant = useAppSelector(tenantsSelectors.selectAll)[0];
  const w = window;

  const [editAccount, setEditAccount] = useState<UserInfo>({} as UserInfo);
  const [editPreferences, setEditPreferences] = useState<Preferences>(
    {} as Preferences
  );
  const [editCompany, setCompany] = useState<Tenant>({} as Tenant);
  let account: UserInfo =
    useAppSelector((state) =>
      userInfoSelectors.selectById(
        state,
        Number(localStorage.getItem("customerId"))
      )
    ) ?? ({} as UserInfo);

  const [preferencesContext, setPreferencesContext]: [
    Preferences,
    Dispatch<SetStateAction<Preferences>>
  ] = useContext(UserContext);

  let company: Tenant =
    useAppSelector((state) =>
      tenantsSelectors.selectById(state, account.tenant)
    ) ?? ({} as Tenant);

  const [addressInfoOnAutoComplete, setAddressInfoOnAutoComplete] =
    useState<AddressInfo>({} as AddressInfo);

  /**
   * This useEffect handles the updating of the address info once the user
   * select an address from the autocompletion functionality.
   * It solves a bug. The callback function of the autocompletion
   * does not keep memory of the state of the editing/creating driver.
   */
  useEffect(() => {
    if (!_.isEmpty(addressInfoOnAutoComplete)) {
      setEditAccount({
        ...editAccount,
        address: addressInfoOnAutoComplete.address,
        city: addressInfoOnAutoComplete.city,
        province: addressInfoOnAutoComplete.province,
        zip: addressInfoOnAutoComplete.zip,
        country: addressInfoOnAutoComplete.country,
      });
    }
  }, [addressInfoOnAutoComplete]);

  // API load account details
  useEffect(() => {
    const customerId = Number(localStorage.getItem("customerId"));
    store.dispatch(getUserInfoAsync({ id: customerId }));
    document.title = t("navigation.menuBalloon.userMenu.accountSettings");
    const loader = new Loader({
      apiKey: process.env?.REACT_APP_GOOGLE_MAPS_API_KEY ?? "",
      libraries: ["places", "drawing", "geometry"],
      version: "beta",
    });
    loader.load().then();
    return () => {
      loader.deleteScript();
      store.dispatch(usersInfoEmptyState());
    };
  }, []);

  useEffect(() => {
    const openModal = () => {
      if (location.state) {
        setShowModal(true);
      }
    };
    openModal();
  }, [location]);

  useEffect(() => {
    if (
      editAccountRoute !== null &&
      (editAccountRoute.params.action === "view" ||
        editAccountRoute.params.action === "edit")
    ) {
      setAction(editAccountRoute.params.action);
    }
  }, [editAccountRoute]);

  // API update account details
  function saveAccount() {
    if (action === "view") {
      navigate("/account-settings/edit-account/edit");
    } else if (action === "edit") {
      Object.values(editAccount).length > 0 &&
        store.dispatch(
          updateUserInfoAsync({ id: account.id, userInfo: editAccount })
        );

      Object.values(editCompany).length > 0 &&
        store.dispatch(
          updateTenantAsync({ id: company.id, tenant: editCompany })
        );

      Object.values(editPreferences).length > 0 &&
        store.dispatch(
          updatePreferencesAsync({
            id: preferencesContext.id,
            preferences: editPreferences,
          })
        );

      updateBaloonMenuNameSurname();

      setLanguageApplication();

      setPreferencesContext({
        ...preferencesContext,
        timeZone: editPreferences.timeZone ?? preferencesContext.timeZone,
        displayLanguage:
          editPreferences.displayLanguage ?? preferencesContext.displayLanguage,
        language: editPreferences.language ?? preferencesContext.language,
        isMetric: editPreferences.isMetric ?? preferencesContext.isMetric,
        listOnFleetCont:
          editPreferences.listOnFleetCont ?? preferencesContext.listOnFleetCont,
        clusterOnMap:
          editPreferences.clusterOnMap ?? preferencesContext.clusterOnMap,
        vehIdOnMap: editPreferences.vehIdOnMap ?? preferencesContext.vehIdOnMap,
        tooltipOnMap:
          editPreferences.tooltipOnMap ?? preferencesContext.tooltipOnMap,
        trafficInfoOnMap:
          editPreferences.trafficInfoOnMap ??
          preferencesContext.trafficInfoOnMap,
        satelliteOnMap:
          editPreferences.satelliteOnMap ?? preferencesContext.satelliteOnMap,
      });

      document.title = t("navigation.menuBalloon.userMenu.accountSettings");
      ToastNotification({
        toastId: "updateDone",
        status: "success",
        description: t("common.updateDone"),
      });

      restoreViewAccountInitialState();
    }
  }

  const closeModal = () => {
    setShowModal(false);
    navigate("", { replace: true });
  };

  const restoreViewAccountInitialState = () => {
    setEditAccount({} as UserInfo);
    setCompany({} as Tenant);
    setEditPreferences({} as Preferences);
    navigate("/account-settings/edit-account/view");
    setAction("view");
  };

  //#region setting button preferences
  const isMetricUnitSystem = () => {
    return editPreferences.isMetric ?? preferencesContext.isMetric;
  };
  const isOpenVehicleListOnFleetControl = () => {
    return (
      editPreferences.listOnFleetCont ?? preferencesContext.listOnFleetCont
    );
  };
  const isActiveClusterOnMap = () => {
    return editPreferences.clusterOnMap ?? preferencesContext.clusterOnMap;
  };
  const isShowVehicleIdOnMap = () => {
    return editPreferences.vehIdOnMap ?? preferencesContext.vehIdOnMap;
  };
  const isShowTooltipOnMap = () => {
    return editPreferences.tooltipOnMap ?? preferencesContext.tooltipOnMap;
  };
  const isShowTrafficInfoOnMap = () => {
    return (
      editPreferences.trafficInfoOnMap ?? preferencesContext.trafficInfoOnMap
    );
  };
  const isSatelliteOnMap = () => {
    return editPreferences.satelliteOnMap ?? preferencesContext.satelliteOnMap;
  };
  //#endregion

  function selectLanguage(label: string) {
    switch (label) {
      case "Italiano":
        return "it";
      case "English US":
      case "English GB":
        return "en";
      case "Français":
        return "fr";
      case "Português":
        return "pt";
      case "Español":
        return "es";
      default:
        return "en";
    }
  }

  const setLanguageApplication = () => {
    if (
      Object.values(editPreferences).length > 0 &&
      editPreferences.displayLanguage !== undefined
    ) {
      i18n.changeLanguage(editPreferences.language);
    }
  };

  const updateBaloonMenuNameSurname = () => {
    const itemFirstName = editAccount.firstName ?? account.firstName;
    const itemLastName = editAccount.secondName ?? account.secondName;
    localStorage.setItem("fullName", itemFirstName + " " + itemLastName);
    const profilePic = editAccount.profilePicture ?? account.profilePicture;
    localStorage.setItem("profilePic", profilePic);
  };

  //Handle slice errors with Toast-Notifications
  if (
    usersInfoSliceStatus === "failed" &&
    usersInfoSliceReasonCode === GTFleetErrorCodes.USERNAME_NOT_FOUND
  ) {
    ToastNotification({
      toastId: "UserNotFoundError",
      status: "error",
      description: t("common.UserNotFoundError"),
    });
  } else if (
    usersInfoSliceStatus === "failed" &&
    usersInfoSliceReasonCode === ""
  ) {
    ToastNotification({
      toastId: "networkError",
      status: "error",
      description: t("common.networkError"),
    });
  }
  //#endregion

  return (
    <>
      <FeedbackModal
        open={showModal}
        title={t(
          "customModals.infoModal.accountSettings.changePassword.changedPassword"
        )}
        desc={t("customModals.infoModal.accountSettings.changePassword.goBack")}
        primaryLabel={t(
          "customModals.infoModal.accountSettings.changePassword.close"
        )}
        primaryAction={closeModal}
        closeAction={closeModal}
      />

      {account.id && preferencesContext.id && company.id && (
        <>
          <PageFilters>
            {/*------------ Account FilterBar ---------*/}
            <div className="col col-16">
              <div className="ea-subtopbar">
                <div className="ea-subsection-name">
                  {action === "edit" &&
                    t("account.settings.edit.nameSubTopBar")}
                  {action === "view" &&
                    t("account.settings.view.nameSubTopBar")}
                </div>
                <div className="ea-button-save-group">
                  {action === "edit" && (
                    <Button
                      size="small"
                      aspect="secondary"
                      label={t("common.cancel")}
                      isLoading={isAccountLoading}
                      onClick={restoreViewAccountInitialState}
                    />
                  )}

                  <Button
                    size="small"
                    aspect="primary"
                    isLoading={isAccountLoading}
                    disabled={
                      action === "edit"
                        ? errorList.length > 0 ||
                          (Object.values(editAccount).length === 0 &&
                            Object.values(editPreferences).length === 0 &&
                            Object.values(editCompany).length === 0)
                        : false
                    }
                    label={
                      action === "view" ? t("common.edit") : t("common.save")
                    }
                    onClick={() => saveAccount()}
                  >
                    {action === "view" && (
                      <IconEdit className="" color="" size={14} />
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </PageFilters>
          <PageContent>
            <div className="ea-form-container">
              <Form>
                {/* PERSONAL INFO FORM */}
                <div className="ea-personal-info-form">
                  <div className="ea-forms-title">
                    {t("account.settings.edit.titlePersonalInfo")}
                  </div>
                  <div className="ea-forms-container">
                    {/*nome e cognome */}
                    <div className="ea-half-form">
                      <div className="ea-forms-label">{t("common.name")} *</div>
                      <div className="ea-forms">
                        <div
                          className={
                            action === "view"
                              ? "ea-left-form-name ea-only-view"
                              : "ea-left-form-name"
                          }
                        >
                          <TextField
                            id={t("common.name")}
                            placeholder={t("common.name")}
                            validate="alphabetic|isNotEmpty"
                            errorList={setErrorList}
                            events={{
                              onChange: (data) => {
                                setEditAccount({
                                  ...editAccount,
                                  firstName: data.value ?? account.firstName,
                                });
                              },
                            }}
                            value={
                              editAccount.firstName ?? account.firstName ?? ""
                            }
                          ></TextField>
                        </div>
                      </div>
                    </div>
                    <div className="ea-half-form">
                      <div className="ea-right-forms-label">
                        {t("common.surname")} *
                      </div>
                      <div
                        className={
                          action === "view"
                            ? "ea-right-form-name ea-only-view"
                            : "ea-right-form-name"
                        }
                      >
                        <TextField
                          id={t("common.surname")}
                          placeholder={t("common.surname")}
                          validate="alphabetic|isNotEmpty"
                          errorList={setErrorList}
                          events={{
                            onChange: (data) => {
                              setEditAccount({
                                ...editAccount,
                                secondName: data.value ?? account.secondName,
                              });
                            },
                          }}
                          value={
                            editAccount.secondName ?? account.secondName ?? ""
                          }
                        ></TextField>
                      </div>
                    </div>
                  </div>
                  <div className="ea-forms-container">
                    {/* indirizzo e città */}
                    <div className="ea-half-form">
                      <div className="ea-forms-label">
                        {t("common.address")} *
                      </div>
                      <div className="ea-forms">
                        <div
                          className={
                            action === "view"
                              ? "ea-left-form-name ea-only-view"
                              : "ea-left-form-name"
                          }
                        >
                          <SearchField
                            id={t("common.address")}
                            placeholder={t(
                              "map.actions.searchActionMenu.locationPlaceholder"
                            )}
                            onChange={(data) => {
                              setEditAccount({
                                ...editAccount,
                                address: data ?? account.address,
                              });
                              setAccountFieldsDisabled(false);
                            }}
                            value={editAccount.address ?? account.address}
                            searchAddress={true}
                            hasSearchIcon={true}
                            onAddressClick={(data) => {
                              const addressInfo = autoCompleteAddressFields(
                                data,
                                setAccountFieldsDisabled
                              );
                              setAddressInfoOnAutoComplete(addressInfo);
                            }}
                            autocompleteOptions={{
                              types: ["route"],
                              componentRestrictions: { country: "it" },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="ea-half-form">
                      <div className="ea-right-forms-label">
                        {t("common.city")} *
                      </div>
                      <div
                        className={
                          action === "view"
                            ? "ea-right-form-name ea-only-view"
                            : "ea-right-form-name"
                        }
                      >
                        <TextField
                          id={t("common.city")}
                          placeholder={t("common.city")}
                          validate="isNotEmpty"
                          errorList={setErrorList}
                          value={editAccount.city ?? account.city ?? ""}
                          events={{
                            onChange: (data) => {
                              setEditAccount({
                                ...editAccount,
                                city: data.value ?? account.city,
                              });
                            },
                          }}
                          autoComplete="off"
                          disabled={accountFieldsDisabled}
                        ></TextField>
                      </div>
                    </div>
                  </div>
                  <div className="ea-forms-container">
                    {/* provincia e cap*/}
                    <div className="ea-half-form">
                      <div className="ea-forms-label">
                        {t("common.province")} *
                      </div>
                      <div className="ea-forms">
                        <div
                          className={
                            action === "view"
                              ? "ea-left-form-name ea-only-view"
                              : "ea-left-form-name"
                          }
                        >
                          <TextField
                            id={t("common.province")}
                            placeholder={t("common.province")}
                            validate="alphabetic|isNotEmpty"
                            maxLength="2"
                            errorList={setErrorList}
                            value={
                              (editAccount.province &&
                                editAccount.province.toUpperCase()) ??
                              (account.province &&
                                account.province.toUpperCase()) ??
                              ""
                            }
                            events={{
                              onChange: (data) => {
                                setEditAccount({
                                  ...editAccount,
                                  province: data.value ?? account.province,
                                });
                              },
                            }}
                            autoComplete="off"
                            disabled={accountFieldsDisabled}
                          ></TextField>
                        </div>
                      </div>
                    </div>
                    <div className="ea-half-form">
                      <div className="ea-right-forms-label">
                        {t("common.zip")} *
                      </div>
                      <div
                        className={
                          action === "view"
                            ? "ea-right-form-name ea-only-view"
                            : "ea-right-form-name"
                        }
                      >
                        <TextField
                          id={t("common.zip")}
                          placeholder={t("common.zip")}
                          validate="numeric|isNotEmpty"
                          maxLength="5"
                          value={editAccount.zip ?? account.zip ?? ""}
                          events={{
                            onChange: (data) => {
                              setEditAccount({
                                ...editAccount,
                                zip: data.value ?? account.zip,
                              });
                            },
                          }}
                          errorList={setErrorList}
                          disabled={accountFieldsDisabled}
                          autoComplete="off"
                        ></TextField>
                      </div>
                    </div>
                  </div>
                  <div className="ea-forms-container">
                    {/* stato e data di nascita*/}
                    <div className="ea-half-form">
                      <div className="ea-forms-label">
                        {t("common.country")} *
                      </div>
                      <div className="ea-forms">
                        <div
                          className={
                            action === "view"
                              ? "ea-left-form-name ea-only-view"
                              : "ea-left-form-name"
                          }
                        >
                          <TextField
                            id={t("common.country")}
                            placeholder={t("common.country")}
                            validate="alphabetic|isNotEmpty"
                            errorList={setErrorList}
                            value={
                              (editAccount.country &&
                                editAccount.country.toUpperCase()) ??
                              (account.country &&
                                account.country.toUpperCase()) ??
                              ""
                            }
                            events={{
                              onChange: (data) => {
                                setEditAccount({
                                  ...editAccount,
                                  country: data.value ?? account.country,
                                });
                              },
                            }}
                            autoComplete="off"
                            disabled={accountFieldsDisabled}
                          ></TextField>
                        </div>
                      </div>
                    </div>
                    <div className="ea-half-form">
                      <div className="ea-right-forms-label">
                        {t("common.dateOfBirth")} *
                      </div>
                      <div
                        className={
                          action === "view"
                            ? "ea-right-form-name ea-only-view"
                            : "ea-right-form-name"
                        }
                      >
                        <SingleDatePicker
                          id="BirthDate"
                          validation="isNotEmpty"
                          setErrorList={setErrorList}
                          errorList={errorList}
                          dateRange={[dayjs().subtract(100, "year"), dayjs()]}
                          oldDate={
                            editAccount.dateOfBirth
                              ? String(editAccount.dateOfBirth)
                              : String(account.dateOfBirth)
                          }
                          setDate={(data) =>
                            setEditAccount({
                              ...editAccount,
                              dateOfBirth: data ?? account.dateOfBirth,
                            })
                          }
                          placeholder={t("common.dateOfBirth")}
                          localeFormat={
                            preferencesContext.localeFormat ?? "DD / MM / YYYY"
                          }
                          language={
                            preferencesContext.language ?? i18next.language
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {/* telefono e codice fiscale*/}
                  <div className="ea-forms-container">
                    <div className="ea-half-form-splitted-left">
                      <div className="ea-forms-label">{t("common.phone")}</div>
                      <div className="ea-forms">
                        <div className="ea-left-form-phone">
                          <div
                            className={
                              action === "view"
                                ? "ea-drpdwn-small ea-only-view"
                                : "ea-drpdwn-small"
                            }
                          >
                            <Dropdown
                              itemAttribute="label"
                              placeholder="Prefix"
                              value={{
                                label:
                                  editAccount.prefix ?? account.prefix ?? "",
                              }}
                              size="normal"
                              onChange={(data) => {
                                setEditAccount({
                                  ...editAccount,
                                  prefix: data[0]
                                    ? data[0].label
                                    : account.prefix,
                                });
                              }}
                              options={prefixList}
                              isClearable={false}
                            />
                          </div>
                        </div>
                        <div
                          className={
                            action === "view"
                              ? "ea-right-form-phone ea-only-view"
                              : "ea-right-form-phone"
                          }
                        >
                          <TextField
                            id={t("common.phoneNumber")}
                            placeholder={t("common.phoneNumber")}
                            validate="numeric"
                            errorList={setErrorList}
                            events={{
                              onChange: (data) => {
                                setEditAccount({
                                  ...editAccount,
                                  telephone: data.value ?? account.telephone,
                                });
                              },
                            }}
                            value={
                              editAccount.telephone ?? account.telephone ?? ""
                            }
                            maxLength="10"
                            minLength="9"
                          ></TextField>
                        </div>
                      </div>
                    </div>
                    <div className="ea-half-form-splitted-right">
                      <div className="ea-right-forms-label">
                        {t("common.fiscalCode")} *
                      </div>
                      <div
                        className={
                          action === "view"
                            ? "ea-right-form-name ea-only-view"
                            : "ea-right-form-name"
                        }
                      >
                        <TextField
                          id={t("common.fiscalCode")}
                          placeholder={t("common.fiscalCode")}
                          validate="fiscalCode|isNotEmpty"
                          errorList={setErrorList}
                          events={{
                            onChange: (data) => {
                              setEditAccount({
                                ...editAccount,
                                fiscalCode:
                                  data.value.toUpperCase() ??
                                  account.fiscalCode,
                              });
                            },
                          }}
                          value={
                            (editAccount.fiscalCode &&
                              editAccount.fiscalCode.toUpperCase()) ??
                            (account.fiscalCode &&
                              account.fiscalCode.toUpperCase()) ??
                            ""
                          }
                        ></TextField>
                      </div>
                    </div>
                  </div>
                  <div className="ea-forms-container">
                    <div className="ea-forms-label">{t("common.email")} *</div>
                    <div className="ea-forms">
                      <div
                        className={
                          action === "view"
                            ? "ea-left-form ea-only-view"
                            : "ea-left-form"
                        }
                      >
                        <TextField
                          id={t("common.email")}
                          placeholder={t("common.email")}
                          validate="email|isNotEmpty"
                          errorList={setErrorList}
                          value={editAccount.email ?? account.email ?? ""}
                          disabled={true}
                        ></TextField>
                      </div>
                    </div>
                  </div>
                  <div className="ea-forms-container">
                    <div className="ea-forms-label">
                      {t("account.settings.edit.profilePicture")}
                    </div>
                    <div className="ea-forms">
                      <div
                        className={
                          action === "view"
                            ? "ea-left-form ea-only-view"
                            : "ea-left-form"
                        }
                      >
                        <Button
                          aspect="secondary"
                          size="regular"
                          label={
                            editAccount.profilePicture?.split("/").pop() ??
                            account.profilePicture?.split("/").pop() ??
                            t("common.upload")
                          }
                          onClick={() => {
                            setOpenModalAvatar(true);
                          }}
                        >
                          <IconUpload
                            className=""
                            size={14}
                            color="--global-colors-ink-light"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* CHANGE PASSWORD FORM */}
                <div className="ea-security-form">
                  <div className="ea-forms-title">
                    {t("account.settings.edit.titleSecurity")}
                  </div>
                  <div className="ea-forms-container">
                    <div className="ea-forms-label">{t("common.password")}</div>
                    <div className="ea-forms">
                      <div className="ea-left-form-text">
                        <div
                          className="ea-change-password"
                          onClick={() =>
                            navigate("/account-settings/change-password")
                          }
                        >
                          {t("account.settings.edit.changePassword")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* NOTIFICATIONS CREDIT FORM */}
                <div className="ea-security-form">
                  <div className="ea-forms-title">
                    {t("account.settings.edit.creditNotifications")}
                  </div>
                  <div
                    className="ea-forms-container"
                    style={{ display: "flex" }}
                  >
                    <div
                      className="ea-forms-label"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {t("account.settings.edit.notificationsAvabile")}
                    </div>
                    <div
                      className="ea-forms"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <div
                        className="ea-credit-container"
                        style={{
                          backgroundColor:
                            editAccountRoute?.params.action === "edit"
                              ? "var(--global-colors-sky-dark)"
                              : "transparent",
                          opacity:
                            editAccountRoute?.params.action === "edit"
                              ? "0.5"
                              : "",
                          animation: "0.5",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "var(--global-font-size-sm)",
                            fontFamily: "var(--global-font-regular)",
                            color: "var(--global-colors-ink-light)",
                          }}
                        >
                          {company.notificationsCredit ?? 0}
                        </span>
                      </div>
                      <Button
                        aspect="secondary"
                        size="regular"
                        label={t("common.customerService")}
                        onClick={() => {
                          {
                            const fullName =
                              localStorage.getItem("fullName") || "";
                            const companyName = tenant.name;
                            const message = encodeURIComponent(
                              t("common.whatsappCredit")
                                .replace("*fullName", fullName)
                                .replace("*companyName", companyName)
                            );
                            w.open(
                              "https://wa.me/+390802464245?text=" + message,
                              "_blank"
                            );
                          }
                        }}
                      >
                        <IconPhone
                          className=""
                          size={14}
                          color="--global-colors-ink-light"
                        />
                      </Button>
                    </div>
                  </div>
                </div>

                {/* COMPANY INFO FORM */}
                <div className="ea-company-info-form">
                  <div className="ea-forms-title">
                    {t("account.settings.edit.titleCompanyInfo")}
                  </div>
                  <div className="ea-forms-container">
                    <div className="ea-company-info-label ea-forms-label">
                      {t("account.settings.edit.companyName")}
                    </div>
                    <div className="ea-company-info-forms">
                      <div
                        className={
                          action === "view"
                            ? "ea-left-form ea-only-view"
                            : "ea-left-form"
                        }
                      >
                        <TextField
                          id={t("account.settings.edit.companyName")}
                          placeholder={t("account.settings.edit.companyName")}
                          errorList={setErrorList}
                          events={{
                            onChange: (data) =>
                              setCompany({
                                ...editCompany,
                                name: data.value ?? company.name,
                              }),
                          }}
                          value={editCompany.name ?? company.name ?? ""}
                          disabled={true}
                        ></TextField>
                      </div>
                    </div>
                  </div>
                  <div className="ea-forms-container">
                    <div className="ea-half-form">
                      <div className="ea-forms-label">
                        {t("common.address")} *
                      </div>
                      <div className="ea-forms">
                        <div
                          className={
                            action === "view"
                              ? "ea-left-form ea-only-view"
                              : "ea-left-form"
                          }
                        >
                          <SearchField
                            id={t("account.settings.edit.address")}
                            placeholder={t("account.settings.edit.address")}
                            onChange={(data) => {
                              setCompany({
                                ...editCompany,
                                address: data ?? company.address,
                              });
                              setCompanyFieldsDisabled(false);
                            }}
                            value={editCompany.address ?? company.address}
                            searchAddress={true}
                            hasSearchIcon={true}
                            onAddressClick={(data) => {
                              const addressInfo = autoCompleteAddressFields(
                                data,
                                setCompanyFieldsDisabled
                              );
                              setAddressInfoOnAutoComplete(addressInfo);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="ea-half-form">
                      <div className="ea-right-forms-label">
                        {t("common.city")} *
                      </div>
                      <div
                        className={
                          action === "view"
                            ? "ea-right-form-name ea-only-view"
                            : "ea-right-form-name"
                        }
                      >
                        <TextField
                          id={t("account.settings.edit.city")}
                          placeholder={t("account.settings.edit.city")}
                          validate="isNotEmpty"
                          errorList={setErrorList}
                          value={editCompany.city ?? company.city ?? ""}
                          events={{
                            onChange: (data) => {
                              setCompany({
                                ...editCompany,
                                city: data.value ?? company.city,
                              });
                            },
                          }}
                          autoComplete="off"
                          disabled={companyFieldsDisabled}
                        ></TextField>
                      </div>
                    </div>
                  </div>
                  <div className="ea-forms-container">
                    <div className="ea-half-form">
                      <div className="ea-forms-label">
                        {t("common.province")} *
                      </div>
                      <div className="ea-forms">
                        <div
                          className={
                            action === "view"
                              ? "ea-left-form-name ea-only-view"
                              : "ea-left-form-name"
                          }
                        >
                          <TextField
                            id={t("account.settings.edit.province")}
                            placeholder={t("account.settings.edit.province")}
                            validate="alphabetic|isNotEmpty"
                            maxLength="2"
                            errorList={setErrorList}
                            value={
                              (editCompany.province &&
                                editCompany.province.toUpperCase()) ??
                              (company.province &&
                                company.province.toUpperCase()) ??
                              ""
                            }
                            events={{
                              onChange: (data) => {
                                setCompany({
                                  ...editCompany,
                                  province: data?.value ?? company?.province,
                                });
                              },
                            }}
                            autoComplete="off"
                            disabled={companyFieldsDisabled}
                          ></TextField>
                        </div>
                      </div>
                    </div>
                    <div className="ea-half-form">
                      <div className="ea-right-forms-label">
                        {t("common.zip")} *
                      </div>
                      <div
                        className={
                          action === "view"
                            ? "ea-right-form-name ea-only-view"
                            : "ea-right-form-name"
                        }
                      >
                        <TextField
                          id={t("account.settings.edit.zip")}
                          placeholder={t("account.settings.edit.zip")}
                          validate="numeric|isNotEmpty"
                          maxLength="5"
                          value={editCompany.zip ?? company.zip ?? ""}
                          events={{
                            onChange: (data) => {
                              setCompany({
                                ...editCompany,
                                zip: data.value ?? company.zip,
                              });
                            },
                          }}
                          errorList={setErrorList}
                          disabled={companyFieldsDisabled}
                          autoComplete="off"
                        ></TextField>
                      </div>
                    </div>
                  </div>

                  <div className="ea-forms-container">
                    <div className="ea-half-form">
                      <div className="ea-forms-label">
                        {t("common.country")} *
                      </div>
                      <div className="ea-forms">
                        <div
                          className={
                            action === "view"
                              ? "ea-left-form-name ea-only-view"
                              : "ea-left-form-name"
                          }
                        >
                          <TextField
                            id={t("account.settings.edit.country")}
                            placeholder={t("account.settings.edit.country")}
                            validate="alphabetic|isNotEmpty"
                            errorList={setErrorList}
                            value={
                              (editCompany.country &&
                                editCompany.country.toUpperCase()) ??
                              (company.country &&
                                company.country.toUpperCase()) ??
                              ""
                            }
                            events={{
                              onChange: (data) => {
                                setCompany({
                                  ...editCompany,
                                  country: data.value ?? company.country,
                                });
                              },
                            }}
                            autoComplete="off"
                            disabled={companyFieldsDisabled}
                          ></TextField>
                        </div>
                      </div>
                    </div>
                    <div className="ea-half-form-company-info-tel">
                      <div className="ea-right-forms-label">
                        {t("common.phone")}
                      </div>
                      <div className="ea-company-forms">
                        <div className="ea-company-left-form-phone">
                          <div
                            className={
                              action === "view"
                                ? "ea-drpdwn-small ea-only-view"
                                : "ea-drpdwn-small"
                            }
                          >
                            <Dropdown
                              placeholder="Prefix"
                              itemAttribute="label"
                              value={{
                                label:
                                  editCompany.prefix ?? company.prefix ?? "",
                              }}
                              size="normal"
                              onChange={(data) => {
                                setCompany({
                                  ...editCompany,
                                  prefix: data[0]
                                    ? data[0].label
                                    : company.prefix,
                                });
                              }}
                              options={prefixList}
                              isClearable={false}
                            />
                          </div>
                        </div>
                        <div
                          className={
                            action === "view"
                              ? "ea-company-right-form-phone ea-only-view"
                              : "ea-company-right-form-phone"
                          }
                        >
                          <TextField
                            id={t("account.settings.edit.phoneNumber")}
                            placeholder={t("account.settings.edit.phoneNumber")}
                            validate="numeric"
                            errorList={setErrorList}
                            events={{
                              onChange: (data) => {
                                setCompany({
                                  ...editCompany,
                                  telephone: data.value ?? company.telephone,
                                });
                              },
                            }}
                            value={
                              editCompany.telephone ?? company.telephone ?? ""
                            }
                          ></TextField>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ea-forms-container">
                    <div className="ea-forms-label">
                      {t("account.settings.edit.vatNumber")}
                    </div>
                    <div className="ea-forms">
                      <div
                        className={
                          action === "view"
                            ? "ea-left-form ea-only-view"
                            : "ea-left-form"
                        }
                      >
                        <TextField
                          id={t("account.settings.edit.vatNumber")}
                          placeholder={t("account.settings.edit.vatNumber")}
                          validate="alphaNumeric"
                          errorList={setErrorList}
                          events={{
                            onChange: (data) =>
                              setCompany({
                                ...editCompany,
                                vatNumber: data.value ?? company.vatNumber,
                              }),
                          }}
                          value={
                            editCompany.vatNumber ?? company.vatNumber ?? ""
                          }
                          disabled={true}
                        ></TextField>
                      </div>
                    </div>
                  </div>
                </div>

                {/* PREFERENCES FORM */}
                <div className="ea-preferences-form">
                  <div className="ea-forms-title">
                    {t("account.settings.edit.titlePreferences")}
                  </div>
                  <div className="ea-forms-container">
                    <div className="ea-forms-label">
                      {t("account.settings.edit.timeZone")}
                    </div>
                    <div className="ea-forms">
                      <div
                        className={
                          action === "view"
                            ? "ea-left-form ea-only-view"
                            : "ea-left-form"
                        }
                      >
                        <div className="ea-drpdwn-large">
                          <Dropdown
                            placeholder="Time Zone"
                            itemAttribute="label"
                            value={{
                              label:
                                editPreferences.timeZone ??
                                preferencesContext.timeZone ??
                                "",
                            }}
                            size="normal"
                            onChange={(data) => {
                              setEditPreferences({
                                ...editPreferences,
                                timeZone: data[0]
                                  ? data[0].label
                                  : preferencesContext.timeZone,
                              });
                            }}
                            options={timeZoneList}
                            isClearable={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ea-forms-container">
                    <div className="ea-preferences-label ea-forms-label">
                      {t("account.settings.edit.displayLanguage")}
                    </div>
                    <div className="ea-preferences-forms">
                      <div
                        className={
                          action === "view"
                            ? "ea-left-form ea-only-view"
                            : "ea-left-form"
                        }
                      >
                        <div className="ea-drpdwn-large">
                          <Dropdown
                            placeholder="Language"
                            itemAttribute="label"
                            value={{
                              label:
                                editPreferences.displayLanguage ??
                                preferencesContext.displayLanguage ??
                                "",
                            }}
                            size="normal"
                            onChange={(data) => {
                              setEditPreferences({
                                ...editPreferences,
                                currency:
                                  data[0]?.label === "Italiano"
                                    ? "EURO"
                                    : data[0]?.label.includes("English")
                                    ? "POUND_STERLING"
                                    : "DOLLAR",
                                displayLanguage:
                                  data[0]?.label ??
                                  preferencesContext.displayLanguage,
                                language:
                                  data[0]?.label &&
                                  selectLanguage(data[0]?.label),
                                localeFormat:
                                  data[0]?.label &&
                                  (data[0]?.label === "English US"
                                    ? "MM / DD / YYYY"
                                    : "DD / MM / YYYY"),
                              });
                            }}
                            options={languageList}
                            isClearable={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ea-forms-container">
                    <div className="ea-forms-label">
                      {t("account.settings.edit.unitSystem")}
                    </div>
                    <div className="ea-forms">
                      <div
                        className={
                          action === "view" ? "ea-bg ea-only-view" : "ea-bg"
                        }
                      >
                        <ButtonGroup size="regular">
                          <Button
                            size="regular"
                            label={t("account.settings.edit.metric")}
                            aspect={
                              isMetricUnitSystem() ? "primary" : "outline"
                            }
                            onClick={() => {
                              setEditPreferences({
                                ...editPreferences,
                                isMetric: true,
                              });
                            }}
                            active={isMetricUnitSystem()}
                          />
                          <Button
                            size="regular"
                            label={t("account.settings.edit.imperial")}
                            aspect={
                              !isMetricUnitSystem() ? "primary" : "outline"
                            }
                            onClick={() => {
                              setEditPreferences({
                                ...editPreferences,
                                isMetric: false,
                              });
                            }}
                            active={!isMetricUnitSystem()}
                          />
                        </ButtonGroup>
                      </div>
                    </div>
                  </div>
                  <div className="ea-splitter"></div>
                  <div className="ea-forms-container">
                    <div className="ea-forms-label">
                      {t("account.settings.edit.vehicleListOnFleetControl")}
                    </div>
                    <div className="ea-preferences-forms">
                      <div
                        className={
                          action === "view" ? "ea-bg ea-only-view" : "ea-bg"
                        }
                      >
                        <ButtonGroup size="regular">
                          <Button
                            size="regular"
                            label={t("account.settings.edit.open")}
                            aspect={
                              isOpenVehicleListOnFleetControl()
                                ? "primary"
                                : "outline"
                            }
                            onClick={() => {
                              setEditPreferences({
                                ...editPreferences,
                                listOnFleetCont: true,
                              });
                            }}
                            active={isOpenVehicleListOnFleetControl()}
                          />
                          <Button
                            size="regular"
                            label={t("account.settings.edit.close")}
                            aspect={
                              !isOpenVehicleListOnFleetControl()
                                ? "primary"
                                : "outline"
                            }
                            onClick={() => {
                              setEditPreferences({
                                ...editPreferences,
                                listOnFleetCont: false,
                              });
                            }}
                            active={!isOpenVehicleListOnFleetControl()}
                          />
                        </ButtonGroup>
                      </div>
                    </div>
                  </div>
                  <div className="ea-forms-container">
                    <div className="ea-preferences-label ea-forms-label">
                      {t("account.settings.edit.clusterOnMap")}
                    </div>
                    <div className="ea-preferences-forms">
                      <div
                        className={
                          action === "view" ? "ea-bg ea-only-view" : "ea-bg"
                        }
                      >
                        <ButtonGroup size="regular">
                          <Button
                            size="regular"
                            label={t("account.settings.edit.active")}
                            aspect={
                              isActiveClusterOnMap() ? "primary" : "outline"
                            }
                            onClick={() => {
                              setEditPreferences({
                                ...editPreferences,
                                clusterOnMap: true,
                              });
                            }}
                            active={isActiveClusterOnMap()}
                          />
                          <Button
                            size="regular"
                            label={t("account.settings.edit.notActive")}
                            aspect={
                              !isActiveClusterOnMap() ? "primary" : "outline"
                            }
                            onClick={() => {
                              setEditPreferences({
                                ...editPreferences,
                                clusterOnMap: false,
                              });
                            }}
                            active={!isActiveClusterOnMap()}
                          />
                        </ButtonGroup>
                      </div>
                    </div>
                  </div>
                  <div className="ea-forms-container">
                    <div className="ea-preferences-label ea-forms-label">
                      {t("account.settings.edit.vehicleIdOnMap")}
                    </div>
                    <div className="ea-preferences-forms">
                      <div
                        className={
                          action === "view" ? "ea-bg ea-only-view" : "ea-bg"
                        }
                      >
                        <ButtonGroup size="regular">
                          <Button
                            size="regular"
                            label={t("account.settings.edit.show")}
                            aspect={
                              isShowVehicleIdOnMap() ? "primary" : "outline"
                            }
                            onClick={() => {
                              setEditPreferences({
                                ...editPreferences,
                                vehIdOnMap: true,
                              });
                            }}
                            active={isShowVehicleIdOnMap()}
                          />
                          <Button
                            size="regular"
                            label={t("account.settings.edit.hide")}
                            aspect={
                              !isShowVehicleIdOnMap() ? "primary" : "outline"
                            }
                            onClick={() => {
                              setEditPreferences({
                                ...editPreferences,
                                vehIdOnMap: false,
                              });
                            }}
                            active={!isShowVehicleIdOnMap()}
                          />
                        </ButtonGroup>
                      </div>
                    </div>
                  </div>
                  <div className="ea-forms-container">
                    <div className="ea-preferences-label ea-forms-label">
                      {t("account.settings.edit.tooltipOnMap")}
                    </div>
                    <div className="ea-preferences-forms">
                      <div
                        className={
                          action === "view" ? "ea-bg ea-only-view" : "ea-bg"
                        }
                      >
                        <ButtonGroup size="regular">
                          <Button
                            size="regular"
                            label={t("account.settings.edit.show")}
                            aspect={
                              isShowTooltipOnMap() ? "primary" : "outline"
                            }
                            onClick={() => {
                              setEditPreferences({
                                ...editPreferences,
                                tooltipOnMap: true,
                              });
                            }}
                            active={isShowTooltipOnMap()}
                          />
                          <Button
                            size="regular"
                            label={t("account.settings.edit.hide")}
                            aspect={
                              !isShowTooltipOnMap() ? "primary" : "outline"
                            }
                            onClick={() => {
                              setEditPreferences({
                                ...editPreferences,
                                tooltipOnMap: false,
                              });
                            }}
                            active={!isShowTooltipOnMap()}
                          />
                        </ButtonGroup>
                      </div>
                    </div>
                  </div>
                  <div className="ea-forms-container">
                    <div className="ea-preferences-label ea-forms-label">
                      {t("account.settings.edit.trafficInfoOnMap")}
                    </div>
                    <div className="ea-preferences-forms">
                      <div
                        className={
                          action === "view" ? "ea-bg ea-only-view" : "ea-bg"
                        }
                      >
                        <ButtonGroup size="regular">
                          <Button
                            size="regular"
                            label={t("account.settings.edit.show")}
                            aspect={
                              isShowTrafficInfoOnMap() ? "primary" : "outline"
                            }
                            onClick={() => {
                              setEditPreferences({
                                ...editPreferences,
                                trafficInfoOnMap: true,
                              });
                            }}
                            active={isShowTrafficInfoOnMap()}
                          />
                          <Button
                            size="regular"
                            label={t("account.settings.edit.hide")}
                            aspect={
                              !isShowTrafficInfoOnMap() ? "primary" : "outline"
                            }
                            onClick={() => {
                              setEditPreferences({
                                ...editPreferences,
                                trafficInfoOnMap: false,
                              });
                            }}
                            active={!isShowTrafficInfoOnMap()}
                          />
                        </ButtonGroup>
                      </div>
                    </div>
                  </div>
                  <div className="ea-forms-container">
                    <div className="ea-preferences-label ea-forms-label">
                      {t("account.settings.edit.satelliteOnMap")}
                    </div>
                    <div className="ea-preferences-forms">
                      <div
                        className={
                          action === "view" ? "ea-bg ea-only-view" : "ea-bg"
                        }
                      >
                        <ButtonGroup size="regular">
                          <Button
                            size="regular"
                            label={t("account.settings.edit.show")}
                            aspect={isSatelliteOnMap() ? "primary" : "outline"}
                            onClick={() => {
                              setEditPreferences({
                                ...editPreferences,
                                satelliteOnMap: true,
                              });
                            }}
                            active={isSatelliteOnMap()}
                          />
                          <Button
                            size="regular"
                            label={t("account.settings.edit.hide")}
                            aspect={!isSatelliteOnMap() ? "primary" : "outline"}
                            onClick={() => {
                              setEditPreferences({
                                ...editPreferences,
                                satelliteOnMap: false,
                              });
                            }}
                            active={!isSatelliteOnMap()}
                          />
                        </ButtonGroup>
                      </div>
                    </div>
                  </div>
                </div>

                {/* CUSTOM LOGO FORM */}
                <div className="ea-custom-logo-form">
                  <div className="ea-forms-title">
                    {t("account.settings.edit.titleCustomLogo")}
                  </div>
                  <div className="ea-forms-container">
                    <div className="ea-forms-label">
                      {t("account.settings.edit.platformLogo")}
                    </div>
                    <div className="ea-forms">
                      <div
                        className={
                          action === "view"
                            ? "ea-left-form ea-only-view"
                            : "ea-left-form"
                        }
                      >
                        <Button
                          aspect="secondary"
                          size="regular"
                          label={t("common.upload")}
                        >
                          <IconUpload
                            className=""
                            size={14}
                            color="--global-colors-ink-light"
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </PageContent>
          <UploadImage
            openModal={openModalAvatar}
            setOpenModal={() => {
              setOpenModalAvatar(false);
            }}
            imageAvatar={account.profilePicture?.split("/").pop() || ""}
            setUploadNameFile={(name) => {
              setEditAccount({
                ...editAccount,
                profilePicture:
                  process.env.REACT_APP_BUCKET_URL + name,
              });
              if (!checkShowToast) {
                checkShowToast = true;
                ToastNotification({
                  toastId: "titleMessageUploadingAvatar",
                  status: "success",
                  description: t(
                    "account.settings.edit.descriptionMessageUploadingAvatar"
                  ),
                  title: t("account.settings.edit.titleMessageUploadingAvatar"),
                  onClose: () => (checkShowToast = false),
                });
              }
            }}
          />
        </>
      )}
    </>
  );
};

EditAccount.propTypes = {
  action: PropTypes.oneOf(["view", "edit"]).isRequired,
};

EditAccount.defaultProps = {
  action: "view",
};
