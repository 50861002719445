import { IconActivity } from "../ui/Icon/Line/Activity";
import { IconAdd } from "../ui/Icon/Line/Add";
import { IconAddView } from "../ui/Icon/Line/AddView";
import { IconAirFilter } from "../ui/Icon/Line/AirFilter";
import { IconAlarmSiren } from "../ui/Icon/Line/AlarmSiren";
import { IconAlert } from "../ui/Icon/Line/Alert";
import { IconAlerts } from "../ui/Icon/Line/Alerts";
import { IconAlertSimple } from "../ui/Icon/Line/AlertSimple";
import { IconArrowDown } from "../ui/Icon/Line/ArrowDown";
import { IconArrowLeft } from "../ui/Icon/Line/ArrowLeft";
import { IconArrowRight } from "../ui/Icon/Line/ArrowRight";
import { IconArrowToLeft } from "../ui/Icon/Line/ArrowToLeft";
import { IconArrowToRight } from "../ui/Icon/Line/ArrowToRight";
import { IconArrowUp } from "../ui/Icon/Line/ArrowUp";
import { IconAttach } from "../ui/Icon/Line/Attach";
import { IconAttention } from "../ui/Icon/Line/Attention";
import { IconAutomations } from "../ui/Icon/Line/Automations";
import { IconAverageSpeed } from "../ui/Icon/Line/AverageSpeed";
import { IconBack } from "../ui/Icon/Line/Back";
import { IconBookmark } from "../ui/Icon/Line/Bookmark";
import { IconBrakeParts } from "../ui/Icon/Line/BrakeParts";
import { IconBuild } from "../ui/Icon/Line/Build";
import { IconCalendar } from "../ui/Icon/Line/Calendar";
import { IconCallCenter } from "../ui/Icon/Line/CallCenter";
import { IconCar } from "../ui/Icon/Line/Car";
import { IconCarInsurance } from "../ui/Icon/Line/CarInsurance";
import { IconCarRepair } from "../ui/Icon/Line/CarRepair";
import { IconCarTax } from "../ui/Icon/Line/CarTax";
import { IconCheck } from "../ui/Icon/Line/Check";
import { IconClock } from "../ui/Icon/Line/Clock";
import { IconClose } from "../ui/Icon/Line/Close";
import { IconCloseToLeft } from "../ui/Icon/Line/CloseToLeft";
import { IconCloudUpdate } from "../ui/Icon/Line/CloudUpdate";
import { IconCornerUpLeft } from "../ui/Icon/Line/CornerUpLeft";
import { IconCornerUpRight } from "../ui/Icon/Line/CornerUpRight";
import { IconDashboard } from "../ui/Icon/Line/Dashboard";
import { IconDevices } from "../ui/Icon/Line/Devices";
import { IconDiagnosisReport } from "../ui/Icon/Line/DiagnosisReport";
import { IconDollar } from "../ui/Icon/Line/Dollar";
import { IconDoorBackClosed } from "../ui/Icon/Line/DoorBackClosed";
import { IconDoorBackOpen } from "../ui/Icon/Line/DoorBackOpen";
import { IconDown } from "../ui/Icon/Line/Down";
import { IconDownload } from "../ui/Icon/Line/Download";
import { IconDownPoint } from "../ui/Icon/Line/DownPoint";
import { IconDraggable } from "../ui/Icon/Line/Draggable";
import { IconDriverBehaviour } from "../ui/Icon/Line/DriverBehaviour";
import { IconDrivers } from "../ui/Icon/Line/Drivers";
import { IconEdit } from "../ui/Icon/Line/Edit";
import { IconEndB } from "../ui/Icon/Line/EndB";
import { IconEndPoint } from "../ui/Icon/Line/EndPoint";
import { IconEngineLock } from "../ui/Icon/Line/EngineLock";
import { IconEuro } from "../ui/Icon/Line/Euro";
import { IconExchange } from "../ui/Icon/Line/Exchange";
import { IconExpandMap } from "../ui/Icon/Line/ExpandMap";
import { IconExternalLink } from "../ui/Icon/Line/ExternalLink";
import { IconEye } from "../ui/Icon/Line/Eye";
import { IconEyeSlash } from "../ui/Icon/Line/EyeSlash";
import { IconFileReport } from "../ui/Icon/Line/FileReport";
import { IconFilter } from "../ui/Icon/Line/Filter";
import { IconFinishB } from "../ui/Icon/Line/FinishB";
import { IconForward } from "../ui/Icon/Line/Forward";
import { IconFullScreen } from "../ui/Icon/Line/FullScreen";
import { IconGasStation } from "../ui/Icon/Line/GasStation";
import { IconGenericalInspection } from "../ui/Icon/Line/GenericalInspection";
import { IconGeofence } from "../ui/Icon/Line/Geofence";
import { IconGeofenceLine } from "../ui/Icon/Line/GeofenceLine";
import { IconGeofenceOval } from "../ui/Icon/Line/GeofenceOval";
import { IconGeofencePolygon } from "../ui/Icon/Line/GeofencePolygon";
import { IconGroup } from "../ui/Icon/Line/Group";
import { IconHelp } from "../ui/Icon/Line/Help";
import { IconHomeWork } from "../ui/Icon/Line/HomeWork";
import { IconKilometers } from "../ui/Icon/Line/Kilometers";
import { IconLayers } from "../ui/Icon/Line/Layers";
import { IconLicense } from "../ui/Icon/Line/License";
import { IconList } from "../ui/Icon/Line/List";
import { IconLoader } from "../ui/Icon/Line/Loader";
import { IconLocationHistory } from "../ui/Icon/Line/LocationHistory";
import { IconLocatorOff } from "../ui/Icon/Line/LocatorOff";
import { IconLockerOpen } from "../ui/Icon/Line/LockerOpen";
import { IconLogs } from "../ui/Icon/Line/Logs";
import { IconMail } from "../ui/Icon/Line/Mail";
import { IconMaintenanceDeadlines } from "../ui/Icon/Line/MaintenanceDeadlines";
import { IconMap } from "../ui/Icon/Line/Map";
import { IconMenu } from "../ui/Icon/Line/Menu";
import { IconMenuDashboard } from "../ui/Icon/Line/MenuDashboard";
import { IconMenuHelp } from "../ui/Icon/Line/MenuHelp";
import { IconMenuProfile } from "../ui/Icon/Line/MenuProfile";
import { IconMenuRoutes } from "../ui/Icon/Line/MenuRoutes";
import { IconMessages } from "../ui/Icon/Line/Messages";
import { IconMinus } from "../ui/Icon/Line/Minus";
import { IconMissions } from "../ui/Icon/Line/Missions";
import { IconMobileDevice } from "../ui/Icon/Line/MobileDevice";
import { IconMoon } from "../ui/Icon/Line/Moon";
import { IconMore } from "../ui/Icon/Line/More";
import { IconNotification } from "../ui/Icon/Line/Notification";
import { IconOdometer } from "../ui/Icon/Line/Odometer";
import { IconOpenToRight } from "../ui/Icon/Line/OpenToRight";
import { IconOrderBy } from "../ui/Icon/Line/OrderBy";
import { IconParking } from "../ui/Icon/Line/Parking";
import { IconParkingPoint } from "../ui/Icon/Line/ParkingPoint";
import { IconParkingProtection } from "../ui/Icon/Line/ParkingProtection";
import { IconPeriodicalInspection } from "../ui/Icon/Line/PeriodicalInspection";
import { IconPermissions } from "../ui/Icon/Line/Permissions";
import { IconPhone } from "../ui/Icon/Line/Phone";
import { IconPoll } from "../ui/Icon/Line/Poll";
import { IconPollenFilter } from "../ui/Icon/Line/PollenFilter";
import { IconPound } from "../ui/Icon/Line/Pound";
import { IconPower } from "../ui/Icon/Line/Power";
import { IconRadar } from "../ui/Icon/Line/Radar";
import { IconRefresh } from "../ui/Icon/Line/Refresh";
import { IconReorder } from "../ui/Icon/Line/Reorder";
import { IconRepeat } from "../ui/Icon/Line/Repeat";
import { IconScaleDown } from "../ui/Icon/Line/ScaleDown";
import { IconSearch } from "../ui/Icon/Line/Search";
import { IconSecurity } from "../ui/Icon/Line/Security";
import { IconShoppingCart } from "../ui/Icon/Line/ShoppingCart";
import { IconSnow } from "../ui/Icon/Line/Snow";
import { IconSpeed } from "../ui/Icon/Line/Speed";
import { IconSpeedCamera } from "../ui/Icon/Line/SpeedCamera";
import { IconStartA } from "../ui/Icon/Line/StartA";
import { IconStartPoint } from "../ui/Icon/Line/StartPoint";
import { IconStopPoint } from "../ui/Icon/Line/StopPoint";
import { IconSun } from "../ui/Icon/Line/Sun";
import { IconSync } from "../ui/Icon/Line/Sync";
import { IconTachometer } from "../ui/Icon/Line/Tachometer";
import { IconTarget } from "../ui/Icon/Line/Target";
import { IconTemperature } from "../ui/Icon/Line/Temperature";
import { IconTerrain } from "../ui/Icon/Line/Terrain";
import { IconTheftProtection } from "../ui/Icon/Line/TheftProtection";
import { IconTimer } from "../ui/Icon/Line/Timer";
import { IconTrailerTruck } from "../ui/Icon/Line/TrailerTruck";
import { IconTrash } from "../ui/Icon/Line/Trash";
import { IconTrophy } from "../ui/Icon/Line/Trophy";
import { IconTruck } from "../ui/Icon/Line/Truck";
import { IconUp } from "../ui/Icon/Line/Up";
import { IconUpload } from "../ui/Icon/Line/Upload";
import { IconUpPoint } from "../ui/Icon/Line/UpPoint";
import { IconUser } from "../ui/Icon/Line/User";
import { IconUtilization } from "../ui/Icon/Line/Utilization";
import { IconVehicle } from "../ui/Icon/Line/Vehicle";
import { IconVideo } from "../ui/Icon/Line/Video";
import { IconWarning } from "../ui/Icon/Line/Warning";

export default {
  title: "Icon/Line",
  component: IconActivity,
  argTypes: {
    color: { control: "color" },
    size: { control: "number" },
  },
};

export const Activity = (args) => <IconActivity {...args} />;

export const Add = (args) => <IconAdd {...args} />;

export const AddView = (args) => <IconAddView {...args} />;

export const AirFilter = (args) => <IconAirFilter {...args} />;

export const AlarmSiren = (args) => <IconAlarmSiren {...args} />;

export const Alert = (args) => <IconAlert {...args} />;

export const Alerts = (args) => <IconAlerts {...args} />;

export const AlertSimple = (args) => <IconAlertSimple {...args} />;

export const ArrowDown = (args) => <IconArrowDown {...args} />;

export const ArrowLeft = (args) => <IconArrowLeft {...args} />;

export const ArrowRight = (args) => <IconArrowRight {...args} />;

export const ArrowToLeft = (args) => <IconArrowToLeft {...args} />;

export const ArrowToRight = (args) => <IconArrowToRight {...args} />;

export const ArrowUp = (args) => <IconArrowUp {...args} />;

export const Attach = (args) => <IconAttach {...args} />;

export const Attention = (args) => <IconAttention {...args} />;

export const Automations = (args) => <IconAutomations {...args} />;

export const AverageSpeed = (args) => <IconAverageSpeed {...args} />;

export const Back = (args) => <IconBack {...args} />;

export const Bookmark = (args) => <IconBookmark {...args} />;

export const BrakeParts = (args) => <IconBrakeParts {...args} />;

export const Build = (args) => <IconBuild {...args} />;

export const Calendar = (args) => <IconCalendar {...args} />;

export const CallCenter = (args) => <IconCallCenter {...args} />;

export const CarInsurance = (args) => <IconCarInsurance {...args} />;

export const CarRepair = (args) => <IconCarRepair {...args} />;

export const CarTax = (args) => <IconCarTax {...args} />;

export const Car = (args) => <IconCar {...args} />;

export const Check = (args) => <IconCheck {...args} />;

export const Clock = (args) => <IconClock {...args} />;

export const CloseToLeft = (args) => <IconCloseToLeft {...args} />;

export const Close = (args) => <IconClose {...args} />;

export const CloudUpdate = (args) => <IconCloudUpdate {...args} />;

export const Vehicle = (args) => <IconVehicle {...args} />;

export const Video = (args) => <IconVideo {...args} />;

export const Warning = (args) => <IconWarning {...args} />;

export const TheftProtection = (args) => <IconTheftProtection {...args} />;

export const Timer = (args) => <IconTimer {...args} />;

export const TailerTruck = (args) => <IconTrailerTruck {...args} />;

export const Trash = (args) => <IconTrash {...args} />;

export const Trophy = (args) => <IconTrophy {...args} />;

export const Truck = (args) => <IconTruck {...args} />;

export const Up = (args) => <IconUp {...args} />;

export const Upload = (args) => <IconUpload {...args} />;

export const User = (args) => <IconUser {...args} />;

export const Utilization = (args) => <IconUtilization {...args} />;

export const Odometer = (args) => <IconOdometer {...args} />;

export const OpenToRight = (args) => <IconOpenToRight {...args} />;

export const OrderBy = (args) => <IconOrderBy {...args} />;

export const Parking = (args) => <IconParking {...args} />;

export const PeriodicalInspection = (args) => (
  <IconPeriodicalInspection {...args} />
);

export const Permissions = (args) => <IconPermissions {...args} />;

export const Phone = (args) => <IconPhone {...args} />;

export const Poll = (args) => <IconPoll {...args} />;

export const PollenFilter = (args) => <IconPollenFilter {...args} />;

export const Power = (args) => <IconPower {...args} />;

export const Radar = (args) => <IconRadar {...args} />;

export const Refresh = (args) => <IconRefresh {...args} />;

export const Reorder = (args) => <IconReorder {...args} />;

export const Repeat = (args) => <IconRepeat {...args} />;

export const Search = (args) => <IconSearch {...args} />;

export const Security = (args) => <IconSecurity {...args} />;

export const ShoppingCart = (args) => <IconShoppingCart {...args} />;

export const Snow = (args) => <IconSnow {...args} />;

export const SpeedCamera = (args) => <IconSpeedCamera {...args} />;

export const Speed = (args) => <IconSpeed {...args} />;

export const StartA = (args) => <IconStartA {...args} />;

export const StartPoint = (args) => <IconStartPoint {...args} />;

export const ParkingPoint = (args) => <IconParkingPoint {...args} />;

export const StopPoint = (args) => <IconStopPoint {...args} />;

export const Sun = (args) => <IconSun {...args} />;

export const Sync = (args) => <IconSync {...args} />;

export const Tachometer = (args) => <IconTachometer {...args} />;

export const Target = (args) => <IconTarget {...args} />;

export const Temperature = (args) => <IconTemperature {...args} />;

export const Terrain = (args) => <IconTerrain {...args} />;

export const GeofencePolygon = (args) => <IconGeofencePolygon {...args} />;

export const Geofence = (args) => <IconGeofence {...args} />;

export const Group = (args) => <IconGroup {...args} />;

export const Help = (args) => <IconHelp {...args} />;

export const HomeWork = (args) => <IconHomeWork {...args} />;

export const Kilometers = (args) => <IconKilometers {...args} />;

export const Layers = (args) => <IconLayers {...args} />;

export const License = (args) => <IconLicense {...args} />;

export const List = (args) => <IconList {...args} />;

export const Loader = (args) => <IconLoader {...args} />;

export const LocationHistory = (args) => <IconLocationHistory {...args} />;

export const LocatorOff = (args) => <IconLocatorOff {...args} />;

export const LockerOpen = (args) => <IconLockerOpen {...args} />;

export const Logs = (args) => <IconLogs {...args} />;

export const Mail = (args) => <IconMail {...args} />;

export const MaintenanceDeadlines = (args) => (
  <IconMaintenanceDeadlines {...args} />
);

export const Map = (args) => <IconMap {...args} />;

export const Menu = (args) => <IconMenu {...args} />;

export const MenuDashboard = (args) => <IconMenuDashboard {...args} />;

export const MenuHelp = (args) => <IconMenuHelp {...args} />;

export const MenuProfile = (args) => <IconMenuProfile {...args} />;

export const MenuRoutes = (args) => <IconMenuRoutes {...args} />;

export const Messages = (args) => <IconMessages {...args} />;

export const Minus = (args) => <IconMinus {...args} />;

export const Missions = (args) => <IconMissions {...args} />;

export const MobileDevice = (args) => <IconMobileDevice {...args} />;

export const Moon = (args) => <IconMoon {...args} />;

export const More = (args) => <IconMore {...args} />;

export const Notification = (args) => <IconNotification {...args} />;

export const CornerUpLeft = (args) => <IconCornerUpLeft {...args} />;

export const CornerUpRight = (args) => <IconCornerUpRight {...args} />;

export const Dashboard = (args) => <IconDashboard {...args} />;

export const Devices = (args) => <IconDevices {...args} />;

export const DiagnosisReport = (args) => <IconDiagnosisReport {...args} />;

export const DoorBackOpen = (args) => <IconDoorBackOpen {...args} />;

export const DoorBackClosed = (args) => <IconDoorBackClosed {...args} />;

export const Down = (args) => <IconDown {...args} />;

export const Download = (args) => <IconDownload {...args} />;

export const Draggable = (args) => <IconDraggable {...args} />;

export const DriverBehaviour = (args) => <IconDriverBehaviour {...args} />;

export const Drivers = (args) => <IconDrivers {...args} />;

export const Edit = (args) => <IconEdit {...args} />;

export const EndB = (args) => <IconEndB {...args} />;

export const EndPoint = (args) => <IconEndPoint {...args} />;

export const EngineLock = (args) => <IconEngineLock {...args} />;

export const Euro = (args) => <IconEuro {...args} />;

export const Exchange = (args) => <IconExchange {...args} />;

export const ExpandMap = (args) => <IconExpandMap {...args} />;

export const ExternalLink = (args) => <IconExternalLink {...args} />;

export const Eye = (args) => <IconEye {...args} />;

export const EyeSlash = (args) => <IconEyeSlash {...args} />;

export const FileReport = (args) => <IconFileReport {...args} />;

export const Filter = (args) => <IconFilter {...args} />;

export const FinishB = (args) => <IconFinishB {...args} />;

export const Forward = (args) => <IconForward {...args} />;

export const FullScreen = (args) => <IconFullScreen {...args} />;

export const GasStation = (args) => <IconGasStation {...args} />;

export const GenericalInspection = (args) => (
  <IconGenericalInspection {...args} />
);

export const GeofenceLine = (args) => <IconGeofenceLine {...args} />;

export const GeofenceOval = (args) => <IconGeofenceOval {...args} />;

export const ParkingProtection = (args) => <IconParkingProtection {...args} />;

export const ScaleDown = (args) => <IconScaleDown {...args} />;

export const DownPoint = (args) => <IconDownPoint {...args} />;

export const UpPoint = (args) => <IconUpPoint {...args} />;

export const Pound = (args) => <IconPound {...args} />;

export const Dollar = (args) => <IconDollar {...args} />;
