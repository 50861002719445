import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { IconAdd } from "../Icon/Line/Add";
import { IconMinus } from "../Icon/Line/Minus";
import { Signal } from "../Indicators/IndicatorSignal";
import { ElementType } from "./ElementTypeEnum";
import "./GroupElement.css";

interface GroupElementProps {
  type: ElementType;
  id: number | string;
  status?:
    | "NO_SIGNAL"
    | "ONLINE"
    | "MOVING"
    | "PARKING"
    | "OFFLINE"
    | "UNKNOWN"
    | "STOP";
  icon?: object;
  categoryColor?: string;
  iconText?: object;
  firstTitle?: string;
  secondTitle?: string;
  firstSubtitle?: string;
  secondSubtitle?: string;
  active?: boolean;
  disableClick?: boolean;
  isAdded?: boolean;
  onClicked?: (e: any) => any;
}

export const GroupElement: React.FC<GroupElementProps> = ({
  id,
  status,
  firstTitle,
  secondTitle,
  firstSubtitle,
  secondSubtitle,
  iconText,
  active,
  icon,
  categoryColor,
  isAdded,
  type,
  disableClick,
  onClicked,
  ...props
}) => {
  const [coords, setCoords] = useState({ x: -1, y: -1 });
  const [isRippling, setIsRippling] = useState(false);

  const onClick = (e: any) => {
    const rect = e.target.getBoundingClientRect();
    setCoords({
      x: rect.width / 2,
      y: rect.height / 2,
    });
  };

  useEffect(() => {
    if (coords.x !== -1 && coords.y !== -1) {
      setIsRippling(true);
      setTimeout(() => setIsRippling(false), 300);
    } else {
      setIsRippling(false);
    }
  }, [coords]);

  useEffect(() => {
    if (!isRippling) {
      setCoords({ x: -1, y: -1 });
    }
  }, [isRippling]);

  return (
    <div
      className={
        disableClick ? "mn-box-container-disabled" : "mn-box-container"
      }
      {...props}
      key={id}
      onClick={(e) => {
        if (!disableClick) {
          onClicked && onClicked(id);
          onClick(e);
        }
      }}
    >
      <div className="mn-box-content">
        {type !== ElementType.geofence ? (
          <>
            <div className="mn-box-icon">
              <div className="mn-box-icon-img">{icon}</div>
            </div>
            {status && (
              <div className="mn-box-icon-status">
                <Signal mode={status} />
              </div>
            )}
          </>
        ) : (
          <div
            className="mn-box-category-color"
            style={{
              backgroundColor: categoryColor,
            }}
          />
        )}
        <div className="mn-box-detail">
          <span className="mn-box-title">
            {firstTitle}
            {!!secondTitle && (
              <>
                <span className="mn-box-alias-plate-divider" />
                {secondTitle}
              </>
            )}
          </span>
          {firstSubtitle && (
            <div className="mn-box-subtitle">
              {firstSubtitle}
              {!!secondSubtitle &&
                secondSubtitle !== "" &&
                type !== ElementType.geofence &&
                `, ${t("admin.groups.editGroups.lastSeen")} ${secondSubtitle}`}
            </div>
          )}
        </div>
        {!disableClick && (
          <button className="mn-box-button">
            <span>
              {isAdded ? (
                <IconMinus size={14} color="--global-colors-ink-dark" />
              ) : (
                <IconAdd size={14} color="--global-colors-ink-dark" />
              )}
            </span>
          </button>
        )}
      </div>
      {isRippling && !disableClick && (
        <span className="ripple" style={{ left: coords.x, top: coords.y }} />
      )}
    </div>
  );
};
