import { t } from "i18next";
import { UserPermissions } from "../../users/privilege/privilegesSlice";

import i18next from "i18next";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../ui/Button/Button";
import { DataLabel } from "../../../ui/DataLabel/DataLabel";
import { Dropdown } from "../../../ui/Dropdown/Dropdown";
import { IconAlert } from "../../../ui/Icon/Line/Alert";
import { IconArrowLeft } from "../../../ui/Icon/Line/ArrowLeft";
import { IconArrowRight } from "../../../ui/Icon/Line/ArrowRight";
import { IconKilometers } from "../../../ui/Icon/Line/Kilometers";
import { IconParking } from "../../../ui/Icon/Line/Parking";
import { IconDriver } from "../../../ui/Icon/Solid/Driver";
import { convertSecondsToTime, numberAnnotation } from "../../../utils/Utils";
import { Preferences } from "../../users/preference/preferencesSlice";
import UserContext from "../../users/userContext";
import "./AnalogicReport.css";
import { MacroSummarySensor } from "./analogicReportMacroSummarySlice";
import { SensorSummary } from "./analogicReportSummarySlice";

export interface AnalogicSummaryReportProps {
  permissions: UserPermissions;
  reportArchiveSummary: SensorSummary;
  macroSummarySensor: MacroSummarySensor;
  reportArchiveSummaries: SensorSummary[];
  isLoading?: boolean;
  onPeriodChange?: (a: any) => any;
}

interface TableData {
  columns: { label: string; field: string; sort: boolean }[] | undefined;
  rows: { [key: string]: any };
}

export const AnalogicSummaryReport: React.FC<AnalogicSummaryReportProps> = ({
  permissions,
  reportArchiveSummary,
  macroSummarySensor,
  reportArchiveSummaries,
  isLoading,
  onPeriodChange,
}) => {
  //STATIC VARIABLES FOR LEGEND SECTION
  const temperatureColor = "#0052BD";

  const humidityColor = "#00AAFF";
  const thresholdColor = "#FF4F48";
  const IgnitionKeyColor = "#007F4D";
  const IgnitionKeyOnColor = "#35465B";
  const IgnitionKeyOffColor = "#35465B";
  const allPeriodList = [
    { label: i18next.t("common.allPeriod"), key: "allPeriod" },
    { label: i18next.t("common.daily"), key: "daily" },
  ];

  //DEFAULT SECTION
  const [preferencesContext]: [Preferences] = useContext(UserContext);
  const navigate = useNavigate();
  const [dropdownPeriod, setDropdownPeriod] = useState(allPeriodList[1]);

  return (
    <>
      {/* LEGEND AND DAYSELECTED SECTION */}
      {reportArchiveSummary?.uuid && macroSummarySensor && (
        <div className="analogic-report-sub-filterbar">
          <div className="analogic-report-summary-legend-row">
            <span
              style={{
                whiteSpace: "nowrap",
                height: "20px",
                marginRight: "20px",
                fontSize: "16px",
                alignItems: "center",
                display: "flex",
              }}
            >
              {dropdownPeriod.key === "daily"
                ? reportArchiveSummary?.date &&
                  new Date(reportArchiveSummary?.date).getDate() +
                    "/" +
                    (new Date(reportArchiveSummary?.date).getMonth() + 1) +
                    "/" +
                    new Date(reportArchiveSummary?.date).getFullYear()
                : new Date(reportArchiveSummaries[0]?.date).getDate() +
                  "/" +
                  (new Date(reportArchiveSummaries[0]?.date).getMonth() + 1) +
                  "/" +
                  new Date(reportArchiveSummaries[0]?.date).getFullYear() +
                  " - " +
                  new Date(
                    reportArchiveSummaries[
                      reportArchiveSummaries.length - 1
                    ]?.date
                  ).getDate() +
                  "/" +
                  (new Date(
                    reportArchiveSummaries[
                      reportArchiveSummaries.length - 1
                    ]?.date
                  ).getMonth() +
                    1) +
                  "/" +
                  new Date(
                    reportArchiveSummaries[
                      reportArchiveSummaries.length - 1
                    ]?.date
                  ).getFullYear()}
            </span>
            <div
              className="details-report-item"
              style={{
                whiteSpace: "nowrap",
                marginRight: "20px",
                marginLeft: "20px",
              }}
            >
              <DataLabel
                dataText={`${numberAnnotation(
                  macroSummarySensor?.kilometersTraveled / 1000
                )} ${preferencesContext.isMetric ? "km" : "mil"}`}
                icon={<IconKilometers size={14} />}
                label={t("customModals.travelDetail.km")}
              />
            </div>
            <div
              className="details-report-item"
              style={{
                whiteSpace: "nowrap",
                marginRight: "20px",
                marginLeft: "20px",
              }}
            >
              <DataLabel
                dataText={
                  (macroSummarySensor?.parkingTime &&
                    convertSecondsToTime(macroSummarySensor?.parkingTime)
                      .replace(":", " h ")
                      .replace(":", " m ") + " s") ??
                  "0h 0m"
                }
                icon={<IconParking size={14} />}
                label={t("report.details.parkTime")}
              />
            </div>
            <div
              className="details-report-item"
              style={{
                whiteSpace: "nowrap",
                marginRight: "20px",
                marginLeft: "20px",
              }}
            >
              <DataLabel
                dataText={
                  (macroSummarySensor?.numberOfDrivers &&
                    macroSummarySensor?.numberOfDrivers) ??
                  "0"
                }
                icon={<IconDriver size={14} />}
                label={t("common.drivers")}
              />
            </div>
            <div
              className="details-report-item"
              style={{
                whiteSpace: "nowrap",
                marginRight: "20px",
                marginLeft: "20px",
              }}
            >
              <DataLabel
                dataText={
                  (macroSummarySensor?.numberOfAlerts &&
                    macroSummarySensor?.numberOfAlerts) ??
                  "0"
                }
                icon={<IconAlert size={14} />}
                label={t("table.columns.alerts")}
              />
            </div>
            <div className="analogic-report-legend-section">
              <div className="analogic-report-legend-element">
                <div className="analogic-report-legend-element-firstsect">
                  <span
                    style={{
                      width: "13px",
                      height: "13px",
                      borderRadius: "4px",
                      background: temperatureColor,
                      display: "flex",
                    }}
                  />
                </div>
                <div className="analogic-report-legend-element-secondsect">
                  {t("report.analogic.temperature")}
                </div>
              </div>

              <div className="analogic-report-legend-element">
                <div className="analogic-report-legend-element-firstsect">
                  <span
                    style={{
                      width: "13px",
                      height: "13px",
                      borderRadius: "4px",
                      background: humidityColor,
                      display: "flex",
                    }}
                  />
                </div>
                <div className="analogic-report-legend-element-secondsect">
                  {t("report.analogic.humidity")}
                </div>
              </div>

              <div className="analogic-report-legend-element">
                <div className="analogic-report-legend-element-firstsect">
                  <span
                    style={{
                      width: "13px",
                      height: "13px",
                      borderRadius: "4px",
                      background: thresholdColor,
                      display: "flex",
                    }}
                  />
                </div>
                <div className="analogic-report-legend-element-secondsect">
                  {t("report.analogic.threshold")}
                </div>
              </div>

              <div className="analogic-report-legend-element">
                <div className="analogic-report-legend-element-firstsect">
                  <span
                    style={{
                      width: "13px",
                      height: "13px",
                      borderRadius: "4px",
                      background: IgnitionKeyColor,
                      display: "flex",
                    }}
                  />
                </div>
                <div className="analogic-report-legend-element-secondsect">
                  {t("report.analogic.ignitionKey")}
                </div>
              </div>

              <div className="analogic-report-legend-element">
                <div className="analogic-report-legend-element-secondsect">
                  On
                </div>
                <div className="analogic-report-legend-element-firstsect">
                  <span
                    style={{
                      width: "40px",
                      height: "1px",
                      border: "2px solid " + IgnitionKeyOnColor,
                      display: "flex",
                    }}
                  />
                </div>
              </div>

              <div className="analogic-report-legend-element">
                <div className="analogic-report-legend-element-secondsect">
                  Off
                </div>
                <div className="analogic-report-legend-element-firstsect">
                  <span
                    style={{
                      width: "40px",
                      height: "1px",
                      border: "2px dashed " + IgnitionKeyOffColor,
                      display: "flex",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="analogic-report-day-selector-section">
            {dropdownPeriod?.key === "daily" ? (
              <>
                <span
                  style={{
                    whiteSpace: "nowrap",
                    fontSize: "14px",
                    color: "#687484",
                  }}
                >
                  {reportArchiveSummaries.findIndex(
                    (summary) => summary.uuid === reportArchiveSummary?.uuid
                  ) +
                    1 +
                    " / " +
                    reportArchiveSummaries.length +
                    " " +
                    t("timeFormats.days")}
                </span>
                <span id="button_prev_page-count">
                  <Button
                    aspect="outline"
                    size="small"
                    onlyIcon={true}
                    disabled={
                      reportArchiveSummaries.findIndex(
                        (summary) => summary.uuid === reportArchiveSummary?.uuid
                      ) === 0
                    }
                    onClick={() => {
                      const currentIndex = reportArchiveSummaries.findIndex(
                        (summary) => summary.uuid === reportArchiveSummary?.uuid
                      );
                      if (currentIndex > 0) {
                        const newId =
                          reportArchiveSummaries[currentIndex - 1]?.uuid;
                        navigate(`/reports/analogic-report/${newId}`);
                      }
                    }}
                    label={undefined}
                  >
                    <IconArrowLeft size={11} color="--global-colors-ink-ink" />
                  </Button>
                </span>
                <span id="button_next_page-count">
                  <Button
                    aspect="outline"
                    size="small"
                    disabled={
                      reportArchiveSummaries.findIndex(
                        (summary) => summary.uuid === reportArchiveSummary?.uuid
                      ) ===
                      reportArchiveSummaries.length - 1
                    }
                    onlyIcon={true}
                    onClick={() => {
                      const currentIndex = reportArchiveSummaries.findIndex(
                        (summary) => summary.uuid === reportArchiveSummary?.uuid
                      );
                      if (currentIndex < reportArchiveSummaries.length - 1) {
                        const newId =
                          reportArchiveSummaries[currentIndex + 1]?.uuid;
                        navigate(`/reports/analogic-report/${newId}`);
                      }
                    }}
                    label={undefined}
                  >
                    <IconArrowRight size={11} color="--global-colors-ink-ink" />
                  </Button>
                </span>{" "}
              </>
            ) : (
              <span
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                  color: "#687484",
                }}
              >
                {reportArchiveSummaries.findIndex(
                  (summary) => summary.uuid === reportArchiveSummary?.uuid
                ) +
                  reportArchiveSummaries.length +
                  " " +
                  t("timeFormats.days")}
              </span>
            )}
            {
              <Dropdown
                itemAttribute="label"
                size="small"
                onChange={(val) => {
                  onPeriodChange && onPeriodChange(val[0].key);
                  setDropdownPeriod(val[0]);
                }}
                value={dropdownPeriod}
                options={allPeriodList}
                isDisabled={isLoading || reportArchiveSummaries.length < 2}
              />
            }
          </div>
        </div>
      )}
    </>
  );
};
