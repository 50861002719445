import PropTypes from "prop-types";

export const IllustrationMaxiVan = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 70 60"
      fill="none"
    >
      <path
        d="M69.8251 31.6196L18.9877 60L12.0591 57.8216L3.03982 52.7869L0 49.4002L50.8374 21.0197L69.8251 31.6196Z"
        fill="black"
      />
      <path
        d="M30.4506 50.2457L27.1627 48.4102V44.739L30.4506 46.5746V50.2457Z"
        fill="#29333F"
      />
      <path
        d="M28.7687 47.9689L26.7686 46.8528C26.1915 46.5315 25.395 46.5777 24.5142 47.0681C23.6334 47.56 22.8369 48.4025 22.2598 49.3694C21.6827 50.3364 21.3265 51.4248 21.3265 52.4087C21.3265 53.3911 21.6827 54.0828 22.2598 54.4041L24.2598 55.5202C24.837 55.8431 25.6335 55.7954 26.5143 55.305C27.395 54.8131 28.1916 53.9706 28.7687 53.0036C29.3458 52.0367 29.7019 50.9483 29.7019 49.9644C29.7019 48.9805 29.3458 48.2902 28.7687 47.9689Z"
        fill="#414E5F"
      />
      <path
        opacity="0.5"
        d="M28.7685 47.9689C28.1914 47.6476 27.3949 47.6937 26.5141 48.1842C25.6333 48.6761 24.8368 49.5185 24.2597 50.4855C23.6825 51.4525 23.3264 52.5409 23.3264 53.5248C23.3264 54.5071 23.6825 55.1989 24.2597 55.5202C24.8368 55.8431 25.6333 55.7954 26.5141 55.305C27.3949 54.8131 28.1914 53.9706 28.7685 53.0036C29.3456 52.0367 29.7018 50.9483 29.7018 49.9644C29.7018 48.982 29.3456 48.2902 28.7685 47.9689Z"
        fill="#29333F"
      />
      <path
        opacity="0.5"
        d="M23.3249 53.5248L21.3248 52.4087C21.3248 53.391 21.681 54.0828 22.2581 54.4041L24.2581 55.5202C23.681 55.1974 23.3249 54.5071 23.3249 53.5248Z"
        fill="#29333F"
      />
      <path
        d="M26.7685 46.8528C26.1913 46.5315 25.3948 46.5777 24.514 47.0681L26.5141 48.1842C27.3949 47.6922 28.1914 47.6461 28.7685 47.9689L26.7685 46.8528Z"
        fill="#414E5F"
      />
      <path
        d="M22.2597 49.3694C21.6825 50.3364 21.3264 51.4248 21.3264 52.4087L23.3265 53.5248C23.3265 52.5409 23.6826 51.4525 24.2597 50.4855L22.2597 49.3694Z"
        fill="#414E5F"
      />
      <path
        opacity="0.5"
        d="M24.5141 47.0681C23.6333 47.56 22.8368 48.4024 22.2596 49.3694L24.2597 50.4855C24.8368 49.5185 25.6333 48.6761 26.5141 48.1841L24.5141 47.0681Z"
        fill="#5B6879"
      />
      <path
        d="M28.7081 48.3456C28.1787 48.0504 27.4473 48.0935 26.6397 48.5439C25.832 48.9943 25.1007 49.7676 24.5713 50.6546C24.0419 51.5417 23.7144 52.5394 23.7144 53.4418C23.7144 54.3427 24.0419 54.9776 24.5713 55.2727C25.1007 55.5679 25.832 55.5249 26.6397 55.0744C27.4473 54.624 28.1787 53.8507 28.7081 52.9637C29.2375 52.0782 29.565 51.0789 29.565 50.1765C29.565 49.2741 29.2375 48.6407 28.7081 48.3456Z"
        fill="#29333F"
      />
      <path
        d="M28.0928 49.6338L27.9609 49.5601C27.6222 49.371 27.1564 49.3986 26.6397 49.6861C26.1246 49.9736 25.6572 50.4686 25.3185 51.0343C24.9799 51.6001 24.7716 52.2381 24.7716 52.8146C24.7716 53.391 24.9815 53.7954 25.3185 53.9845L25.4505 54.0582C25.7891 54.2473 26.2549 54.2197 26.7717 53.9322C27.2868 53.6447 27.7542 53.1497 28.0928 52.584C28.4315 52.0182 28.6397 51.3802 28.6397 50.8037C28.6397 50.2272 28.4299 49.8229 28.0928 49.6338Z"
        fill="#95A7B2"
      />
      <path
        d="M28.0928 49.6338C27.7542 49.4448 27.2884 49.4724 26.7717 49.7599C26.2565 50.0474 25.7891 50.5424 25.4505 51.1081C25.1118 51.6739 24.9036 52.3119 24.9036 52.8883C24.9036 53.4648 25.1134 53.8692 25.4505 54.0582C25.7891 54.2473 26.2549 54.2197 26.7717 53.9322C27.2868 53.6447 27.7542 53.1497 28.0928 52.584C28.4315 52.0182 28.6397 51.3802 28.6397 50.8037C28.6397 50.2273 28.4299 49.8229 28.0928 49.6338Z"
        fill="#7C8E99"
      />
      <path
        opacity="0.5"
        d="M24.9051 52.8884L24.7732 52.8146C24.7732 53.3911 24.9831 53.7954 25.3201 53.9845L25.4521 54.0583C25.1134 53.8692 24.9051 53.4649 24.9051 52.8884Z"
        fill="#7C8E99"
      />
      <path
        d="M26.6412 49.6861C26.1261 49.9736 25.6587 50.4686 25.3201 51.0343L25.452 51.1081C25.7907 50.5424 26.2565 50.0474 26.7732 49.7599L26.6412 49.6861Z"
        fill="#BBCDD8"
      />
      <path
        d="M28.0181 49.7599C27.9402 49.7169 27.8559 49.6861 27.7653 49.6677C27.9592 49.8752 28.0721 50.1934 28.0721 50.6054C28.0721 51.1481 27.875 51.7507 27.557 52.2842C27.239 52.8176 26.7986 53.2834 26.3121 53.5555C25.9433 53.7615 25.6015 53.8246 25.3201 53.7677C25.3821 53.8338 25.452 53.8891 25.5283 53.9322C25.8463 54.1105 26.2867 54.0844 26.7732 53.8123C27.2597 53.5402 27.7001 53.0744 28.0181 52.5409C28.336 52.0075 28.5332 51.4048 28.5332 50.8622C28.5332 50.3195 28.336 49.9382 28.0181 49.7599Z"
        fill="#95A7B2"
      />
      <path
        opacity="0.5"
        d="M28.018 49.7599C27.9401 49.7169 27.8559 49.6861 27.7653 49.6677C27.9592 49.8752 28.0721 50.1934 28.0721 50.6054L28.5332 50.8622C28.5332 50.3195 28.336 49.9382 28.018 49.7599Z"
        fill="#7C8E99"
      />
      <path
        d="M27.557 52.2827C27.239 52.8161 26.7986 53.2819 26.3121 53.554L26.7732 53.8107C27.2597 53.5386 27.7001 53.0728 28.0181 52.5394L27.557 52.2827Z"
        fill="#BBCDD8"
      />
      <path
        d="M25.2788 53.0283C25.2788 52.4856 25.476 51.8829 25.7939 51.3495C26.1119 50.816 26.5523 50.3502 27.0388 50.0781C27.503 49.8183 27.9243 49.786 28.2391 49.9382C28.1739 49.866 28.1008 49.8045 28.0181 49.7584C27.7002 49.58 27.2598 49.6062 26.7733 49.8783C26.2868 50.1504 25.8464 50.6162 25.5284 51.1496C25.2104 51.6831 25.0133 52.2857 25.0133 52.8284C25.0133 53.3711 25.2104 53.7523 25.5284 53.9307C25.5427 53.9383 25.5586 53.9445 25.5729 53.9506C25.3869 53.7431 25.2788 53.4295 25.2788 53.0267V53.0283Z"
        fill="black"
      />
      <path
        d="M27.5888 50.4441C27.3535 50.3134 27.0292 50.3318 26.6715 50.5317C26.3138 50.7315 25.9894 51.0744 25.7541 51.4679C25.5188 51.8615 25.3741 52.3042 25.3741 52.7039C25.3741 53.1036 25.5188 53.3849 25.7541 53.5156C25.9894 53.6463 26.3138 53.6278 26.6715 53.428C27.0292 53.2281 27.3535 52.8853 27.5888 52.4918C27.8241 52.0982 27.9688 51.6555 27.9688 51.2558C27.9688 50.8561 27.8241 50.5747 27.5888 50.4441Z"
        fill="black"
      />
      <path
        d="M27.6286 52.3242C27.848 51.9568 27.9831 51.5432 27.9831 51.1697C27.9831 50.7961 27.848 50.5332 27.6286 50.4118L27.1675 50.155C26.9481 50.032 26.6461 50.0505 26.3106 50.2365C26.2756 50.2549 26.2422 50.2765 26.2088 50.2995C25.9497 50.5424 25.716 50.8345 25.5268 51.1527C25.3376 51.471 25.1913 51.8123 25.1039 52.1505C25.1007 52.1889 25.0991 52.2273 25.0991 52.2658C25.0991 52.6393 25.2343 52.9022 25.4537 53.0237L25.9147 53.2804C26.1341 53.4034 26.4362 53.3849 26.7717 53.1989C27.1055 53.0129 27.4092 52.6916 27.6286 52.3242Z"
        fill="#95A7B2"
      />
      <path
        d="M27.6286 50.4117C27.4092 50.2888 27.1071 50.3072 26.7717 50.4932C26.4378 50.6792 26.1341 51.0005 25.9147 51.3679C25.6953 51.7354 25.5602 52.1489 25.5602 52.5225C25.5602 52.896 25.6953 53.1589 25.9147 53.2804C26.1341 53.4034 26.4362 53.3849 26.7717 53.1989C27.1055 53.0129 27.4092 52.6916 27.6286 52.3242C27.848 51.9567 27.9831 51.5432 27.9831 51.1696C27.9831 50.7961 27.848 50.5332 27.6286 50.4117Z"
        fill="#7C8E99"
      />
      <path
        d="M26.9911 51.4786C26.9354 51.4479 26.8575 51.4525 26.7717 51.5002C26.6858 51.5478 26.6095 51.6293 26.5523 51.7231C26.4966 51.8168 26.4617 51.9229 26.4617 52.0182C26.4617 52.1135 26.4966 52.1812 26.5523 52.2119C26.6079 52.2427 26.6858 52.2381 26.7717 52.1904C26.8575 52.1428 26.9339 52.0613 26.9911 51.9675C27.0467 51.8737 27.0817 51.7676 27.0817 51.6723C27.0817 51.577 27.0467 51.5094 26.9911 51.4786Z"
        fill="#414E5F"
      />
      <path
        opacity="0.5"
        d="M25.5618 52.5225L25.1007 52.2657C25.1007 52.6393 25.2358 52.9022 25.4552 53.0236L25.9163 53.2804C25.6969 53.1574 25.5618 52.896 25.5618 52.5225Z"
        fill="#7C8E99"
      />
      <path
        d="M26.2105 50.298C25.9513 50.5409 25.7176 50.833 25.5284 51.1512L25.918 51.368C26.1374 51.0006 26.4394 50.6808 26.7749 50.4932L26.3138 50.2365C26.2789 50.255 26.2455 50.2765 26.2121 50.2995L26.2105 50.298Z"
        fill="#BBCDD8"
      />
      <path
        d="M25.3742 51.434C25.344 51.4955 25.3153 51.557 25.2883 51.62C25.2804 51.637 25.274 51.6539 25.2676 51.6708C25.239 51.7384 25.2136 51.8076 25.1897 51.8752C25.1866 51.886 25.1818 51.8968 25.1786 51.9075C25.1516 51.9875 25.1277 52.0674 25.1071 52.1489C25.1039 52.1873 25.1023 52.2258 25.1023 52.2642C25.1023 52.4287 25.1293 52.5701 25.1786 52.69C25.2231 52.2073 25.4075 51.6938 25.6842 51.2296C25.9576 50.7714 26.3201 50.3656 26.724 50.0873C26.5952 50.1073 26.4584 50.155 26.3138 50.2349C26.2788 50.2534 26.2454 50.2749 26.212 50.298C26.15 50.3548 26.0912 50.4163 26.0324 50.4794C26.0244 50.487 26.0165 50.4963 26.0085 50.504C25.9592 50.5578 25.9115 50.6146 25.8638 50.6715C25.8527 50.6854 25.84 50.7007 25.8289 50.7146C25.7859 50.7684 25.7462 50.8237 25.7049 50.8806C25.6921 50.8975 25.681 50.9144 25.6683 50.9313C25.619 51.0036 25.5713 51.0758 25.5268 51.1512C25.4823 51.2265 25.4409 51.3018 25.4012 51.3787C25.3917 51.3971 25.3837 51.4156 25.3742 51.434Z"
        fill="black"
      />
      <path
        d="M39.6447 24.4387L24.1786 15.7944L17.5727 19.4471L16.2833 20.414L15.3787 21.6055L8.58363 32.8079L2.43723 39.3184L1.53101 43.5307V47.4785L4.66144 50.2104L5.02711 50.4148L5.47227 50.1535L12.6839 54.1782V54.6886L13.0496 54.893L17.4932 56.4811L18.3899 56.6394L18.8891 56.6994L22.3407 54.7731V53.9614C22.3407 52.71 22.7938 51.3249 23.5283 50.0966C24.2628 48.8667 25.2772 47.7952 26.3964 47.1695C27.5157 46.5438 28.53 46.4854 29.263 46.8944C29.9959 47.3033 30.4506 48.1826 30.4506 49.4325V50.2472L40.0931 44.8651V25.1905L39.6432 24.4387H39.6447Z"
        fill="#D4DBDF"
      />
      <path
        d="M17.5171 52.5409L17.4948 56.4811L13.0511 54.8915L12.6839 54.687V54.1766L5.47227 50.1519L5.02711 50.4133L4.66144 50.2103L1.53101 47.4785V43.5307L4.66144 46.2625L13.0511 50.9437L17.5171 52.5409Z"
        fill="#D4DBDF"
      />
      <path
        opacity="0.2"
        d="M13.0511 50.9452V54.8915L17.4948 56.4811L17.5171 52.5409L13.0511 50.9452Z"
        fill="#BBC2C6"
      />
      <path
        opacity="0.2"
        d="M1.53101 47.4785L4.66144 50.2103V46.2625L1.53101 43.5307V47.4785Z"
        fill="#BBC2C6"
      />
      <path
        d="M18.4137 48.3779L17.5171 52.5409L13.0511 50.9452L4.66144 46.2625L1.53101 43.5307L2.43723 39.3185L5.44524 42.6221L13.8349 47.3048L18.4137 48.3779Z"
        fill="#D4DBDF"
      />
      <path
        opacity="0.1"
        d="M13.8349 47.3048L13.0511 50.9452L17.5171 52.5409L18.4138 48.3779L13.8349 47.3048Z"
        fill="#BBC2C6"
      />
      <path
        opacity="0.1"
        d="M1.53101 43.5307L4.66144 46.2625L5.44524 42.6221L2.43723 39.3185L1.53101 43.5307Z"
        fill="#BBC2C6"
      />
      <path
        opacity="0.6"
        d="M8.58048 32.8094L2.43726 39.3185L5.44527 42.6222L13.835 47.3048L18.4138 48.3779L24.5952 41.749L8.58048 32.8094Z"
        fill="#EEF5F9"
      />
      <path
        opacity="0.2"
        d="M30.9037 30.2852L24.6237 41.7182L24.5951 41.749L8.58044 32.8094L15.3755 21.6055L30.9037 30.2852Z"
        fill="#EEF5F9"
      />
      <path
        d="M24.177 15.796L17.5712 19.4471L33.0723 28.1099L39.6448 24.4388L24.177 15.796Z"
        fill="#EEF5F9"
      />
      <path
        opacity="0.7"
        d="M17.5711 19.4471L16.2802 20.4156L31.8067 29.0922L33.0723 28.1099L17.5711 19.4471Z"
        fill="#EEF5F9"
      />
      <path
        opacity="0.4"
        d="M31.8067 29.0922L30.9116 30.2713L30.9037 30.2852L15.3755 21.6055L16.2801 20.4156L31.8067 29.0922Z"
        fill="#EEF5F9"
      />
      <path
        d="M40.0947 25.1905V44.8651L30.4522 50.2472V49.4325C30.4522 48.1826 29.9975 47.3033 29.2645 46.8944C28.53 46.4839 27.5157 46.5438 26.398 47.1695C25.2788 47.7952 24.2644 48.8667 23.5299 50.0966C22.797 51.3249 22.3423 52.71 22.3423 53.9614V54.7731L18.8907 56.6994L18.3915 56.6394V52.7377L19.4201 48.5301L25.363 42.224L31.6748 30.7356L32.5222 29.618L33.7384 28.7063L40.0947 25.1936V25.1905Z"
        fill="#BBC2C6"
      />
      <path
        opacity="0.5"
        d="M17.4948 56.4811L18.3914 56.6394V52.7377L17.517 52.541L17.4948 56.4811Z"
        fill="#BBC2C6"
      />
      <path
        opacity="0.3"
        d="M18.4138 48.3779L19.4186 48.5285L18.3915 52.7377L17.5171 52.5409L18.4138 48.3779Z"
        fill="#BBC2C6"
      />
      <path
        opacity="0.3"
        d="M24.5951 41.749L25.3614 42.2225L19.4185 48.5285L18.4137 48.3779L24.5951 41.749Z"
        fill="#EEF5F9"
      />
      <path
        opacity="0.4"
        d="M30.9037 30.2852L31.6732 30.7341L25.3614 42.2225L24.5951 41.749L30.9037 30.2852Z"
        fill="#BBC2C6"
      />
      <path
        d="M31.8067 29.0922L32.5222 29.6164L31.6732 30.7341L30.9037 30.2852L31.8067 29.0922Z"
        fill="#D4DBDF"
      />
      <path
        opacity="0.4"
        d="M33.0723 28.1099L33.7369 28.7048L32.5222 29.6164L31.8068 29.0922L33.0723 28.1099Z"
        fill="#EEF5F9"
      />
      <path
        opacity="0.5"
        d="M39.6448 24.4388L40.0931 25.1905L33.7368 28.7048L33.0723 28.1099L39.6448 24.4388Z"
        fill="#EEF5F9"
      />
      <path
        opacity="0.7"
        d="M24.5951 41.749L13.8349 47.3048L5.44519 42.6222L8.5804 32.8094L24.5951 41.749Z"
        fill="#EEF5F9"
      />
      <path
        d="M18.3788 52.6455L17.5441 52.4579L13.0893 50.8653L4.7155 46.1933L1.55485 43.3124L1.53101 43.4108V43.5307L4.60739 46.3317L13.005 51.019L17.4869 52.6224L18.4042 52.8284L23.5283 50.0951L18.3788 52.6455Z"
        fill="#EEF5F9"
      />
      <path
        d="M12.6855 52.2072C12.6855 51.9873 12.606 51.7429 12.4772 51.5277C12.3484 51.3109 12.1704 51.1234 11.9732 51.0127L5.74256 47.5337C5.65989 47.4876 5.58199 47.4599 5.50885 47.4476C5.48818 47.5107 5.47546 47.586 5.47546 47.669V50.1502L12.6871 54.1749V52.2056L12.6855 52.2072Z"
        fill="#414E5F"
      />
      <path
        d="M5.23537 47.4862C5.10659 47.5585 5.0271 47.7122 5.0271 47.932V50.4133L5.47226 50.1504V47.6691C5.47226 47.5861 5.48339 47.5108 5.50565 47.4478C5.40231 47.4309 5.31009 47.4432 5.23537 47.4847V47.4862Z"
        fill="#BBC2C6"
      />
      <path
        d="M14.3945 54.1167V53.3649L16.1672 53.9983L16.1609 54.7485L14.3945 54.1167Z"
        fill="white"
      />
      <path
        d="M2.41333 46.982V46.2302L3.39586 47.1188L3.3895 47.869L2.41333 46.982Z"
        fill="white"
      />
      <path
        d="M37.1168 28.9L32.8687 31.2721C32.6112 31.4166 32.4013 31.6273 32.2614 31.8809L27.3615 40.802C27.1834 41.1263 27.5554 41.4738 27.8845 41.2893L36.2106 36.15C37.0373 35.6888 37.5461 34.8356 37.5461 33.9132V29.1399C37.5461 28.9277 37.3076 28.794 37.1168 28.9Z"
        fill="#39464A"
      />
      <path
        d="M30.7972 31.1153L30.511 30.9985L14.9526 22.3034L8.57886 32.8094C8.57886 32.8094 10.1496 34.6604 15.5886 37.8288C20.6046 40.7512 24.5935 41.7489 24.5935 41.7489L25.5761 41.8304L31.0643 31.8394C31.2137 31.5688 31.0897 31.2337 30.7972 31.1138V31.1153Z"
        fill="#465357"
      />
      <path
        d="M20.4916 25.398C20.2818 25.9684 19.8684 26.4588 19.3104 26.7708L12.2212 31.1476V28.4773L15.6696 22.7031L20.4932 25.398H20.4916Z"
        fill="#818E9F"
      />
      <path
        opacity="0.5"
        d="M31.0642 31.8409C31.2137 31.5704 31.0897 31.2352 30.7971 31.1153L30.511 30.9985L24.5935 41.7505L25.576 41.832L31.0642 31.8409Z"
        fill="#39464A"
      />
      <path
        d="M22.3407 54.7731V54.0875L18.3915 56.292V56.6394L18.8891 56.6994L22.3407 54.7731Z"
        fill="black"
      />
      <path
        d="M28.7288 37.054C28.7288 36.871 28.7924 36.7419 28.8957 36.6789L29.1437 36.5298C29.2471 36.4683 29.3901 36.4729 29.5491 36.562L30.5444 37.1186C30.7018 37.2062 30.8449 37.3615 30.9498 37.5382C31.0531 37.7166 31.1167 37.9164 31.1167 38.0994V41.955C31.1167 42.1379 31.0531 42.267 30.9498 42.3301L30.7018 42.4792C30.5984 42.5407 30.4553 42.5361 30.2964 42.4469L29.3011 41.8904C29.1437 41.8028 29.0006 41.6475 28.8957 41.4707C28.7924 41.2924 28.7288 41.0925 28.7288 40.9096V37.054Z"
        fill="#D4DBDF"
      />
      <path
        d="M28.8957 36.6789C28.999 36.6174 29.1421 36.622 29.3011 36.7112L30.2964 37.2677C30.4538 37.3553 30.5968 37.5106 30.7018 37.6874C30.8051 37.8657 30.8687 38.0655 30.8687 38.2485V42.1041C30.8687 42.287 30.8051 42.4161 30.7018 42.4792C30.5984 42.5407 30.4553 42.5361 30.2964 42.4469L29.3011 41.8904C29.1437 41.8028 29.0006 41.6475 28.8957 41.4707C28.7924 41.2924 28.7288 41.0925 28.7288 40.9096V37.054C28.7288 36.871 28.7924 36.7419 28.8957 36.6789Z"
        fill="#D4DBDF"
      />
      <path
        opacity="0.5"
        d="M30.9482 37.5382C31.0516 37.7166 31.1151 37.9164 31.1151 38.0994L30.8671 38.2485C30.8671 38.0655 30.8035 37.8642 30.7002 37.6874L30.9482 37.5382Z"
        fill="#BBC2C6"
      />
      <path
        opacity="0.5"
        d="M30.5444 37.1186C30.7018 37.2062 30.8449 37.3615 30.9498 37.5382L30.7018 37.6874C30.5985 37.509 30.4554 37.3553 30.2964 37.2677L30.5444 37.1186Z"
        fill="#EEF5F9"
      />
      <path
        d="M30.8687 42.1041V38.2485L31.1167 38.0994V41.955C31.1167 42.1379 31.0531 42.267 30.9498 42.3301L30.7018 42.4792C30.8051 42.4177 30.8687 42.287 30.8687 42.1041Z"
        fill="#BBC2C6"
      />
      <path
        d="M29.1438 36.5298C29.2471 36.4683 29.3902 36.4729 29.5492 36.562L30.5444 37.1186L30.2964 37.2677L29.3012 36.7112C29.1438 36.6235 29.0007 36.6174 28.8958 36.6789L29.1438 36.5298Z"
        fill="#EEF5F9"
      />
      <path
        d="M19.2102 49.5462L18.2038 49.3956L13.625 48.321L5.23532 43.6383L2.22095 40.3269L1.95703 41.5322L4.96505 44.8513L13.2912 49.537L17.8954 50.6147L18.8573 50.7684L20.145 50.1212L20.4026 49.0159L19.2102 49.5462Z"
        fill="#414E5F"
      />
      <path
        d="M20.4027 49.0159L20.1451 50.1212L18.8573 50.7684L17.8955 50.6147L13.2912 49.537L4.96513 44.8513L2.08907 41.6921L1.95711 41.5322L1.91577 41.7182L4.92379 45.0219L13.3135 49.7046L17.8923 50.7776L18.8971 50.9283L20.2739 50.2104L20.5744 48.939L20.4027 49.0159Z"
        fill="#EEF5F9"
      />
      <path
        d="M19.153 49.7215L18.1466 49.5708L15.2849 48.9252L15.0416 49.6646L17.9479 50.3564L18.9288 50.4733L20.2087 49.8476L20.3692 49.1619L19.153 49.7215Z"
        fill="white"
      />
      <path
        d="M19.153 49.7215L18.1466 49.5709L17.9479 50.3564L18.9288 50.4733L20.2087 49.8476L20.3692 49.1619L19.153 49.7215Z"
        fill="black"
      />
      <path
        d="M1.99841 41.3523L3.94758 43.5046L4.08272 42.6468L2.17966 40.5099L1.99841 41.3523Z"
        fill="white"
      />
      <path
        d="M18.6554 49.6477L18.4709 50.4179L18.9288 50.4733L20.2087 49.8476L20.3692 49.1619L19.153 49.7215L18.6554 49.6477Z"
        fill="#F26722"
      />
      <path
        d="M19.153 49.7215L18.9288 50.4733L20.2087 49.8476L20.3692 49.1619L19.153 49.7215Z"
        fill="#E55A15"
      />
      <path
        d="M2.41337 40.7666L2.17966 40.5099L1.99841 41.3523L2.2353 41.6137L2.41337 40.7666Z"
        fill="#F26722"
      />
      <path
        d="M63.7916 31.6226L60.5054 29.7871V26.116L63.7932 27.9515L63.7916 31.6226Z"
        fill="#29333F"
      />
      <path
        d="M62.162 29.269L60.1619 28.1529C59.5848 27.8316 57.1746 28.7817 56.2938 29.2721C55.413 29.764 54.6165 30.6065 54.0394 31.5735C53.4622 32.5404 54.7182 32.7249 54.7182 33.7088C54.7182 34.6911 55.0744 35.3829 55.6515 35.7042L57.6515 36.8203C58.2286 37.1432 59.0252 37.0955 59.9059 36.6051C60.7867 36.1132 61.5832 35.2707 62.1604 34.3037C62.7375 33.3368 63.0936 32.2483 63.0936 31.2645C63.0936 30.2821 62.7375 29.5903 62.1604 29.269H62.162Z"
        fill="#414E5F"
      />
      <path
        opacity="0.5"
        d="M62.1619 29.269C61.5848 28.9477 60.7883 28.9938 59.9075 29.4842C59.0267 29.9762 58.2302 30.8186 57.6531 31.7856C57.076 32.7526 56.7198 33.841 56.7198 34.8249C56.7198 35.8072 57.076 36.499 57.6531 36.8203C58.2302 37.1432 59.0267 37.0955 59.9075 36.6051C60.7883 36.1131 61.5848 35.2707 62.1619 34.3037C62.7391 33.3368 63.0952 32.2483 63.0952 31.2644C63.0952 30.2821 62.7391 29.5903 62.1619 29.269Z"
        fill="#29333F"
      />
      <path
        opacity="0.5"
        d="M56.7199 34.8249L54.7198 33.7088C54.7198 34.6911 55.076 35.3829 55.6531 35.7042L57.6531 36.8203C57.076 36.499 56.7199 35.8072 56.7199 34.8249Z"
        fill="#29333F"
      />
      <path
        d="M60.162 28.1529C59.5848 27.8316 57.1746 28.7817 56.2938 29.2721L59.9076 29.4842C60.7884 28.9923 61.5849 28.9462 62.162 29.269L60.162 28.1529Z"
        fill="#414E5F"
      />
      <path
        d="M54.0409 31.5735C53.4638 32.5404 54.7198 32.7249 54.7198 33.7088L56.7199 34.8249C56.7199 33.8425 57.076 32.7526 57.6531 31.7856L54.0393 31.5735H54.0409Z"
        fill="#414E5F"
      />
      <path
        opacity="0.5"
        d="M56.2953 29.2721C55.4145 29.764 54.618 30.6065 54.0409 31.5735L57.6546 31.7856C58.2318 30.8186 59.0283 29.9762 59.9091 29.4842L56.2953 29.2721Z"
        fill="#5B6879"
      />
      <path
        d="M62.1031 29.6457C61.5737 29.3505 60.8424 29.3935 60.0347 29.844C59.2271 30.2944 58.4957 31.0677 57.9663 31.9547C57.4369 32.8417 57.1094 33.8395 57.1094 34.7419C57.1094 35.6427 57.4369 36.2777 57.9663 36.5728C58.4957 36.868 59.2271 36.8249 60.0347 36.3745C60.8424 35.9241 61.5737 35.1508 62.1031 34.2638C62.6326 33.3783 62.9601 32.379 62.9601 31.4766C62.9601 30.5742 62.6326 29.9408 62.1031 29.6457Z"
        fill="#29333F"
      />
      <path
        d="M61.4878 30.9339L61.3559 30.8601C61.0173 30.671 60.5514 30.6987 60.0347 30.9862C59.5196 31.2737 59.0522 31.7687 58.7135 32.3344C58.3749 32.9002 58.1666 33.5381 58.1666 34.1146C58.1666 34.6911 58.3765 35.0954 58.7135 35.2845L58.8455 35.3583C59.1841 35.5474 59.65 35.5197 60.1667 35.2323C60.6818 34.9448 61.1492 34.4498 61.4878 33.884C61.8265 33.3183 62.0348 32.6803 62.0348 32.1038C62.0348 31.5273 61.8249 31.123 61.4878 30.9339Z"
        fill="#95A7B2"
      />
      <path
        d="M61.4878 30.9339C61.1492 30.7448 60.6834 30.7725 60.1667 31.06C59.6516 31.3475 59.1841 31.8425 58.8455 32.4082C58.5069 32.9739 58.2986 33.6119 58.2986 34.1884C58.2986 34.7649 58.5084 35.1692 58.8455 35.3583C59.1841 35.5474 59.65 35.5197 60.1667 35.2323C60.6818 34.9448 61.1492 34.4498 61.4878 33.884C61.8265 33.3183 62.0348 32.6803 62.0348 32.1038C62.0348 31.5273 61.8249 31.123 61.4878 30.9339Z"
        fill="#7C8E99"
      />
      <path
        opacity="0.5"
        d="M58.2986 34.1884L58.1666 34.1146C58.1666 34.6911 58.3749 35.0954 58.7135 35.2845L58.8455 35.3583C58.5069 35.1692 58.2986 34.7649 58.2986 34.1884Z"
        fill="#7C8E99"
      />
      <path
        d="M60.0347 30.9862C59.5196 31.2737 59.0521 31.7687 58.7135 32.3344L58.8455 32.4082C59.1841 31.8425 59.6499 31.3475 60.1666 31.06L60.0347 30.9862Z"
        fill="#BBCDD8"
      />
      <path
        d="M61.4131 31.06C61.3352 31.0169 61.2509 30.9862 61.1603 30.9677C61.3543 31.1753 61.4671 31.4935 61.4671 31.9055C61.4671 32.4482 61.27 33.0508 60.952 33.5843C60.6341 34.1177 60.1937 34.5835 59.7072 34.8556C59.3383 35.0616 58.9965 35.1247 58.7151 35.0678C58.7771 35.1339 58.847 35.1892 58.9234 35.2323C59.2413 35.4106 59.6817 35.3845 60.1682 35.1124C60.6547 34.8402 61.0951 34.3744 61.4131 33.841C61.7311 33.3075 61.9282 32.7049 61.9282 32.1622C61.9282 31.6196 61.7311 31.2383 61.4131 31.06Z"
        fill="#95A7B2"
      />
      <path
        opacity="0.5"
        d="M61.4131 31.06C61.3352 31.0169 61.2509 30.9862 61.1603 30.9677C61.3542 31.1753 61.4671 31.4935 61.4671 31.9055L61.9282 32.1622C61.9282 31.6196 61.731 31.2383 61.4131 31.06Z"
        fill="#7C8E99"
      />
      <path
        d="M60.9504 33.5843C60.6325 34.1177 60.1921 34.5835 59.7056 34.8556L60.1666 35.1124C60.6531 34.8402 61.0935 34.3744 61.4115 33.841L60.9504 33.5843Z"
        fill="#BBCDD8"
      />
      <path
        d="M58.6722 34.3299C58.6722 33.7872 58.8694 33.1846 59.1874 32.6511C59.5053 32.1177 59.9457 31.6518 60.4322 31.3797C60.8965 31.1199 61.3178 31.0877 61.6326 31.2399C61.5674 31.1676 61.4942 31.1061 61.4116 31.06C61.0936 30.8817 60.6532 30.9078 60.1667 31.1799C59.6802 31.452 59.2398 31.9178 58.9219 32.4513C58.6039 32.9847 58.4067 33.5873 58.4067 34.13C58.4067 34.6727 58.6039 35.0539 58.9219 35.2323C58.9362 35.24 58.9521 35.2461 58.9664 35.2522C58.7804 35.0447 58.6722 34.7311 58.6722 34.3283V34.3299Z"
        fill="black"
      />
      <path
        d="M60.9838 31.7456C60.7485 31.615 60.4242 31.6334 60.0665 31.8333C59.7088 32.0331 59.3844 32.3759 59.1491 32.7695C58.9138 33.163 58.7692 33.6058 58.7692 34.0055C58.7692 34.4052 58.9138 34.6865 59.1491 34.8172C59.3844 34.9479 59.7088 34.9294 60.0665 34.7296C60.4242 34.5297 60.7485 34.1869 60.9838 33.7933C61.2191 33.3998 61.3638 32.957 61.3638 32.5573C61.3638 32.1576 61.2191 31.8763 60.9838 31.7456Z"
        fill="black"
      />
      <path
        d="M61.0236 33.6242C61.243 33.2568 61.3781 32.8433 61.3781 32.4697C61.3781 32.0961 61.243 31.8333 61.0236 31.7118L60.5625 31.4551C60.3431 31.3321 60.0411 31.3505 59.7056 31.5365C59.6706 31.555 59.6372 31.5765 59.6039 31.5996C59.3447 31.8425 59.111 32.1346 58.9218 32.4528C58.7326 32.771 58.5864 33.1123 58.4989 33.4505C58.4957 33.4889 58.4941 33.5274 58.4941 33.5658C58.4941 33.9394 58.6293 34.2023 58.8487 34.3237L59.3097 34.5804C59.5291 34.7034 59.8312 34.685 60.1667 34.499C60.5005 34.3129 60.8042 33.9916 61.0236 33.6242Z"
        fill="#95A7B2"
      />
      <path
        d="M61.0236 31.7118C60.8042 31.5888 60.5021 31.6073 60.1667 31.7933C59.8328 31.9793 59.5291 32.3006 59.3097 32.668C59.0903 33.0354 58.9552 33.449 58.9552 33.8226C58.9552 34.1961 59.0903 34.459 59.3097 34.5805C59.5291 34.7034 59.8312 34.685 60.1667 34.499C60.5005 34.313 60.8042 33.9917 61.0236 33.6242C61.243 33.2568 61.3781 32.8433 61.3781 32.4697C61.3781 32.0961 61.243 31.8333 61.0236 31.7118Z"
        fill="#7C8E99"
      />
      <path
        d="M60.3861 32.7802C60.3305 32.7495 60.2526 32.7541 60.1667 32.8018C60.0809 32.8494 60.0045 32.9309 59.9473 33.0247C59.8917 33.1184 59.8567 33.2245 59.8567 33.3198C59.8567 33.4151 59.8917 33.4828 59.9473 33.5135C60.003 33.5443 60.0809 33.5397 60.1667 33.492C60.2526 33.4444 60.3289 33.3629 60.3861 33.2691C60.4418 33.1753 60.4767 33.0693 60.4767 32.9739C60.4767 32.8786 60.4418 32.811 60.3861 32.7802Z"
        fill="#414E5F"
      />
      <path
        opacity="0.5"
        d="M58.9552 33.8225L58.4941 33.5658C58.4941 33.9394 58.6293 34.2022 58.8487 34.3237L59.3097 34.5804C59.0903 34.4574 58.9552 34.1961 58.9552 33.8225Z"
        fill="#7C8E99"
      />
      <path
        d="M59.6038 31.5981C59.3447 31.8409 59.1109 32.133 58.9218 32.4513L59.3113 32.668C59.5307 32.3006 59.8327 31.9808 60.1682 31.7933L59.7071 31.5366C59.6722 31.555 59.6388 31.5765 59.6054 31.5996L59.6038 31.5981Z"
        fill="#BBCDD8"
      />
      <path
        d="M58.7692 32.7341C58.739 32.7956 58.7104 32.8571 58.6833 32.9201C58.6754 32.937 58.669 32.9539 58.6627 32.9709C58.634 33.0385 58.6086 33.1077 58.5848 33.1753C58.5816 33.1861 58.5768 33.1968 58.5736 33.2076C58.5466 33.2875 58.5228 33.3675 58.5021 33.449C58.4989 33.4874 58.4973 33.5258 58.4973 33.5643C58.4973 33.7288 58.5243 33.8702 58.5736 33.9901C58.6181 33.5074 58.8026 32.9939 59.0792 32.5296C59.3527 32.0715 59.7152 31.6657 60.119 31.3874C59.9902 31.4074 59.8535 31.4551 59.7088 31.535C59.6738 31.5535 59.6404 31.575 59.607 31.598C59.545 31.6549 59.4862 31.7164 59.4274 31.7794C59.4194 31.7871 59.4115 31.7963 59.4035 31.804C59.3543 31.8578 59.3066 31.9147 59.2589 31.9716C59.2477 31.9854 59.235 32.0008 59.2239 32.0146C59.181 32.0685 59.1412 32.1238 59.0999 32.1807C59.0872 32.1976 59.076 32.2145 59.0633 32.2314C59.014 32.3037 58.9663 32.3759 58.9218 32.4512C58.8773 32.5266 58.836 32.6019 58.7962 32.6788C58.7867 32.6972 58.7787 32.7157 58.7692 32.7341Z"
        fill="black"
      />
      <path
        d="M70 24.1636L69.5612 23.9376V10.0156L69.4435 9.32998L68.7917 8.65971L53.2874 0H52.4559L22.6014 16.6661L38.0819 25.3104L38.5303 26.0606V45.7337L55.6753 36.1546V35.3921C55.6753 34.1408 56.13 32.7541 56.8645 31.5242C57.5991 30.2944 58.6134 29.2213 59.7342 28.5957C60.8551 27.97 61.8694 27.9116 62.6039 28.3205C63.3385 28.7309 63.7932 29.6103 63.7932 30.8601V31.6226L69.3942 28.4773L70.0032 27.4796V24.1636H70Z"
        fill="#D4DBDF"
      />
      <path
        d="M55.6737 35.3937C55.6737 34.1423 56.1284 32.7556 56.863 31.5258C57.5975 30.2959 58.6118 29.2229 59.7327 28.5972C60.8535 27.9715 61.8678 27.9131 62.6024 28.322C63.3369 28.7325 63.7916 29.6118 63.7916 30.8617V31.6242L69.3927 28.4788L70.0016 27.4811V24.1651L69.5628 24.4111V10.0156L69.4451 9.32999H68.5055L38.5319 26.0622V45.7352L55.6769 36.1562V35.3937H55.6737Z"
        fill="#BBC2C6"
      />
      <path
        opacity="0.5"
        d="M68.7932 8.65818L53.2873 0H52.4558L67.9363 8.64434L68.7932 8.65818Z"
        fill="#EEF5F9"
      />
      <path
        d="M67.9363 8.64434L52.4559 0L22.6014 16.6661L38.0819 25.3104L67.9363 8.64434Z"
        fill="#EEF5F9"
      />
      <path
        opacity="0.5"
        d="M67.9363 8.64435L38.0819 25.3104L38.5303 26.0622L68.5039 9.32845L67.9363 8.64435Z"
        fill="#EEF5F9"
      />
      <path
        d="M68.7932 8.65818L69.4435 9.32845H68.5039L67.9363 8.64435L68.7932 8.65818Z"
        fill="#D4DBDF"
      />
      <path
        d="M62.5737 28.3066L70 24.365V24.1636L69.5612 23.9376V24.4095L62.5737 28.3066Z"
        fill="#D4DBDF"
      />
      <path
        d="M29.3043 46.919C29.3329 46.9359 29.3616 46.9528 29.3902 46.9712L56.7739 31.6811C56.8026 31.6319 56.8296 31.5827 56.8598 31.5335L29.3043 46.919Z"
        fill="#D4DBDF"
      />
      <path
        d="M30.4506 50.2457L40.0947 44.8651V44.862L55.6737 36.1562V35.4752L30.4506 49.5601V50.2457Z"
        fill="black"
      />
      <path
        d="M63.79 31.6242L69.3927 28.4788L70 27.4811L63.79 30.9447V31.6242Z"
        fill="black"
      />
      <path
        d="M39.8101 34.2023V28.053C39.8101 27.9239 39.8562 27.7824 39.9325 27.6548C40.0073 27.5288 40.1122 27.4181 40.2283 27.3535L67.3354 12.2217C67.4085 12.1817 67.4769 12.1633 67.5357 12.1663C67.5166 12.1356 67.4928 12.111 67.4642 12.0941C67.3879 12.0526 67.2845 12.0587 67.1685 12.1218L40.0613 27.2536C39.9469 27.3181 39.8419 27.4288 39.7656 27.5549C39.6893 27.681 39.6432 27.8239 39.6432 27.9531V34.1023C39.6432 34.2315 39.6893 34.3206 39.7656 34.3637C39.7942 34.3791 39.826 34.3883 39.8594 34.3898C39.8276 34.3422 39.8085 34.2791 39.8085 34.2007L39.8101 34.2023Z"
        fill="black"
      />
      <path
        d="M67.4641 12.0956C67.4212 12.0726 67.3703 12.0649 67.3131 12.0726C67.3306 12.1141 67.3401 12.1617 67.3401 12.2201V18.3694C67.3401 18.4985 67.294 18.64 67.2177 18.7676C67.143 18.8936 67.038 19.0043 66.922 19.0689L39.8148 34.2007C39.764 34.2284 39.7163 34.2453 39.6702 34.2514C39.6908 34.3022 39.7226 34.3421 39.764 34.3652C39.8403 34.4067 39.9436 34.4006 40.0597 34.3375L67.1668 19.2057C67.2813 19.1411 67.3862 19.0305 67.4625 18.9044C67.5373 18.7783 67.5849 18.6354 67.5849 18.5062V12.357C67.5849 12.2278 67.5388 12.1387 67.4625 12.0956H67.4641Z"
        fill="#D4DBDF"
      />
      <path
        opacity="0.6"
        d="M67.2193 18.7676C67.143 18.8936 67.0396 19.0043 66.9236 19.0689L67.1684 19.2057C67.2829 19.1411 67.3878 19.0305 67.4641 18.9044L67.2193 18.7676Z"
        fill="#EEF5F9"
      />
      <path
        opacity="0.3"
        d="M67.3401 18.3694C67.3401 18.4986 67.294 18.64 67.2177 18.7676L67.4625 18.9044C67.5372 18.7783 67.5849 18.6354 67.5849 18.5062L67.3401 18.3694Z"
        fill="#EEF5F9"
      />
      <path
        d="M66.9236 19.0674L39.8164 34.1992C39.7656 34.2269 39.7179 34.2438 39.6718 34.2499C39.6924 34.3006 39.7242 34.3406 39.7656 34.3637C39.8419 34.4052 39.9452 34.399 40.0613 34.336L67.1684 19.2042L66.9236 19.0674Z"
        fill="#EEF5F9"
      />
      <path
        d="M51.4177 36.4391L41.1138 42.1917V25.7101L51.4177 19.959V36.4391Z"
        stroke="black"
        stroke-width="0.75"
        stroke-miterlimit="10"
      />
      <path
        d="M69.5612 20.9137L68.9937 21.2304V24.7293L69.5612 24.4126V20.9137Z"
        fill="#C1060B"
      />
      <path
        d="M62.5737 28.3082C62.5833 28.3128 62.5928 28.3174 62.6024 28.322C63.3369 28.7325 63.7916 29.6118 63.7916 30.8617V31.6242L69.3926 28.4788L70.0016 27.4811V24.365L62.5753 28.3066L62.5737 28.3082Z"
        fill="#414E5F"
      />
      <path
        d="M63.79 31.6242L69.3927 28.4788L70 27.4811L63.79 30.9447V31.6242Z"
        fill="black"
      />
      <path
        d="M62.5737 28.3066L70 24.365V24.1636L69.5612 23.9376V24.4095L62.5737 28.3066Z"
        fill="#5B6879"
      />
      <path
        d="M17.4948 56.4811L18.3914 56.6394V52.7377L17.517 52.541L17.4948 56.4811Z"
        fill="#414E5F"
      />
      <path
        d="M17.5171 52.5409L17.4948 56.4811L13.0511 54.8915L12.6839 54.687V54.1766L5.47227 50.1519L5.02711 50.4133L4.66144 50.2103L1.53101 47.4785V43.5307L4.66144 46.2625L13.0511 50.9437L17.5171 52.5409Z"
        fill="#414E5F"
      />
      <path
        opacity="0.2"
        d="M13.0511 50.9452V54.8915L17.4948 56.4811L17.5171 52.5409L13.0511 50.9452Z"
        fill="#29333F"
      />
      <path
        opacity="0.2"
        d="M1.53101 47.4785L4.66144 50.2103V46.2625L1.53101 43.5307V47.4785Z"
        fill="#29333F"
      />
      <path
        opacity="0.5"
        d="M17.4948 56.4811L18.3914 56.6394V52.7377L17.517 52.541L17.4948 56.4811Z"
        fill="#29333F"
      />
      <path
        opacity="0.3"
        d="M12.6855 52.2072C12.6855 51.9873 12.606 51.7429 12.4772 51.5277C12.3484 51.3109 12.1704 51.1234 11.9732 51.0127L5.74256 47.5337C5.65989 47.4876 5.58199 47.4599 5.50885 47.4476C5.48818 47.5107 5.47546 47.586 5.47546 47.669V50.1502L12.6871 54.1749V52.2056L12.6855 52.2072Z"
        fill="#29333F"
      />
      <path
        d="M5.23537 47.4862C5.10659 47.5585 5.0271 47.7122 5.0271 47.932V50.4133L5.47226 50.1504V47.6691C5.47226 47.5861 5.48339 47.5108 5.50565 47.4478C5.40231 47.4309 5.31009 47.4432 5.23537 47.4847V47.4862Z"
        fill="#29333F"
      />
      <path
        d="M14.3945 54.1167V53.3649L16.1672 53.9983L16.1609 54.7485L14.3945 54.1167Z"
        fill="white"
      />
      <path
        d="M2.41333 46.982V46.2302L3.39586 47.1188L3.3895 47.869L2.41333 46.982Z"
        fill="white"
      />
      <path
        d="M18.4185 52.6255L18.3915 52.7361V56.6379L18.8907 56.6978L22.3423 54.7716V53.9599C22.3423 52.7085 22.7954 51.3233 23.5283 50.095L18.4185 52.6255Z"
        fill="#29333F"
      />
      <path
        d="M18.3788 52.6455L17.5441 52.4579L13.0893 50.8653L4.7155 46.1933L1.55485 43.3124L1.53101 43.4108V43.5307L4.60739 46.3317L13.005 51.019L17.4869 52.6224L18.4042 52.8284L23.5283 50.0951L18.3788 52.6455Z"
        fill="#5B6879"
      />
      <path
        d="M29.3091 46.922C29.7765 47.2003 30.1247 47.6738 30.3075 48.3179L55.828 34.07C56.0172 33.2168 56.3733 32.3498 56.8502 31.5473L29.3091 46.922Z"
        fill="#29333F"
      />
      <path
        d="M56.7628 31.701C56.7914 31.6503 56.82 31.598 56.8502 31.5473L29.3091 46.922C29.3377 46.9389 29.3663 46.9605 29.3949 46.9789L56.7628 31.701Z"
        fill="#5B6879"
      />
      <defs>
        <clipPath id="clip0_601_32193">
          <rect width="70" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

IllustrationMaxiVan.propType = {
  /**
   * Size of the illustration.
   */
  size: PropTypes.number,
};

IllustrationMaxiVan.defaultProps = {
  size: 70,
};

export { IllustrationMaxiVan as default } from "./MaxiVan";
