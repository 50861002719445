import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { getErrorCodes } from "../../utils/Utils";
import DriversStatusRepository from "./diversStatusRepository";
import {
  driversStatusAndDetailsSchema,
  driversStatusSchema,
  driverStatusSchema,
} from "./driverNormalization";

//#region Type
export const driverStatusType_Vehicle = {
  OFFLINE: "OFFLINE",
  ONLINE: "ONLINE",
  UNKNOWN: "UNKNOWN",
  DRIVING: "DRIVING",
  WORKING: "WORKING",
  RESTING: "RESTING",
};
export type DriverStatusType_Vehicle = keyof typeof driverStatusType_Vehicle;

export type DriverStatus = {
  id: number;
  driverStatus: DriverStatusType_Vehicle;
  dynamicFields: {
    drivingBar: number;
    odometer: number;
    todayHours: number;
    currentPosition: string;
    drivingSince: number;
    tomorrowHours: number;
    available: number;
    kmTraveled: number;
    totalToday: number;
    slot: string;
    breakBar: number;
    breakTime: number;
    lastUpdate: Date;
    ignitionKey: boolean;
    vehicleId: number;
    availableBar: number;
    multipleVehicle: boolean;
    vehicleDetails: number;
    tachographData: TachographData;
  };
};

export const tachographStatus = {
  REST: "REST",
  AVAILABLE: "AVAILABLE",
  WORK: "WORK",
  DRIVE: "DRIVE",
  ERROR: "ERROR",
  NOT_AVAILABLE: "NOT_AVAILABLE",
};
export type TachographStatus = keyof typeof tachographStatus;

export const tachographDriverCardStatus = {
  CARD_NOT_PRESENT: "CARD_NOT_PRESENT",
  CARD_PRESENT: "CARD_PRESENT",
  ERROR: "ERROR",
  NOT_AVAILABLE: "NOT_AVAILABLE",
};
export type TachographDriverCardStatus =
  keyof typeof tachographDriverCardStatus;

export type TachographData = {
  driverId: string;
  slot: number;
  tachographStatus: TachographStatus;
  cardPresence: TachographDriverCardStatus;
  contDriveTime: number;
  breakTime: number;
  activityDuration: number;
  totalDriveTime: number;
  dailyDriveTime: number;
  weeklyDriveTime: number;
  timeUntilRest: number;
  timeUntilDrive: number;
  minWeeklyRest: number;
  weekDriveTimeLeft: number;
  dailyDriveAvailable: number;
  dailyRestAvailable: number;
  tomorrowDriveAvailable: number;
  todayRest: number;
  lastWeekRest: number;
  twoWeekRest: number;
  maxDriveTime: number;
  maxDriveAvailable: number;
  maxRestTime: number;
  maxRestUntilNextDrive: number;
};

//#endregion Type

//#region API
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getDriversFleetAsync = createAsyncThunk(
  "drivers/getDriversFleetAsync",
  async (fleetId: number, { rejectWithValue }) => {
    try {
      const driversStatusRepository = new DriversStatusRepository();
      const response =
        await driversStatusRepository.getFilteredDriversStatusAndDetailsByFleetId(
          fleetId
        );
      // The value we return becomes the `fulfilled` action payload
      const drivers = _.get(
        response,
        Config.DRIVERS_STATUSES_AND_DETAILS_RESPONSE_PATH
      );
      const normalizedData =
        drivers !== undefined
          ? normalize(drivers, driversStatusAndDetailsSchema)
          : normalize([], driversStatusAndDetailsSchema);
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const getDriverStatusAsync = createAsyncThunk(
  "driver/getDriverStatus",
  async (driverId: number, { rejectWithValue }) => {
    try {
      const driversStatusRepository = new DriversStatusRepository();
      const response = await driversStatusRepository.getDriverStatus(driverId);
      const driverStatus = _.get(response, Config.DRIVER_STATUS_RESPONSE_PATH);
      const normalizedData = normalize(driverStatus, driverStatusSchema);
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const getDriversStatusesAsync = createAsyncThunk(
  "driver/getDriversStatus",
  async (_data, { rejectWithValue }) => {
    try {
      const driversStatusRepository = new DriversStatusRepository();
      const response = await driversStatusRepository.getDriversStatuses();
      const driversStatus = _.get(
        response,
        Config.DRIVERS_STATUSES_RESPONSE_PATH
      );
      const normalizedData = normalize(driversStatus, driversStatusSchema);
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const getFilteredDriversStatusAndDetailsAsync = createAsyncThunk(
  "drivers/getFilteredDriversStatus",
  async (queryParams: string, { rejectWithValue, dispatch }) => {
    try {
      const driversStatusRepository = new DriversStatusRepository();
      const response =
        await driversStatusRepository.getFilteredDriversStatusAndDetails(
          queryParams
        );
      const driversStatusAndDetails = _.get(
        response,
        Config.DRIVERS_STATUSES_AND_DETAILS_RESPONSE_PATH
      );
      const totalPages = _.get(
        response,
        Config.DRIVERS_STATUS_TOTAL_PAGES_RESPONSE_PATH
      );
      const totalElements = _.get(
        response,
        Config.DRIVERS_STATUS_TOTAL_ELEMENTS_RESPONSE_PATH
      );
      if (totalPages) {
        dispatch(setNumberOfPages(totalPages));
      }
      if (totalElements) {
        dispatch(setNumberOfElements(totalElements));
      }
      if (response) {
        const normalizedData = normalize(
          driversStatusAndDetails,
          driversStatusAndDetailsSchema
        );
        return normalizedData.entities;
      } else {
        return [];
      }
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const getFilteredDriversStatusAndDetailsPaginationAsync =
  createAsyncThunk(
    "drivers/getFilteredDriversStatusAndDetailsPagination",
    async (
      data: { queryParams?: string; isActive?: boolean },
      { rejectWithValue, dispatch }
    ) => {
      try {
        const driversStatusRepository = new DriversStatusRepository();
        if (data.isActive != undefined) {
          data.queryParams =
            (_.isEmpty(data.queryParams)
              ? "?isActive="
              : data.queryParams + "&isActive=") + data.isActive;
        }
        const response =
          await driversStatusRepository.getFilteredDriversStatusAndDetails(
            data.queryParams
          );
        const driversStatusAndDetails = _.get(
          response,
          Config.DRIVERS_STATUSES_AND_DETAILS_RESPONSE_PATH
        );
        const totalPages = _.get(
          response,
          Config.DRIVERS_STATUS_TOTAL_PAGES_RESPONSE_PATH
        );
        const totalElements = _.get(
          response,
          Config.DRIVERS_STATUS_TOTAL_ELEMENTS_RESPONSE_PATH
        );
        if (totalPages) {
          dispatch(setNumberOfPages(totalPages));
        }
        if (totalElements) {
          dispatch(setNumberOfElements(totalElements));
        }
        if (response) {
          const normalizedData = normalize(
            driversStatusAndDetails,
            driversStatusAndDetailsSchema
          );
          return normalizedData.entities;
        } else {
          return [];
        }
      } catch (err: any) {
        if (!err.response) throw err;
        return rejectWithValue(err.response.data.message);
      }
    }
  );
//#endregion API

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.driverStatus
    ? driversStatusAdapter.setAll(state, action.payload.driverStatus)
    : driversStatusAdapter.setAll(state, []);
}
//#endregiorn Custom Functions

//#region Slice
const driversStatusAdapter = createEntityAdapter<DriverStatus>({
  selectId: (driver) => driver.id,
  sortComparer: (a, b) => {
    const aString = a.id.toString();
    const bString = b.id.toString();
    return aString.localeCompare(bString);
  },
});

export const driversStatusSlice = createSlice({
  name: "driversStatus",
  initialState: driversStatusAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
    totalElements: 0,
  }),
  reducers: {
    upsertDriverStatus: (state, action: PayloadAction<any>) => {
      action.payload.id &&
        driversStatusAdapter.upsertOne(state, action.payload);
    },
    setNumberOfPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    setNumberOfElements: (state, action: PayloadAction<number>) => {
      state.totalElements = action.payload;
    },
    driversStatusEmptyState: (state) => {
      driversStatusAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(
        getDriversStatusesAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          driversStatusAdapter.upsertMany(
            state,
            action.payload.driverStatus ?? []
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getDriversStatusesAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getDriversStatusesAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(
        getDriverStatusAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          driversStatusAdapter.upsertMany(
            state,
            action.payload.driverStatus ?? []
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getDriverStatusAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getDriverStatusAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(
        getFilteredDriversStatusAndDetailsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getFilteredDriversStatusAndDetailsAsync.pending,
        (state: any) => {
          state.status = "loading";
        }
      )
      .addCase(
        getFilteredDriversStatusAndDetailsAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(
        getFilteredDriversStatusAndDetailsPaginationAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getFilteredDriversStatusAndDetailsPaginationAsync.pending,
        (state: any) => {
          state.status = "loading";
        }
      )
      .addCase(
        getFilteredDriversStatusAndDetailsPaginationAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(getDriversFleetAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getDriversFleetAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.driverStatus &&
            driversStatusAdapter.upsertMany(state, action.payload.driverStatus);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getDriversFleetAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
    //#endregion Entity Reducers
  },
});
//#endregion Slice

//#region Status
export const driversStatusSelectors =
  driversStatusAdapter.getSelectors<RootState>((state) => state.driversStatus);

export const selectDriversStatus = (state: any) => state.driversStatus.state;

export const selectDriverStatusSliceStatus = (state: any) =>
  state.driversStatus.status;
export const selectDriverStatusSliceReasonCode = (state: any) =>
  state.driversStatus.reasonCode;
export const selectDriverStatusSlicePage = (state: any) =>
  state.driversStatus.totalPages;
export const selectDriverStatusSliceTotalElements = (state: any) =>
  state.driversStatus.totalElements;

export const {
  upsertDriverStatus,
  setNumberOfPages,
  driversStatusEmptyState,
  setNumberOfElements,
} = driversStatusSlice.actions;
//#endregion Status

export default driversStatusSlice.reducer;
