import { useState } from "react";
import { getTranslationByLanguage } from "../../utils/Utils";
import { Signal } from "../Indicators/IndicatorSignal";
import "./StatusFilterBadge.css";

export const StatusFilterBadge = ({
  statusDetails,
  onItemSelected,
  events,
  preferences,
}) => {
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [hoveredIndex, setHoveredIndex] = useState(-1);

  const handleItemClick = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(-1);
      onItemSelected && onItemSelected(null);
      return;
    }

    setExpandedIndex(index);
    onItemSelected && onItemSelected(statusDetails.statusCount[index]);
  };

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(-1);
  };

  const translate = (status) => {
    const eventMatch = events?.find((event) => event.name === status);
    const translation = eventMatch?.translations || [];

    return getTranslationByLanguage(translation, preferences.language);
  };

  return (
    <div className="status-filter-badge-container">
      {statusDetails?.statusCount?.map((statusDetail, index) => (
        <div
          key={statusDetail.status}
          className={`status-filter-badge ${
            expandedIndex === index || hoveredIndex === index ? "expanded" : ""
          }`}
          onClick={() => handleItemClick(index)}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
        >
          {(expandedIndex === index || hoveredIndex === index) && (
            <div className="fleet-name">{translate(statusDetail.status)}</div>
          )}
          <div>{statusDetail.vehicles}</div>
          <Signal mode={statusDetail.status} size="small" />
        </div>
      ))}
    </div>
  );
};
