import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { vehiclesTypeCountSchema } from "./vehicleNormalization";
import VehiclesRepository from "./vehiclesRepository";

//#region Type
export interface VehicleTypeCount {
  name: string;
  count: number;
}

export interface VehiclesTypeCount {
  vehiclesTypeCount: VehiclesTypeCount[];
}

//#endregion Type

//#region API
export const getVehicleTypesCountAsync = createAsyncThunk(
  "vehicles/getVehicleTypesCount",
  async (_data, { rejectWithValue }) => {
    try {
      const vehiclesRepository = new VehiclesRepository();
      const response = await vehiclesRepository.getVehicleTypesCount();
      const vehiclesTypeCount = _.get(
        response,
        Config.VEHICLE_TYPE_COUNT_RESPONSE_PATH
      );
      const normalizedData = normalize(
        vehiclesTypeCount ?? [],
        vehiclesTypeCountSchema
      );
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

//#endregion API

//#region Slice
const vehicleTypeCountAdapter = createEntityAdapter<VehicleTypeCount>({
  selectId: (e) => e.name,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const vehicleTypeCountSlice = createSlice({
  name: "vehicleTypeCount",
  initialState: vehicleTypeCountAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    vehicleTypeCountEmptyState: (state) => {
      vehicleTypeCountAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(
        getVehicleTypesCountAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          vehicleTypeCountAdapter.setAll(
            state,
            Object.values(action.payload.vehicleTypeCount) as VehicleTypeCount[]
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getVehicleTypesCountAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getVehicleTypesCountAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});

//#endregion Slice
export const vehicleTypeCountSelectors =
  vehicleTypeCountAdapter.getSelectors<RootState>(
    (state) => state.vehicleTypeCount
  );
//#region Status
export const selectvehicleTypeCountStatus = (state: any) =>
  state.vehicleTypeCount.status;
export const selectvehicleTypeCountReasonCode = (state: any) =>
  state.vehicleTypeCount.reasonCode;
//#endregion Status
export default vehicleTypeCountSlice.reducer;
