import "./ProgressBar.css";

function computeProgress(value, max) {
  // Checking data
  if (
    typeof value !== "number" ||
    typeof max !== "number" ||
    max <= 0 ||
    value <= 0
  ) {
    return { progressPercentage: 0, overflowPercentage: 0 };
  }

  // Setting data
  const progressPercentage = Math.min((value / max) * 100, 100);
  const overflowPercentage = value > max ? ((value - max) / max) * 100 : 0;

  return { progressPercentage, overflowPercentage };
}

const ProgressBar = ({ value, max, color }) => {
  const { progressPercentage, overflowPercentage } = computeProgress(
    value,
    max
  );

  return (
    <div className="progress-container">
      <div className="progress-bar-driver">
        <div
          className="progress-fill"
          style={{
            width: `${progressPercentage}%`,
            backgroundColor: color,
          }}
        />
        {overflowPercentage > 0 && (
          <div
            className="progress-overflow"
            style={{
              width: `${overflowPercentage}%`,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ProgressBar;
