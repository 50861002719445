import { t } from "i18next";
import _ from "lodash";
import { NavigateFunction } from "react-router-dom";
import { DropdownButton, ListItem } from "../../../ui/Button/DropdownButton";
import { Tooltip } from "../../../ui/Forms/Tooltip";
import { StatusBadge } from "../../../ui/Map/StatusBadge";
import { Table } from "../../../ui/Table/Table";
import {
  AbstractTableBuilder,
  Row,
} from "../../../ui/Table/utils/AbstractTableBuilder";
import { Tag } from "../../../ui/Tags/Tag";
import { AddressBook } from "../../address/addressBookSlice";

import { Event } from "../../event/eventsSlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import "./modals/AddressBookModal.css";
import {
  NotificationRuleAggregation,
  NotificationRuleEntityDTO,
  NotificationRuleSchedulation,
} from "./notificationRuleAggregationSlice";
import { NotificationRuleDestination } from "./notificationRuleDestinationSlice";
import { NotificationRule } from "./notificationRuleSlice";

export class AdminNotificationTableBuilder extends AbstractTableBuilder {
  rowsBuilder = (
    columns: { label: string; field: string; sort: boolean }[] | undefined,
    entities: {
      notificationRuleAggregation?: NotificationRuleAggregation;
      notificationRuleDestinations: NotificationRuleDestination[];
      notificationRules: NotificationRule[];
      notificationRuleSchedulation?: NotificationRuleSchedulation;
      addressBooks: AddressBook[];
      notificationRulesEntities: NotificationRuleEntityDTO[];
      preference: Preferences;
      evs: Event[];
    },
    navigate: NavigateFunction,
    writePermission: boolean = false
  ) => {
    let row: Row = { render: {} as Row };

    let notificationRuleAggregation: NotificationRuleAggregation =
      entities.notificationRuleAggregation ??
      ({} as NotificationRuleAggregation);

    let preferences: Preferences = entities.preference ?? ({} as Preferences);

    const cronstrue = require("cronstrue/i18n");

    let notificationRuleDestinations: NotificationRuleDestination[] =
      entities.notificationRuleDestinations ??
      ([] as NotificationRuleDestination[]);

    let notificationRuleSchedulation: NotificationRuleSchedulation =
      entities.notificationRuleSchedulation ??
      ({} as NotificationRuleSchedulation);

    let notificationRules: NotificationRule[] =
      entities.notificationRules ?? ([] as NotificationRule[]);

    let notificationRulesEntities: NotificationRuleEntityDTO[] =
      entities.notificationRulesEntities ?? ([] as NotificationRuleEntityDTO[]);

    let addressBooks: AddressBook[] =
      entities.addressBooks ?? ([] as AddressBook[]);

    columns?.forEach((column) => {
      switch (column.field) {
        case "name":
          row.render.name = notificationRuleAggregation.name ?? t("common.na");
          break;
        case "contacts":
          const remainingContacts = addressBooks.length - 1;
          const remainingContactsList: string = addressBooks
            .slice(1)
            .map(
              (addressBook) =>
                `${addressBook.firstName} ${addressBook.lastName}`
            )
            .join(", ");
          row.render.contacts = addressBooks.map((addressBook, index) => {
            if (index === 0) {
              return addressBook.firstName + " " + addressBook.lastName;
            } else {
              return (
                <span
                  key={addressBook.id}
                  data-for="tooltip"
                  data-tip={remainingContactsList}
                >
                  <Tooltip />
                  {index === 1 ? ` + ${remainingContacts}` : ""}
                </span>
              );
            }
          });
          break;
        case "events":
          const events = notificationRules.map((x) => x.event);
          row.render.events = events.map((event, index) => {
            const remainingCount = events.length - 5;
            const remainingEvents: string = events
              .slice(5)
              .map(
                (event) =>
                  entities.evs
                    .find((ev: any) => ev.name === event)
                    ?.translations.find(
                      (translation: any) =>
                        translation.language === preferences.language
                    )?.translation
              )
              .join(", ");
            let translatedAssociation = entities.evs
              .find((ev: any) => ev.name === event)
              ?.translations.find(
                (translation: any) =>
                  translation.language === preferences.language
              )?.translation;
            if (index < 5) {
              return (
                <Tag
                  key={event}
                  className="an-table-tags"
                  text={translatedAssociation ?? event.replaceAll("_", " ")}
                  size="small"
                  mode="outline"
                  type="neutro"
                ></Tag>
              );
            }
            if (index == 5) {
              return (
                <span key={event} data-for="tooltip" data-tip={remainingEvents}>
                  <Tag
                    key={event}
                    className="an-table-tags"
                    text={`+ ${remainingCount}`}
                    size="small"
                    mode="outline"
                    type="neutro"
                  ></Tag>
                </span>
              );
            }
          });
          break;
        case "channels":
          const channels = _.uniqBy(
            notificationRuleDestinations.map((x) => x.channel),
            (x) => x
          );
          row.render.channels = channels.map((channel) => {
            return (
              <StatusBadge
                key={channel}
                size="small"
                className="an-table-status-badge"
                status={
                  notificationRuleAggregation.status === "ACTIVE"
                    ? "ONLINE"
                    : "OFFLINE"
                }
                id={channel}
              />
            );
          });
          break;
        case "period":
          row.render.period =
            notificationRuleSchedulation != null &&
            notificationRuleSchedulation.cronExpression != null
              ? cronstrue.toString(
                  notificationRuleSchedulation?.cronExpression,
                  {
                    locale: preferences.language,
                  }
                )
              : "--:--";
          break;
        case "timeSlot":
          let timeRanges: string[] =
            notificationRuleSchedulation &&
            notificationRuleSchedulation.timeSlot
              ? notificationRuleSchedulation?.timeSlot.split(" & ")
              : [];

          if (_.isEmpty(timeRanges)) {
            row.render.timeSlot = (
              <span data-for="tooltip" data-tip={t("table.columns.allDay")}>
                <Tag
                  className="an-table-tags"
                  text={t("table.columns.allDay")}
                  size="small"
                  mode="outline"
                  type="neutro"
                />
              </span>
            );
            break;
          }
          const firstTag = (
            <span data-for="tooltip" data-tip={timeRanges}>
              <Tag
                className="an-table-tags"
                text={timeRanges[0].replace("|", "-")}
                size="small"
                mode="outline"
                type="neutro"
              />
            </span>
          );
          const additionalTag = timeRanges.length > 1 && (
            <span data-for="tooltip" data-tip={timeRanges.length}>
              <Tag
                key={`+${timeRanges.length - 1}`}
                className="an-table-tags"
                text={`+ ${timeRanges.length - 1}`}
                size="small"
                mode="outline"
                type="neutro"
              />
            </span>
          );
          row.render.timeSlot =
            timeRanges.length === 1 ? (
              firstTag
            ) : (
              <>
                {firstTag}
                {additionalTag}
              </>
            );

          break;
        case "status":
          row.render.status = (
            <Table.TextStatus
              text={
                t(`table.status.${notificationRuleAggregation.status}`) ??
                t("common.na")
              }
            />
          );
          break;

        case "frequency":
          const frequencies = notificationRules.map((x) => x.frequency);
          row.render.frequency = frequencies[0]
            ? t("common.every") + frequencies[0] + " min"
            : t("schedule.always");
          break;
        case "entities":
          row.render.entities =
            notificationRulesEntities.length > 0
              ? t("table.columns.custom")
              : t("table.columns.everything");
          break;

        default:
          break;
      }
    });
    const notificationDropDownList: ListItem[] = [
      {
        id: 0,
        title: t("common.view"),
        onClick: () =>
          navigate(
            "/admin/notifications/view-rule/" + notificationRuleAggregation.id
          ),
      },
      {
        id: 1,
        title:
          notificationRuleAggregation.status === "ACTIVE"
            ? t("common.actionOption.deactivate")
            : t("common.actionOption.activate"),
        onClick: () => {
          navigate(
            "/admin/notifications/" +
              (notificationRuleAggregation.status === "ACTIVE"
                ? "deactivate"
                : "activate") +
              "/" +
              notificationRuleAggregation.id
          );
        },
      },
      {
        id: 2,
        title: t("common.delete"),
        onClick: () => {
          navigate(
            "/admin/notifications/delete/" + notificationRuleAggregation.id
          );
        },
      },
    ];
    if (!writePermission) {
      // hide edit and activate/deactivate
      notificationDropDownList.splice(0, 1);
    }
    row.render.action = (
      <DropdownButton
        aspect="ghost"
        size="small"
        list={notificationDropDownList}
      />
    );
    return row;
  };
}
