import { t } from "i18next";
import { IconMoon } from "../ui/Icon/Line/Moon";
import { IconWarning } from "../ui/Icon/Line/Warning";
import { IconDriver } from "../ui/Icon/Solid/Driver";

export const currentIcon = (status) => {
  if (status) {
    switch (status) {
      case "ONLINE":
        return <span style={{ color: "#00cfa2", fontSize: "16px" }}>●</span>;
      case "OFFLINE":
        return <span style={{ color: "red", fontSize: "16px" }}>●</span>;
      default:
        return <span style={{ color: "gray", fontSize: "16px" }}>●</span>;
    }
  }
};

export const cardRecognition = (card) => {
  switch (card) {
    case "CARD_NOT_PRESENT":
      return t("fleetControl.hoursOfService.notInsert");
    case "CARD_PRESENT":
      return t("fleetControl.hoursOfService.insert");
    case "ERROR":
      return t("fleetControl.hoursOfService.error");
    case "NOT_AVAILABLE":
      return t("common.na");
    default:
      return t("common.na");
  }
};

export const activityRecognition = (card) => {
  switch (card) {
    case "REST":
      return t("fleetControl.hoursOfService.rest");
    case "AVAILABLE":
      return t("fleetControl.hoursOfService.available");
    case "WORK":
      return t("fleetControl.hoursOfService.work");
    case "DRIVE":
      return t("fleetControl.hoursOfService.drive");
    case "ERROR":
      return t("fleetControl.hoursOfService.error");
    case "NOT_AVAILABLE":
      return t("common.na");
    default:
      return t("common.na");
  }
};

export const activityIconRecognition = (card) => {
  switch (card) {
    case "REST":
      return <IconMoon size={12} />;
    case "WORK":
    case "DRIVE":
      return <IconDriver size={12} />;
    default:
      return <IconWarning size={12} />;
  }
};
