import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import BeaconRepository from "./beaconRepository";
import { beaconsSchema } from "./beaconSchema";
import { BeaconThreshold } from "./beaconThresholdSlice";

export type BeaconAssociationEntityType =
  keyof typeof typeOfBeaconAssociationEntity;

export const typeOfBeaconAssociationEntity = {
  DRIVER: "DRIVER",
  ASSET: "ASSET",
  VEHICLE: "VEHICLE",
};

export type Beacon = {
  namespace: string;
  name: string;
  inUse: boolean;
  mac: string;
  entityType: BeaconAssociationEntityType;
  entityId: string;
  beaconThreshold?: BeaconThreshold;
};

export type BeaconAssociationListRequest = {
  type: BeaconAssociationEntityType;
  beaconsToAssociate: BeaconAssociationRequest[];
  beaconNamespacesToDisassociate: string[];
};

export type BeaconAssociationRequest = {
  namespace: string;
  name?: string;
  thresholdId?: number | null;
};

export const getBeaconsByEntityIdAsync = createAsyncThunk(
  "beacons/getBeaconsByEntityId",
  async (
    data: { entityId: number; queryParams?: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const beaconRepository = new BeaconRepository();
      const response = await beaconRepository.getAllBeaconsByTenantAndEntityId(
        data.entityId,
        data?.queryParams
      );
      const beacons = _.get(response, Config.BEACONS_RESPONSE_PATH);
      const normalizedData = normalize(beacons ?? [], beaconsSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.status);
    }
  }
);

export const getBeaconsFilteredAsync = createAsyncThunk(
  "beacons/getBeaconsFiltered",
  async (data: { queryParams?: string }, { dispatch, rejectWithValue }) => {
    try {
      const beaconRepository = new BeaconRepository();
      const response = await beaconRepository.getAllBeaconsFiltered(data);
      const beacons = _.get(response, Config.BEACONS_RESPONSE_PATH);
      const normalizedData = normalize(beacons ?? [], beaconsSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.status);
    }
  }
);

export const updateBeaconAssociationAsync = createAsyncThunk(
  "beacons/updateBeaconAssociation",
  async (
    data: {
      beaconAssociationListRequest: BeaconAssociationListRequest;
      entityId: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const beaconRepository = new BeaconRepository();
      const response = await beaconRepository.updateBeacons(data);
      const beacons = _.get(response, Config.BEACONS_RESPONSE_PATH);
      const normalizedData = normalize(beacons ?? [], beaconsSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.status);
    }
  }
);

const beaconsAdapter = createEntityAdapter<Beacon>({
  selectId: (beacon) => beacon.namespace,
});

export const beaconSlice = createSlice({
  name: "beaconSlice",
  initialState: beaconsAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    beaconEmptyState: (state) => {
      beaconsAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(
        getBeaconsByEntityIdAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          beaconsAdapter.setAll(state, action.payload.beacon ?? []);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getBeaconsByEntityIdAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getBeaconsByEntityIdAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(
        getBeaconsFilteredAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          beaconsAdapter.setAll(state, action.payload.beacon ?? []);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getBeaconsFilteredAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getBeaconsFilteredAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(
        updateBeaconAssociationAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          beaconsAdapter.upsertMany(state, action.payload.beacon ?? []);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(updateBeaconAssociationAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(updateBeaconAssociationAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});

export const beaconsSelectors = beaconsAdapter.getSelectors<RootState>(
  (state) => state.beacons
);

export const selectBeaconsSliceStatus = (state: any) => state.beacons.status;
export const selectBeaconsSliceReasonCode = (state: any) =>
  state.beacons.reasonCode;

export const { beaconEmptyState } = beaconSlice.actions;

export default beaconSlice.reducer;
