import { schema } from "normalizr";
import { assetsSchema } from "../asset/assetNormalization";
import { contractSchema } from "../contract/contractNormalization";
import { deviceSchema } from "../device/deviceNormalization";
import { fleetSchema } from "../fleet/fleetNormalization";

const vehicleStatusSchema = new schema.Entity(
  "vehicleStatus",
  {},
  { idAttribute: "id" }
);

export const vehicleSchema: schema.Entity<any> = new schema.Entity("vehicle", {
  fleet: fleetSchema,
  driver: new schema.Entity("driver", {
    fleet: fleetSchema,
    vehicle: new schema.Entity("vehicle"),
  }),
  device: deviceSchema,
  contract: contractSchema,
  vehicleStatus: vehicleStatusSchema,
  asset: assetsSchema,
});

export const vehicleDetailsSchema: schema.Entity<any> = new schema.Entity(
  "vehicle",
  {
    fleet: fleetSchema,
    driver: new schema.Entity("driver"),
  }
);

export const vehicleFuelsConsumptionSchema: schema.Entity<any> =
  new schema.Entity("vehicleFuelsConsumption");

export const vehicleFuelCostsSchema: schema.Entity<any> = new schema.Entity(
  "vehicleFuelCosts",
  {},
  { idAttribute: "vehicleId" }
);

export const vehiclesFuelCostsSchema: schema.Entity<any> = new schema.Entity(
  "vehiclesFuelCosts",
  {},
  { idAttribute: "tenantId" }
);

export const vehiclesSchema = new schema.Array(vehicleSchema);

export const vehiclesDetailsSchema = new schema.Array(vehicleDetailsSchema);

export const vehicleMaintenanceCostsSchema = new schema.Entity(
  "VehicleMaintenanceCosts",
  {},
  { idAttribute: "vehicleId" }
);

export const vehicleTypeCountSchema: schema.Entity<any> = new schema.Entity(
  "vehicleTypeCount",
  {},
  { idAttribute: "name" }
);

export const vehiclesTypeCountSchema = new schema.Array(vehicleTypeCountSchema);
