export const vehicleStatusValues = { ACTIVE: "ACTIVE", INACTIVE: "INACTIVE" };
export type VehicleStatusType = keyof typeof vehicleStatusValues;
export const authenticationType = {
  MOBILE: "MOBILE",
  WEB: "WEB",
  BEACON: "BEACON",
  KEYPAD: "KEYPAD",
  TACHO: "TACHO",
};
export type AuthenticationType = keyof typeof authenticationType;

export const vehicleUnitOfMeasurementValues = { KM: "KM", MILES: "MILES" };
export type VehicleUnitOfMeasurementType =
  keyof typeof vehicleUnitOfMeasurementValues;

export const vehicleFuelValues = {
  PETROL: "PETROL",
  DIESEL: "DIESEL",
  ELETTRICALLY_CHARGEABLE: "ELETTRICALLY_CHARGEABLE",
  HYBRID_ELECTRIC: "HYBRID_ELECTRIC",
  COMPRESSED_NATURAL_GAS: "COMPRESSED_NATURAL_GAS",
  LIQUIFIED_PETROLEUM_GAS: "LIQUIFIED_PETROLEUM_GAS",
  BIO_DIESEL: "BIO_DIESEL",
  ETHANOL: "ETHANOL",
  HYDROGEN: "HYDROGEN",
  ALTERNATIVE_FUELS: "ALTERNATIVE_FUELS",
};

export type VehicleFuelType = keyof typeof vehicleFuelValues;

export const typeOfVehicleValues = {
  CAR: "CAR",
  TRUCK: "TRUCK",
  AMBULANCE: "AMBULANCE",
  AUTOBUS: "AUTOBUS",
  BIKE: "BIKE",
  AIRPLANE: "AIRPLANE",
  CAMPER: "CAMPER",
  BOAT: "BOAT",
  SCOOTER: "SCOOTER",
  OPERATING_MACHINE: "OPERATING_MACHINE",
  HUMAN_BEING: "HUMAN_BEING",
  TRAILER_CAR: "TRAILER_CAR",
  TRAILER: "TRAILER",
  VAN: "VAN",
  PACKAGE: "PACKAGE",
};
export type TypeOfVehicleType = keyof typeof typeOfVehicleValues;
