import { Cluster, MarkerClusterer } from "@googlemaps/markerclusterer";
import _ from "lodash";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { DetailsTrackBar } from "../../ui/LocationHistory/DetailsTrackBar";
import { EventDetail } from "../../ui/LocationHistory/EventDetail";
import { SummaryDetails } from "../../ui/LocationHistory/SummaryDetails";
import { SummaryClassPolyline } from "../../ui/LocationHistory/SummaryPolyline";
import { ViewArrow } from "../../ui/LocationHistory/ViewArrow";
import { Actions } from "../../ui/Map/Actions";
import { BaseMap, ComponentOnMap } from "../../ui/Map/BaseMap";
import { MapType } from "../../ui/Map/MapType";
import { MarkerAsComponent } from "../../ui/Map/MarkerAsComponent";
import { Zoom } from "../../ui/Map/Zoom";
import { ClusterMarker } from "../../ui/Marker/ClusterMarker";
import { ClusterMarkerEvent } from "../../ui/Marker/ClusterMarkerEvent";
import { MapMarkerEventLocation } from "../../ui/Marker/MapMarkerEventLocation";
import { MapMarkerEventToggle } from "../../ui/Marker/MapMarkerEventToggle";
import { MapMarkerLocation } from "../../ui/Marker/MapMarkerLocation";
import { MapMarkerLocator } from "../../ui/Marker/MapMarkerLocator";
import { MapMarkerPin } from "../../ui/Marker/MapMarkerPin";
import { TypeOfVehicleType } from "../../ui/Vehicles/VehicleTypes";
import { ConvertTimeZone, formatTotalTime } from "../../utils/DateAndTimeUtils";
import { isTrailerCarOrPackage, isTrailerLike } from "../../utils/Utils";
import { Driver, DriverView, driversSelectors } from "../driver/driversSlice";
import { Event, eventsSelectors } from "../event/eventsSlice";
import { Geofence, geofencesSelectors } from "../geofence/geofenceSlice";
import {
  GeofenceCategory,
  geofenceCategoriesSelectors,
} from "../geofenceCategory/geofenceCategoriesSlice";
import { VehicleTravelsMacroSummary } from "../report/vehicle/vehicleTravelsMacroSummarySlice";
import { GpsDataEvent, RouteEvents } from "../route/routeEventsSlice";
import {
  RouteEvent,
  RouteHistory,
  RoutePosition,
  RouteState,
  RouteStateType,
} from "../route/routesHistorySlice";
import { GpsData, Route } from "../route/routesSlice";
import { Preferences } from "../users/preference/preferencesSlice";
import UserContext from "../users/userContext";
import { Vehicle } from "../vehicle/vehiclesSlice";
import "./LocationHistoryMap.css";

interface LocationHistorySummaryMapProps {
  id: number;
  vehicleView: Vehicle;
  expandedSidebar: boolean;
  googleMapsApiKey: string;
  routes: Route[];
  routesSliceStatus: string;
  events: RouteEvents[];
  eventSliceStatus: string;
  eventsType: Event[];
  zoom: number;
  latitude: number;
  longitude: number;
  queryParams: string;
  summaryDetails?: VehicleTravelsMacroSummary;
  openTrackDetails: boolean;
  selectedEvents: Event[];
  routesIds: number[];
  vehicleType: TypeOfVehicleType;
  completedRoutesIds: boolean;
  showTrackbar?: boolean;
}
declare global {
  interface HTMLElement {
    msRequestFullScreen?: () => Promise<void>;
    mozRequestFullScreen?: () => Promise<void>;
    webkitRequestFullScreen?: () => Promise<void>;
  }
}
interface RouteInfo {
  id: number;
  start: string;
  end: string;
  duration: string;
  address: string;
}

interface RouteEventReduced {
  event: RouteEvent;
  count: number;
}

interface EventInfo {
  id: number;
  name: string;
  speed?: number;
  timestamp?: string;
  routeId?: number;
  queryParams?: string;
}
interface MarkerData {
  id: string;
  latitude: number;
  longitude: number;
  address: string;
  eventInfo: EventInfo;
  routeInfo: RouteInfo;
  showTooltip: boolean | undefined;
  component: any;
  eventType: string;
  routeType?: string;
  eventsType?: Event[];
}

const componentsToAdd: ComponentOnMap[] = [];
let infoWindow: google.maps.InfoWindow;
let geofenceArea: any = null;
let geofenceClusterMarkers: MarkerClusterer;
let geofenceMarkers: google.maps.Marker[] = [];
let arrowMarkers: google.maps.Marker[] = [];
let arrowClusterMarkers: MarkerClusterer;
let clusterList: any[] = [];
let _arrows: google.maps.Marker[] = [];

export const LocationHistorySummaryMap: React.FC<LocationHistorySummaryMapProps> =
  ({
    id,
    vehicleView,
    googleMapsApiKey,
    routes,
    expandedSidebar,
    routesSliceStatus,
    events,
    eventSliceStatus,
    eventsType,
    zoom,
    latitude,
    longitude,
    queryParams,
    summaryDetails,
    openTrackDetails,
    selectedEvents,
    routesIds,
    completedRoutesIds,
    vehicleType,
    showTrackbar,
  }) => {
    let markerClusterList: any[] = [];
    let googleMap = window.google;
    let routesBounds: google.maps.LatLngBounds;
    const [summaryTrackDetails, setSummaryTrackDetails] = useState(
      {} as VehicleTravelsMacroSummary
    );
    const [streetViewEnabled, setStreetViewEnabled] = useState(false);
    const [oldUrl, setOldUrl] = useState("");
    const [previousSelectedEvents, setPreviousSelectedEvent] =
      useState<Event[]>(selectedEvents);
    const [map, setMap] = useState<any>();
    const [isMapIdle, setIsMapIdle] = useState<boolean>(false);
    const [summaryPolylines, setSummaryPolylines] = useState<any[]>([]);
    const [summaryMarkers, setSummaryMarkers] = useState<MarkerClusterer>();
    const [componentsOnMap, setComponentsOnMap] = useState<ComponentOnMap[]>(
      []
    );
    // This useEffect manage the polyline and stop markers for all routes
    const [avgSpeed, setAvgSpeed] = useState(-1);
    const [maxSpeed, setMaxSpeed] = useState(-1);
    const [totalTravelled, setTotalTravelled] = useState(-1);
    const navigate = useNavigate();
    const [directionArrow, setDirectionArrows] = useState<boolean>(false);
    let [prevStatusArrows, setPrevStatusArrows] =
      useState<boolean | undefined>(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const [routeHistory, setRouteHistory] = useState<RouteHistory>(
      {} as RouteHistory
    );
    const [value, setValue] = useState(0);
    const [trackBarCollapsed, setTrackBarCollapsed] = useState(false);
    const [routeIdSelected, setRouteIdSelected] = useState(0);
    const [positionTimestamp, setPositionTimestamp] = useState(new Date());
    const [polylineCreated, setPolylineCreated] = useState(false);
    const [prevRoute, setPrevRoute] = useState<any>();

    const currentPathUrl = window.location.pathname;
    const currentUrl = currentPathUrl + queryParams;

    if (isMapIdle && googleMap) {
      infoWindow = new googleMap.maps.InfoWindow({
        content: "",
        pixelOffset: new googleMap.maps.Size(0, -40),
      });

      routesBounds = new googleMap.maps.LatLngBounds();
    }

    let geofences: Geofence[] = useAppSelector(geofencesSelectors.selectAll);
    let geofenceCategories: GeofenceCategory[] = useAppSelector(
      geofenceCategoriesSelectors.selectAll
    );

    const drivers: Driver[] = useAppSelector(driversSelectors.selectAll);
    const eventTypes: Event[] = useAppSelector(eventsSelectors.selectAll);
    const [preferencesContext]: [
      Preferences,
      Dispatch<SetStateAction<Preferences>>
    ] = useContext(UserContext);

    //#region Business Logic region

    //#region RECOMPUTE AVERAGE SPEED
    useEffect(() => {
      if (summaryDetails && !_.isEqual(summaryDetails, summaryTrackDetails)) {
        setSummaryTrackDetails(summaryDetails);
      }
    }, [summaryDetails]);
    useEffect(() => {
      if (
        completedRoutesIds &&
        avgSpeed !== -1 &&
        !_.isEmpty(summaryTrackDetails)
      ) {
        const newSummaryDetails = Object.assign(
          {},
          {
            ...summaryTrackDetails,
            macroAverageSpeed: avgSpeed,
          }
        );
        if (!_.isEqual(newSummaryDetails, summaryTrackDetails)) {
          setSummaryTrackDetails(newSummaryDetails);
        }
      }
    }, [avgSpeed, summaryTrackDetails]);
    useEffect(() => {
      if (
        completedRoutesIds &&
        maxSpeed !== -1 &&
        !_.isEmpty(summaryTrackDetails)
      ) {
        const newSummaryDetails = Object.assign(
          {},
          {
            ...summaryTrackDetails,
            macroMaxSpeed:
              summaryTrackDetails.macroMaxSpeed > 0
                ? summaryTrackDetails.macroMaxSpeed
                : maxSpeed,
          }
        );
        if (!_.isEqual(newSummaryDetails, summaryTrackDetails)) {
          setSummaryTrackDetails(newSummaryDetails);
        }
      }
    }, [maxSpeed, summaryTrackDetails]);
    useEffect(() => {
      if (
        completedRoutesIds &&
        totalTravelled !== -1 &&
        !_.isEmpty(summaryTrackDetails)
      ) {
        const newSummaryDetails = Object.assign(
          {},
          {
            ...summaryTrackDetails,
            totalTraveled:
              summaryTrackDetails.totalTraveled > 0
                ? summaryTrackDetails.totalTraveled
                : totalTravelled,
            totalFuelConsumption:
              summaryTrackDetails.totalFuelConsumption > 0
                ? summaryTrackDetails.totalFuelConsumption
                : vehicleView?.consumption > 0
                ? totalTravelled / 1000 / vehicleView.consumption
                : 0,
          }
        );
        if (!_.isEqual(newSummaryDetails, summaryTrackDetails)) {
          setSummaryTrackDetails(newSummaryDetails);
        }
      }
    }, [totalTravelled, summaryTrackDetails, vehicleView]);
    //#endregion RECOMPUTE AVERAGE SPEED

    /**
     * This function checks if the route history has been updated so that it avoids
     * a new loading of the other components.
     */
    useEffect(() => {
      if (!_.isEqual(routeHistory, prevRoute)) {
        setPrevRoute(routeHistory);
      }
    }, [routeHistory]);

    /** This function handles the coloring of the polylines */
    useEffect(() => {
      let routeSelected = routes.find((route) => route.id == routeIdSelected);

      let valueSelected = routeSelected?.gpsData.findIndex(
        (gps) =>
          new Date(
            new Date(gps.gpsdataByRouteTypeKey.timestamp).toString()
          ).getTime() === positionTimestamp.getTime()
      );

      summaryPolylines.map((summaryPolyline) => {
        let marker = summaryPolyline.incrementalPolyline.find(
          (incremental: any) =>
            incremental.id != null && incremental.id === "batchPolyLineMarker"
        );
        summaryPolyline.onRemoveMarker(marker);
      });

      summaryPolylines.map((summaryPolyline) => {
        if (summaryPolyline.id < routeIdSelected) {
          let lengthRoute = routes.find(
            (route) => route.id == summaryPolyline.id
          )?.gpsData.length;
          //colora intera polilinea
          summaryPolyline.settingIncrementalPolyline(
            {
              start: 0,
              stop: (lengthRoute || 1) - 1,
              indexPoint: (lengthRoute || 1) - 1,
            },
            false
          );
        } else if (summaryPolyline.id === routeIdSelected) {
          //colora polilinea parzialmente
          summaryPolyline.settingIncrementalPolyline(
            {
              start: 0,
              stop: (routeSelected?.gpsData.length || 1) - 1,
              indexPoint: valueSelected,
            },
            true
          );
        } else if (summaryPolyline.id > routeIdSelected) {
          let lengthRoute = routes.find(
            (route) => route.id == summaryPolyline.id
          )?.gpsData.length;
          summaryPolyline.settingIncrementalPolyline(
            {
              start: 0,
              stop: (lengthRoute || 1) - 1,
              indexPoint: 0,
            },
            false
          );
        }
      });
    }, [value, polylineCreated, routeIdSelected]);

    /**
     * This function processes a list of routes and associated events to update a map display with route and event information.
     * 1. **Iterates through Routes**: For each route in the `routes` array:
     *    - **Filter GPS Data**: Filters out GPS data points with zero latitude and longitude and computes the average speed from points with a positive speed.
     *    - **Handle Events**: Filters and handles events associated with the current route, excluding specific event types based on certain conditions.
     *    - **Add Markers**: Adds markers for events to the map, handling special cases for "SPEED_LIMIT" events to ensure markers are not duplicated unnecessarily.
     *    - **Draw Polylines**: If the route type is "TRACK", draws a polyline on the map to represent the route and extends the map bounds to fit the route.
     *    - **Create Map Markers**: For non-empty routes, creates markers with additional information like address, latitude, longitude, and event type.
     *    - **Handle Arrows**: If direction arrows are enabled, adds arrows to the map for each GPS point of the route.
     * 2. **Update Map View**: After processing all routes:
     *    - **Adjust Map Bounds**: Adjusts the map view to fit all routes or sets a default view if no routes are available.
     *    - **Manage Marker Clusters**: Creates a marker cluster to manage markers efficiently on the map.
     * 3. **Cleanup**: If there are no routes or events, removes existing markers, polylines, and clusters from the map to clean up the display.
     * 4. **State Updates**: Updates the state with the new markers and polylines to reflect the changes on the map.
     * In summary, the function processes route data to filter and manage GPS points and events, adds corresponding markers and polylines to a map, adjusts the map view accordingly, and handles cleanup and state updates as needed.
     */
    useEffect(() => {
      if (
        routes.length > 0 &&
        events.length > 0 &&
        map &&
        routesBounds &&
        isMapIdle &&
        routesSliceStatus === "idle" &&
        eventSliceStatus === "idle" &&
        (oldUrl !== currentUrl ||
          !_.isEqual(selectedEvents, previousSelectedEvents) ||
          !_.isEqual(directionArrow, prevStatusArrows))
      ) {
        setOldUrl(currentUrl);
        setPreviousSelectedEvent(selectedEvents);
        setPrevStatusArrows(directionArrow);
        if (summaryMarkers) {
          markerClusterList = [];
          summaryMarkers.onRemove();
          setSummaryMarkers(undefined);
        }

        if (summaryPolylines.length > 0) {
          summaryPolylines.forEach((x) => x.onRemove());
          setSummaryPolylines([]);
        }
        if (_arrows.length > 0) {
          _arrows.forEach((arrow: any) => {
            arrow.setMap(null);
          });
          _arrows = [];
        }
        if (clusterList.length > 0) {
          clusterList.forEach((cluster: any) => {
            cluster.onRemove();
          });
        }

        let eventsByRouteId: { [id: number]: GpsDataEvent[] } = {};

        //#region RECOMPUTE EVENTS BASED ON CURRENTLY DOWNLOADED LIST OF ROUTES
        events?.forEach((event: RouteEvents) => {
          let routeId: number = event?.id;
          if (routesIds.includes(routeId)) {
            if (!eventsByRouteId[routeId]) {
              eventsByRouteId[routeId] = [];
            }
            event.event.forEach((element) => {
              eventsByRouteId[routeId].push(element);
            });
          }
        });
        //#endregion RECOMPUTE EVENTS BASED ON CURRENTLY DOWNLOADED LIST OF ROUTES

        // create new polylines

        let polylines: any = [];
        let polyline;
        let speedSum = 0;
        let speedCount = 0;
        let travelledTime = 0;
        let maxSpeedTemp = Number.MIN_VALUE;
        let odometers: number[] = [];

        //#region COMPUTE THE MINIMUM START AND MAXIMUM END
        let firstAIndex = -1;
        let lastBIndex = -1;

        for (let i = 0; i < routes.length; i++) {
          if (
            firstAIndex === -1 &&
            routes[i].gpsData.length > 0 &&
            routes[i].gpsData[0].gpsdataByRouteTypeKey.routeType === "TRACK"
          ) {
            let routeEvents = eventsByRouteId[routes[i].id] || [];
            if (
              routeEvents.length > 0 &&
              routeEvents.some((e) => e.eventLogByVehicleIdKey.name === "START")
            ) {
              firstAIndex = i;
            }
          }

          if (
            routes[i].gpsData.length > 0 &&
            routes[i].gpsData[0].gpsdataByRouteTypeKey.routeType === "TRACK"
          ) {
            let routeEvents = eventsByRouteId[routes[i].id] || [];
            if (
              routeEvents.length > 0 &&
              routeEvents.some((e) => e.eventLogByVehicleIdKey.name === "END")
            ) {
              lastBIndex = i;
            }
          }
        }
        //#endregion COMPUTE THE MINIMUM START AND MAXIMUM END

        //#region Processes routes, filters GPS data, adds markers, draws polylines, updates map
        routes?.forEach((currentRoute, g) => {
          const routeId = currentRoute?.id;
          if (routesIds.includes(routeId)) {
            const route = {
              ...currentRoute,
              gpsData: currentRoute.gpsData.filter((e) => {
                if (e?.dynamicFields?.speed > 0) {
                  speedCount += 1;
                  speedSum += e?.dynamicFields?.speed;
                  if (
                    e?.dynamicFields?.speed > 0 &&
                    e?.dynamicFields?.speed > maxSpeedTemp
                  ) {
                    maxSpeedTemp = e?.dynamicFields?.speed;
                  }
                  odometers.push(e?.dynamicFields.odometer);
                }
                return (
                  e.dynamicFields.latitude !== 0 &&
                  e.dynamicFields.longitude !== 0
                );
              }),
            };
            const associatedEvents = eventsByRouteId[routeId] || [];
            let excludedEventNames: string[] = [
              "IGNITION_KEY_ON",
              "IGNITION_KEY_OFF",
            ];

            if (firstAIndex !== g && lastBIndex !== g) {
              excludedEventNames = [...excludedEventNames, "START", "END"];
            } else {
              if (firstAIndex !== lastBIndex) {
                if (firstAIndex === g) {
                  excludedEventNames = [...excludedEventNames, "END"];
                }
                if (lastBIndex === g) {
                  excludedEventNames = [...excludedEventNames, "START"];
                }
              }
            }

            route?.gpsData.forEach((point, j) => {
              const currPoint = point;
              const currentEvents = associatedEvents.filter(
                (el) =>
                  el.eventLogByVehicleIdKey.timestamp ===
                    currPoint?.gpsdataByRouteTypeKey?.timestamp &&
                  !excludedEventNames.includes(el.eventLogByVehicleIdKey.name)
              );

              if (currentEvents.length > 0) {
                currentEvents.forEach((currentEvent) => {
                  if (
                    currentEvent.eventLogByVehicleIdKey.name === "SPEED_LIMIT"
                  ) {
                    const prevPoint = route.gpsData[j - 1];
                    const nextPoint = route.gpsData[j + 1];

                    const findedEventPrev = associatedEvents.find(
                      (el) =>
                        el.eventLogByVehicleIdKey.timestamp ===
                        prevPoint?.gpsdataByRouteTypeKey?.timestamp
                    );
                    const findedEventNext = associatedEvents.find(
                      (el) =>
                        el.eventLogByVehicleIdKey.timestamp ===
                        nextPoint?.gpsdataByRouteTypeKey?.timestamp
                    );

                    const addMarker = () => {
                      addMarkerForEvent(currentEvent, currPoint);
                    };

                    if (
                      !findedEventPrev ||
                      findedEventPrev.eventLogByVehicleIdKey.name !==
                        "SPEED_LIMIT"
                    ) {
                      addMarker();
                    } else if (
                      !findedEventNext ||
                      findedEventNext.eventLogByVehicleIdKey.name !==
                        "SPEED_LIMIT"
                    ) {
                      addMarker();
                    } else {
                      return;
                    }
                  } else {
                    addMarkerForEvent(currentEvent, currPoint);
                  }
                });
              }
            });

            if (
              route?.gpsData[0].gpsdataByRouteTypeKey?.routeType === "TRACK"
            ) {
              polyline = SummaryClassPolyline({
                hasMarker: false,
                googleMap: googleMap,
                map: map,
                gpsData: route?.gpsData,
                eventsType: eventsType,
                routeId: route.id,
                gpsDataEvent:
                  selectedEvents.length === 0
                    ? associatedEvents
                    : associatedEvents.filter((e) =>
                        selectedEvents
                          .map((se) => se.name)
                          .includes(e.eventLogByVehicleIdKey.name)
                      ),
              });
              if (route.gpsData) {
                route.gpsData.forEach((point) => {
                  routesBounds.extend(
                    new window.google.maps.LatLng(
                      point.dynamicFields.latitude,
                      point.dynamicFields.longitude
                    )
                  );
                });
              }

              polylines.push(polyline);
            } else if (!_.isEmpty(route) && routesIds.includes(route.id)) {
              const markerData = {} as MarkerData;
              markerData.id = route?.id?.toString();
              markerData.routeType =
                route?.gpsData?.length > 0
                  ? route.gpsData[0].gpsdataByRouteTypeKey.routeType
                  : "";
              const firstPosition = route.gpsData[0];
              const lastPosition = route.gpsData[route.gpsData.length - 1];
              markerData.address = firstPosition?.dynamicFields?.address;
              markerData.component = (
                <MapMarkerLocation
                  type={isTrailerLike(vehicleType) ? "POSITION" : "PARKING"}
                  size={undefined}
                />
              );
              markerData.latitude = firstPosition?.dynamicFields?.latitude;
              markerData.longitude = firstPosition?.dynamicFields?.longitude;
              markerData.showTooltip = true;
              markerData.eventType = "PARKING";
              markerData.routeInfo = {} as RouteInfo;
              markerData.routeInfo.id = route?.id;
              markerData.routeInfo.end = ConvertTimeZone(
                lastPosition?.gpsdataByRouteTypeKey?.timestamp,
                preferencesContext.timeZone,
                preferencesContext.localeFormat
              );
              if (!isTrailerLike(vehicleType)) {
                markerData.routeInfo.duration = formatTotalTime(
                  firstPosition?.gpsdataByRouteTypeKey?.timestamp.toString(),
                  lastPosition?.gpsdataByRouteTypeKey?.timestamp?.toString()
                );
                markerData.routeInfo.start = ConvertTimeZone(
                  firstPosition?.gpsdataByRouteTypeKey?.timestamp,
                  preferencesContext.timeZone,
                  preferencesContext.localeFormat
                );
              }
              markerData.routeInfo.address =
                firstPosition?.dynamicFields?.address;
              if (route?.gpsData) {
                route.gpsData.forEach((point: GpsData) => {
                  routesBounds.extend(
                    new window.google.maps.LatLng(
                      point.dynamicFields?.latitude,
                      point.dynamicFields?.longitude
                    )
                  );
                });
              }
              if (!isTrailerLike(vehicleType)) {
                polyline = SummaryClassPolyline({
                  hasMarker: false,
                  googleMap: googleMap,
                  map: map,
                  gpsData: route?.gpsData.filter(
                    (x, index) =>
                      index === 0 || index === route?.gpsData?.length - 1
                  ),
                  eventsType: eventsType,
                  routeId: route.id,
                  gpsDataEvent:
                    selectedEvents.length === 0
                      ? associatedEvents
                      : associatedEvents.filter((e) =>
                          selectedEvents
                            .map((se) => se.name)
                            .includes(e.eventLogByVehicleIdKey.name)
                        ),
                });
                polylines.push(polyline);
              }

              markerClusterList.push(createMarker(markerData));

              const gpsDataWithSpeed = route.gpsData.filter(
                (x) => x.dynamicFields?.speed > 0
              );
              if (gpsDataWithSpeed.length > 0) {
                setPrevStatusArrows(directionArrow);
                polyline = SummaryClassPolyline({
                  hasMarker: false,
                  googleMap: googleMap,
                  map: map,
                  gpsData: route?.gpsData,
                  gpsDataEvent: associatedEvents,
                  eventsType: eventsType,
                  routeId: route.id,
                });
                if (route.gpsData) {
                  route.gpsData.forEach((point) => {
                    routesBounds.extend(
                      new window.google.maps.LatLng(
                        point.dynamicFields.latitude,
                        point.dynamicFields.longitude
                      )
                    );
                  });
                }

                polylines.push(polyline);
              }
            }

            if (directionArrow) {
              route.gpsData.forEach((showPoint: GpsData) => {
                if (showPoint?.gpsdataByRouteTypeKey?.routeType === "TRACK") {
                  const arrow = MarkerAsComponent({
                    id: showPoint.routeId,
                    googleMap: googleMap,
                    lat: showPoint.dynamicFields.latitude,
                    lng: showPoint.dynamicFields.longitude,
                    address: showPoint.dynamicFields.address,
                    show: true,
                    map: map,
                    preferences: preferencesContext,
                    infoWindow: infoWindow,
                    component: (
                      <MapMarkerLocator
                        rotate={showPoint.dynamicFields.direction}
                      />
                    ),
                    showTooltip: true,
                    arrowInfo: {
                      id: showPoint.routeId,
                      address: showPoint.dynamicFields.address,
                      date: showPoint?.gpsdataByRouteTypeKey?.timestamp,
                      speed: showPoint.dynamicFields.speed,
                    },

                    onClick: () => {
                      const timestamp =
                        showPoint?.gpsdataByRouteTypeKey?.timestamp;
                      const routeType =
                        showPoint?.gpsdataByRouteTypeKey?.routeType;
                      const routeId = showPoint?.routeId;
                      const vehicleId =
                        showPoint?.gpsdataByRouteTypeKey?.vehicleId;
                      if (timestamp && routeType && routeId && vehicleId) {
                        window.open(
                          `/dashboard/vehicles/location-history/track/${routeId}?${searchParams.toString()}&currentPos=${timestamp}`
                        );
                      }
                    },
                  }) as google.maps.Marker;

                  _arrows.push(arrow);
                }
              });
            }
          }
        });
        //#endregion Processes routes, filters GPS data, adds markers, draws polylines, updates map

        if (speedCount > 0 && speedSum > 0) {
          setAvgSpeed(speedSum / speedCount);
          setMaxSpeed(maxSpeedTemp);
        }
        if (odometers.length > 0) {
          odometers = _.orderBy(odometers);
          setTotalTravelled(odometers[odometers.length - 1] - odometers[0]);
        }
        if (_arrows.length !== 0) {
          const rendererArrow = {
            render(__namedParameters: Cluster) {
              let firstMarker: any = null;
              if (
                __namedParameters.markers &&
                __namedParameters.markers.length > 0
              ) {
                firstMarker = __namedParameters.markers[0];
                if (firstMarker) {
                  firstMarker.setMap(map);
                }
              }
              return MarkerAsComponent({
                id: "markerCluster",
                googleMap: googleMap,
                preferences: preferencesContext,
                lat: __namedParameters.position.lat(),
                lng: __namedParameters.position.lng(),
                map: map,
                hasArrowCluster: true,
                onClick: () => {
                  map.fitBounds(__namedParameters.bounds, 100);
                },
                component: (
                  <MapMarkerLocator
                    stroke="#1572a3"
                    rotate={parseInt(firstMarker?.directionArrow)}
                  />
                ),
              });
            },
          };

          let cluster: any;

          if (_arrows.length > 0) {
            cluster = new MarkerClusterer({
              map: map,
              markers: _arrows,
              renderer: rendererArrow,
            });
          }
          clusterList.push(cluster);
        }
        if (
          _.isEqual(directionArrow, prevStatusArrows) &&
          _.isEqual(selectedEvents, previousSelectedEvents) &&
          routes.length > 0
        ) {
          map.fitBounds(routesBounds, {
            top: 150,
            bottom: 350,
            right: 150,
            left: 150,
          });
        } else if (routes.length == 0) {
          map.fitBounds(
            new google.maps.LatLngBounds(
              new google.maps.LatLng(41.9027835, 12.4963655),
              new google.maps.LatLng(41.9027835, 12.4963655)
            )
          );
          map.setZoom(6);
        }

        setSummaryMarkers(
          new MarkerClusterer({
            algorithmOptions: { maxZoom: 20 },
            map: map,
            markers: markerClusterList as google.maps.Marker[],
            renderer: renderer,
          })
        );
        setSummaryPolylines(polylines);
        if (polylines.length > 0) {
          setPolylineCreated(true);
        }
      } else if (
        routes.length === 0 &&
        events.length === 0 &&
        routesSliceStatus === "idle" &&
        eventSliceStatus === "idle"
      ) {
        if (summaryMarkers) {
          markerClusterList = [];
          summaryMarkers.onRemove();
          setSummaryMarkers(undefined);
        }
        if (summaryPolylines.length > 0) {
          summaryPolylines.forEach((x) => x.onRemove());
          setSummaryPolylines([]);
        }
        if (_arrows.length > 0) {
          _arrows.forEach((arrow: any) => {
            arrow.setMap(null);
          });
          _arrows = [];
        }
        if (clusterList.length > 0) {
          clusterList.forEach((cluster: any) => {
            cluster.onRemove();
          });
        }
      }
    }, [
      routes,
      directionArrow,
      routesSliceStatus,
      events,
      eventSliceStatus,
      map,
      isMapIdle,
      selectedEvents,
    ]);

    /** The function retrieves the routes so that instantiates for the trackbar */
    useEffect(() => {
      if (routes) {
        let tempRouteHistory: RouteHistory = {} as RouteHistory;
        let firstPosition: RoutePosition = {} as RoutePosition;
        let lastPosition: RoutePosition = {} as RoutePosition;
        let routesFiltered = routes.filter((route) =>
          routesIds.includes(route.id)
        );
        let routeStates: RouteState[] = routesFiltered
          .filter((route) => routesIds.includes(route.id))
          .flatMap((route, i) => {
            return route.gpsData.map((gps, j) => {
              if (i === 0 && j === 0) {
                firstPosition.address = gps.dynamicFields.address;
                firstPosition.gpsPositionTimestamp = new Date(
                  gps.gpsdataByRouteTypeKey.timestamp
                );
                firstPosition.latitude = gps.dynamicFields.latitude;
                firstPosition.longitude = gps.dynamicFields.longitude;
              }
              if (
                i === routesFiltered.length - 1 &&
                j === route.gpsData.length - 1
              ) {
                lastPosition.address = gps.dynamicFields.address;
                lastPosition.gpsPositionTimestamp = new Date(
                  gps.gpsdataByRouteTypeKey.timestamp
                );
                lastPosition.latitude = gps.dynamicFields.latitude;
                lastPosition.longitude = gps.dynamicFields.longitude;
              }
              let routeState: RouteState = {} as RouteState;
              routeState.dynamicFields = {
                ...gps.dynamicFields,
                lastUpdate: new Date(gps.gpsdataByRouteTypeKey.timestamp),
              } as {
                latitude: number;
                longitude: number;
                lastUpdate: Date;
                address: string;
                direction: number;
                speed: number;
                fuelLevel: number;
                fuelLevelLiters: number;
                odometer: number;
              };
              let tempEvents = events.find((event) => event.id === route.id);
              if (tempEvents != null) {
                let eventsByTimestamp = tempEvents.event.filter(
                  (event) =>
                    event.eventLogByVehicleIdKey.timestamp ===
                    gps.gpsdataByRouteTypeKey.timestamp
                );
                routeState.events = [];
                eventsByTimestamp.forEach((eventByTimestamp) => {
                  let eventType = eventTypes.find(
                    (evntInfo) => evntInfo.id == eventByTimestamp.typeId
                  );
                  let routeEvent: RouteEvent = {} as RouteEvent;
                  if (eventType != null) {
                    routeEvent.type = {
                      id: eventType?.id,
                      category: eventType.category,
                      description: eventType.description,
                      name: eventType.name,
                      severity: eventType.severity,
                      hardwareSource: eventType.hardwareSource,
                      translations: eventType.translations,
                      iconCode: eventType.iconCode,
                    };
                  }

                  routeState.events.push(routeEvent);
                  // });
                });
              }

              routeState.driver = { id: gps.driverId } as DriverView;
              routeState.routeStateType = gps.gpsdataByRouteTypeKey
                .routeType as RouteStateType;
              routeState.routeId = route.id;
              return routeState;
            });
          });
        tempRouteHistory.routeStates = routeStates;
        tempRouteHistory.id = -1;
        tempRouteHistory.routeStateType = "TRACK";
        tempRouteHistory.lastPosition = lastPosition;
        tempRouteHistory.firstPosition = firstPosition;
        setRouteHistory(tempRouteHistory);
      }
    }, [routes, events]);

    useEffect(() => {
      return function cleanUp() {
        if (summaryMarkers) {
          summaryMarkers.onRemove();
        }
        if (summaryPolylines) {
          summaryPolylines.forEach((x) => x.onRemove());
          setSummaryPolylines([]);
        }
        removeMarkers();
      };
    }, []);
    //#endregion Business Logic region

    // #region map component methods and useEffects
    const plusClick = () => {
      if (map) {
        const zoomTemp = map.getZoom();
        if (zoomTemp) {
          map.setZoom(zoomTemp + 1);
        }
      }
    };

    const minusClick = () => {
      if (map) {
        const zoomTemp = map.getZoom();
        if (zoomTemp) {
          map.setZoom(zoomTemp - 1);
        }
      }
    };

    // this method is used to activate street map view from mapType button
    const mapClick = () => {
      map.setMapTypeId("roadmap");
    };

    // this method is used to activate satellite view from mapType button
    const satelliteClick = () => {
      map.setMapTypeId("hybrid");
    };

    // This methods are used by action component for fullScreen button
    function isFullscreen(element: any) {
      return (
        (document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement ||
          document.msFullscreenElement) === element
      );
    }

    function requestFullscreen(element: any) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullScreen) {
        element.msRequestFullScreen();
      }
    }

    function exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }

    const fullsc = () => {
      const elementToSendFullscreen = map?.getDiv().firstChild;
      if (isFullscreen(elementToSendFullscreen)) {
        exitFullscreen();
      } else {
        requestFullscreen(elementToSendFullscreen);
      }
    };

    useEffect(() => {
      let dragListener: any;
      let zoomListener: any;

      if (googleMap && map && directionArrow) {
        dragListener = googleMap.maps.event.addListener(
          map,
          "dragend",
          handleMapChangeArrow
        );
        zoomListener = googleMap.maps.event.addListener(
          map,
          "zoom_changed",
          handleMapChangeArrow
        );
      }

      return () => {
        if (googleMap) {
          googleMap.maps.event.removeListener(dragListener);
          googleMap.maps.event.removeListener(zoomListener);
        }
      };
    }, [map, directionArrow]);
    // #endregion map component methods

    // #region Geofence Action Menu methods
    // marker cluster for geofences
    const geofenceRenderer = {
      render(__namedParameters: Cluster) {
        const clusterData = _.countBy(__namedParameters.markers, "status");
        return MarkerAsComponent({
          id: "geofenceCluster",
          googleMap: googleMap,
          preferences: preferencesContext,
          lat: __namedParameters.position.lat(),
          lng: __namedParameters.position.lng(),
          map: map,
          onClick: () => {
            map.fitBounds(__namedParameters.bounds, 100);
          },
          component: (
            <ClusterMarker
              data={Object.keys(clusterData).map((key) => ({
                status: key ?? "UNKNOWN",
                numbers: clusterData[key],
                color:
                  geofenceCategories.find(
                    (item: GeofenceCategory) => item.name === key
                  )?.color ?? "#0052BD",
              }))}
              hasChart={true}
              text={`${__namedParameters.markers?.length ?? ""}`}
            />
          ),
        });
      },
    };

    // This method show geofence markers, creating cluster for nearby markers
    function setGeofences(geofenceOptions: any) {
      removeMarkers();
      let markerArray: google.maps.Marker[] = [];
      let bounds = new googleMap.maps.LatLngBounds();
      geofenceOptions.forEach((option: any) => {
        if (option.checked) {
          option.geofences.forEach((geofence: any) => {
            const category = geofenceCategoriesSelectors.selectById(
              store.getState(),
              geofence.geofenceCategory
            );
            markerArray.push(
              MarkerAsComponent({
                id: "customMarker-" + geofence?.id,
                googleMap: googleMap,
                preferences: preferencesContext,
                lat: geofence?.shape?.center.lat,
                lng: geofence?.shape?.center.lng,
                map: map,
                geofence: geofence.name,
                isGeofence: true,
                onClick: () => clickOnGeofence(geofence, option.color),
                status: category?.name,
                component: (
                  <>
                    <MapMarkerPin
                      key={geofence.id}
                      color={category?.color ?? "#0052BD"}
                    />
                    {geofence.name && (
                      <label className="labelId">{geofence.name}</label>
                    )}
                  </>
                ),
              })
            );
            bounds.extend(
              new googleMap.maps.LatLng(
                geofence.shape.center.lat,
                geofence.shape.center.lng
              )
            );
          });
        }
      });

      geofenceMarkers.push(...markerArray);
      geofenceClusterMarkers = new MarkerClusterer({
        map: map,
        markers: geofenceMarkers,
        renderer: geofenceRenderer,
      });
    }

    // This method show the geofence area after user click on geofence marker
    function clickOnGeofence(geofence: any, color: string) {
      if (geofenceArea) geofenceArea.setMap(null);
      map.panTo(geofence.shape.center);

      if (geofence.geofenceShapeEnum === "CIRCLE") {
        geofenceArea = new googleMap.maps.Circle({
          strokeColor: color,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: color,
          fillOpacity: 0.3,
          map,
          center: geofence.shape.center,
          radius: geofence.shape.radius,
        });

        map.fitBounds(geofenceArea.getBounds(), 100);
      } else {
        if (geofence.geofenceShapeEnum === "POLYGON") {
          geofenceArea = new googleMap.maps.Polygon({
            paths: geofence.shape.points,
            strokeColor: color,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: color,
            fillOpacity: 0.35,
            map,
          });
        }
        let bounds = new googleMap.maps.LatLngBounds();
        geofence.shape.points.forEach((point: any) => {
          bounds.extend(new googleMap.maps.LatLng(point.lat, point.lng));
        });
        map.fitBounds(bounds, 100);
      }
    }

    // This use effect updates components on map on geofences data changes

    useEffect(() => {
      if (!_.isEmpty(geofences) && !_.isEmpty(geofenceCategories)) {
        setComponentsOnMap((prev: ComponentOnMap[]) => {
          prev.forEach((item: any) => {
            if (item.selectorName === ".action-control") {
              item.component.props.children.props.geofencesProp.geofences =
                geofences;
              item.component.props.children.props.geofencesProp.geofenceCategories =
                geofenceCategories;
            }
          });
          return prev;
        });
      }
    }, [geofences, geofenceCategories]);
    // #endregion

    // #region progressive markers loader

    const updateMapDataArrow = useCallback(() => {
      const visibleMarkers: any[] = [];

      _arrows.forEach((markerGroup: any) => {
        const position = markerGroup.getPosition();
        if (!_.isEmpty(position) && map.getBounds().contains(position)) {
          visibleMarkers.push(markerGroup);
          if (!markerGroup.getMap()) {
            markerGroup.setMap(map);
          }
        } else {
          markerGroup.setMap(null);
        }
      });

      clusterList?.forEach((cluster) => {
        cluster.clearMarkers();
        cluster.addMarkers(visibleMarkers);
      });
    }, [map, _arrows, clusterList]);

    function handleMapChangeArrow() {
      updateMapDataArrow();
    }

    useEffect(() => {
      // Create event listeners for map drag and zoom events
      let dragListener: any;
      let zoomListener: any;
      if (googleMap && map) {
        dragListener = googleMap.maps.event.addListener(
          map,
          "dragend",
          handleMapChange
        );
        zoomListener = googleMap.maps.event.addListener(
          map,
          "zoom_changed",
          handleMapChange
        );
      }

      // Cleanup function to remove event listeners
      return () => {
        if (googleMap) {
          googleMap.maps.event.removeListener(dragListener);
          googleMap.maps.event.removeListener(zoomListener);
        }
      };
    }, [map, geofenceMarkers]);

    const updateMapData = useCallback(() => {
      const visibleMarkers: any[] = []; // Array of visible markers

      if (geofenceMarkers.length > 0) {
        geofenceMarkers.forEach((marker: any) => {
          if (map.getBounds().contains(marker.getPosition())) {
            visibleMarkers.push(marker); // Add marker to array of visible markers
            if (!marker.getMap()) {
              marker.setMap(map);
            }
          } else {
            marker.setMap(null);
          }
        });

        if (geofenceClusterMarkers) {
          geofenceClusterMarkers.clearMarkers(); // Remove marker from cluster
          geofenceClusterMarkers.addMarkers(visibleMarkers); // Add markers to cluster
        }
      }
    }, [map, geofenceMarkers, geofenceClusterMarkers]);

    function handleMapChange() {
      updateMapData();
    }
    // #endregion progressive markers loader

    // #region to add components on map
    useEffect(() => {
      if (isMapIdle) {
        componentsToAdd.length = 0;
        componentsToAdd.push({
          selectorName: ".action-control",
          selectorPosition: googleMap.maps.ControlPosition.TOP_RIGHT,
          component: (
            <div
              className="action-control"
              style={{
                zIndex: 1,
                position: "absolute",
                padding: "16px 16px 8px 16px",
              }}
            >
              <Actions
                isFull={true}
                handleFullScreen={() => fullsc()}
                handleScaleDown={() => fullsc()}
                searchDisabled={true}
                settingsDisabled={true}
                geofencesProp={{
                  geofences: geofences,
                  geofenceCategories: geofenceCategories,
                  setGeofences: setGeofences,
                }}
              />
            </div>
          ),
        });
        preferencesContext.satelliteOnMap
          ? map.setMapTypeId("hybrid")
          : map.setMapTypeId("roadmap");

        componentsToAdd.push({
          selectorName: ".maptype-control",
          selectorPosition: googleMap.maps.ControlPosition.RIGHT_TOP,
          component: (
            <MapType
              handleMap={() => mapClick()}
              handleSatellite={() => satelliteClick()}
              activeSatellite={preferencesContext.satelliteOnMap}
            />
          ),
        });
        componentsToAdd.push({
          selectorName: ".zoom-control-container",
          selectorPosition: googleMap.maps.ControlPosition.RIGHT_TOP,
          component: (
            <div
              className="zoom-control-container"
              style={{
                padding: "0px 16px 0px 16px",
              }}
            >
              <Zoom
                handleMinus={() => minusClick()}
                handlePlus={() => plusClick()}
              />
            </div>
          ),
        });
        componentsToAdd.push({
          selectorName: ".arrow-view",
          selectorPosition: googleMap.maps.ControlPosition.RIGHT_TOP,
          component: (
            <div
              className="arrow-view"
              style={{
                padding: "16px 16px 0px 16px",
              }}
            >
              <ViewArrow
                onClick={(directionArrow: boolean) => {
                  setDirectionArrows(directionArrow);
                }}
                isDisableArrow={{ isDisabled: directionArrow }}
              />
            </div>
          ),
        });
        setComponentsOnMap(componentsToAdd);
      }
    }, [isMapIdle]);

    const areThereRouteStates =
      !!routeHistory &&
      routeHistory?.routeStates &&
      routeHistory.routeStates.length > 0;

    const areThereEvents =
      areThereRouteStates &&
      routeHistory?.routeStates[value] &&
      routeHistory?.routeStates[value]?.events &&
      routeHistory?.routeStates[value].events.length !== 0;

    // This method is in charge of adding custom cluster component for googleMarkerClusterer library

    // This method is in charge of adding custom cluster component for googleMarkerClusterer library

    function handleStatusColor(status: string) {
      switch (status) {
        case "WARNING":
          return "var(--global-colors-feedback-warning)";
        case "INFO":
          return "var(--global-colors-ui-primary)";
        case "ALARM":
          return "var(--global-colors-feedback-danger)";
        case "PARKING":
          return "var(--global-colors-ui-primary)";
        default:
      }
    }

    function eventCount(eventName: string, events: RouteEvent[]) {
      let counter = 0;
      events.forEach((event) => {
        event.type?.name === eventName && counter++;
      }, []);
      return counter;
    }

    function handleEvents(events: RouteEvent[]): RouteEventReduced[] {
      return events
        .filter(
          (event) => event.type?.name !== "START" && event.type?.name !== "END"
        )
        .map((event) => {
          return {
            event: event,
            count: eventCount(event.type?.name, events),
          };
        });
    }

    function checkEvents(eventList: any): string[] {
      const populatedCategories: string[] = [];

      if (eventList.ALARM && eventList.ALARM.length > 0) {
        populatedCategories.push("ALARM");
      }
      if (eventList.INFO && eventList.INFO.length > 0) {
        populatedCategories.push("INFO");
      }
      if (eventList.WARNING && eventList.WARNING.length > 0) {
        populatedCategories.push("WARNING");
      }

      return populatedCategories;
    }

    const renderCluster = (__namedParameters: Cluster) => {
      const clusterData = _.countBy(__namedParameters.markers, "eventType");

      let hasChart = false;
      __namedParameters.markers?.forEach((marker) => {
        if (
          marker.eventInfo &&
          (marker.eventInfo.name === "START" || marker.eventInfo.name === "END")
        ) {
          hasChart = true;
        }
      });

      if (
        Object.keys(clusterData).length === 2 &&
        Object.keys(clusterData).includes("PARKING") &&
        clusterData["PARKING"] === 1 &&
        (clusterData["WARNING"] === 1 ||
          clusterData["INFO"] === 1 ||
          clusterData["ALARM"] === 1)
      ) {
        let includedStartEnd = __namedParameters.markers?.some(
          (marker: MarkerData) =>
            marker.eventInfo != null &&
            (marker.eventInfo.name.includes("START") ||
              marker.eventInfo.name.includes("END"))
        );

        const colorBorder = Object.keys(clusterData).find(
          (item) => item !== "PARKING"
        );
        return (
          <ClusterMarkerEvent
            data={Object.keys(clusterData).map((key) => ({
              status: colorBorder ?? "INFO",
              numbers: clusterData[key],
              color:
                handleStatusColor(
                  colorBorder ?? "var(--global-colors-ui-secondary)"
                ) ?? "var(--global-colors-ui-secondary)",
            }))}
            hasChart={true}
            hasParking={includedStartEnd}
            text={
              __namedParameters.markers?.some(
                (marker: MarkerData) =>
                  marker.eventInfo != null &&
                  marker.eventInfo.name.includes("START")
              )
                ? `A`
                : __namedParameters.markers?.some(
                    (marker: MarkerData) =>
                      marker.eventInfo != null &&
                      marker.eventInfo.name.includes("END")
                  )
                ? `B`
                : `P`
            }
            letter={includedStartEnd ? "P" : undefined}
          />
        );
      } else if (Object.keys(clusterData).length > 1 || hasChart) {
        const hasStart = __namedParameters.markers?.some((marker: any) => {
          return (
            marker.eventInfo != null && marker.eventInfo.name.includes("START")
          );
        });
        const hasEnd = __namedParameters.markers?.some((marker: any) => {
          return (
            marker.eventInfo != null && marker.eventInfo.name.includes("END")
          );
        });
        let clusterText = `${__namedParameters.markers?.length ?? ""}`;

        if (hasStart && hasEnd) {
          clusterText = "A/B";
        } else {
          if (hasStart) {
            clusterText = "A";
          } else if (hasEnd) {
            clusterText = "B";
          }
        }

        return (
          <ClusterMarkerEvent
            letter="P"
            data={Object.keys(clusterData).map((key: any) => ({
              status: key ?? "INFO",
              numbers: clusterData[key],
              color:
                handleStatusColor(key) ?? "var(--global-colors-ui-secondary)",
            }))}
            hasChart={true}
            text={clusterText}
            hasParking={Object.keys(clusterData).includes("PARKING")}
          />
        );
      } else {
        return (
          <MapMarkerEventToggle
            color={handleStatusColor(Object.keys(clusterData)[0])}
            status={Object.keys(clusterData)[0]}
            number={`${__namedParameters.markers?.length ?? ""}`}
            letter="P"
          />
        );
      }
    };

    const renderer = {
      render(__namedParameters: Cluster) {
        let clusterRendered: any = renderCluster(__namedParameters);

        // #region Create object for petals cluster
        let eventList: any = {
          WARNING: [],
          INFO: [],
          ALARM: [],
          PARKING: [],
        };

        __namedParameters.markers?.forEach((marker: MarkerData) => {
          let obj: any = {
            id: marker?.eventInfo?.id,
            name: marker?.eventInfo?.name,
            category: marker?.eventType,
            timestamp: marker?.eventInfo?.timestamp,
          };

          if (eventList.hasOwnProperty(obj.category)) {
            eventList[obj.category].push(obj);
          } else {
            return null;
          }
        });

        // #endregion Create object for petals cluster

        return MarkerAsComponent({
          id: "customMarker-" + Math.floor(Math.random() * 100) + 1,
          googleMap: googleMap,
          lat: __namedParameters.position.lat(),
          lng: __namedParameters.position.lng(),
          map: map,
          infoWindow: infoWindow,
          show: true,
          preferences: preferencesContext,
          showTooltip: checkEvents(eventList).length > 0,
          eventsType: eventsType,
          clusterInfoLocationHistory:
            checkEvents(eventList).length > 0 ? eventList : undefined,
          component: clusterRendered,
          hasPetals: false,
          onClick: () => {
            map.fitBounds(__namedParameters.bounds, 100);
            infoWindow.close();
          },
          navigate: (properties) => {
            let informationsForQueryParams = properties.split("/");
            let routeId = informationsForQueryParams[0];
            let timestamp = informationsForQueryParams[1];
            window.open(
              `/dashboard/vehicles/location-history/track/${routeId}?${searchParams.toString()}&currentPos=${timestamp}`
            );
          },
        });
      },
    };

    // This method is in charge of stop markers creation
    function createMarker(properties: MarkerData) {
      return MarkerAsComponent({
        id: "customMarker-" + properties.id,
        googleMap: googleMap,
        lat: properties.latitude,
        lng: properties.longitude,
        address: properties.address,
        show: true,
        map: map,
        infoWindow: infoWindow,
        eventType: properties.eventType,
        component: properties.component,
        showTooltip: properties.showTooltip,
        routeInfo: properties.routeInfo,
        eventsType: properties.eventsType,
        eventInfo: properties.eventInfo,
        preferences: preferencesContext,
        navigate: () => {
          if (
            properties?.eventInfo?.name !== "START" &&
            properties?.eventInfo?.name !== "END"
          ) {
            navigate(
              `/dashboard/vehicles/location-history/track/${properties?.eventInfo?.id}?${properties?.eventInfo?.queryParams}&currentPos=${properties?.eventInfo?.timestamp}`
            );
          }
        },
        onClick: () => {
          navigate(
            `/dashboard/vehicles/location-history/stop/${
              properties.routeInfo.id
            }?${searchParams.toString()}`
          );
        },
      }) as google.maps.Marker;
    }

    // This method is in charge of stop markers creation

    const addMarkerForEvent = (event: GpsDataEvent, point: GpsData) => {
      let eventName = event.eventLogByVehicleIdKey.name;
      const routeType = point?.gpsdataByRouteTypeKey.routeType;
      const markerData = {} as MarkerData;
      markerData.id = (Math.floor(Math.random() * 100) + 1).toString();
      markerData.address = event?.dynamicFields?.address;
      markerData.routeType = routeType;
      markerData.component = (
        <MapMarkerEventLocation
          eventsType={eventTypes}
          type={eventName}
          hasTooltip={false}
          preferences={preferencesContext}
        />
      );
      markerData.latitude = event?.dynamicFields?.latitude;
      markerData.longitude = event?.dynamicFields?.longitude;
      markerData.showTooltip = true;
      markerData.eventsType = eventsType;
      markerData.eventInfo = {} as EventInfo;
      markerData.eventInfo.id = event.routeId;
      markerData.eventInfo.name = eventName;
      markerData.eventInfo.speed = _.isEqual(
        event.eventLogByVehicleIdKey.timestamp,
        point?.gpsdataByRouteTypeKey?.timestamp
      )
        ? point.dynamicFields.speed
        : 0;
      markerData.eventInfo.timestamp = event.eventLogByVehicleIdKey.timestamp;
      markerData.eventInfo.routeId = event.routeId;
      markerData.eventInfo.queryParams = searchParams.toString();
      markerData.eventType =
        eventsType.find((el) => el.name == eventName)?.severity.toUpperCase() ??
        "INFO";
      routesBounds.extend(
        new window.google.maps.LatLng(
          event.dynamicFields?.latitude,
          event.dynamicFields?.longitude
        )
      );
      markerClusterList.push(createMarker(markerData));
    };

    // This method must be sure that all markers and clusters being removed from map
    const removeMarkers = () => {
      if (geofenceMarkers.length !== 0) {
        geofenceMarkers.forEach((geofence: any) => geofence.setMap(null));
      }
      if (geofenceArea) {
        geofenceArea.setMap(null);
      }
      if (arrowMarkers.length > 0) {
        arrowMarkers.forEach((el: any) => {
          el.forEach((element: google.maps.Marker) => {
            element.setMap(null);
          });
        });
      }
      if (!_.isEmpty(arrowClusterMarkers)) {
        arrowClusterMarkers.setMap(null);
        arrowClusterMarkers = {} as MarkerClusterer;
      }
      if (!_.isEmpty(geofenceClusterMarkers)) {
        geofenceClusterMarkers.setMap(null);
        geofenceClusterMarkers = {} as MarkerClusterer;
      }
      markerClusterList = [];
      arrowMarkers = [];
      geofenceMarkers = [];
    };
    // #endregion add components on map

    return (
      <>
        <BaseMap
          id={id}
          hasStreetViewUp={true}
          googleMapsApiKey={googleMapsApiKey}
          hasStreetView={true}
          zoom={zoom}
          latitude={latitude}
          longitude={longitude}
          getMap={setMap}
          getIsMapIdle={setIsMapIdle}
          showMarkers={true}
          setStreetViewEnabled={setStreetViewEnabled}
        >
          {componentsOnMap}
        </BaseMap>
        <div
          className="vlh-open-details"
          style={
            expandedSidebar
              ? {
                  marginLeft: "18px",
                  position: "fixed",
                  top: "155px",
                  width: "fit-content",
                }
              : {
                  marginLeft: "18px",
                  position: "fixed",
                  top: "75px",
                  width: "fit-content",
                }
          }
        >
          <div className="vlh-open-summary-details">
            {!openTrackDetails && !streetViewEnabled && summaryTrackDetails && (
              <div className="track-details-up">
                <SummaryDetails
                  details={{
                    totalKm: summaryTrackDetails?.totalTraveled,
                    cost: summaryTrackDetails?.totalCosts,
                    avgSpeed: summaryTrackDetails?.macroAverageSpeed,
                    totalParkingTime: summaryTrackDetails?.totalParkingTime,
                    trackTime: summaryTrackDetails?.totalDrivingTime,
                    fuelCons: summaryTrackDetails?.totalFuelConsumption,
                    utilization: summaryTrackDetails?.utilization,
                    maxSpeed: summaryTrackDetails?.macroMaxSpeed,
                    stopCounter: summaryTrackDetails?.stopCounter ?? 0,
                    trackCounter: isTrailerCarOrPackage(vehicleType)
                      ? undefined
                      : summaryTrackDetails?.trackCounter ?? 0,
                  }}
                  preference={preferencesContext}
                />
              </div>
            )}
            <div className="track-details-down">
              {!streetViewEnabled && routeHistory?.routeStates?.length! > 1 && (
                <>
                  {areThereEvents &&
                    routeHistory?.routeStates[value].events.filter(
                      (event) =>
                        event.type.name !== "START" && event.type.name !== "END"
                    ) && (
                      <div
                        style={
                          trackBarCollapsed
                            ? { float: "left", marginTop: "-65px" }
                            : { float: "left", marginTop: "-46px" }
                        }
                      >
                        <EventDetail
                          events={handleEvents(
                            routeHistory?.routeStates[value].events
                          )}
                        />
                      </div>
                    )}
                  <div>
                    {routeHistory &&
                      prevRoute &&
                      routeHistory?.routeStates.length > 0 &&
                      !streetViewEnabled &&
                      !_.isEmpty(routeHistory) &&
                      !showTrackbar && (
                        <DetailsTrackBar
                          data={
                            !_.isEqual(routeHistory, prevRoute)
                              ? routeHistory
                              : prevRoute
                          }
                          collapseTrackbar={true}
                          sendPosition={setValue}
                          getCollapsed={setTrackBarCollapsed}
                          sendPositionTimestamp={(
                            positionTimestamp: string
                          ) => {
                            setPositionTimestamp(new Date(positionTimestamp));
                          }}
                          sendRouteId={(routeId: number) => {
                            setRouteIdSelected(routeId);
                          }}
                          defaultPosition={undefined}
                          drivers={drivers}
                          vehicleInfoView={vehicleView}
                        />
                      )}{" "}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };
